import React, { useState, useEffect, useRef } from "react";
import Header from "../layout/Header";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import Spinner from "../plugins/Spinner";
import useAPI from "../../hooks/useAPI";
import { upload_s3 } from './UploaderS3';
import Modal from "../layout/Modal";

import { PiBrainLight } from "react-icons/pi";
import { BsFiletypeTxt, BsFillCheckCircleFill } from "react-icons/bs";
import { FaSortUp, FaSortDown } from "react-icons/fa";
import { sendToast } from "../plugins/toastAll";
import { ToastContainer } from "react-toastify";


const AbsentMessage = () => {
  const [loading, setLoading] = useState(false);
  const [isAssistant, setIsAssistant] = useState(false);
  const [fileContent, setFileContent] = useState("");
  const [txtFile, setTxtFile] = useState(false);
  const [isAdvanced, setIsAdvanced] = useState(false);
  const [modalViewTxt, setModalViewTxt] = useState(false);


  const { urlAPI_1, urlAPI_2, urlAPI_3, fetchWithAuth } = useAPI();
  const {register,handleSubmit,reset,setValue,formState: { errors }} = useForm({});

  const urlFile = useRef("");
  const nameFile = useRef("");
  const sizeFile = useRef("");

  useEffect(() => {
    getAssistant();
  }, []);

  //#--> Guardar asistente
  const getAssistant = async () => {
    try {
      setLoading(true);
      const url = urlAPI_3 + "getAssistant";
      const options = { method: "GET", mode: "cors"};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      console.log("Result of the API: getAssistant", result);
      if (result.code === 0) {
        sendToast(result)
      } else {
        const {isAssistant,language,role,instructions,tag,prompt} = result.data;
        setIsAssistant(isAssistant)
        reset({
            isAssistant: isAssistant,
            language: language,
            role: role,
            instructions: instructions,
            tag: tag,
          },
          { keepDefaultValues: true }
        );
        if(prompt){
          fetch(prompt)
            .then(response => {return response.text()})
            .then(data => {
              setFileContent(data)
              // Aquí estableces el valor del input file usando setValue
              setValue("file", new File([data], prompt));
            })
            .catch(error => {console.error('Error del archivo:', error)});
          reset({file: prompt},{ keepDefaultValues: true })
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log('error---->', error);
    }finally{
      setLoading(false);
    }
  };
  //#--> Guardar asistente
  const putAssistant = async (values) => {
    const {language, role, instructions, tag} = values
    const objData = {
      isAssistant: isAssistant,
      language: language,
      role: role,
      instructions: instructions,
      tag: tag,
      prompt: urlFile.current
    };
    console.log('---------objData---------', objData)
    
    try {
      setLoading(true);
      const url = urlAPI_3 + "putAssistant";
      const options = { method: "PUT", mode: "cors", body: JSON.stringify(objData)};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      console.log("Result of the API: putAssistant", result);
      sendToast(result)
      setLoading(false);
    } catch (error) {
      console.log('error---->', error);
    }finally{
      setLoading(false);
    }
  };

  //#--> Control cuando se selecciona el archivo
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    onFileTxt(selectedFile)

    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setFileContent(e.target.result);
        setTxtFile(true)
      };
      reader.readAsText(selectedFile);
    }
  };

  //Convertir documento en base64
  const onFileTxt = async (file) => {
    var base64 = await convertTxtBase64(file);
    console.log("Documento base64=>", base64);

    await uploadToS3(file);
  };
  const convertTxtBase64 = (file) => {
    return new Promise((res, rej) => {
      var fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        res(fileReader.result);
      };
      fileReader.onerror = (error) => {
        rej(error);
      };
    });
  };

  //#--> Retornar la url de almacenamiento del archivo
  const getSignedUrl = async (key_name, size) => {
    try {
      key_name = encodeURIComponent(key_name);
      const url = urlAPI_1 + `getSignedURL/${key_name}/${size}`;
      const options = { method: "GET", mode: "cors"};
      const response = await fetchWithAuth(url, options);
      if(response.status === 200){
          let url_signed = await response.json();
          if(url_signed.error){
              return false;
          }
          return url_signed;
      }else{
          console.error('Error getting signed URL');
          return false;
      }
    } catch (error) {
      console.error('Error getting signed URL:', error);
      return false;
    }
  };
  const uploadToS3 = async (file) => {
    try {
      setLoading(true);
      const signedUrl = await getSignedUrl(file.name, file.size);
      if(signedUrl){
        urlFile.current = await upload_s3(file, signedUrl);
        nameFile.current = file.name;
        sizeFile.current = file.size;
        console.log('urlFile.current::', urlFile.current);
        console.log('nameFile.current::', nameFile.current);
        console.log('file.type::', file.type);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Error uploading to s3...', error);
      return false;
    }finally{
      setLoading(false);
    }
  };

  return (
    <>
      {loading ? (
        <div className="content-spinner">
          <Spinner />
        </div>
      ) : null}

      <div className="m-auto container-fluid p-0">
        <div className="col-xs-9 heading">
          <div>
            <h1>Asistente AI</h1>
          </div>
          <Header />
        </div>

        <div className="container-fluid mt-3 px-0 d-flex align-items-center" style={{ height: "670px", overflowY: "auto" }}>
          <section className="module-create-user myData content-asisstant" style={{ height: "auto", backgroundColor: "transparent", boxShadow: "none" }}>
            <div>
              <form onSubmit={handleSubmit(putAssistant)}>
                <div className="sections-my-data mb-0">
                  <div className="d-flex align-items-center">
                    <h2 className="d-flex align-items-center" style={{ fontSize: "1.4em", padding: "20px 20px 0px" }}>
                      <PiBrainLight style={{ fontSize: "27px", marginRight: "10px" }} /> Asistente AI
                    </h2>
                    <input
                      name="assistant"
                      id="assistant"
                      type="checkbox"
                      value={isAssistant}
                      className="cmn-toggle cmn-toggle-round-flat"
                      checked={isAssistant}
                      {...register("assistant")}
                      onChange={(e) => {setIsAssistant(e.target.checked)}}
                    />
                    <label className="ml-4 mb-0" htmlFor="assistant"></label>
                  </div>
                  
                  {isAssistant &&
                    <div className="module-section border-top-0 pb-0" id="product-basic">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="row">

                            <div className="col-md-12 m-auto p-0">
                              <div className="col-md-12 m-auto">
                                <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable mt-3">
                                  <label>Cargar archivo: </label>
                                  <p>Carga un archivo txt con las instrucciones y comportamientos que deseas que tenga el asistente virtual</p>
                                  <div className="su-control-group">
                                    <div className="text-center mt-3">
                                      {txtFile ? (
                                        <label htmlFor="file" className="btn btn-default pointer d-flez" style={{ fontSize: "1.2rem" }}>
                                          <BsFillCheckCircleFill style={{ color: "#5cb85c", fontSize: "1.2rem" }} className="mr-1"/>
                                          Archivo cargado
                                        </label>
                                      ) : (
                                        <label htmlFor="file" className="btn btn-default pointer d-flez" style={{ fontSize: "1.2rem" }}>
                                          <BsFiletypeTxt style={{ fontSize: "1.5rem" }} className="mr-1"/>
                                          Selecciona un archivo
                                        </label>
                                      )}
                                      <input
                                        type="file"
                                        id="file"
                                        name="file"
                                        accept=".txt"
                                        className="col-2-sm btn my-auto mb-1 d-none"
                                        {...register("file", {
                                          required: {
                                            value: prompt ? false : true,
                                            message: "Debes cargar un archivo para poder guardar.",
                                          },
                                        })}
                                        onChange={handleFileChange}
                                      />
                                    </div>
                                  </div>
                                  {errors.file && (<p className="text-danger mt-1 f-w-900 text-center">* {errors.file.message}</p>)}
                                  {fileContent && (
                                    <div className="text-center mt-3 mx-auto">
                                        <label 
                                          className="pointer"
                                          style={{fontSize: "1rem"}}
                                          onClick={() => setModalViewTxt(true)}
                                        >Ver archivo</label>
                                    </div>
                                  )}
                                </div>
                              </div>

                              <div className="col-md-12 mt-4 mx-auto">
                                <div 
                                  className="su-control su-with-prefix su-with-suffix su-segmentation-disable mt-3 text-center d-flex"
                                  onClick={() => {setIsAdvanced((data) => !data)}}
                                >
                                  <label className="pointer">Opciones avanzadas </label>
                                  {isAdvanced ? 
                                    <FaSortUp className="pointer ml-2 mr-1" style={{ fontSize: "18px", marginTop: "5px" }}/> :
                                    <FaSortDown className="pointer ml-2 mr-1" style={{ fontSize: "18px", marginBottom: "5px" }}/> 
                                  }
                                </div>
                              </div>
                            </div>

                            {isAdvanced && 
                              <>
                                <div className="col-md-6">
                                  <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable mt-3">
                                    <label>Idioma del asistente: </label>
                                    <p>Selecciona el idioma con el que el asistente virtual se comunicara con los clientes</p>
                                    <div className="su-control-group">
                                      <select
                                        name="language"
                                        id="language"
                                        className="form-control"
                                        defaultValue=""
                                        {...register("language")}
                                      >
                                        <option value="" disabled>
                                          Seleccione...
                                        </option>
                                        <option value="spanish" >
                                          Español
                                        </option>
                                        <option value="english" >
                                          Ingles
                                        </option>
                                      </select>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-6">
                                  <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable mt-3">
                                    <label>Rol del asistente: </label>
                                    <p>Este sera el rol que asumira el asistente virtual frente a los clientes</p>
                                    <div className="su-control-group">
                                      <textarea 
                                        className="form-control"
                                        style={{height: "2.6rem"}}
                                        name="role"
                                        id="role"
                                        {...register("role")}
                                      ></textarea>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-6">
                                  <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable mt-3">
                                    <label>Instrucciones del asistente: </label>
                                    <p>Redacta las instrucciones que quieres que el asistente virtual realice y se comporte</p>
                                    <div className="su-control-group">
                                      <textarea 
                                        className="form-control"
                                        style={{height: "2.6rem"}}
                                        name="instructions"
                                        id="instructions"
                                        {...register("instructions")}
                                      ></textarea>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-6">
                                  <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable mt-3">
                                    <label>Etiqueta cuando el cliente requiera atención humana: </label>
                                    <p>Esta será la etiqueta que el asistente entendera para asignar la conversación a un humano.</p>
                                    <div className="su-control-group">
                                      <input 
                                        type="text"
                                        className="form-control"
                                        name="tag"
                                        id="tag"
                                        {...register("tag")}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </>
                            }

                          </div>
                        </div>
                      </div>
                    </div>
                  }

                  <footer className="mx-0">
                    <div className="row">
                      <div className="col-sm-6"></div>
                      <div className="col-sm-6 right">
                        <input
                          className="btn btn-lg btn-info"
                          // disabled={!assistant}
                          value="Aceptar"
                          type="submit"
                          />
                      </div>
                    </div>
                  </footer>
                </div>
              </form>
            </div>
          </section>
        </div>
      </div>

      <Modal
        status={modalViewTxt}
        changeStatus={setModalViewTxt}
        title="Contenido del archivo"
        width={"750px"}
      >
        <Content>
          <form style={{ width: "100%" }}>
            <div>
              <pre className="text-content mb-0">{fileContent}</pre>
            </div>
          </form>

          <div className="row justify-content-end bd col-12 mt-4">
            <a href="javascript:;" className="btn btn-lg btn-default mr-1" onClick={() => setModalViewTxt(false) }>
              Cerrar
            </a>
          </div>
        </Content>
      </Modal>

      
      
      <ToastContainer 
        position="top-center"
        autoClose={52000}
        // autoClose={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        // draggable
        theme={JSON.parse(localStorage.getItem("THEME")) ? "dark" : "light"}
        toastClassName="custom-toast" // Aplica estilos personalizados a cada toast individualmente
        bodyClassName="custom-toast-body"
      />
    </>
  );
};

export default AbsentMessage;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  h1 {
    font-size: 42px;
    font-weight: 700;
    margin-bottom: 10px;
    text-align: center;
  }
`;