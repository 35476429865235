import React from 'react';
import { BsStars } from 'react-icons/bs';
import { FaUserAstronaut } from 'react-icons/fa';
import { IoClose } from "react-icons/io5";

const listAnswerAI = ({listAnswer, setListAnswer, setInputStr, inputRef, setContenMessage, setMsgIA}) => {
  return (
    <div className="content-answers cont py-0 bg-white position-absolute" style={{ width:"auto", bottom:"4.1rem", right:"0rem", borderRadius:"12px", border:"1px solid #ccc" }}>
      {listAnswer-length <= 0 ? (
        <header className="p-3 d-flex justify-content-between position-relative" style={{ borderRadius:"12px" }}>
          <h5 className="m-0 mr-3">Sin respuestas sugeridas, intenta nuevamente.</h5>
          <IoClose className="position-absolute pointer mt-1 mr-1" style={{fontSize: "1.4rem", right: "0", top: "0"}} onClick={() => setMsgIA(false)}/>
        </header>
      ) : (
        <>
          <header className="pl-3 d-flex justify-content-between" style={{borderTopLeftRadius: "12px", borderTopRightRadius: "12px"}}>
            <h4 className="m-0">Respuestas sugeridas</h4>
            <IoClose className="pointer" style={{fontSize: "1.4rem"}} onClick={() => setMsgIA(false)}/>
          </header>

          <div className="px-0 list-quickly-answers" style={{ height: "auto", overflow: "auto" }}>
          {/* <div className="px-3 list-quickly-answers" style={{ height: "176px", overflow: "auto" }}> */}
            <ul>
              {listAnswer.map((answer, q) => (
                <li
                  key={q}
                  id="listAnswer"
                  className="pointer py-2 pl-2 pr-4 d-flex justify-content-between align-items-center"
                  style={{borderBottom:"1px solid rgb(0,0,0,.05)", fontSize:"0.9em", borderRadius:"12px"}}
                  onClick={() => {
                    setListAnswer(false);
                    setMsgIA(false);
                    setInputStr(answer)
                    setContenMessage(answer);
                    
                    if (inputRef.current) {
                      inputRef.current.focus();
                    }
                  }}
                >
                  <div className="d-flex align-items-center">
                  {/* <FaUserAstronaut className="icon-astronaut mr-2" /> {answer} */}
                  <BsStars className="icon-astronaut mr-2" /> {answer}
                  </div>
                </li>
              ))}
            </ul>
            
          </div>
        </>
      )}
    </div>
  );
};

export default listAnswerAI;