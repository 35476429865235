import React from 'react';
import styled from "styled-components";
import Avvvatars from "avvvatars-react";
import { FaAngleLeft, FaSpinner } from 'react-icons/fa';
import { BsSearch } from "react-icons/bs";
import { IoMdClose } from "react-icons/io";

const ListContacts = ({
  setNewChat,
  setSearchConversation,
  setSearchPersons,
  setClassChat,
  searchConversation,
  searchPersons,
  persons,
  onSearchPersons,
  onStartChat,
  getIsDark,
  loadingContacts,
  searchQuery
}) => {

  const clearSearch = () => {
    setSearchPersons("");
    searchQuery(true);
  };

  return (
    <>
      <header className="message" style={{ height: "7%" }}>
        <div className="d-flex align-items-center h-100">
          <FaAngleLeft 
            className="pointer icon-angle-left" 
            style={{ fontSize: "22px" }} 
            onClick={() => {
              setNewChat(false); 
              setSearchConversation(false)
            }}
          />
          <h5 className="ml-2 mb-0">{searchConversation ? "Buscar chat" : "Nuevo chat"}</h5>
        </div>
      </header>

      <div id="content-list-chats" className="pt-1 content-list-chats-newchat" style={{ height: "93%"}}>
        <form className="form-inline pb-2 justify-content-center" style={{ height: "10%" }}   onSubmit={(e) => {
            e.preventDefault();
            searchQuery();
          }}>
          {loadingContacts ? (
            <>
              <div className="su-control mt-1" style={{ width: "90%" }}>
                <input
                  className="form-control w-100"
                  type="text"
                  placeholder="Búsqueda"
                  value={searchPersons}
                  disabled
                  onChange={(e) => {
                    setSearchPersons(e.target.value);
                    onSearchPersons(e);
                  }}
                />
                {searchPersons && (
                  <ClearIcon 
                    onClick={clearSearch}
                  />
                )}
                <button
                  className="ml-1 btn btn-info"
                  type="button"
                  id="buttonSearchProductBack"
                  style={{ height: "34px" }}
                  onClick={() => {
                    searchQuery();
                  }}
                  >
                  <BsSearch />
                </button>
              </div>
            </>
          ) : (
            <>
              <div className="su-control mt-1" style={{ width: "90%" }}>
                <input
                  className="form-control w-100"
                  type="text"
                  placeholder="Búsqueda"
                  value={searchPersons}
                  onChange={(e) => {
                    setSearchPersons(e.target.value);
                    onSearchPersons(e);
                  }}
                />
                {searchPersons && (
                  <ClearIcon 
                    onClick={clearSearch}
                  />
                )}
                <button
                  className="ml-1 btn btn-info"
                  type="button"
                  id="buttonSearchProductBack"
                  style={{ height: "34px" }}
                  onClick={() => {
                    searchQuery();
                  }}
                  >
                  <BsSearch />
                </button>                
              </div>
            </>
          )}
        </form>
        <ul className="chat-list" style={{ height: "90%", overflow: "auto" }}>
          {loadingContacts && (
            <>
              <h4 className="text-center">Cargando registros...</h4>
              <span className="d-flex justify-content-center">
                <FaSpinner
                  id="spinner-list-conv"
                  className="fa-spin"
                  style={{ fontSize: "23px" }}
                />
              </span>
            </>
          )}
          {(persons && persons.length) ? (persons.map((user, k) => {
            if (user) {
              const initialWords = user.name.split(" ");
              const firstWord = initialWords[0];
              const secondWord = initialWords[1];
              var icon = "";
              if (user.lastName) {
                const initialWordsLastName = user.lastName.split(" ");
                const firstWordLastName = initialWordsLastName[0];
                icon = firstWord[0] + firstWordLastName[0];
              } else {
                if (secondWord === undefined) {
                  icon = firstWord[0];
                } else {
                  icon = firstWord[0] + secondWord;
                }
              }
            }

            return (
              <li
                key={k}
                className="d-flex"
                style={{ alignItems: "center" }}
                onClick={() => {
                  onStartChat(user._id)
                  setClassChat(true);
                  getIsDark()
                }}
              >
                <div className="col-2" style={{ alignItems: "center", textAlign: "end" }}>
                  <IconList className="m-0">
                    <Avvvatars value={icon} />
                  </IconList>
                </div>
                <div className="px-2 col-8">
                  <div>
                    <strong className="name-contact">{user.name}{" "}{user.lastName && user.lastName}</strong>
                  </div>
                </div>
              </li>
            )})
          ) : (
            !loadingContacts && (<h2 className="text-center mt-3">No existen registros</h2>)
          )}
        </ul>
      </div>
    </>
  );
};

export default ListContacts;

const IconList = styled.div`
  margin-bottom: 0px;

  div {
    height: 37px;
    width: 37px;
    border-radius: 50%;
    // box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.7);
    margin: 4px auto;
  }

  p {
    font-size: 18px;
    font-weight: 600;
  }
`;

const ClearIcon = styled(IoMdClose)`
  margin-left: -1.7rem;
  margin-top: 0.2rem;
  font-size: 30px;
  cursor: pointer;
  color: gray;
  
  &:hover {
    color: black;
  }
`;