import React, { Fragment, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import dayjs from "dayjs";
import uuid from "react-uuid";
import styled from "styled-components";
import Modal from "../layout/Modal";
import useAPI from "../../hooks/useAPI";
import usePage from "../../hooks/usePage";
import Pagination from "../plugins/Pagination";
import Spinner from "../plugins/Spinner";
import { useNavigate } from "react-router-dom";
import Header from "../layout/Header";
import Navigation from "../layout/Navigation";
import logoLogin from "../../imgs/logo-login.png";
import Countries from "../plugins/Countries.json";
import Select from "react-select";
import TimezoneSelect from 'react-timezone-select'
import { FaAngleLeft } from "react-icons/fa";
import { BsSearch, BsPencilFill, BsCheckCircle, BsExclamationCircle, BsXCircle, BsQuestionCircle } from "react-icons/bs";
import { is } from "date-fns/locale";
import {sendToast} from "../plugins/toastAll";
import { ToastContainer } from "react-toastify";
import { useQuery } from '@tanstack/react-query';


const Companies = () => {
  const [companies, setCompanies] = useState([]);
  const [tableCompanies, setTableCompanies] = useState([]);
  const [company, setCompany] = useState({});
  const [types, setTypes] = useState([]);
  const [mode, setMode] = useState("list");
  const [validation, setValidation] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modeEdit, setModeEdit] = useState(false);
  const [search, setSearch] = useState("");
  const [plans, setPlans] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [permissionsSelected, setPermissionsSelected] = useState([]);
  // const [selectedTimezone, setSelectedTimezone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone)
  const [selectedTimezone, setSelectedTimezone] = useState({value: "America/Bogota", label: "(GMT-5:00) Bogota, Lima, Quito", offset: -5, abbrev: "COT", altName: "Colombia Standard Time"})
  // const [selectedTimezone, setSelectedTimezone] = useState({})
  const [selectedCountry, setSelectedCountry] = useState("");
  const [errorCountrie, setErrorCountrie] = useState(false);
  
  const [modalCreatePlan, setModalCreatePlan] = useState(false);
  const [planId, setPlanId] = useState("")
  const [selectedPlan, setSelectedPlan] = useState("");
  const [editPlan, setEditPlan] = useState(false);
  const [idPlan, setIdPlan] = useState("");
  const [isPlan, setIsPlan] = useState(false);
  
  const [idCompany, setIdCompany] = useState("");

  const [isLimitCheck, setIsLimitCheck] = useState(false);

  const [isDark, setIsDark] = useState(false);
  const [integrationUrls, setIntegrationUrls] = useState(['']);
  const inputIntegrationsUrls = useRef([]);

  const { urlAPI_1, urlAPI_2, fetchWithAuth } = useAPI();
  const { page, byPage, setPage, resetPage } = usePage();

  //#Estado para dark mode
  const {data: isDarkTheme} = useQuery({
    queryKey: ['isDarkTheme'],
    queryFn: () => {
      const value = localStorage.getItem('IS_DARK');
      return value === 'true';
    }
  });

  const listPermissions = [
    { value: 'interestedBuying', label: 'Interés de compra' },
    { value: 'feelings', label: 'Análisis de Sentimientos' }
  ];
  const roleDescription = localStorage.getItem("USER_ROLEDESCRIPTION");
  const user = localStorage.getItem("USER_NAME");

  if (roleDescription !== "SuperAdmin") {
    return navigate("/");
  }

  // const {watch,register,handleSubmit,formState: { errors },reset,} = useForm();
  const formCompany = useForm({
    defaultValues: {
      name: "",
      identification: "",
      typeIdentification: "",
      email: "",
      idPhone: "",
      tokenWhatsapp: "",
      uuid: uuid(),
      webhook: "",
      tokenWebhook: "",
      idWspBusinessAcount: "",
      idApp: "",
      plan: "",
      displayPhoneNumber: "",
      qualityRating: "",
      verifiedName: "",
      currentLimit: "",
    },
  });
  const formPlan = useForm();

  useEffect(() => {
    getCompanies();
    getTypeIdentification();
    getPlans();
  }, []);

  const getQualityRatingDisplay = (qualityRating) => {
    switch (qualityRating) {
      case "GREEN":
        return { text: "Alta", color: "#28a745", icon: <BsCheckCircle /> };
      case "YELLOW":
        return { text: "Media", color: "#ffc107", icon: <BsExclamationCircle /> };
      case "RED":
        return { text: "Baja", color: "#dc3545", icon: <BsXCircle /> };
      default:
        return { text: "Desconocida", color: "#6c757d", icon: <BsQuestionCircle /> };
    }
  };

  const getCurrentLimitDisplay = (currentLimit) => {
    switch (currentLimit) {
      case "TIER_50":
        return { text: "50"};
      case "TIER_250":
        return { text: "250"};
      case "TIER_1K":
        return { text: "1000"};
      case "TIER_10K":
        return { text: "10000"};
      case "TIER_100K":
        return { text: "100000"};
      case "TIER_UNLIMITED":
        return { text: "Ilimitado"};                        
      default:
        return { text: "Desconocido"};
    }
  };

  const getCompanies = async () => {
    try {
      setLoading(true);
      const url = urlAPI_2 + "companies";
      const options = { method: "GET", mode: "cors"};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      setCompanies(result.data);
      setTableCompanies(result.data);
      if (result.type === "error") {
        sendToast(result)
      } else if (result.message === 'Tu petición no tiene cabecera de autorización') {
        sendToast({type: "info", message: "Tu sesion ha caducado, verifica si alguien mas se ha conectado con tus credenciales"})
        navigate('/');
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getTypeIdentification = async () => {
    try {
      // setLoading(true);
      const url = urlAPI_2 + "typesDocument";
      const options = { method: "GET", mode: "cors"};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      setTypes(result.data);
      if (result.code === 0) {
        sendToast(result)
      }
      // setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const postCompany = async (inputs) => {
    if (!selectedPlan) {
      setIsPlan(true)
      return
    }
    setIsPlan(false)
    var objFinal = inputs;
    objFinal.country = selectedCountry.name
    objFinal.timezone = selectedTimezone
    objFinal.plan = selectedPlan.id

    if (permissionsSelected.length > 0) {
      objFinal.permissions = permissionsSelected;
    }
    
    if (inputIntegrationsUrls.current && inputIntegrationsUrls.current.length) {
      let newUrlsParsed = [];
      for(let url_integration of inputIntegrationsUrls.current){
        if(url_integration){
          newUrlsParsed.push(url_integration);
        }
      }
      objFinal.integrations = newUrlsParsed;
    }else{
      objFinal.integrations = [];
    }

    try {
      setLoading(true);
      const url = urlAPI_2 + "companies";
      const options = { method: "POST", mode: "cors", body: JSON.stringify(objFinal)};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();

      console.log("Response of the API: Post Company", result);
      sendToast(result)
      if (result.type === "success") {
        setIntegrationUrls(['']);
        inputIntegrationsUrls.current = [];
        formCompany.reset();
        getCompanies();
        setMode("list");
        setPermissions([]);
        setPermissionsSelected([]);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getCompany = async (id) => {
    try {
      setLoading(true);
      const url = urlAPI_2 + "companiesByAdmin/" + id;
      const options = { method: "GET", mode: "cors"};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();      
      if (result.code === 0) {
        sendToast(result)
      } else if (!result.data) {
        setValidation(true);
        setCompany([]);
      } else {
        setCompany(result.data[0]);
        setValidation(false);
        const {name,identification,documentTypeId,email,status,idPhone,tokenWhatsapp,webhook,tokenWebhook,idWspBusinessAcount,idApp,plan,country,timezone, displayPhoneNumber, qualityRating, verifiedName, currentLimit, permissions} = result.data[0];
        if (country) {
          const countrieSelect = Countries.filter(countrie => countrie.name === country);
          setSelectedCountry(countrieSelect[0]);
        }
        if (timezone) {
          setSelectedTimezone(timezone)
        }
        if(plan) {
          const planSelected = plans.filter(p => p.id === plan);
          setSelectedPlan(planSelected[0]);
          setPlanId(plan);
        }
        if (permissions.length > 0) {
          setPermissions(permissions);
        };

        if (result.data[0].integrations && result.data[0].integrations.length) {
          setIntegrationUrls(result.data[0].integrations);
          inputIntegrationsUrls.current = result.data[0].integrations;
        }else{
          setIntegrationUrls(['']);
          inputIntegrationsUrls.current = [];
        }

        setIdCompany(result.data[0]._id);

        formCompany.reset(
          {
            name,
            identification,
            typeIdentification: documentTypeId,
            email,
            status,
            idPhone,
            tokenWhatsapp,
            webhook,
            tokenWebhook,
            idWspBusinessAcount,
            idApp,
            displayPhoneNumber,
            qualityRating,
            verifiedName,
            currentLimit,
          },
          { keepDefaultValues: true }
        );
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const putCompany = async (inputs) => {
    const id = company._id;
    var values = inputs;
    values.country = selectedCountry.name
    values.timezone = selectedTimezone
    values.plan = selectedPlan.id
    values.permissions = permissions;
    values._id = idCompany
    if (inputIntegrationsUrls.current && inputIntegrationsUrls.current.length) {
      let newUrlsParsed = [];
      for(let url_integration of inputIntegrationsUrls.current){
        if(url_integration){
          newUrlsParsed.push(url_integration);
        }
      }
      values.integrations = newUrlsParsed;
    }else{
      values.integrations = [];
    }
    try {
      setLoading(true);
      const url = urlAPI_2 + "companies/" + id;
      const options = { method: "PUT", mode: "cors", body: JSON.stringify(values)};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      console.log("Result of the API: Put Commpany", result);
      sendToast(result)
      setMode("list");
      setModeEdit(false);
      getCompanies();
      setLoading(false);
      setPermissions([]);
      setPermissionsSelected([]);
    } catch (error) {
      console.log(error);
    }
  };

  const getPlans = async () => {
    try {
      // setLoading(true);
      const url = urlAPI_2 + "plans";
      const options = { method: "GET", mode: "cors"};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      setPlans(result.data);
      if (result.code === 0) {
        sendToast(result)
      }
      // setLoading(false);
    } catch (error) {
      console.log(error);
    }
  }

  const formatCompanies = [...companies].reverse();
  const navigate = useNavigate();  

  const searchQuery = (searchTerm) => {
    const searchResults = tableCompanies.filter((element) => {
      if (element.name.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
        element.contactMainDesc.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
        element.identification.toString().toLowerCase().includes(searchTerm.toLowerCase())
      ) {
        return element;
      }
    });
    setCompanies(searchResults);
    if (searchResults.length === 0) {
      setValidation(true);
    } else {
      setValidation(false);
    }
  };

  //#--> Almacenar la zona horaria seleccionada
  const handleChangeTimezone = async (e) => {
    setSelectedTimezone(e);
  }

  //#--> Funcion para almacenar el país
  const handleChangeCountries = (selectedCountry) => {
    setErrorCountrie(false);
    setSelectedCountry(selectedCountry);
  };

  const getIsDark = () => {
    const dark = localStorage.getItem("IS_DARK");
    setIsDark(dark);
  }

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? 'lightblue' : '#fff',
      color: state.isSelected ? '#333' : '#333',
      '&:hover': {
        backgroundColor: 'lightgray',
        color: '#333',
        cursor: 'pointer',
      },
    }),
  };

  const customStylesDark = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#455a64' : '#202c33',
      color: state.isSelected ? 'white' : 'white',
      '&:hover': {
        backgroundColor: '#374954',
        color: 'white',
        cursor: 'pointer',
      },
    }),
  };

  const { status } = formCompany.watch();
  const statusBool = Boolean(status);
  const error = formCompany.formState.errors;
  const error2 = formPlan.formState.errors;


  //#--> Funcion cuando seleccionamos plan
  const handleChangePlans = (selectedPlan) => {
    // setErrorCountrie(false);
    setIsPlan(false)
    setSelectedPlan(selectedPlan);
    formPlan.reset({
      planName: selectedPlan.name,
      planDescription: selectedPlan.description,
      planPrice: selectedPlan.price,
      planNumberAgents: selectedPlan.number_agents,
      planNumberAdmin: selectedPlan.number_administrators,
      additionalAgentPrice: selectedPlan.additionalAgentPrice,
      planNotificationPrice: selectedPlan.type[0].price,
      planChatPrice:selectedPlan.type[1].price,
      planBotPrice: selectedPlan.type[2].price,
      planConversationPrice: selectedPlan.type[3].price,
      isLimit: selectedPlan.isLimit,
      limit: selectedPlan.limit
    })
  };

  const postPlan = async (values) => {
    const {
      planName,
      planDescription,
      planPrice,
      planNumberAgents,
      planNumberAdmin,
      additionalAgentPrice,
      planNotificationPrice,
      planChatPrice,
      planBotPrice,
      planConversationPrice,
      isLimit,
      limit
    } = values;    

    const objPlan = {
      id: uuid(),
      name: planName,
      description: planDescription,
      price: planPrice,
      number_agents: planNumberAgents,
      number_administrators: planNumberAdmin,
      additionalAgentPrice: additionalAgentPrice,
      type: [
        {
          description: "Mensaje de notificacion masiva",
          id: 1,
          name: "notificación",
          price: planNotificationPrice
        },
        {
          description: "Mensaje de chat multilinea",
          id: 2,
          name: "chat",
          price: planChatPrice
        },
        {
          description: "Mensaje de bot",
          id: 3,
          name: "bot",
          price: planBotPrice
        },
        {
          description: "Conversaciones",
          id: 4,
          name: "conversación",
          price: planConversationPrice
        }
      ],
      isLimit: isLimit,
      limit: limit && isLimit ? Number(limit) : 0
    }
    setPlanId(objPlan.id)

    try {
      setLoading(true);
      const url = urlAPI_2 + "plan";
      const options = { method: "POST", mode: "cors", body: JSON.stringify(objPlan)};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();

      console.log("-->Response of the API: post plan: : :", result);
      setLoading(false);
      setModalCreatePlan(false)
      await getPlans()
      setSelectedPlan(result.data)
      formPlan.reset();
      sendToast(result)
    } catch (error) {
      console.log(error);
    }
  }

  const putPlan = async (values) => {
    const {
      planName,
      planDescription,
      planPrice,
      planNumberAgents,
      planNumberAdmin,
      additionalAgentPrice,
      planNotificationPrice,
      planChatPrice,
      planBotPrice,
      planConversationPrice,
      isLimit,
      limit
    } = values;

    const objPlan = {
      id: idPlan,
      name: planName,
      description: planDescription,
      price: planPrice,
      number_agents: planNumberAgents,
      number_administrators: planNumberAdmin,
      additionalAgentPrice: additionalAgentPrice,
      type: [
        {
          description: "Mensaje de notificacion masiva",
          id: 1,
          name: "notificación",
          price: planNotificationPrice
        },
        {
          description: "Mensaje de chat multilinea",
          id: 2,
          name: "chat",
          price: planChatPrice
        },
        {
          description: "Mensaje de bot",
          id: 3,
          name: "bot",
          price: planBotPrice
        },
        {
          description: "Conversaciones",
          id: 4,
          name: "conversación",
          price: planConversationPrice
        }
      ],
      isLimit: isLimit,
      limit: limit && isLimit ? Number(limit) : 0
    }

    try {
      setLoading(true);
      const url = urlAPI_2 + "plan/" + idPlan;
      const options = { method: "PUT", mode: "cors", body: JSON.stringify(objPlan)};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      console.log("-->Response of the API: PUT plan: : :", result);
      setLoading(false);
      setModalCreatePlan(false)
      await getPlans()
      setSelectedPlan(result.data)
      formPlan.reset();
      sendToast(result)
    } catch (error) {
      console.log(error);
    }
  }

  const handleIntegrationUrlChange = (index, event) => {
    const newUrls = [...integrationUrls];
    newUrls[index] = event.target.value;
    setIntegrationUrls(newUrls);
    inputIntegrationsUrls.current = newUrls;
    
  };

  const addIntegrationUrlField = () => {
    const newUrls = [...integrationUrls];
    let newUrlsParsed = [];
    for(let url_integration of newUrls){
      if(url_integration){
        newUrlsParsed.push(url_integration);
      }
    }
    newUrlsParsed.push('');
    setIntegrationUrls(newUrlsParsed);
  };

  const customStylesSelect = {
    menuList: styles => ({
      ...styles,
      background: isDarkTheme ? "#202c33" : "#fff"
    }),
    option: (styles, {isFocused, isSelected}) => ({
      ...styles,
      background: isFocused
          ? isDarkTheme ? '#374957' : "#cce7ff"
          : isSelected
              ? isDarkTheme ? '#374957' : "#cce7ff"
              : undefined,
      zIndex: 1
    }),
    menu: base => ({
      ...base,
      zIndex: 100
    })
  };

  const handleChangePermissions = data => {
    console.log('Permisos seleccionados=====>>',data);
    setPermissionsSelected(data);
    setPermissions(data);
  };

  return (
    <Fragment>
      {loading ? (
        <div className="content-spinner">
          <Spinner />
        </div>
      ) : null}
      {/* <Navigation user={user} roleDescription={roleDescription} /> */}
      {mode === "list" ? (
        <div className="m-auto container-fluid p-0 h-100 px-3" style={{overflow: "auto"}}>
          <div className="col-xs-9 heading justify-content-between">
            <div>
              <h1>Empresas</h1>
              <input
                type="button"
                className="btn btn-default"
                value="Agregar Nuevo"
                onClick={() => {
                  setMode("create");
                  formCompany.reset();
                  setSelectedPlan("")
                  setPlanId("")
                  getIsDark()
                }}
              />
            </div>

            <Header />
          </div>
          <>
            <header>
              <div style={{ float: "right" }}>
                <form className="form-inline">
                  <div className="su-control mt-1">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Búsqueda"
                      value={search}
                      onChange={(e) => {
                        setSearch(e.target.value);
                        searchQuery(e.target.value);
                        resetPage();
                      }}
                    />
                    <button
                      className="btn btn-info"
                      type="button"
                      id="buttonSearchProductBack"
                      style={{ height: "34px" }}
                    >
                      <BsSearch />
                    </button>
                  </div>
                </form>
              </div>
            </header>

            <div className="container-fluid mt-3 px-0" style={{ height: "580px", overflow: "auto" }}>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th style={{ width: "auto" }}>Nombre</th>
                    <th>Tipo de identificación</th>
                    <th>Identificación</th>
                    <th>Contacto principal</th>
                    <th>Plan</th>
                    <th>Creación</th>
                  </tr>
                </thead>
                <tbody>
                  {formatCompanies.length > 0 &&
                    formatCompanies
                      .slice(
                        (page - 1) * byPage,
                        (page - 1) * byPage + byPage
                      )
                      .map((companie) => (
                        <tr key={companie._id}>
                          <td
                            style={{ width: "auto" }}
                            className="name-companie"
                            onClick={() => {
                              getCompany(companie._id);
                              // getCompanySelected(companie._id);
                              setMode("create");
                              setModeEdit(true);
                            }}
                          >
                            <strong>{companie.name}</strong>
                          </td>
                          <td>{companie.documenyTypeDesc}</td>
                          <td>{companie.identification}</td>
                          <td>{companie.email}</td>
                          <td>
                            {
                              companie.plan === "1" ? 'Básico' : companie.plan === "2" ? 'Estandar' : companie.plan === "3" ? 'Notificaciones' : companie.plan === "4" ? 'Profesional' : 'Sin plan'
                            }
                            </td>
                          <td>
                            {/* Activo desde{" "} */}
                            {dayjs(companie.created)
                              .locale("es")
                              .format("DD MMMM YYYY")}
                          </td>
                        </tr>
                      ))}
                </tbody>
              </table>
              {validation && (
                <h1 className="text-center mt-3">
                  No existen registros en el sistema
                </h1>
              )}
            </div>

            <footer>
              <div className="row">
                <div className="col-md-8"></div>

                <div className="col-md-4">
                  <Pagination
                    totalData={formatCompanies.length}
                    byPage={byPage}
                    setPage={setPage}
                  />
                </div>
              </div>
            </footer>
          </>
        </div>
      ) : (
        <div className="h-100">
          <div className="row mx-auto container-fluid p-0 justify-content-between border-top-0 border-right-0 border-left-0 border-secondary mb-3" style={{height:"6%"}}>
              <div style={{ height: "45px", lineHeight: "95px" }}>
                <button
                  onClick={() => {
                    setIntegrationUrls(['']);
                    inputIntegrationsUrls.current = [];
                    setMode("list");
                    formCompany.reset();
                    setModeEdit(false);
                  }}
                  type="button"
                  className="btn btn-left col-1-sm-3 my-auto position-absolute"
                >
                  <FaAngleLeft /> Empresas
                </button>
              </div>

              <Header />
          </div>

          <section className="module-create-user" style={{height: "90%"}}>
            <header className="message d-flex" style={{height: "7%"}}>
              <div id="tittleFormProduct" className="w-50">
                <h1>{modeEdit ? "Editar empresa" : "Crear empresa"}</h1>
              </div>
              <div>
              </div>
            </header>

            <div style={{height: "90%"}}>
              <form className="h-100" onSubmit={modeEdit ? formCompany.handleSubmit(putCompany) : formCompany.handleSubmit(postCompany)}>
                <div className="module-section" id="product-basic" style={{height: "74vh", overflow: "auto"}}>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable">
                            <label htmlFor="select">
                              Tipo de documento:{" "}
                            </label>
                            <select
                              name="select"
                              id="select"
                              className="form-control"
                              {...formCompany.register("typeIdentification", {
                                required: {
                                  value: true,
                                  message:
                                    "El tipo de identificación es obligatoria.",
                                },
                              })}
                            >
                              <option value="" key="0" disabled selected>
                                Seleccione...
                              </option>
                              {types &&
                                types.map((type) => (
                                  <option value={type._id} key={type._id}>
                                    {type.description}
                                  </option>
                                ))}
                            </select>
                          </div>
                          {error?.typeIdentification && (<p className="text-danger mt-1 f-w-900">* {error.typeIdentification.message}</p>)}
                        </div>

                        <div className="col-md-6">
                          <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable">
                            <label>Numero de documento: </label>
                            <div className="su-control-group">
                              <input
                                className="form-control"
                                id="uuid"
                                type="number"
                                {...formCompany.register("identification", {
                                  required: {
                                    value: true,
                                    message:
                                      "El numero de identificación es obligatorio.",
                                  },
                                  pattern: {
                                    value: /\S+/,
                                    message: "No puede ser solo espacios en blanco",
                                  },
                                })}
                              />
                            </div>
                            {error?.identification && (
                              <p className="text-danger mt-1 f-w-900">
                                * {error.identification.message}
                              </p>
                            )}
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable">
                            <label>Nombre/Razón social : </label>
                            <div className="su-control-group">
                              <input
                                className="form-control"
                                id="companyName"
                                type="text"
                                {...formCompany.register("name", {
                                  required: {
                                    value: true,
                                    message:
                                      "El nombre de la empresa es obligatorio.",
                                  },
                                  pattern: {
                                    value: /\S+/,
                                    message: "No puede ser solo espacios en blanco",
                                  },
                                })}
                              />
                            </div>
                            {error?.name && (
                              <p className="text-danger mt-1 f-w-900">
                                * {error.name.message}
                              </p>
                            )}
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable">
                            <label>Correo electronico: </label>
                            <div className="su-control-group">
                              <input
                                className="form-control"
                                id="companyEmail"
                                type="email"
                                {...formCompany.register("email", {
                                  required: {
                                    value: true,
                                    message: "El correo electrónico es requerido.",
                                  },
                                  pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                    message: "El formato del correo no es correcto",
                                  },
                                })}
                              />
                            </div>
                            {error?.email && (
                              <p className="text-danger mt-1 f-w-900">
                                * {error.email.message}
                              </p>
                            )}
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable">
                            <label>Token whatsapp: </label>
                            <div className="su-control-group">
                              <input
                                className="form-control"
                                id="tokenWhatsapp"
                                type="text"
                                {...formCompany.register("tokenWhatsapp", {
                                })}
                              />
                            </div>
                            {error?.tokenWhatsapp && (<p className="text-danger mt-1 f-w-900">* {error.tokenWhatsapp.message}</p>
                            )}
                          </div>
                        </div>

                        {modeEdit ? (
                          <>
                            <div className="col-md-6">
                              <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable">
                                <label>URL webhook: </label>
                                <div className="su-control-group">
                                  <input
                                    className="form-control"
                                    id="webhook"
                                    name="webhook"
                                    type="text"
                                    {...formCompany.register("webhook")}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable">
                                <label>Token webhook: </label>
                                <div className="su-control-group">
                                  <input
                                    className="form-control"
                                    id="tokenWebhook"
                                    name="tokenWebhook"
                                    type="text"
                                    {...formCompany.register("tokenWebhook")}
                                  />
                                </div>
                              </div>
                            </div>
                          </>
                        ) : null}

                        <div className="col-md-6">
                          <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable">
                            <label>ID teléfono: </label>
                            <div className="su-control-group">
                              <input
                                className="form-control"
                                id="idPhone"
                                type="text"
                                {...formCompany.register("idPhone", {
                                })}
                              />
                            </div>
                            {error?.idPhone && (<p className="text-danger mt-1 f-w-900">* {error.idPhone.message}</p>
                            )}
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable">
                            <label>ID cuenta whatsapp business: </label>
                            <div className="su-control-group">
                              <input
                                className="form-control"
                                id="idWspBusinessAcount"
                                type="text"
                                {...formCompany.register("idWspBusinessAcount", {
                                })}
                              />
                            </div>
                            {error?.idWspBusinessAcount && (<p className="text-danger mt-1 f-w-900">* {error.idWspBusinessAcount.message}</p>
                            )}
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable">
                            <label>ID de la app: </label>
                            <div className="su-control-group">
                              <input
                                className="form-control"
                                id="idApp"
                                type="text"
                                {...formCompany.register("idApp", {
                                  required: {
                                    value: true,
                                    message: "El ID de la app es requerido.",
                                  },
                                  pattern: {
                                    value: /\S+/,
                                    message: "No puede ser solo espacios en blanco",
                                  },
                                })}
                              />
                            </div>
                            {error?.idApp && (<p className="text-danger mt-1 f-w-900">* {error.idApp.message}</p>
                            )}
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable ">
                            <label>País:</label>
                            <Select
                              styles={customStylesSelect}
                              className="form-control select-timezone"
                              placeholder="Seleciona un país"
                              value={selectedCountry}
                              onChange={handleChangeCountries}
                              options={Countries}
                              getOptionLabel={(option) => option.name}
                              getOptionValue={(option) => option.name}
                            />
                          </div>
                          {errorCountrie && (
                              <p className="text-danger mt-1 f-w-900">
                                * El país es requerido.
                              </p>
                            )}
                        </div>

                        <div className="col-md-6">
                          <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable ">
                            <label>Zona horaria:</label>
                            <TimezoneSelect
                              styles={customStylesSelect}
                              className="form-control select-timezone"
                              value={selectedTimezone}
                              onChange={(e) => handleChangeTimezone(e)}
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable ">
                            <label htmlFor="select">Tipo de plan:{" "}</label>
                            <div className="d-flex">
                              <Select
                                styles={customStylesSelect}
                                className="form-control select-timezone"
                                placeholder="Seleciona un plan"
                                value={selectedPlan}
                                onChange={handleChangePlans}
                                options={plans}
                                getOptionLabel={(option) => (
                                  <div className="option countrie d-flex justify-content-between" style={{fontSize: "15px"}}>
                                    <label>{option.name}</label>
                                    {
                                      planId === option.id && 
                                      <BsPencilFill 
                                        className="icon-pencil mr-2" 
                                        title="Editar" 
                                        style={{ fontSize: "15px" }} 
                                        onClick={() => {
                                          setEditPlan(true);
                                          setModalCreatePlan(true)
                                          setIdPlan(option.id)
                                        }}
                                      />
                                    }
                                  </div>
                                )}
                                getOptionValue={(option) => option.id}
                              />
                              {!planId ?
                                <a
                                  href="javascript:;"
                                  className="form-control btn btn-lg btn-default mr-1 w-25"
                                  onClick={() => {
                                    setModalCreatePlan(true);
                                  }}
                                >
                                  Crear Plan
                                </a>
                              : null}
                            </div>
                          </div>
                          {isPlan && (<p className="text-danger mt-1 f-w-900">* El plan es requerido.</p>)}
                        </div>

                        <div className="col-md-6">
                          <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable">
                            <label>Permisos</label>
                            <Select
                              styles={customStylesSelect}
                              isMulti
                              name="permissions"
                              className="form-control basic-multi-select"
                              placeholder="Selecciona..."
                              value={permissions}
                              onChange={handleChangePermissions}
                              options={listPermissions}
                              classNamePrefix="select"
                              noOptionsMessage={() => "No hay permisos disponibles."}
                              getOptionLabel={(option) => (
                                <div className="option d-flex" style={{fontSize: "15px"}}>
                                  {option.label} 
                                </div>
                              )}
                              getOptionValue={(option) => option.value}
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable">
                            <label>Integraciones:</label>
                            {integrationUrls.map((url, index) => (
                              <div key={index} className="su-control-group">
                                <input
                                  className="form-control"
                                  type="text"
                                  value={url}
                                  onChange={(event) => handleIntegrationUrlChange(index, event)}
                                  placeholder="Ingrese URL de integración"
                                />
                                {index === integrationUrls.length - 1 && ( // Agrega el botón "+" solo en el último campo
                                  <button className="mt-1" type="button" onClick={addIntegrationUrlField}>+</button>
                                )}
                              </div>
                            ))}
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable">
                            <label>Nombre verificado</label>
                            <div className="su-control-group">
                              <input
                                className="form-control"
                                id="verifiedName"
                                type="text"
                                disabled
                                {...formCompany.register("verifiedName", {})}
                              />
                            </div>
                            {error?.verifiedName && (<p className="text-danger mt-1 f-w-900">* {error.verifiedName.message}</p>
                            )}
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable">
                            <label>Display Phone Number: </label>
                            <div className="su-control-group">
                              <input
                                className="form-control"
                                id="displayPhoneNumber"
                                type="text"
                                disabled
                                {...formCompany.register("displayPhoneNumber", {})}
                              />
                            </div>
                            {error?.displayPhoneNumber && (<p className="text-danger mt-1 f-w-900">* {error.displayPhoneNumber.message}</p>
                            )}
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable mt-3">
                            <label>Quality Rating</label>
                            <div className="su-control-group">
                              {(() => {
                                const { text, color, icon } = getQualityRatingDisplay(formCompany.getValues("qualityRating"));
                                return (
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "flex-start",
                                      backgroundColor: color,
                                      color: "#fff",
                                      borderRadius: "20px",
                                      padding: "5px 10px",
                                      fontWeight: "bold",
                                      width: "50%",
                                    }}
                                  >
                                    <span
                                      style={{
                                        marginRight: "10px",
                                        fontSize: "16px",
                                        marginTop: "4px",
                                      }}
                                    >
                                      {icon}
                                    </span>
                                    {text}
                                  </div>
                                );
                              })()}
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable mt-3">
                            <label>Límite de mensajes</label>
                            <div className="su-control-group">
                              {(() => {
                                const { text } = getCurrentLimitDisplay(formCompany.getValues("currentLimit"));
                                return (
                                  <input
                                    className="form-control"
                                    id="currentLimit"
                                    type="text"
                                    disabled
                                    value={text}
                                  />
                                );
                              })()}
                            </div>
                            {error?.currentLimit && (<p className="text-danger mt-1 f-w-900">* {error.currentLimit.message}</p>)}
                          </div>
                        </div>


                      </div>
                    </div>
                  </div>
                </div>

                <footer style={{height: "10vh", padding: "1rem"}}>
                  <div className="row">
                    <div className="col-sm-6"></div>
                    <div className="col-sm-6 right">
                      <a
                        href="javascript:;"
                        className="btn btn-lg btn-default mr-1"
                        onClick={() => {
                          setMode("list");
                          formCompany.reset();
                          setModeEdit(false);
                        }}
                      >
                        Volver
                      </a>

                      <input
                        className="btn btn-lg btn-info"
                        value="Guardar"
                        type="submit"
                        onClick={() => {
                          if (selectedCountry) {setErrorCountrie(false)} 
                          else {setErrorCountrie(true)}
                          if (selectedCountry) {setErrorCountrie(false)} 
                          else {setErrorCountrie(true)}
                        }}
                      />
                    </div>
                  </div>
                </footer>
              </form>
            </div>
          </section>
        </div>
      )}

      <Modal
        status={modalCreatePlan}
        changeStatus={setModalCreatePlan}
        title={editPlan ? "Editar plan" : "Configurar plan"}
        width={"900px"}
      >
        <Content>
          <form
            onSubmit={editPlan ? formPlan.handleSubmit(putPlan) : formPlan.handleSubmit(postPlan)}
            style={{ width: "100%" }}
          >
            <div className="row mb-3">
              <div className="col-md-6">
                <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable">
                  <label>Nombre: </label>
                  <div className="su-control-group">
                    <input
                      className="form-control"
                      type="text"
                      name="planName"
                      // value={answerTitle}
                      {...formPlan.register("planName", {
                        required: {
                          value: true,
                          message:"El nombre del plan es obligatorio.",
                        },
                        pattern: {
                          value: /\S+/,
                          message: "No puede ser solo espacios en blanco",
                        },
                      })}
                    />
                  </div>
                  {error2.planName && <Alert>* {error2.planName.message}</Alert>}
                </div>
              </div>
              
              <div className="col-md-6">
                <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable">
                  <label>Description: </label>
                  <div className="su-control-group">
                    <input
                      className="form-control"
                      type="text"
                      name="planDescription"
                      maxLength="4096"
                      // value={inputStr2}
                      {...formPlan.register("planDescription", {
                        required: {
                          value: true,
                          message:"La descripción del plan es obligatoria.",
                        },
                        pattern: {
                          value: /\S+/,
                          message: "No puede ser solo espacios en blanco",
                        },
                      })}
                    />
                  </div>
                  {error2.planDescription && <Alert>* {error2.planDescription.message}</Alert>}
                </div>
              </div>
              
              <div className="col-md-3">
                <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable">
                  <label>Precio plan: </label>
                  <div className="su-control-group">
                    <input
                      className="form-control"
                      type="number"
                      name="planPrice"
                      // value={inputStr2}
                      {...formPlan.register("planPrice", {
                        required: {
                          value: true,
                          message:"El precio del plan es obligatorio.",
                        },
                        pattern: {
                          value: /\S+/,
                          message: "No puede ser solo espacios en blanco",
                        },
                      })}
                    />
                  </div>
                  {error2.planPrice && <Alert>* {error2.planPrice.message}</Alert>}
                </div>
              </div>
              
              <div className="col-md-3">
                <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable">
                  <label>N° de agentes: </label>
                  <div className="su-control-group">
                    <input
                      className="form-control"
                      type="number"
                      name="planNumberAgents"
                      // value={inputStr2}
                      {...formPlan.register("planNumberAgents", {
                        required: {
                          value: true,
                          message:"El numero de agentes es obligatorio.",
                        },
                        pattern: {
                          value: /\S+/,
                          message: "No puede ser solo espacios en blanco",
                        },
                      })}
                    />
                  </div>
                  {error2.planNumberAgents && <Alert>* {error2.planNumberAgents.message}</Alert>}
                </div>
              </div>
              
              <div className="col-md-3">
                <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable">
                  <label>N° de Administradores: </label>
                  <div className="su-control-group">
                    <input
                      className="form-control"
                      type="number"
                      name="planNumberAdmin"
                      // value={inputStr2}
                      {...formPlan.register("planNumberAdmin", {
                        required: {
                          value: true,
                          message:"El numero de administradores es obligatorio.",
                        },
                        pattern: {
                          value: /\S+/,
                          message: "No puede ser solo espacios en blanco",
                        },
                      })}
                    />
                  </div>
                  {error2.planNumberAdmin && <Alert>* {error2.planNumberAdmin.message}</Alert>}
                </div>
              </div>

              <div className="col-md-3">
                <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable">
                  <label>Precio agente adicional: </label>
                  <div className="su-control-group">
                    <input
                      className="form-control"
                      type="number"
                      name="additionalAgentPrice"
                      // value={inputStr2}
                      {...formPlan.register("additionalAgentPrice", {
                        required: {
                          value: true,
                          message:"El precio del agente adicional es obligatorio.",
                        },
                        pattern: {
                          value: /\S+/,
                          message: "No puede ser solo espacios en blanco",
                        },
                      })}
                    />
                  </div>
                  {error2.additionalAgentPrice && <Alert>* {error2.additionalAgentPrice.message}</Alert>}
                </div>
              </div>

              <div className="col-md-3">
                <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable">
                  <label>Precio notificaciones: </label>
                  <div className="su-control-group">
                    <input
                      className="form-control"
                      type="number"
                      step="0.0001"
                      name="planNotificationPrice"
                      // value={inputStr2}
                      {...formPlan.register("planNotificationPrice", {
                        required: {
                          value: true,
                          message:"El precio de notificaciones es obligatorio.",
                        },
                        pattern: {
                          value: /\S+/,
                          message: "No puede ser solo espacios en blanco",
                        },
                      })}
                    />
                  </div>
                  {error2.planNotificationPrice && <Alert>* {error2.planNotificationPrice.message}</Alert>}
                </div>
              </div>

              <div className="col-md-3">
                <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable">
                  <label>Precio chat: </label>
                  <div className="su-control-group">
                    <input
                      className="form-control"
                      type="number"
                      step="0.0001"
                      name="planChatPrice"
                      // value={inputStr2}
                      {...formPlan.register("planChatPrice", {
                        required: {
                          value: true,
                          message:"El precio de chat es obligatorio.",
                        },
                        pattern: {
                          value: /\S+/,
                          message: "No puede ser solo espacios en blanco",
                        },
                      })}
                    />
                  </div>
                  {error2.planChatPrice && <Alert>* {error2.planChatPrice.message}</Alert>}
                </div>
              </div>

              <div className="col-md-3">
                <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable">
                  <label>Precio bot: </label>
                  <div className="su-control-group">
                    <input
                      className="form-control"
                      type="number"
                      step="0.0001"
                      name="planBotPrice"
                      // value={inputStr2}
                      {...formPlan.register("planBotPrice", {
                        required: {
                          value: true,
                          message:"El precio de bot es obligatorio.",
                        },
                        pattern: {
                          value: /\S+/,
                          message: "No puede ser solo espacios en blanco",
                        },
                      })}
                    />
                  </div>
                  {error2.planBotPrice && <Alert>* {error2.planBotPrice.message}</Alert>}
                </div>
              </div>

              <div className="col-md-3">
                <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable">
                  <label>Precio conversación: </label>
                  <div className="su-control-group">
                    <input
                      className="form-control"
                      type="number"
                      step="0.0001"
                      name="planConversationPrice"
                      // value={inputStr2}
                      {...formPlan.register("planConversationPrice", {
                        required: {
                          value: true,
                          message:"El precio de conversación es obligatorio.",
                        },
                        pattern: {
                          value: /\S+/,
                          message: "No puede ser solo espacios en blanco",
                        },
                      })}
                    />
                  </div>
                  {error2.planConversationPrice && <Alert>* {error2.planConversationPrice.message}</Alert>}
                </div>
              </div>

              <div className="col-md-3">
                <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable">
                  <label>Limite de conversaciones: </label>
                  <div className="d-flex justify-content-center align-items-center ml-4">
                    <input
                      name="isLimit"
                      id="isLimitId"
                      type="checkbox"
                      className="cmn-toggle cmn-toggle-round-flat"
                      checked={isLimitCheck}
                      {...formPlan.register("isLimit")}
                      onChange={(e) => {
                        const isChecked = e.target.checked;
                        setIsLimitCheck(isChecked);
                        formPlan.setValue("isLimit", isChecked);
                      }}
                    />
                    <label className="m-0" htmlFor="isLimitId"></label>
                  </div>
                </div>
              </div>

              {isLimitCheck && (
                <div className="col-md-3">
                  <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable">
                    <label>Limite: </label>
                    <div className="su-control-group">
                      <input
                        className="form-control"
                        type="number"
                        name="limit"
                        {...formPlan.register("limit", {
                          required: {
                            value: true,
                            message:"El limite es obligatorio.",
                          },
                          pattern: {
                            value: /\S+/,
                            message: "No puede ser solo espacios en blanco",
                          },
                        })}
                      />
                    </div>
                    {error2.limit && <Alert>* {error2.limit.message}</Alert>}
                  </div>
                </div>
              )}

            </div>

            <div className="row justify-content-end bd col-12 mt-4">
              <a 
                href="javascript:;" 
                className="btn btn-lg btn-default mr-1" 
                onClick={() => {
                  setModalCreatePlan(false);
                  formPlan.reset()
                }}
              >
                Volver
              </a>
              <input
                className="btn btn-lg btn-info"
                value={editPlan ? "Actualizar" : "Crear"}
                type="submit"
                // type="button"
                // onClick={() =>  editAnswer ? putQuickReply() : postQuickReply()}
              />
            </div>
          </form>


        </Content>
      </Modal>

      <ToastContainer 
        position="top-center"
        autoClose={52000}
        // autoClose={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        // draggable
        theme={JSON.parse(localStorage.getItem("THEME")) ? "dark" : "light"}
        toastClassName="custom-toast" // Aplica estilos personalizados a cada toast individualmente
        bodyClassName="custom-toast-body"
      />

    </Fragment>
  );
};

export default Companies;

const Alert = styled.span`
  color: red;
  display: block;
  width: 100%;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  h1 {
    font-size: 42px;
    font-weight: 700;
    margin-bottom: 10px;
    text-align: center;
  }
  li {
    padding: 10px 5px;
    border-bottom: 1px solid rgb(0, 0, 0, 0.05);
  }
  li:hover {
    background: rgb(0, 0, 0, 0.05);
  }
  p {
    font-size: 18px;
    margin-bottom: 0px;
  }
  input {
    padding: 10px;
    border-radius: 0px;
    font-size: 17px;
  }
  img.img-answer {
    cursor: pointer;
    width: auto;
    height: 180px;
    object-fit: scale-down;
  }
  .content-img {
    position: relative;
  }
  // .content-img .icon-eye {
  //   cursor: pointer;
  //   position: absolute;
  //   top: 50%;
  //   left: 50%;
  //   transform: translate(-50%, -50%);
  // }
  .content-img img:hover {
    opacity: 0.6;
  }
`;
