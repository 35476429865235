import React, { Fragment, useState } from "react";
import { Line } from 'react-chartjs-2';

const RealTimeMetrics = ({metrics}) => {
    const data = {
        labels: [],
        datasets: [
            {
                label: 'Conversaciones',
                data: [],
                backgroundColor: 'rgba(75, 192, 192, 0.2)',
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 2,
            },
        ],
    };
    if(metrics.lastHours.ordered_dates){
        metrics.lastHours.ordered_dates.forEach(key => {
          data.labels.push(key);
          data.datasets[0].data.push(metrics.lastHours.data[key]);
        });
    }

    const options = {
        scales: {
            y: {
                beginAtZero: true,
            },
        },
    };
    return (
    <Fragment>
        <div className="content-1 real-time-metrics" >
            <header className="text-center">
                <h2 className="m-0">Métricas tiempo real</h2>
            </header>
            <div className="text-right">
                <span className="p-2 mr-2" style={{backgroundColor: "transparent", borderRadius: "6px"}}></span>
            </div>

            <div className="content-bubbles d-flex justify-content-around align-items-center py-3 my-3" style={{backgroundColor: "#dceef7"}}>
                <div className="d-flex align-items-center">
                    <div className="drop" style={{backgroundColor: "#5bc0de"}}>
                        <span className="drop-value">{metrics.open + metrics.closed + metrics.onHold}</span>
                    </div>
                    <span className="ml-4">Conversaciones recibidas</span>
                </div>
                <div className="d-flex align-items-center">
                    <div className="drop" style={{backgroundColor: "#a7ada7"}}>
                        <span className="drop-value">{metrics.onHold}</span>
                    </div>
                    <span className="ml-4">En espera</span>
                </div>
                <div className="d-flex align-items-center">
                    <div className="drop" style={{backgroundColor: "#00a884"}}>
                        <span className="drop-value">{metrics.open}</span>
                    </div>
                    <span className="ml-4">En atención</span>
                </div>
                <div className="d-flex align-items-center">
                    <div className="drop" style={{backgroundColor: "#f56f89"}}>
                        <span className="drop-value">{metrics.closed}</span>
                    </div>
                    <span className="ml-4">Cerradas</span>
                </div>
            </div>

            <div className="content-graphics d-flex my-3">
                <div className="graphics-1 mr-3" style={{backgroundColor: "#dceef7", width: "50%"}}>
                    <h4 className="text-center p-3">Volumen de conversaciones</h4>
                    <div style={{width: "90%", margin: "0 auto 10px"}}>
                        <Line data={data} options={options} />
                    </div>
                </div>


                <div className="graphics-2 ml-3" style={{backgroundColor: "#dceef7", width: "50%"}}>
                    <div className="d-flex justify-content-center align-items-center" style={{height: "50%", textAlign: "center"}}>
                        <div>
                        <h4 className="text-center p-3">Tiempo de atención</h4>
                        <p style={{fontSize: "2rem"}}>{metrics.atentionTime}</p>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center align-items-center" style={{height: "50%", textAlign: "center"}}>
                        <div>
                        <h4 className="text-center p-3">Tiempo de espera</h4>
                        <p style={{fontSize: "2rem"}}>{metrics.waitTime}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    </Fragment>
    );
};

export default RealTimeMetrics;
