// Resources
import React, {useState} from "react";
import dayjs from "dayjs";

// Icons
import { TfiBackRight } from "react-icons/tfi";

const HistoryVersions = ({
    selectLog,
    handleShowLogs,
    listLogs,
    animateClass,
    isDark
}) => {

    const [selectedLog, setSelectedLog] = useState(null);

    // Functions

    //# Function
    
    return (
        <>
            <div 
                className={`card mt-4 border border-12 border-black mr-4 ${animateClass}`} 
                style={{
                    width: '300px', 
                    transition: 'height 0.5s ease', 
                    maxHeight: '460px',
                    zIndex: 100,
                    paddingBlock: "20px", 
                    overflowY: 'auto',
                    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                    backgroundColor: isDark == "true" ? "#111B21" : "#fff"
                }}
            >
                <div 
                    style={{
                        height: "10%",
                        fontSize: "1.2rem", 
                        fontWeight: "bold",
                        paddingInline: "20px",
                        paddingBottom: "20px",
                        borderBottom: "1px solid #c0c0c0"
                    }}
                    className="d-flex justify-content-around"
                >
                    <div>
                        Historial de versiones
                    </div>
                    <div>
                        <TfiBackRight 
                            style={{cursor: "pointer"}}
                            onClick={handleShowLogs}
                        />
                    </div>
                </div>
                
                <div
                    id="content-list-chats"
                    style={{
                        height: "90%",
                        overflowY: "auto",
                    }}
                >
                    <ul>
                        {listLogs.length && listLogs.map((log, index) => {
                            return (
                                <li 
                                    className={`items-logs ${selectedLog == index ? 'items-logs-active' : ''}`}
                                    style={{
                                        height: "70px",
                                        padding: "15px 30px",
                                        display: "flex",
                                        flexDirection: "column",
                                        cursor: "pointer"
                                    }}
                                    onClick={() => {
                                        selectLog(log);
                                        setSelectedLog(index);
                                    }}
                                >
                                    <div style={{fontWeight: 600}}>
                                        {dayjs(log.created).locale("es").format('DD [de] MMMM [del] YYYY HH:mm')} 
                                    </div>
                                    <div>
                                        <span>Creado por: </span>
                                        <strong>{log.user.length > 20 ? log.user.substring(0, 19) + '...' : log.user}</strong>
                                    </div>
                                </li>
                            )
                        })}
                    </ul>
                </div>
            </div>
        </>
    )
}

export default HistoryVersions;