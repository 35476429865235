import React, { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";

import styledComponent from "styled-components";
import Select from "react-select";
import { ToastContainer } from "react-toastify";
import Picker from "emoji-picker-react";

import {
    BsTrash3,
} from "react-icons/bs";
import { VscVariableGroup } from "react-icons/vsc";
import { IoIosArrowDropdown, IoIosArrowDropup, IoMdAdd } from "react-icons/io";
import { FaSpinner, FaCheckCircle } from "react-icons/fa";
import { BsPencilFill } from "react-icons/bs";
import { BsDatabaseFillAdd } from "react-icons/bs";
import { BiSolidError, BiTransfer } from "react-icons/bi";

const QueryApi = ({
    selectedNode,
    handleSelectedNode,
    removeNode,
    nodes,
    edges,
    editFlow,
    updateBot,
    truncateText,
    handleClickModalVariables,
    onEmojiClick,
    customStyles,
    customStylesDark,
    isDark,
    handleCursorPosition,
    handleEditModal,
    handleModalHeaders,
    handeHeaderParams
}) => {
    const {
        watch,
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm({
        user_response: null,
        dynamic_buttons_variable: null,
        dynamic_buttons_variable_description: null,
        regular_expressions_error_message: null,
    });

    const [errorProtocol, setErrorProtocol] = useState(false);
    const [errorAuth, setErrorAuth] = useState(false);

    // ------> Selects, Radios, Checkbox
    const [loadingUpdate, setLoadingUpdate] = useState(false);

    // ------> Modals, Dialogs
    const [showPicker, setShowPicker] = useState(false);

    // ------> Dropdowns
    const [dropdownRegularExpression, setDropdownRegularExpression] = useState(false);
    const [dropdownHeaders, setDropdownHeaders] = useState(false);
    const [dropdownRequest, setDropdownRequest] = useState(false);
    const [dropdownRequestBody, setDropdownRequestBody] = useState(false);
    const [dropdownResponse, setDropdownResponse] = useState(false);

    // FUNCTIONS

    //# Function para manejar cierre y aparetura de dropdowns
    const handleDropdown = (property) => {
        switch (property) {
            case "dropdownHeaders":
                setDropdownHeaders(!dropdownHeaders);
                break;
            case "dropdownRequest":
                setDropdownRequest(!dropdownRequest);
                break;
            case "dropdownRequestBody":
                setDropdownRequestBody(!dropdownRequestBody);
                break;
            case "dropdownResponse":
                setDropdownResponse(!dropdownResponse);
                break;
            case "dropdownRegularExpression":
                setDropdownRegularExpression(!dropdownRegularExpression);
                break;
            default:
                console.error(`Property ${property} not recognized`);
        }
    };

    //# Function para manejar cambios de estados de campos
    const handleInputChange = (fieldPath, value) => {
        handleSelectedNode((prevInputValue) => {
            const updateNestedField = (obj = {}, path, val) => {
                const [key, ...rest] = path.split(".");
                return {
                    ...obj,
                    [key]:
                        rest.length > 0
                            ? updateNestedField(
                                  obj[key] || {},
                                  rest.join("."),
                                  val
                              )
                            : val,
                };
            };

            return {
                ...prevInputValue,
                data: updateNestedField(prevInputValue.data, fieldPath, value),
            };
        });
    };

    //# Function para guardar los datos
    const saveData = (values) => {
        if (!selectedNode.data.protocol) {
            setErrorProtocol(true);
            return;
        };
        
        if (!selectedNode.data.auth) {
            setErrorAuth(true);
            return;
        };

        setLoadingUpdate(true);

        const data = {
            title: values.title,
            user_response: values.user_response,
            bot_response: values.bot_response,
            url_api: values.url_api,
            user: values?.user ?? null,
            password: values?.password ?? null,
            advanced: {
                ...selectedNode.data?.advanced,
                regularExpressions: {
                    ...selectedNode.data?.advanced?.regularExpressions,
                    errorMessage: values.regular_expressions_error_message,
                },
            },
        };

        handleSelectedNode((prevInputValue) => ({
            ...prevInputValue,
            data: {
                ...prevInputValue.data,
                ...data,
                saved: true,
            },
        }));
        setTimeout(async () => {
            await updateBot(editFlow, edges, nodes);
            setLoadingUpdate(false);
        }, 500);
    };

    //# Function para obtner data
    const getData = () => {
        let objectData = {
            title: selectedNode.data?.title ?? null,
            user_response: selectedNode.data?.user_response ?? null,
            bot_response: selectedNode.data?.bot_response ?? null,
            url_api: selectedNode.data?.url_api ?? null,
            user: selectedNode.data?.user ?? null,
            password: selectedNode.data?.password ?? null,
            regular_expressions_error_message:
                selectedNode.data?.advanced?.regularExpressions?.errorMessage ??
                null,
        };

        reset(objectData, { keepDefaultValues: true });
    };

    useEffect(() => {
        getData();
    }, [selectedNode]);

    return (
        <>
            <div
                className="d-flex align-items-center justify-content-between text-white"
                style={{
                    height: "50px",
                    padding: "10px",
                    borderBottom: "1px solid #CED4DA",
                    backgroundColor: "#7ECCD8",
                }}
            >
                <div className="d-flex align-items-center">
                    <BsDatabaseFillAdd
                        className="mr-1"
                        style={{ fontSize: "24px" }}
                    />
                    <span style={{ fontSize: "22px", whiteSpace: "nowrap" }}>
                        {truncateText(selectedNode.data.title)}
                    </span>
                </div>
                <button
                    className="text-white border-0 bg-transparent d-block pointer mt-2"
                    onClick={() => {
                        removeNode(selectedNode.id);
                    }}
                >
                    <BsTrash3 style={{ fontSize: "18px" }} />
                </button>
            </div>
            <div
                id="content-list-chats"
                className="card-body"
                style={{ overflowY: "auto" }}
            >
                <form onSubmit={handleSubmit(saveData)} style={{ width: "100%" }}>
                    <div>
                        <div className="col p-0">
                            <label
                                htmlFor="title"
                                style={{ color: "#6C757D", fontWeight: 500 }}
                            >
                                Título:{" "}
                            </label>
                            <div>
                                <input
                                    className="form-control"
                                    id="title"
                                    name="title"
                                    type="text"
                                    style={{ width: "100%", height: "35px" }}
                                    {...register("title", {
                                        required: {
                                            value: true,
                                            message:"Campo requerido.",
                                        },
                                        pattern: {
                                            value: /\S+/,
                                            message: "No puede ser solo espacios en blanco",
                                        },
                                    })}
                                />
                                {errors.title && <Alert>* {errors.title.message}</Alert>}
                            </div>
                        </div>
                        <div className="col p-0 mt-4">
                            <label
                                htmlFor="user_response"
                                style={{ color: "#6C757D", fontWeight: 500 }}
                            >
                                Respuesta del cliente:{" "}
                            </label>
                            <div>
                                <textarea
                                    className="form-control"
                                    id="user_response"
                                    name="user_response"
                                    type="text"
                                    style={{
                                        width: "100%",
                                        height: "50px",
                                        borderRadius: "4px",
                                    }}
                                    {...register("user_response", {
                                        required: {
                                            value: true,
                                            message:"Campo requerido.",
                                        },
                                        pattern: {
                                            value: /\S+/,
                                            message: "No puede ser solo espacios en blanco",
                                        },
                                    })}
                                />
                                {errors.user_response && <Alert>* {errors.user_response.message}</Alert>}
                                <p
                                    style={{
                                        fontSize: 11,
                                        color: "#6C757D",
                                        fontWeight: 500,
                                    }}
                                >
                                    Mensaje que envía el cliente para
                                    activar la respuesta.
                                </p>
                            </div>
                            <div
                                className="p-1"
                                style={{
                                    backgroundColor: "rgba(126, 204, 216, 0.3)",
                                    borderRadius: "4px",
                                }}
                            >
                                <p
                                    style={{
                                        fontSize: 11,
                                        color:
                                            isDark == "true"
                                                ? "#b6b6b6"
                                                : "#6C757D",
                                        fontWeight: 500,
                                    }}
                                >
                                    <span style={{ fontSize: 12, fontWeight: 600 }}>
                                        Importante:
                                    </span>{" "}
                                    Con el signo * funcionará para cuaquier
                                    respuesta que de el cliente.
                                </p>
                            </div>
                            <div
                                className="p-1 mt-2"
                                style={{
                                    backgroundColor: "rgba(126, 204, 216, 0.3)",
                                    borderRadius: "4px",
                                }}
                            >
                                <p
                                    style={{
                                        fontSize: 11,
                                        color:
                                            isDark == "true"
                                                ? "#b6b6b6"
                                                : "#6C757D",
                                        fontWeight: 500,
                                    }}
                                >
                                    <span style={{ fontSize: 12, fontWeight: 600 }}>
                                        Importante:
                                    </span>{" "}
                                    Los nodos se pueden activar con multiples
                                    respuestas según esten configuradas, para ello
                                    debe tener el siguiente formato: Respuesta 1 /
                                    Respuesta 2 / Respuesta 3 configurado en dicho
                                    apartado.
                                </p>
                            </div>
                        </div>
                        <div className="col p-0 mt-4">
                            <label
                                htmlFor="url_api"
                                style={{ color: "#6C757D", fontWeight: 500 }}
                            >
                                URL API externa:{" "}
                            </label>
                            <div>
                                <input
                                    className="form-control"
                                    id="url_api"
                                    name="url_api"
                                    type="text"
                                    style={{ width: "100%", height: "35px" }}
                                    {...register("url_api", {
                                        required: {
                                            value: selectedNode.data.first ? false : true,
                                            message:"Campo requerido.",
                                        },
                                        pattern: {
                                            value: /\S+/,
                                            message: "No puede ser solo espacios en blanco",
                                        },
                                    })}
                                />
                                {errors.url_api && <Alert>* {errors.url_api.message}</Alert>}
                                <p
                                    style={{
                                        fontSize: 11,
                                        color: "#6C757D",
                                        fontWeight: 500,
                                    }}
                                >
                                    URL a la cual se va a realizar la petición.
                                </p>
                            </div>
                        </div>
                        <div className="col p-0 mt-4">
                            <label
                                htmlFor="protocol"
                                style={{ color: "#6C757D", fontWeight: 500 }}
                            >
                                Protocolo HTTP:{" "}
                            </label>
                            <div>
                                <Select
                                    styles={
                                        isDark == "true"
                                            ? customStylesDark
                                            : customStyles
                                    }
                                    className="form-control basic-multi-select"
                                    placeholder="Seleccione..."
                                    name="protocol"
                                    value={selectedNode.data.protocol}
                                    onChange={(protocol) =>{
                                        handleInputChange("protocol", protocol);
                                        setErrorProtocol(false);
                                    }}
                                    options={[
                                        {
                                            label: "GET",
                                            id: "GET",
                                        },
                                        {
                                            label: "POST",
                                            id: "POST",
                                        },
                                    ]}
                                    classNamePrefix="select"
                                    noOptionsMessage={() => "Sin opciones"}
                                    getOptionLabel={(option) => (
                                        <div
                                            className="option d-flex"
                                            style={{ fontSize: "15px" }}
                                        >
                                            {option.label}
                                        </div>
                                    )}
                                    getOptionValue={(option) => option.label}
                                />
                                {errorProtocol && (
                                    <Alert>* Campo requerido</Alert>
                                )}
                                <p
                                    style={{
                                        fontSize: 11,
                                        color: "#6C757D",
                                        fontWeight: 500,
                                    }}
                                >
                                    Protocolo HTTP para realiza la petición.
                                </p>
                            </div>
                        </div>
                        <div className="col p-0 mt-4">
                            <label
                                htmlFor="auth"
                                style={{ color: "#6C757D", fontWeight: 500 }}
                            >
                                Tipo de autenticación:{" "}
                            </label>
                            <div>
                                <Select
                                    styles={
                                        isDark == "true"
                                            ? customStylesDark
                                            : customStyles
                                    }
                                    className="form-control basic-multi-select"
                                    placeholder="Seleccione..."
                                    name="auth"
                                    value={selectedNode.data.auth}
                                    onChange={(item) =>{
                                        handleInputChange("auth", item);
                                        setErrorAuth(false);
                                    }}
                                    options={[
                                        {
                                            label: "Ninguna",
                                            id: "none",
                                        },
                                        {
                                            label: "Usuario/Contraseña",
                                            id: "user_password",
                                        },
                                    ]}
                                    classNamePrefix="select"
                                    noOptionsMessage={() => "Sin opciones"}
                                    getOptionLabel={(option) => (
                                        <div
                                            className="option d-flex"
                                            style={{ fontSize: "15px" }}
                                        >
                                            {option.label}
                                        </div>
                                    )}
                                    getOptionValue={(option) => option.label}
                                />
                                {errorAuth && (
                                    <Alert>* Campo requerido</Alert>
                                )}
                                <p
                                    style={{
                                        fontSize: 11,
                                        color: "#6C757D",
                                        fontWeight: 500,
                                    }}
                                >
                                    Seguridad del webhook.
                                </p>
                            </div>
                        </div>
                        {selectedNode.data.auth &&
                            selectedNode.data.auth == "user_password" && (
                                <>
                                    <div className="col p-0">
                                        <label
                                            htmlFor="user"
                                            style={{
                                                color: "#6C757D",
                                                fontWeight: 500,
                                            }}
                                        >
                                            Usuario:{" "}
                                        </label>
                                        <div>
                                            <input
                                                className="form-control"
                                                id="user"
                                                name="user"
                                                type="text"
                                                style={{
                                                    width: "100%",
                                                    height: "35px",
                                                }}
                                                {...register("user", {
                                                    required: {
                                                        value: selectedNode.data.auth == "user_password" ? true : false,
                                                        message:"Campo requerido.",
                                                    },
                                                    pattern: {
                                                        value: /\S+/,
                                                        message: "No puede ser solo espacios en blanco",
                                                    },
                                                })}
                                            />
                                            {errors.user && <Alert>* {errors.user.message}</Alert>}
                                        </div>
                                    </div>
                                    <div className="col p-0">
                                        <label
                                            htmlFor="password"
                                            style={{
                                                color: "#6C757D",
                                                fontWeight: 500,
                                            }}
                                        >
                                            Contraseña:{" "}
                                        </label>
                                        <div>
                                            <input
                                                className="form-control"
                                                id="password"
                                                name="password"
                                                type="text"
                                                style={{
                                                    width: "100%",
                                                    height: "35px",
                                                }}
                                                {...register("password", {
                                                    required: {
                                                        value: selectedNode.data.auth == "user_password" ? true : false,
                                                        message:"Campo requerido.",
                                                    },
                                                    pattern: {
                                                        value: /\S+/,
                                                        message: "No puede ser solo espacios en blanco",
                                                    },
                                                })}
                                            />
                                            {errors.password && <Alert>* {errors.password.message}</Alert>}
                                        </div>
                                    </div>
                                </>
                            )}
                        <div className="col p-0 mt-4">
                            <div
                                className="panel-group collapsible"
                                id="user-collapsible"
                            >
                                <div className="panel panel-default">
                                    <div
                                        className="panel-heading p-2"
                                        style={{
                                            border: "1px solid #4F4F4F",
                                            borderRadius: "4px",
                                        }}
                                    >
                                        <div
                                            id="title-aditionalDates"
                                            className="panel-title d-flex justify-content-between align-items-center pointer"
                                            onClick={() =>
                                                handleDropdown("dropdownHeaders")
                                            }
                                        >
                                            <span>Cabeceras (Opcional)</span>
                                            {dropdownHeaders ? (
                                                <IoIosArrowDropup
                                                    className="pointer mr-3"
                                                    style={{ fontSize: "20px" }}
                                                />
                                            ) : (
                                                <IoIosArrowDropdown
                                                    className="pointer mr-3"
                                                    style={{ fontSize: "20px" }}
                                                />
                                            )}
                                        </div>
                                    </div>

                                    {dropdownHeaders ? (
                                        <div
                                            className="panel-collapse pt-2"
                                            style={{
                                                borderRadius: "4px",
                                            }}
                                        >
                                            <div
                                                className="panel-body"
                                                style={{ paddingInline: "0px" }}
                                            >
                                                <div className="text-center">
                                                    <button
                                                        type="button"
                                                        style={{
                                                            border: 0,
                                                            borderRadius: "4px",
                                                            color: "#7ECCD8",
                                                            backgroundColor:
                                                                "rgba(126, 204, 216, 0.3)",
                                                            cursor: "pointer",
                                                            fontFamily:
                                                                "Helvetica, Arial, sans-serif",
                                                            fontSize: "12px",
                                                            fontWeight: "bold",
                                                            width: "150px",
                                                            zIndex: 100,
                                                        }}
                                                        onClick={() =>
                                                            handleModalHeaders(true)
                                                        }
                                                    >
                                                        <IoMdAdd />
                                                        Agregar Cabecera
                                                    </button>
                                                </div>
                                                <div className="mt-2">
                                                    {selectedNode.data.headers
                                                        .length > 0 &&
                                                        selectedNode.data.headers.map(
                                                            (head, index) => (
                                                                <div
                                                                    className="d-flex justify-content-between px-2"
                                                                    style={{
                                                                        overflowWrap:
                                                                            "anywhere",
                                                                    }}
                                                                >
                                                                    <div>
                                                                        <div
                                                                            style={{
                                                                                fontWeight:
                                                                                    "bolder",
                                                                            }}
                                                                        >
                                                                            {
                                                                                head.key
                                                                            }
                                                                        </div>
                                                                        <div>
                                                                            {
                                                                                head.value
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className="d-flex justify-content-end">
                                                                        <button
                                                                            onClick={() => {
                                                                                handleEditModal(
                                                                                    true
                                                                                );
                                                                                handleModalHeaders(
                                                                                    true
                                                                                );
                                                                                handeHeaderParams(
                                                                                    {
                                                                                        key: head.key,
                                                                                        value: head.value,
                                                                                        index: index,
                                                                                    }
                                                                                );
                                                                            }}
                                                                            className="text-primary border-0 bg-transparent d-block pointer mt-2"
                                                                        >
                                                                            <BsPencilFill
                                                                                style={{
                                                                                    fontSize:
                                                                                        "16px",
                                                                                }}
                                                                            />
                                                                        </button>
                                                                        <button
                                                                            className="text-danger border-0 bg-transparent d-block pointer mt-2"
                                                                            onClick={() => {
                                                                                const filter =
                                                                                    selectedNode.data.headers.filter(
                                                                                        (
                                                                                            v
                                                                                        ) =>
                                                                                            v.key !==
                                                                                            head.key
                                                                                    );
                                                                                handleSelectedNode(
                                                                                    (
                                                                                        prevInputValue
                                                                                    ) => ({
                                                                                        ...prevInputValue,
                                                                                        data: {
                                                                                            ...prevInputValue.data,
                                                                                            headers:
                                                                                                filter,
                                                                                        },
                                                                                    })
                                                                                );
                                                                            }}
                                                                        >
                                                                            <BsTrash3
                                                                                style={{
                                                                                    fontSize:
                                                                                        "16px",
                                                                                }}
                                                                            />
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            )
                                                        )}
                                                </div>
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                        <div className="col p-0 mt-4">
                            <div
                                className="panel-group collapsible"
                                id="user-collapsible"
                            >
                                <div className="panel panel-default">
                                    <div
                                        className="panel-heading p-2"
                                        style={{
                                            border: "1px solid #4F4F4F",
                                            borderRadius: "4px",
                                        }}
                                    >
                                        <div
                                            id="title-aditionalDates"
                                            className="panel-title d-flex justify-content-between align-items-center pointer"
                                            onClick={() =>
                                                handleDropdown("dropdownRequest")
                                            }
                                        >
                                            <span>
                                                Parámetros de petición (Opcional)
                                            </span>
                                            {dropdownRequest ? (
                                                <IoIosArrowDropup
                                                    className="pointer mr-3"
                                                    style={{ fontSize: "20px" }}
                                                />
                                            ) : (
                                                <IoIosArrowDropdown
                                                    className="pointer mr-3"
                                                    style={{ fontSize: "20px" }}
                                                />
                                            )}
                                        </div>
                                    </div>

                                    {dropdownRequest ? (
                                        <div
                                            className="panel-collapse pt-2"
                                            style={{
                                                borderRadius: "4px",
                                            }}
                                        >
                                            <div
                                                className="panel-body"
                                                style={{ paddingInline: "0px" }}
                                            >
                                                <div className="text-center">
                                                    <button
                                                        type="button"
                                                        style={{
                                                            border: 0,
                                                            borderRadius: "4px",
                                                            color: "#7ECCD8",
                                                            backgroundColor:
                                                                "rgba(126, 204, 216, 0.3)",
                                                            cursor: "pointer",
                                                            fontFamily:
                                                                "Helvetica, Arial, sans-serif",
                                                            fontSize: "12px",
                                                            fontWeight: "bold",
                                                            width: "150px",
                                                            zIndex: 100,
                                                        }}
                                                        onClick={() =>
                                                            handleModalRequestParams(
                                                                true
                                                            )
                                                        }
                                                    >
                                                        <IoMdAdd />
                                                        Agregar Parámetro
                                                    </button>
                                                </div>
                                                <div className="mt-2">
                                                    {selectedNode.data.requestParams
                                                        .length > 0 &&
                                                        selectedNode.data.requestParams.map(
                                                            (req, index) => (
                                                                <div
                                                                    className="d-flex justify-content-between px-2"
                                                                    style={{
                                                                        overflowWrap:
                                                                            "anywhere",
                                                                    }}
                                                                >
                                                                    <div>
                                                                        <div
                                                                            style={{
                                                                                fontWeight:
                                                                                    "bolder",
                                                                            }}
                                                                        >
                                                                            {
                                                                                req.key
                                                                            }
                                                                        </div>
                                                                        <div>
                                                                            {
                                                                                req.value
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className="d-flex justify-content-end">
                                                                        <button
                                                                            onClick={() => {
                                                                                handleEditModal(
                                                                                    true
                                                                                );
                                                                                handleModalRequestParams(
                                                                                    true
                                                                                );
                                                                                handleRequestParams(
                                                                                    {
                                                                                        key: req.key,
                                                                                        value: req.value,
                                                                                        index: index,
                                                                                    }
                                                                                );
                                                                            }}
                                                                            className="text-primary border-0 bg-transparent d-block pointer mt-2"
                                                                        >
                                                                            <BsPencilFill
                                                                                style={{
                                                                                    fontSize:
                                                                                        "16px",
                                                                                }}
                                                                            />
                                                                        </button>
                                                                        <button
                                                                            className="text-danger border-0 bg-transparent d-block pointer mt-2"
                                                                            onClick={() => {
                                                                                const filter =
                                                                                    selectedNode.data.requestParams.filter(
                                                                                        (
                                                                                            v
                                                                                        ) =>
                                                                                            v.key !==
                                                                                            req.key
                                                                                    );
                                                                                handleSelectedNode(
                                                                                    (
                                                                                        prevInputValue
                                                                                    ) => ({
                                                                                        ...prevInputValue,
                                                                                        data: {
                                                                                            ...prevInputValue.data,
                                                                                            requestParams:
                                                                                                filter,
                                                                                        },
                                                                                    })
                                                                                );
                                                                            }}
                                                                        >
                                                                            <BsTrash3
                                                                                style={{
                                                                                    fontSize:
                                                                                        "16px",
                                                                                }}
                                                                            />
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            )
                                                        )}
                                                </div>
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                        <div className="col p-0 mt-4">
                            <div
                                className="panel-group collapsible"
                                id="user-collapsible"
                            >
                                <div className="panel panel-default">
                                    <div
                                        className="panel-heading p-2"
                                        style={{
                                            border: "1px solid #4F4F4F",
                                            borderRadius: "4px",
                                        }}
                                    >
                                        <div
                                            id="title-aditionalDates"
                                            className="panel-title d-flex justify-content-between align-items-center pointer"
                                            onClick={() =>
                                                handleDropdown("dropdownRequestBody")
                                            }
                                        >
                                            <span>
                                                Parámetros de cuerpo (Opcional)
                                            </span>
                                            {dropdownRequestBody ? (
                                                <IoIosArrowDropup
                                                    className="pointer mr-3"
                                                    style={{ fontSize: "20px" }}
                                                />
                                            ) : (
                                                <IoIosArrowDropdown
                                                    className="pointer mr-3"
                                                    style={{ fontSize: "20px" }}
                                                />
                                            )}
                                        </div>
                                    </div>

                                    {dropdownRequestBody ? (
                                        <div
                                            className="panel-collapse pt-2"
                                            style={{
                                                borderRadius: "4px",
                                            }}
                                        >
                                            <div
                                                className="panel-body"
                                                style={{ paddingInline: "0px" }}
                                            >
                                                <div className="text-center">
                                                    <button
                                                        type="button"
                                                        style={{
                                                            border: 0,
                                                            borderRadius: "4px",
                                                            color: "#7ECCD8",
                                                            backgroundColor:
                                                                "rgba(126, 204, 216, 0.3)",
                                                            cursor: "pointer",
                                                            fontFamily:
                                                                "Helvetica, Arial, sans-serif",
                                                            fontSize: "12px",
                                                            fontWeight: "bold",
                                                            width: "150px",
                                                            zIndex: 100,
                                                        }}
                                                        onClick={() =>
                                                            handleModalRequestParamsBody(
                                                                true
                                                            )
                                                        }
                                                    >
                                                        <IoMdAdd />
                                                        Agregar Parámetro
                                                    </button>
                                                </div>
                                                <div className="mt-2">
                                                    {selectedNode.data
                                                        .requestParamsBody.length >
                                                        0 &&
                                                        selectedNode.data.requestParamsBody.map(
                                                            (req, index) => (
                                                                <div
                                                                    className="d-flex justify-content-between px-2"
                                                                    style={{
                                                                        overflowWrap:
                                                                            "anywhere",
                                                                    }}
                                                                >
                                                                    <div>
                                                                        <div
                                                                            style={{
                                                                                fontWeight:
                                                                                    "bolder",
                                                                            }}
                                                                        >
                                                                            {
                                                                                req.key
                                                                            }
                                                                        </div>
                                                                        <div>
                                                                            {
                                                                                req.value
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className="d-flex justify-content-end">
                                                                        <button
                                                                            onClick={() => {
                                                                                handleEditModal(
                                                                                    true
                                                                                );
                                                                                handleModalRequestParamsBody(
                                                                                    true
                                                                                );
                                                                                handleRequestParamsBody(
                                                                                    {
                                                                                        key: req.key,
                                                                                        value: req.value,
                                                                                        index: index,
                                                                                    }
                                                                                );
                                                                            }}
                                                                            className="text-primary border-0 bg-transparent d-block pointer mt-2"
                                                                        >
                                                                            <BsPencilFill
                                                                                style={{
                                                                                    fontSize:
                                                                                        "16px",
                                                                                }}
                                                                            />
                                                                        </button>
                                                                        <button
                                                                            className="text-danger border-0 bg-transparent d-block pointer mt-2"
                                                                            onClick={() => {
                                                                                const filter =
                                                                                    selectedNode.data.requestParamsBody.filter(
                                                                                        (
                                                                                            v
                                                                                        ) =>
                                                                                            v.key !==
                                                                                            req.key
                                                                                    );
                                                                                handleSelectedNode(
                                                                                    (
                                                                                        prevInputValue
                                                                                    ) => ({
                                                                                        ...prevInputValue,
                                                                                        data: {
                                                                                            ...prevInputValue.data,
                                                                                            requestParamsBody:
                                                                                                filter,
                                                                                        },
                                                                                    })
                                                                                );
                                                                            }}
                                                                        >
                                                                            <BsTrash3
                                                                                style={{
                                                                                    fontSize:
                                                                                        "16px",
                                                                                }}
                                                                            />
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            )
                                                        )}
                                                </div>
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                        <div className="col p-0 mt-4">
                            <div
                                className="panel-group collapsible"
                                id="user-collapsible"
                            >
                                <div className="panel panel-default">
                                    <div
                                        className="panel-heading p-2"
                                        style={{
                                            border: "1px solid #4F4F4F",
                                            borderRadius: "4px",
                                        }}
                                    >
                                        <div
                                            id="title-aditionalDates"
                                            className="panel-title d-flex justify-content-between align-items-center pointer"
                                            onClick={() =>
                                                handleDropdown("dropdownResponse")
                                            }
                                        >
                                            <span>
                                                Parámetros de respuesta (Opcional)
                                            </span>
                                            {dropdownResponse ? (
                                                <IoIosArrowDropup
                                                    className="pointer mr-3"
                                                    style={{ fontSize: "20px" }}
                                                />
                                            ) : (
                                                <IoIosArrowDropdown
                                                    className="pointer mr-3"
                                                    style={{ fontSize: "20px" }}
                                                />
                                            )}
                                        </div>
                                    </div>

                                    {dropdownResponse ? (
                                        <div
                                            className="panel-collapse pt-2"
                                            style={{
                                                borderRadius: "4px",
                                            }}
                                        >
                                            <div
                                                className="panel-body"
                                                style={{ paddingInline: "0px" }}
                                            >
                                                <div className="text-center">
                                                    <button
                                                        type="button"
                                                        style={{
                                                            border: 0,
                                                            borderRadius: "4px",
                                                            color: "#7ECCD8",
                                                            backgroundColor:
                                                                "rgba(126, 204, 216, 0.3)",
                                                            cursor: "pointer",
                                                            fontFamily:
                                                                "Helvetica, Arial, sans-serif",
                                                            fontSize: "12px",
                                                            fontWeight: "bold",
                                                            width: "150px",
                                                            zIndex: 100,
                                                        }}
                                                        onClick={() =>
                                                            handleModalResponseParams(
                                                                true
                                                            )
                                                        }
                                                    >
                                                        <IoMdAdd />
                                                        Agregar Respuesta
                                                    </button>
                                                </div>
                                                <div className="mt-2">
                                                    {selectedNode.data
                                                        .responseParams.length >
                                                        0 &&
                                                        selectedNode.data.responseParams.map(
                                                            (res, index) => (
                                                                <div
                                                                    className="d-flex justify-content-between px-2"
                                                                    style={{
                                                                        overflowWrap:
                                                                            "anywhere",
                                                                    }}
                                                                >
                                                                    <div>
                                                                        <div
                                                                            style={{
                                                                                fontWeight:
                                                                                    "bolder",
                                                                            }}
                                                                        >
                                                                            {
                                                                                res.key
                                                                            }
                                                                        </div>
                                                                        <div>
                                                                            {
                                                                                res.value
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className="d-flex justify-content-end">
                                                                        <button
                                                                            onClick={() => {
                                                                                handleEditModal(
                                                                                    true
                                                                                );
                                                                                handleModalResponseParams(
                                                                                    true
                                                                                );
                                                                                handleResponseParams(
                                                                                    {
                                                                                        key: res.key,
                                                                                        value: res.value,
                                                                                        index: index,
                                                                                    }
                                                                                );
                                                                            }}
                                                                            className="text-primary border-0 bg-transparent d-block pointer mt-2"
                                                                        >
                                                                            <BsPencilFill
                                                                                style={{
                                                                                    fontSize:
                                                                                        "16px",
                                                                                }}
                                                                            />
                                                                        </button>
                                                                        <button
                                                                            className="text-danger border-0 bg-transparent d-block pointer mt-2"
                                                                            onClick={() => {
                                                                                const filter =
                                                                                    selectedNode.data.responseParams.filter(
                                                                                        (
                                                                                            v
                                                                                        ) =>
                                                                                            v.key !==
                                                                                            res.key
                                                                                    );
                                                                                handleSelectedNode(
                                                                                    (
                                                                                        prevInputValue
                                                                                    ) => ({
                                                                                        ...prevInputValue,
                                                                                        data: {
                                                                                            ...prevInputValue.data,
                                                                                            responseParams:
                                                                                                filter,
                                                                                        },
                                                                                    })
                                                                                );
                                                                            }}
                                                                        >
                                                                            <BsTrash3
                                                                                style={{
                                                                                    fontSize:
                                                                                        "16px",
                                                                                }}
                                                                            />
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            )
                                                        )}
                                                </div>
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                        <div className="col p-0 mt-2">
                            <button
                                type="button"
                                style={{
                                    border: 0,
                                    borderRadius: "4px",
                                    color: "#fff",
                                    backgroundColor: "#7ECCD8",
                                    cursor: "pointer",
                                    fontFamily: "Helvetica, Arial, sans-serif",
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                    width: "150px",
                                    height: "30px",
                                    zIndex: 100,
                                }}
                                onClick={() => {
                                    setConnectionTestData({});
                                    setModalConnectionTest(true);
                                }}
                            >
                                <div className="d-flex align-items-center">
                                    <BiTransfer
                                        className="mr-1"
                                        style={{ fontSize: "15px" }}
                                    />
                                    <span>Consultar API</span>
                                </div>
                            </button>
                        </div>
                        <div className="col p-0 mt-4">
                            <label
                                htmlFor="bot_response"
                                style={{ color: "#6C757D", fontWeight: 500 }}
                            >
                                Respuesta del Bot:{" "}
                            </label>
                            <div>
                                <textarea
                                    className="form-control"
                                    id="bot_response"
                                    name="bot_response"
                                    placeholder="Escribe la respuesta del Bot"
                                    style={{
                                        width: "100%",
                                        height: "90px",
                                        borderRadius: "4px",
                                    }}
                                    onClick={handleCursorPosition}
                                    onKeyUp={handleCursorPosition} 
                                    {...register("bot_response", {
                                        required: {
                                            value: true,
                                            message:"Campo requerido.",
                                        },
                                        pattern: {
                                            value: /\S+/,
                                            message: "No puede ser solo espacios en blanco",
                                        },
                                    })}
                                />
                                {errors.bot_response && <Alert>* {errors.bot_response.message}</Alert>}
                                <p
                                    style={{
                                        fontSize: 11,
                                        color: "#6C757D",
                                        fontWeight: 500,
                                    }}
                                >
                                    Respuesta que se le envía al cliente.
                                </p>
                                <div className="d-flex align-items-center">
                                    <div className="pt-2">
                                        <button
                                            className="text-dark border-0 bg-transparent d-block pointer"
                                            type="button"
                                            onClick={(event) =>
                                                handleClickModalVariables(
                                                    event,
                                                    "bot_response",
                                                    "node"
                                                )
                                            }
                                        >
                                            <VscVariableGroup
                                                style={{ fontSize: "18px" }}
                                            />
                                        </button>
                                    </div>
                                    <div
                                        className="picker-container pt-4"
                                        style={{ width: "100%" }}
                                    >
                                        <i
                                            className={
                                                showPicker
                                                    ? "icon-smile emoji-icon active"
                                                    : "icon-smile emoji-icon"
                                            }
                                            style={{ fontSize: "22px" }}
                                            onClick={() =>
                                                setShowPicker((val) => !val)
                                            }
                                        />

                                        {showPicker && (
                                            <Picker
                                                pickerStyle={{
                                                    width: "100%",
                                                    top: "30px",
                                                    position: "absolute",
                                                    zIndex: "999",
                                                }}
                                                onEmojiClick={onEmojiClick}
                                                groupNames={{
                                                    smileys_people:
                                                        "Emoticones y personas",
                                                    animals_nature:
                                                        "Animales y naturaleza",
                                                    food_drink:
                                                        "Alimentos y bebidas",
                                                    travel_places:
                                                        "Viajes y lugares",
                                                    activities: "Actividades",
                                                    objects: "Objetos",
                                                    symbols: "Símbolos",
                                                    flags: "Banderas",
                                                    recently_used: "Recientes",
                                                }}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex align-items-center mb-3 mt-4">
                            <input
                                name="regularExpressions"
                                id="regularExpressions"
                                type="checkbox"
                                className="cmn-toggle cmn-toggle-round-flat"
                                value={selectedNode.data?.advanced?.regularExpressions?.active || false}
                                checked={selectedNode.data?.advanced?.regularExpressions?.active || false}
                                onChange={(e) =>
                                    handleInputChange(
                                        "advanced.regularExpressions.active",
                                        e.target.checked
                                    )
                                }
                            />
                            <label
                                className="mr-2 mb-0"
                                htmlFor="regularExpressions"
                            ></label>

                            <div
                                style={{
                                    fontSize: 12,
                                    color: "#6C757D",
                                    fontWeight: 500,
                                }}
                            >
                                Expresiones regulares
                            </div>
                        </div>
                        {selectedNode.data?.advanced?.regularExpressions
                            ?.active && (
                            <div
                                className="col p-0 mt-2 mb-4 panel-group collapsible"
                                id="user-collapsible"
                            >
                                <div className="panel panel-default">
                                    <div
                                        className="panel-heading p-2"
                                        style={{
                                            border: "1px solid #4F4F4F",
                                            borderRadius: "4px",
                                        }}
                                    >
                                        <div
                                            id="title-regularExpression"
                                            className="panel-title d-flex justify-content-between align-items-center pointer"
                                            onClick={() =>
                                                handleDropdown(
                                                    "dropdownRegularExpression"
                                                )
                                            }
                                        >
                                            <span>Expresiones Regulares</span>
                                            {dropdownRegularExpression ? (
                                                <IoIosArrowDropup
                                                    className="pointer mr-3"
                                                    style={{ fontSize: "20px" }}
                                                />
                                            ) : (
                                                <IoIosArrowDropdown
                                                    className="pointer mr-3"
                                                    style={{ fontSize: "20px" }}
                                                />
                                            )}
                                        </div>
                                    </div>

                                    {dropdownRegularExpression ? (
                                        <div
                                            className="panel-collapse p-2"
                                            style={{
                                                borderRadius: "4px",
                                            }}
                                        >
                                            <div
                                                className="panel-body"
                                                style={{ paddingInline: 0 }}
                                            >
                                                <div className="col p-0 mt-4">
                                                    <label>
                                                        Tipo de expresión:{" "}
                                                    </label>
                                                    <div className="su-control-group d-flex">
                                                        <Select
                                                            styles={
                                                                isDark == "true"
                                                                    ? customStylesDark
                                                                    : customStyles
                                                            }
                                                            className="form-control basic-multi-select"
                                                            placeholder="Seleccionar"
                                                            value={
                                                                selectedNode.data
                                                                    ?.advanced
                                                                    ?.regularExpressions
                                                                    ?.type || false
                                                            }
                                                            name="expression_type"
                                                            onChange={(value) =>
                                                                handleInputChange("advanced.regularExpressions.type", value)
                                                            }
                                                            options={[
                                                                {
                                                                    label: "Fecha",
                                                                    id: "date",
                                                                },
                                                                {
                                                                    label: "Hora",
                                                                    id: "hour",
                                                                },
                                                                {
                                                                    label: "Correo",
                                                                    id: "mail",
                                                                },
                                                                {
                                                                    label: "Numérico",
                                                                    id: "number",
                                                                },
                                                                {
                                                                    label: "Teléfono",
                                                                    id: "phone",
                                                                },
                                                            ]}
                                                            classNamePrefix="select"
                                                            noOptionsMessage={() =>
                                                                "Sin opciones"
                                                            }
                                                            getOptionLabel={(
                                                                option
                                                            ) => (
                                                                <div
                                                                    className="option d-flex"
                                                                    style={{
                                                                        fontSize:
                                                                            "15px",
                                                                    }}
                                                                >
                                                                    {option.label}
                                                                </div>
                                                            )}
                                                            getOptionValue={(
                                                                option
                                                            ) => option.id}
                                                        />
                                                    </div>
                                                </div>
                                                {![
                                                    "number",
                                                    "mail",
                                                    "phone",
                                                ].includes(
                                                    selectedNode.data?.advanced
                                                        ?.regularExpressions?.type
                                                        ?.id
                                                ) && (
                                                    <div className="col p-0 mt-4">
                                                        <label
                                                            htmlFor="expressionFormat"
                                                            style={{
                                                                color: "#6C757D",
                                                                fontWeight: 600,
                                                            }}
                                                        >
                                                            Formato:{" "}
                                                        </label>
                                                        <div>
                                                            <Select
                                                                styles={
                                                                    isDark == "true"
                                                                        ? customStylesDark
                                                                        : customStyles
                                                                }
                                                                className="form-control basic-multi-select"
                                                                placeholder="Seleccionar"
                                                                value={
                                                                    selectedNode
                                                                        .data
                                                                        ?.advanced
                                                                        ?.regularExpressions
                                                                        ?.format ||
                                                                    false
                                                                }
                                                                name="expressionFormat"
                                                                onChange={(value) =>
                                                                    handleInputChange("advanced.regularExpressions.format", value)
                                                                }
                                                                options={
                                                                    selectedNode
                                                                        .data
                                                                        ?.advanced
                                                                        ?.regularExpressions
                                                                        ?.type
                                                                        ?.id ===
                                                                    "date"
                                                                        ? [
                                                                            {
                                                                                label: "mm/dd/aaaa",
                                                                                id: "mm/dd/aaaa",
                                                                            },
                                                                            {
                                                                                label: "mm/dd/aa",
                                                                                id: "mm/dd/aa",
                                                                            },
                                                                            {
                                                                                label: "dd/mm/aaaa",
                                                                                id: "dd/mm/aaaa",
                                                                            },
                                                                            {
                                                                                label: "dd/mm/aa",
                                                                                id: "dd/mm/aa",
                                                                            },
                                                                            {
                                                                                label: "dd-mm-aaaa",
                                                                                id: "dd-mm-aaaa",
                                                                            },
                                                                            {
                                                                                label: "dd-mm-aa",
                                                                                id: "dd-mm-aa",
                                                                            },
                                                                            {
                                                                                label: "mm-dd-aaaa",
                                                                                id: "mm-dd-aaaa",
                                                                            },
                                                                            {
                                                                                label: "mm-dd-aa",
                                                                                id: "mm-dd-aa",
                                                                            },
                                                                            {
                                                                                label: "aaaa-mm-dd",
                                                                                id: "aaaa-mm-dd",
                                                                            },
                                                                            {
                                                                                label: "m/d/aaaa",
                                                                                id: "m/d/aaaa",
                                                                            },
                                                                            {
                                                                                label: "m/d/aa",
                                                                                id: "m/d/aa",
                                                                            },
                                                                            {
                                                                                label: "d/m/aaaa",
                                                                                id: "d/m/aaaa",
                                                                            },
                                                                            {
                                                                                label: "d/m/aa",
                                                                                id: "d/m/aa",
                                                                            },
                                                                            {
                                                                                label: "m-d-aaaa",
                                                                                id: "m-d-aaaa",
                                                                            },
                                                                            {
                                                                                label: "m-d-aa",
                                                                                id: "m-d-aa",
                                                                            },
                                                                            {
                                                                                label: "d-m-aaaa",
                                                                                id: "d-m-aaaa",
                                                                            },
                                                                            {
                                                                                label: "d-m-aa",
                                                                                id: "d-m-aa",
                                                                            },
                                                                            {
                                                                                label: "aaaa-mm-dd",
                                                                                id: "aaaa-mm-dd",
                                                                            },
                                                                        ]
                                                                        : [
                                                                            {
                                                                                label: "hh:mm",
                                                                                id: "hh:mm",
                                                                            },
                                                                        ]
                                                                }
                                                                classNamePrefix="select"
                                                                noOptionsMessage={() =>
                                                                    "Sin opciones"
                                                                }
                                                                getOptionLabel={(
                                                                    option
                                                                ) => (
                                                                    <div
                                                                        className="option d-flex"
                                                                        style={{
                                                                            fontSize:
                                                                                "15px",
                                                                        }}
                                                                    >
                                                                        {
                                                                            option.label
                                                                        }
                                                                    </div>
                                                                )}
                                                                getOptionValue={(
                                                                    option
                                                                ) => option.id}
                                                            />
                                                            <p
                                                                style={{
                                                                    fontSize: 11,
                                                                    color: "#6C757D",
                                                                    fontWeight: 600,
                                                                }}
                                                            >
                                                                Formato de la
                                                                respuesta que se le
                                                                solicta al usuario.
                                                            </p>
                                                        </div>
                                                    </div>
                                                )}
                                                <div className="col p-0 mt-4">
                                                    <label
                                                        htmlFor="expressionErrorMessage"
                                                        style={{
                                                            color: "#6C757D",
                                                            fontWeight: 600,
                                                        }}
                                                    >
                                                        Mensaje de error:{" "}
                                                    </label>
                                                    <div>
                                                        <textarea
                                                            className="form-control"
                                                            id="expressionErrorMessage"
                                                            name="expressionErrorMessage"
                                                            type="text"
                                                            style={{
                                                                width: "100%",
                                                                height: "80px",
                                                                borderRadius: "4px",
                                                            }}
                                                            {...register("regular_expressions_error_message", {
                                                                required: {
                                                                    value: selectedNode.data?.advanced?.regularExpressions?.active ? true : false,
                                                                    message:"Campo requerido.",
                                                                },
                                                                pattern: {
                                                                    value: /\S+/,
                                                                    message: "No puede ser solo espacios en blanco",
                                                                },
                                                            })}
                                                        />
                                                        {errors.regular_expressions_error_message && <Alert>* {errors.regular_expressions_error_message.message}</Alert>}
                                                        <p
                                                            style={{
                                                                fontSize: 11,
                                                                color: "#6C757D",
                                                                fontWeight: 600,
                                                            }}
                                                        >
                                                            Mensaje que envía el
                                                            cliente para activar la
                                                            respuesta.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        )}
                        <div
                            className="col p-2"
                            style={{
                                border: "1px solid #000",
                                borderRadius: "4px",
                                height: "auto",
                            }}
                        >
                            <div className="col p-0">
                                <span style={{ fontWeight: "bolder" }}>
                                    Estados de la Solicitud
                                </span>
                            </div>
                            <div className="col p-0">
                                <label
                                    htmlFor="succes"
                                    style={{ color: "#6C757D", fontWeight: 500 }}
                                >
                                    Solicitud exitosa:{" "}
                                </label>
                                <div className="d-flex align-items-center">
                                    <FaCheckCircle
                                        className="mr-1"
                                        style={{
                                            fontSize: "22px",
                                            color: "#94CF96",
                                        }}
                                    />
                                    <input
                                        className="form-control"
                                        id="succes"
                                        name="succes"
                                        type="text"
                                        style={{ width: "100%", height: "35px" }}
                                        value={selectedNode.data.succes || ""}
                                        onChange={(e) => {
                                            handleSelectedNode((prevInputValue) => {
                                                return {
                                                    ...prevInputValue,
                                                    data: {
                                                        ...prevInputValue.data,
                                                        succes: e.target.value,
                                                    },
                                                };
                                            });
                                        }}
                                        readOnly
                                    />
                                </div>
                            </div>
                            <div className="col p-0 mt-2">
                                <label
                                    htmlFor="failed"
                                    style={{ color: "#6C757D", fontWeight: 500 }}
                                >
                                    Solicitud fallida:{" "}
                                </label>
                                <div className="d-flex align-items-center">
                                    <BiSolidError
                                        className="mr-1"
                                        style={{
                                            fontSize: "22px",
                                            color: "#F88E86",
                                        }}
                                    />
                                    <input
                                        className="form-control"
                                        id="failed"
                                        name="failed"
                                        type="text"
                                        style={{ width: "100%", height: "35px" }}
                                        value={selectedNode.data.failed || ""}
                                        onChange={(e) => {
                                            handleSelectedNode((prevInputValue) => {
                                                return {
                                                    ...prevInputValue,
                                                    data: {
                                                        ...prevInputValue.data,
                                                        failed: e.target.value,
                                                    },
                                                };
                                            });
                                        }}
                                        readOnly
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className="d-flex justify-content-end mt-4"
                        style={{ gap: "8px" }}
                    >
                        <a
                            href="javascript:;"
                            className="btn btn-lg btn-default mr-1"
                            onClick={() => {
                                setTimeout(() => {
                                    handleSelectedNode(null);
                                    setDropdownRegularExpression(false);
                                }, 200);
                            }}
                        >
                            Cerrar
                        </a>

                        <button
                            style={{
                                width: "100px",
                                backgroundColor: "#7ECCD8",
                                borderColor: "#7ECCD8",
                                color: "#e9edef",
                                boxShadow: "2px 2px 0px 0px #7ECCD8",
                            }}
                            className="btn btn-lg"
                            type="submit"
                        >
                            {loadingUpdate ? (
                                <FaSpinner
                                    className="fa-spin"
                                    style={{ fontSize: "15px" }}
                                />
                            ) : (
                                <span>Guardar</span>
                            )}
                        </button>
                    </div>
                </form>
            </div>

            <ToastContainer
                position="top-center"
                autoClose={52000}
                // autoClose={false}
                newestOnTop={false}
                closeOnClick={false}
                rtl={false}
                pauseOnFocusLoss
                draggable={false}
                // draggable
                theme={
                    JSON.parse(localStorage.getItem("THEME")) ? "dark" : "light"
                }
                toastClassName="custom-toast" // Aplica estilos personalizados a cada toast individualmente
                bodyClassName="custom-toast-body"
            />
        </>
    );
};

export default QueryApi;

const Alert = styledComponent.span`
  color: red;
  display: block;
  width: 100%;
  // width: 200px;
`;
