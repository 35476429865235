import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
// import { Recorder } from "react-voice-recorder";
import { AudioRecorder, useAudioRecorder } from 'react-audio-voice-recorder';
import { BsTrash3 } from "react-icons/bs";
import { IoMdSend } from "react-icons/io";

const SendMessageOrRecordAudio = ({
  recording,
  contenMessage,
  selectedEmoji,
  isRecor,
  audioDetails,
  dataFilesRef,
  setIsMedia,
  setFileType,
  setIsRecor,
  setAudioDetails,
  setRecording,
  setSelectedFiles,
  onSendMessage,
  convertRecorBase64,
  focusLastMessage
}) => {
  const recorderControls = useAudioRecorder();
  const [inactiveState, setInactiveState] = useState(false);
  const {data: isDarkTheme} = useQuery({
    queryKey: ['isDarkTheme'],
    queryFn: () => {
      const value = localStorage.getItem('IS_DARK');
      return value === 'true';
    }
  });

  const handleAudioUpload = (file) => {
    console.log('Actualizando audio.......')
    console.log(file);
  };

  const handleCountDownAudio = (data) => {
    console.log('grabando audio.......')
    setRecording(true);
  };

  //Funciones para controlar el record-audio
  const handleAudioStop = async (data) => {
    console.log("Data del audio: ", data);
    data.blob.name = "record.webm";
    setAudioDetails((prevMessage) => { return data})
    setRecording(false);
    setIsRecor(true);
    //await uploadToS3(data.blob);

    const selectedFiles = [];
    const base64 = await convertRecorBase64(data.blob);
    if (base64) {
      selectedFiles.push({
        src: base64,
        name: "record.webm",
        size: `${((data.blob.size * 1) / 1000000).toFixed(2)} MB`,
        file: data.blob,
        type: "audio",
        url: data.url
      });
    }

    setSelectedFiles(selectedFiles);
    dataFilesRef.current = selectedFiles;
  };

  const handleResetAudio = () => {
    console.log('limpiando audio.......')
    setAudioDetails((prevMessage) => { return {url: null, blob: null, chunks: null, duration: {h: 0, m: 0, s: 0, ms: 0}}})
  };
  
  const svgPause = isDarkTheme 
    ? 'data:image/svg+xml;base64,PCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj4KDTwhLS0gVXBsb2FkZWQgdG86IFNWRyBSZXBvLCB3d3cuc3ZncmVwby5jb20sIFRyYW5zZm9ybWVkIGJ5OiBTVkcgUmVwbyBNaXhlciBUb29scyAtLT4KPHN2ZyB3aWR0aD0iODAwcHgiIGhlaWdodD0iODAwcHgiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KDTxnIGlkPSJTVkdSZXBvX2JnQ2FycmllciIgc3Ryb2tlLXdpZHRoPSIwIi8+Cg08ZyBpZD0iU1ZHUmVwb190cmFjZXJDYXJyaWVyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KDTxnIGlkPSJTVkdSZXBvX2ljb25DYXJyaWVyIj4gPHBhdGggZD0iTTggNVYxOU0xNiA1VjE5IiBzdHJva2U9IiNhZWJhYzEiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+IDwvZz4KDTwvc3ZnPg=='
    : 'data:image/svg+xml;base64,PCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj4KDTwhLS0gVXBsb2FkZWQgdG86IFNWRyBSZXBvLCB3d3cuc3ZncmVwby5jb20sIFRyYW5zZm9ybWVkIGJ5OiBTVkcgUmVwbyBNaXhlciBUb29scyAtLT4KPHN2ZyB3aWR0aD0iNjRweCIgaGVpZ2h0PSI2NHB4IiB2aWV3Qm94PSIwIDAgMjQgMjQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+Cg08ZyBpZD0iU1ZHUmVwb19iZ0NhcnJpZXIiIHN0cm9rZS13aWR0aD0iMCIvPgoNPGcgaWQ9IlNWR1JlcG9fdHJhY2VyQ2FycmllciIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cg08ZyBpZD0iU1ZHUmVwb19pY29uQ2FycmllciI+IDxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNOSA3QzkgNi40NDc3MiA4LjU1MjI4IDYgOCA2QzcuNDQ3NzIgNiA3IDYuNDQ3NzIgNyA3VjE3QzcgMTcuNTUyMyA3LjQ0NzcyIDE4IDggMThDOC41NTIyOCAxOCA5IDE3LjU1MjMgOSAxN1Y3Wk0xNyA3QzE3IDYuNDQ3NzIgMTYuNTUyMyA2IDE2IDZDMTUuNDQ3NyA2IDE1IDYuNDQ3NzIgMTUgN1YxN0MxNSAxNy41NTIzIDE1LjQ0NzcgMTggMTYgMThDMTYuNTUyMyAxOCAxNyAxNy41NTIzIDE3IDE3VjdaIiBmaWxsPSIjNDU1YTY0Ii8+IDwvZz4KDTwvc3ZnPg==';
  const svgResume = isDarkTheme 
    ? 'data:image/svg+xml;base64,PCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj4KDTwhLS0gVXBsb2FkZWQgdG86IFNWRyBSZXBvLCB3d3cuc3ZncmVwby5jb20sIFRyYW5zZm9ybWVkIGJ5OiBTVkcgUmVwbyBNaXhlciBUb29scyAtLT4KPHN2ZyB3aWR0aD0iODAwcHgiIGhlaWdodD0iODAwcHgiIHZpZXdCb3g9IjAgMCAxNiAxNiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiBmaWxsPSIjMDAwMDAwIj4KDTxnIGlkPSJTVkdSZXBvX2JnQ2FycmllciIgc3Ryb2tlLXdpZHRoPSIwIi8+Cg08ZyBpZD0iU1ZHUmVwb190cmFjZXJDYXJyaWVyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KDTxnIGlkPSJTVkdSZXBvX2ljb25DYXJyaWVyIj4gPHBhdGggZD0ibSAyIDIuNSB2IDExIGMgMCAxLjUgMS4yNjk1MzEgMS40OTIxODggMS4yNjk1MzEgMS40OTIxODggaCAwLjEyODkwNyBjIDAuMjQ2MDkzIDAuMDAzOTA2IDAuNDg4MjgxIC0wLjA1MDc4MiAwLjY5OTIxOCAtMC4xNzE4NzYgbCA5Ljc5Njg3NSAtNS41OTc2NTYgYyAwLjQzMzU5NCAtMC4yNDIxODcgMC42NTYyNSAtMC43MzQzNzUgMC42NTYyNSAtMS4yMjY1NjIgYyAwIC0wLjQ5MjE4OCAtMC4yMjI2NTYgLTAuOTg0Mzc1IC0wLjY1NjI1IC0xLjIyMjY1NiBsIC05Ljc5Njg3NSAtNS41OTc2NTcgYyAtMC4yMTA5MzcgLTAuMTIxMDkzIC0wLjQ1MzEyNSAtMC4xNzU3ODEgLTAuNjk5MjE4IC0wLjE3NTc4MSBoIC0wLjEyODkwNyBzIC0xLjI2OTUzMSAwIC0xLjI2OTUzMSAxLjUgeiBtIDAgMCIgZmlsbD0iI2FlYmFjMSAiLz4gPC9nPgoNPC9zdmc+'
    : 'data:image/svg+xml;base64,PCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj4KDTwhLS0gVXBsb2FkZWQgdG86IFNWRyBSZXBvLCB3d3cuc3ZncmVwby5jb20sIFRyYW5zZm9ybWVkIGJ5OiBTVkcgUmVwbyBNaXhlciBUb29scyAtLT4KPHN2ZyB3aWR0aD0iNjRweCIgaGVpZ2h0PSI2NHB4IiB2aWV3Qm94PSIwIDAgMTYgMTYiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgZmlsbD0iIzAwMDAwMCI+Cg08ZyBpZD0iU1ZHUmVwb19iZ0NhcnJpZXIiIHN0cm9rZS13aWR0aD0iMCIvPgoNPGcgaWQ9IlNWR1JlcG9fdHJhY2VyQ2FycmllciIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cg08ZyBpZD0iU1ZHUmVwb19pY29uQ2FycmllciI+IDxwYXRoIGQ9Im0gMiAyLjUgdiAxMSBjIDAgMS41IDEuMjY5NTMxIDEuNDkyMTg4IDEuMjY5NTMxIDEuNDkyMTg4IGggMC4xMjg5MDcgYyAwLjI0NjA5MyAwLjAwMzkwNiAwLjQ4ODI4MSAtMC4wNTA3ODIgMC42OTkyMTggLTAuMTcxODc2IGwgOS43OTY4NzUgLTUuNTk3NjU2IGMgMC40MzM1OTQgLTAuMjQyMTg3IDAuNjU2MjUgLTAuNzM0Mzc1IDAuNjU2MjUgLTEuMjI2NTYyIGMgMCAtMC40OTIxODggLTAuMjIyNjU2IC0wLjk4NDM3NSAtMC42NTYyNSAtMS4yMjI2NTYgbCAtOS43OTY4NzUgLTUuNTk3NjU3IGMgLTAuMjEwOTM3IC0wLjEyMTA5MyAtMC40NTMxMjUgLTAuMTc1NzgxIC0wLjY5OTIxOCAtMC4xNzU3ODEgaCAtMC4xMjg5MDcgcyAtMS4yNjk1MzEgMCAtMS4yNjk1MzEgMS41IHogbSAwIDAiIGZpbGw9IiM0NTVhNjQiLz4gPC9nPgoNPC9zdmc+';
  const svgSendAudio = 'data:image/svg+xml;base64,PCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj4KDTwhLS0gVXBsb2FkZWQgdG86IFNWRyBSZXBvLCB3d3cuc3ZncmVwby5jb20sIFRyYW5zZm9ybWVkIGJ5OiBTVkcgUmVwbyBNaXhlciBUb29scyAtLT4KPHN2ZyBmaWxsPSIjNDU1YTY0IiB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2aWV3Qm94PSIwIDAgMjQgMjQiIHhtbDpzcGFjZT0icHJlc2VydmUiIHdpZHRoPSI4MDBweCIgaGVpZ2h0PSI4MDBweCI+Cg08ZyBpZD0iU1ZHUmVwb19iZ0NhcnJpZXIiIHN0cm9rZS13aWR0aD0iMCIvPgoNPGcgaWQ9IlNWR1JlcG9fdHJhY2VyQ2FycmllciIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cg08ZyBpZD0iU1ZHUmVwb19pY29uQ2FycmllciI+IDxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+IC5zdDB7ZmlsbDpub25lO30gPC9zdHlsZT4gPGcgaWQ9InN1cmZhY2UxIj4gPHBhdGggZD0iTTIsM3Y3LjhMMTgsMTJMMiwxMy4yVjIxbDIwLTlMMiwzeiIvPiA8L2c+IDxyZWN0IGNsYXNzPSJzdDAiIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIvPiA8L2c+Cg08L3N2Zz4=';
  const svgMic = isDarkTheme 
    ? 'data:image/svg+xml;base64,PCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj4KDTwhLS0gVXBsb2FkZWQgdG86IFNWRyBSZXBvLCB3d3cuc3ZncmVwby5jb20sIFRyYW5zZm9ybWVkIGJ5OiBTVkcgUmVwbyBNaXhlciBUb29scyAtLT4KPHN2ZyB2ZXJzaW9uPSIxLjAiIGlkPSJMYXllcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iODAwcHgiIGhlaWdodD0iODAwcHgiIHZpZXdCb3g9IjAgMCA2NCA2NCIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgNjQgNjQiIHhtbDpzcGFjZT0icHJlc2VydmUiIGZpbGw9IiMwMDAwMDAiPgoNPGcgaWQ9IlNWR1JlcG9fYmdDYXJyaWVyIiBzdHJva2Utd2lkdGg9IjAiLz4KDTxnIGlkPSJTVkdSZXBvX3RyYWNlckNhcnJpZXIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgoNPGcgaWQ9IlNWR1JlcG9faWNvbkNhcnJpZXIiPiA8Zz4gPHBhdGggZmlsbD0iI2FlYmFjMSIgZD0iTTMyLDQ0YzYuNjI5LDAsMTItNS4zNzEsMTItMTJWMTJjMC02LjYyOS01LjM3MS0xMi0xMi0xMlMyMCw1LjM3MSwyMCwxMnYyMEMyMCwzOC42MjksMjUuMzcxLDQ0LDMyLDQ0eiIvPiA8cGF0aCBmaWxsPSIjYWViYWMxIiBkPSJNNTIsMjhjLTIuMjExLDAtNCwxLjc4OS00LDRjMCw4LjgzNi03LjE2NCwxNi0xNiwxNnMtMTYtNy4xNjQtMTYtMTZjMC0yLjIxMS0xLjc4OS00LTQtNHMtNCwxLjc4OS00LDQgYzAsMTEuODg3LDguNjU2LDIxLjczLDIwLDIzLjY0MVY2MGMwLDIuMjExLDEuNzg5LDQsNCw0czQtMS43ODksNC00di00LjM1OUM0Ny4zNDQsNTMuNzMsNTYsNDMuODg3LDU2LDMyIEM1NiwyOS43ODksNTQuMjExLDI4LDUyLDI4eiIvPiA8L2c+IDwvZz4KDTwvc3ZnPg=='
    : 'data:image/svg+xml;base64,PCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj4KDTwhLS0gVXBsb2FkZWQgdG86IFNWRyBSZXBvLCB3d3cuc3ZncmVwby5jb20sIFRyYW5zZm9ybWVkIGJ5OiBTVkcgUmVwbyBNaXhlciBUb29scyAtLT4KPHN2ZyB3aWR0aD0iNjRweCIgaGVpZ2h0PSI2NHB4IiB2aWV3Qm94PSIwIDAgMjQgMjQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+Cg08ZyBpZD0iU1ZHUmVwb19iZ0NhcnJpZXIiIHN0cm9rZS13aWR0aD0iMCIvPgoNPGcgaWQ9IlNWR1JlcG9fdHJhY2VyQ2FycmllciIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cg08ZyBpZD0iU1ZHUmVwb19pY29uQ2FycmllciI+IDxwYXRoIGQ9Ik04IDVDOCAyLjc5MDg2IDkuNzkwODYgMSAxMiAxQzE0LjIwOTEgMSAxNiAyLjc5MDg2IDE2IDVWMTJDMTYgMTQuMjA5MSAxNC4yMDkxIDE2IDEyIDE2QzkuNzkwODYgMTYgOCAxNC4yMDkxIDggMTJWNVoiIGZpbGw9IiM0NTVhNjQiLz4gPHBhdGggZD0iTTYuMjUgMTEuODQzOFYxMkM2LjI1IDEzLjUyNSA2Ljg1NTggMTQuOTg3NSA3LjkzNDE0IDE2LjA2NTlDOS4wMTI0NyAxNy4xNDQyIDEwLjQ3NSAxNy43NSAxMiAxNy43NUMxMy41MjUgMTcuNzUgMTQuOTg3NSAxNy4xNDQyIDE2LjA2NTkgMTYuMDY1OUMxNy4xNDQyIDE0Ljk4NzUgMTcuNzUgMTMuNTI1IDE3Ljc1IDEyVjExLjg0MzhDMTcuNzUgMTEuMjkxNSAxOC4xOTc3IDEwLjg0MzggMTguNzUgMTAuODQzOEgxOS4yNUMxOS44MDIzIDEwLjg0MzggMjAuMjUgMTEuMjkxNSAyMC4yNSAxMS44NDM3VjEyQzIwLjI1IDE0LjE4OCAxOS4zODA4IDE2LjI4NjUgMTcuODMzNiAxNy44MzM2QzE2LjU4NDIgMTkuMDgzMSAxNC45NzUzIDE5Ljg5MDMgMTMuMjUgMjAuMTU0OFYyMkMxMy4yNSAyMi41NTIzIDEyLjgwMjMgMjMgMTIuMjUgMjNIMTEuNzVDMTEuMTk3NyAyMyAxMC43NSAyMi41NTIzIDEwLjc1IDIyVjIwLjE1NDhDOS4wMjQ3MSAxOS44OTAzIDcuNDE1NzkgMTkuMDgzMSA2LjE2NjM3IDE3LjgzMzZDNC42MTkxOSAxNi4yODY1IDMuNzUgMTQuMTg4IDMuNzUgMTJWMTEuODQzOEMzLjc1IDExLjI5MTUgNC4xOTc3MiAxMC44NDM4IDQuNzUgMTAuODQzOEg1LjI1QzUuODAyMjggMTAuODQzOCA2LjI1IDExLjI5MTUgNi4yNSAxMS44NDM4WiIgZmlsbD0iIzQ1NWE2NCIvPiA8L2c+Cg08L3N2Zz4=';

  const addAudioElement = async (blob) => {
    if (recorderControls.recordingBlob.size > 0 && !inactiveState) {
      const selectedFiles = [];
      const base64 = await convertRecorBase64(blob);
      if (base64) {
        selectedFiles.push({
          src: base64,
          name: "record.webm",
          size: `${((blob.size * 1) / 1000000).toFixed(2)} MB`,
          file: blob,
          type: "audio",
          url: blob ? URL.createObjectURL(blob) : null
        });
      }

      setRecording(false);
      setSelectedFiles(selectedFiles);
      dataFilesRef.current = selectedFiles;
      onSendMessage({});
      setTimeout(function(){
        focusLastMessage("template-4745");
      }, 200);
    }
  };

  useEffect(() => {
    const imgMic = document.querySelector('.audio-recorder-mic');
    if (imgMic) {
      imgMic.src = svgMic;
        const imgPause = document.querySelector('.audio-recorder-options');
        imgPause.src = imgPause.title === 'Pause recording' 
          ? svgPause
          : svgResume;
    
        if (
          recorderControls.mediaRecorder?.state === 'recording' ||
          recorderControls.mediaRecorder?.state === 'paused'
        ) {
          imgMic.src = svgSendAudio;
          setRecording(true);
          setIsRecor(true);
          setInactiveState(false);
        }
    }
  }, [recorderControls]);

  useEffect(() => {
    if (dataFilesRef.current.length === 0) {
      setRecording(false);
      setIsRecor(false);
      setInactiveState(true);
      recorderControls.stopRecording();
    }
  }, [dataFilesRef.current]);

  return (
    <div 
      id ={isRecor && 'content-recorder'} 
      className={!recording ? "mx-3 d-flex recording justify-content-center" : "d-flex justify-content-center"} 
      style={!recording ? { alignItems: "center", zIndex: "998" } : { alignItems: "center", width: "100%" }}
      data-tooltip-id="my-tooltip" 
      data-tooltip-content="Enviar audio"
    >
      {contenMessage || selectedEmoji ? (
        <span className='d-flex ml-2 w-auto' style={{backgroundColor:"#25d366", padding:"8px 6px 8px 11px", borderRadius:"50%"}}>
          <IoMdSend id='icon-send-messages' className="send pointer" style={{ fontSize: "27px" }}
              onClick={() => {
                  onSendMessage({});
                  setTimeout(function(){
                    focusLastMessage("template-4699");
                  }, 200);
                  setIsMedia((isMedia) => { return false });
                  setFileType("");
              }}
          />
        </span>
      ) : (
        <div className='d-flex align-items-center justify-content-center'>
          {
            <AudioRecorder 
              onRecordingComplete={(blob) => addAudioElement(blob)}
              recorderControls={recorderControls}
              showVisualizer={true}
            />
          }
          {isRecor && (
            <BsTrash3 className="icon-trash-empty audio" style={{ fontSize: "30px" }}
              onClick={() => {
                setRecording(false);
                setIsRecor(false);
                setInactiveState(true);
                recorderControls.stopRecording();
              }}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default SendMessageOrRecordAudio;