import React, { useEffect, useRef, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import useAPI from "../../hooks/useAPI";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import logoLogin from "../../imgs/logo-login.png";
import logoLoginDark from "../../imgs/Sending_logo_dark.png";
import Spinner from "../plugins/Spinner";
import { FaEye, FaSpinner } from "react-icons/fa";
import InstallPWA from "./installPWA";
import './css/login.css';
import './css/chat.css';
import { useQueryClient } from '@tanstack/react-query';

import { toast, ToastContainer } from 'react-toastify';
import {sendToast} from "../plugins/toastAll";

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [optionsList, setOptionsList] = useState([]);
  const [message, setMessage] = useState("");
  const { register, handleSubmit, formState: { errors }} = useForm({ defaultValues: { email: "", password: "" } });
  const { urlAPI, urlAPI_1, urlAPI_2, fetchWithAuth } = useAPI();
  const queryClient = useQueryClient();

  const formOptions = useForm();
  const error2 = formOptions.formState.errors;

  useEffect(() => {
    console.log("Removing USER_TOKEN because it enters to login page....");
    localStorage.removeItem("USER_TOKEN");
    localStorage.removeItem("USER_NAME");
    localStorage.removeItem("USER_ROLEID");
    localStorage.removeItem("USER_ROLEDESCRIPTION");
    localStorage.removeItem("USER_ID");
  }, []);
  
  const onShowPassword = () => {
    if (showPassword) {
      setShowPassword(false);
    } else {
      setShowPassword(true);
    }
  };

  const getPackages = async () => {
    try {
        const url = urlAPI_2 + "packages";
        const options = { method: "GET", mode: "cors"};
        const response = await fetchWithAuth(url, options);
        const result = await response.json();
        console.log("Response of the API: obtener paquetes adicionales::::....>", result);
        setOptionsList(result.data)
    } catch (error) {
        console.log(error);
    }
  }

  const navigate = useNavigate();

  // Actualizar el estado de notificación de advertencias
  const putUser = async (id, noti) => {
    const data = noti === "notifyFinal" ? { notify: true, notifyFinal: true } : { notify: true };
    console.log("Data to send", data);    

    try {
      const url = urlAPI_2 + "users/" + id;
      const options = { method: "PUT", mode: "cors", body: JSON.stringify({isNotification : data})};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      console.log("Response of the API", result);
    } catch (error) {
      console.log(error);
    }
  };
  // Actualizar el estado de los limites en la compañia
  const putCompany = async (id, limit, total) => {
    const data = {
      totalConversations: total,
      limitConversations: limit,
      isActive: false,
    }
    console.log("Data to send", data);
    
    try {
      const url = urlAPI_2 + "companies/" + id;
      const options = { method: "PUT", mode: "cors", body: JSON.stringify({conversationsControl : data})};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      console.log("Response of the API", result);
    } catch (error) {
      console.log(error);
    }
  };

  // listar opciones para recargar paquete
  const Reload = ({ dark, closeToast }) => {
    const [isLoading, setIsLoading] = useState(false);

    const PaymentMethod = ({qr, link}) => {       
            return (
                <div className="grid grid-cols-[1fr_1px_80px] w-full">
                    <div className="flex flex-col p-4">
                        <h3 className="text-zinc-800 text-sm font-semibold">Selecciona la opción para realizar el pago</h3>
                        <div className="d-flex justify-content-between mt-4 pt-1">
                            
                            <div className="text-center w-50">
                                <div>
                                    <label>Escanear código QR</label>
                                </div>
                                <div>
                                    <img src={qr} alt="QR" style={{width: "65%"}}/>
                                </div>
                            </div>
                            <div className="text-center w-50">
                                <div>
                                    <label>Ir a la pasarela de pagos</label>
                                </div>
                                <div id="content-img-pay">
                                    <a href={link} target="_blank" rel="noreferrer" onClick={() => toast.dismiss()}>
                                    <img className="img-pay" src="/images/Icono-pagos.png" alt="QR" style={{width: "65%"}}/>
                                    </a>
                                </div>
                            </div>
    
                        </div>
                    </div>
                    <div className="d-flex justify-content-evenly mt-1 pt-1">
                        <a href="javascript:;" className="btn btn-lg btn-default mr-1" onClick={() => toast.dismiss()}>Cancelar</a>
                    </div>
                </div>
            );
    }

    const postReload = async (data) => {       
      const optionSelected = optionsList.find((option) => option.value == data.options);
  
      //#--> Enviar información para generar link de pago
      console.log('optionSelected::', optionSelected);
      
      setIsLoading(true);
      try {
        const url = urlAPI_1 + "payBill";
        const options = { method: "POST", mode: "cors", body: JSON.stringify(optionSelected)};
        const response = await fetchWithAuth(url, options);
        const result = await response.json();

        console.log("Response of the API: Post payBill-------=>", result);

        if (result.status === "error") {
            sendToast(result)
            setLoading(false);
        } else {
          // setPaymentOptions(true);
          const qr = result.data.data.routeQr;
          const link = result.data.data.routeLink;
          toast(<PaymentMethod qr={qr} link={link} />, {
              position: "top-center",
              autoClose: false,
              hideProgressBar: false,
              closeOnClick: false,
              pauseOnHover: true,
              draggable: false,
              theme: JSON.parse(dark) ? "dark" : "light",
              className: "custom-toast-container",
              bodyClassName: "custom-toast-body",
          });
          closeToast(false);
        }
          
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    }
    
    const formatCurrency = (value) => {
      return new Intl.NumberFormat("es-CO", {
          style: "currency",
          currency: "COP",
          minimumFractionDigits: 0,
      }).format(value);
    };
    return (
      <div className="grid grid-cols-[1fr_1px_80px] w-full">
        <form onSubmit={formOptions.handleSubmit(postReload)}>
          <div className="flex flex-col p-4">
            <h3 className="text-zinc-800 text-sm font-semibold">Selecciona la opción de tu interés</h3>
            <div className="d-flex justify-content-between mt-4 pt-1">
              {optionsList.map((option, index) => (
                <label key={index} id="reload-option" htmlFor={option.value} className="pointer p-2 mx-2">
                  <div className="d-flex justify-content-between radio mt-0">
                      <input
                        className="pointer"
                        style={{ accentColor: '#5BC0DE', width: "1rem" }}
                        type="radio"
                        id={option.value}
                        value={option.value}
                        {...formOptions.register("options", {
                            required: {
                                value: true,
                                message: "Debes seleccionar una opción.",
                            }
                        })}
                        onChange={(e) => { console.log('-------radio--------', e.target.value) }}
                      />
                      <label htmlFor={option.value} className="d-flex align-items-end radio-label"></label>
                      <div className="d-flex justify-content-between align-items-center w-100">
                          <label htmlFor={option.value} className="d-flex mb-0 ml-1" style={{ padding: "5px 2px 5px" }}>{option.title}</label>
                          <label htmlFor={option.value} className="d-flex mb-0 ml-1" style={{ padding: "5px 2px 5px" }}>{formatCurrency(option.amount)}</label>
                      </div>
                  </div>
                  <div className="text-jusify" style={{ fontSize: "0.9rem" }}>{option.body}</div>
                </label>
              ))}
            </div>
            {error2.options && <Alert className="w-100 mt-3 ml-2">* {error2.options.message}</Alert>}
            <div className="mt-3">
              <label style={{
                fontSize: "0.8rem",
                fontStyle: "italic",
                fontWeight: "100",
              }}>
                <i className="icon-info-circled" style={{color:"#46a3d6"}}></i> La opción adicional es válida hasta el final del período de reinicio (31 de enero de 2025).
              </label>
            </div>
          </div>
          <div className="d-flex justify-content-evenly mt-1 pt-1">
            <a
              href="javascript:;"
              className="btn btn-lg btn-default mr-1"
              onClick={() => {
                console.log('cancelarrrrr........')
                  closeToast(false);
              }}
            >
              Cancelar
            </a>
            {isLoading == true ? (
                <h2 className="text-center">
                    <FaSpinner className="fa-spin" style={{ fontSize: "25px" }} />
                </h2>
            ) : (
                <input
                    className="btn btn-lg btn-info"
                    value="Continuar"
                    type="submit"
                    disabled={isLoading} // Desactivar botón mientras está cargando
                />
            )}
          </div>
        </form>
      </div>
    )
  }

  const SplitButtons = ({ message, isButtons, closeToast, dark }) => {
    return (
      <div className="grid grid-cols-[1fr_1px_80px] w-full">
        <div className="flex flex-col p-4">
          <h3 className="text-zinc-800 text-sm font-semibold">¡Atención!</h3>
          <p className="text-sm">{message}</p>
        </div>
        <div className="bg-zinc-900/20 h-full" />
        {isButtons &&
          <div className="d-flex justify-content-evenly mt-1 pt-1">
            <a
              href="javascript:;"
              className="btn btn-lg btn-default mr-1"
              onClick={() => {
                console.log('cancelarrrrr........')
                  closeToast();
              }}
            >
              Cancelar
            </a>
            <input
              className="btn btn-lg btn-info"
              value="Recargar"
              type="button"
              onClick={() => {
                console.log('Recargandoooooo.......')
                closeToast(false)
                toast(<Reload dark={dark}/>, {
                  position: "top-center",
                  autoClose: false,
                  hideProgressBar: false,
                  closeOnClick: false,
                  pauseOnHover: true,
                  draggable: false,
                  progress: undefined,
                  theme: JSON.parse(dark) ? "dark" : "light",
                  className: "custom-toast-container", // Estilos personalizados para el toast
                  bodyClassName: "custom-toast-body",
                })
              }}
            />
          </div>
        }
      </div>
    );
  }

  const postLogin = async (values) => {
    try {
      setLoading(true);
      const url = urlAPI_2 + "auth/login";
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        body: JSON.stringify(values),
        headers: {
          "Content-type": "application/json",
        },
      });
      const result = await response.json();
      // console.log(result.token);
      
      
      //# POST Completado, se reinician lo campos
      if (!result.token) {
        setLoading(false);
        sendToast({type: "error", message: result.message})
      } else {
        localStorage.setItem("USER_TOKEN", result.token);
        localStorage.setItem("USER_NAME", result.nameUser);
        localStorage.setItem("USER_ROLEID", result.roleId);
        localStorage.setItem("USER_ROLEDESCRIPTION", result.roleDescription);
        localStorage.setItem("USER_ID", result.idUser);
        localStorage.setItem("COMPANY_ID", result.company);
        localStorage.setItem("USER_AREA", result.area);
        localStorage.setItem("IS_DARK", result.isDark);
        localStorage.setItem("COMPANY_PLAN", result.companyPlan);
      }

      if (result.token) {
        queryClient.setQueryData(['rolUser'], result.roleDescription);
        navigate("/home");

        // validamos si el plan es de las empresas de CCAS o Cenisoft para notificarle cuando ya estan llegando a su limite de conversaciones.
        if (result.isLimit && result.roleId !== "6284fdb097029c6d9743ccb2") {
          const { conversations: conv, idUser, company, isNotification, limit } = result;         
          // const notify = limit - 10;         //#--> Pruebas local 
          const notify = limit - 100;          
          const msjFinal = conv >= limit;
          const shouldNotify = conv >= notify && !isNotification?.notify;
          const shouldNotifyFinal = msjFinal && !isNotification?.notifyFinal;   
          console.log("Datos de la empresa", company, limit, conv, notify, msjFinal, shouldNotify, shouldNotifyFinal);
                
      
          if (shouldNotify || shouldNotifyFinal) {
            console.log("Se debe notificar al usuario que esta llegando a su limite de conversaciones😏");
            const message = msjFinal 
              ? `Has alcanzado el límite de conversaciones de tu plan (${limit} conversaciones/mes). Para continuar enviando mensajes, ¿consideras realizar una recarga a tu plan?.`
              : `¡Casi llegas a tu límite! Actualmente has consumido ${conv} conversaciones de las ${limit} disponibles. Si necesitas más, comunicate con nosotros.`;

              getPackages()
            
            if (msjFinal) {
              toast.warning(<SplitButtons message={message} isButtons={msjFinal} dark={result.isDark}/>, {
                position: "top-center",
                autoClose: false,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                theme: JSON.parse(result.isDark) ? "dark" : "light",
                className: "custom-toast-container", // Estilos personalizados para el toast
                bodyClassName: "custom-toast-body",
              })
              await putCompany(company, limit, conv);
              await putUser(idUser, "notifyFinal");
            } else {
              toast.info(<SplitButtons message={message} isButtons={msjFinal} dark={result.isDark}/>, {
                position: "top-center",
                autoClose: 10000,
                // autoClose: false,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                theme: JSON.parse(result.isDark) ? "dark" : "light",
                className: "custom-toast-container", // Estilos personalizados para el toast
                bodyClassName: "custom-toast-body",
              })
              await putUser(idUser, "notify");
            }
          }
        }
      } else {
        return;
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      {loading ? (
        <div className="content-spinner">
          <Spinner />
        </div>
      ) : null}
      <div id="main-login">
        <div className="container-fluid">
          <main id="login-register" className="loggin">
            <header>
              <h1>
                <a href="#">
                  <img src={logoLogin} alt="Logo" className="logo-sendingup" />
                  <img src={logoLoginDark} alt="Logo" className="logo-sendingup-dark" />
                </a>
              </h1>
            </header>
            <section className="box login">
              <header>
                <h3>Ingresar</h3>
              </header>
              <div className="padd">
                <form onSubmit={handleSubmit(postLogin)}>
                  <label>Correo electrónico</label>
                  <input
                    className="form-control"
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Correo electrónico"
                    {...register("email", {
                      required: {
                        value: true,
                        message: "* El email es requerido",
                      },
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: "* El formato no es correcto",
                      },
                    })}
                  />
                  {errors.email && <Alert>{errors.email.message}</Alert>}

                  <label>Contraseña</label>
                  <input
                    type={showPassword ? "text" : "password"}
                    id="password"
                    name="password"
                    className="form-control pr-4"
                    placeholder="Contraseña"
                    {...register("password", {
                      required: {
                        value: true,
                        message: "* La contraseña es requerida.",
                      },
                    })}
                  />
                  <button
                    type="button"
                    onClick={onShowPassword}
                    className="show-password"
                  >
                    <FaEye />
                  </button>

                  {errors.password && <Alert>{errors.password.message}</Alert>}

                  <button type="submit" className="btn btn-primary btn-lg">
                    Iniciar sesión
                  </button>
                  <p style={{ fontSize: "14px" }}>
                    <a
                      href="javascript:;"
                      onClick={() => navigate("/forgot-password")}
                      title="Recuperar la contraseña"
                      rel="nofollow"
                    >
                      ¿Olvidaste tu contraseña?
                    </a>
                  </p>
                </form>
                <div className="install-button-container">
                  <InstallPWA />
                </div>

              </div>
            </section>
          </main>
        </div>
      </div>
      <ToastContainer 
        position="top-center"
        autoClose={52000}
        // autoClose={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        // draggable
        theme={JSON.parse(localStorage.getItem("THEME")) ? "dark" : "light"}
        toastClassName="custom-toast" // Aplica estilos personalizados a cada toast individualmente
        bodyClassName="custom-toast-body"
      />
    </>
  );
};

export default Login;

const Alert = styled.span`
  color: red;
  display: block;
  width: 200px;
`;
