import React from 'react';
import dayjs from "dayjs";
import { BsCheckAll, BsCheck } from "react-icons/bs";
import { IoIosAlarm } from "react-icons/io";
import { FaTimesCircle } from "react-icons/fa";

const FooterMessage = ({ message, footerText }) => {
  return (
    <>
      {footerText.length > 0 ? (
        <div className="content-footer span-color">
          {message.bodyType === "interactive" ? footerText : footerText[0].text}
        </div>
      ) : null}

      {(message.originMessage !== "user" || message.bodyType === "text") && (
        <span className="agent-name">
          {message.agentAsign === "bot" ? `🤖 Bot (${message.botInfo})`
            : (message.agentAsign && message.agentAsign.length > 14) ? message.agentAsign.substr(0, 14) + "..."
              : message.agentAsign}
        </span>
      )}

      <time aria-hidden="true" className={message.bodyType === "sticker" ? "_6xe5 d-flex time-sticker align-items-center" : "_6xe5 d-flex align-items-center"}>
        {message.scheduled 
          ? dayjs(parseInt(message.scheduled, 10)).locale("es").format("DD-MMM hh:mm a")
          : dayjs(message.timestamp).locale("es").format("DD-MMM hh:mm a")
        }
        {(message.originMessage !== "user" && message.status === "delivered") || message.status === "sent" ? (
          <BsCheckAll className="ml-1" style={{ fontSize: "21px" }} />
        ) : (message.originMessage !== "user" && message.status === "pending") || message.status === "" ? (
          <BsCheck className="ml-1" style={{ fontSize: "21px" }} />
        ) : message.originMessage !== "user" && message.status === "read" ? (
          <BsCheckAll className="ml-1" style={{ color: "#5bc0de", fontSize: "21px" }} />
        ) : message.originMessage !== "user" && message.status === "failed" ? (
          <FaTimesCircle className="ml-1" style={{ color: "#dc3545", fontSize: "15px" }} /> 
        ) : null}
      </time>

      {message.scheduled && (
        <div className="d-flex align-items-center" style={{ float: "right" }}>
          <IoIosAlarm className="mr-2 icon-alarm" style={{ fontSize: "18px" }} />
        </div>
      )}
    </>
  );
};

export default FooterMessage;
