import React, {useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import useAPI from "../../../../hooks/useAPI";
import dayjs from "dayjs";

import styledComponent from "styled-components";
import Spinner from "../../../plugins/Spinner";
import Avvvatars from "avvvatars-react";
import Select from "react-select";
import { ToastContainer } from "react-toastify";
import { sendToast } from "../../../plugins/toastAll";
import {Box, List, ListItem, ListItemText } from "@mui/material";

import { MdOutlineRestartAlt } from "react-icons/md";
import { IoMdSend } from "react-icons/io";
import { IoClose } from "react-icons/io5";

const messageClasses = {
    user: 'preview-message-user',
    chatbot: 'preview-message-chatbot',
    agent_area: 'preview-message-transfer',
}

const Preview = ({
    handlePreview,
    chatBot,
    animateClass,
    isDark,
    countries,
    customStyles,
    customStylesDark
}) => {
    const { urlAPI_1, urlAPI_3, fetchWithAuth } = useAPI(); 
    const {watch,register,handleSubmit,formState: { errors },reset,} = useForm({
        defaultValues: {
            phone: "",
            name: ""
        },
    });
    
    const chatRef = useRef(null);
    const [openList, setOpenList] = useState(false);
    const [loading, setLoading] = useState(false);
    const [hiddenHeader, setHiddenHeader] = useState(false);
    const [messagePreview, setMessagePreview] = useState(null);
    const [listMessagesPreview, setListMessagesPreview] = useState([]);
    const [optionsList, setOptionList] = useState([]);

    //# Estado para almacenar todos los paises
    const [showPhoneNumber, setShowPhoneNumber] = useState(true);
    const [phoneNumber, setPhoneNumber] = useState(null);
    const [name, setName] = useState(null);
    const [selectedCountrie, setSelectedCountrie] = useState("");
    const [selectedClass, setSelectedClass] = useState("");
    const [errorCountrie, setErrorCountrie] = useState(false);

    const userLogout = localStorage.getItem("USER_NAME");

    //# Funcion para almacenar el codigo del país en el estado
    const handleChange = (selectedCountrie) => {
        setErrorCountrie(false);
        setSelectedCountrie(selectedCountrie);
        setSelectedClass(selectedCountrie.callingCodes[0]);
    };

    //# Funcion para validar si el numero de telefono esta registrado en SU
    const savePerson = async (values) => {
        setName(values.name);
        setPhoneNumber(values.phone);
        setShowPhoneNumber(false);
        setLoading(true);
        await closeChat(values.phone);
        await removeStatuses(true, values.phone);
    };

    //# Funcion para funcionamiento del chatbot preview
    const handleChatbotPreview = async (nextFlow = false, message = null) => {
        let newMessage = {};
        if(message) {
            newMessage = message
        } else {
            newMessage = {
                type: 'text',
                text: { body: messagePreview },
                from: 'user',
                timestamp: dayjs().format('HH:mm')
            };
        }
        if(!nextFlow) setListMessagesPreview(prev => [...prev, newMessage]);
    
        setMessagePreview(null);

        await new Promise(resolve => setTimeout(resolve, 800));
    
        const res = await chatbotPreview({
            message: newMessage,
            chatbot: chatBot,
            phoneNumber: `+${selectedCountrie.callingCodes[0]}${phoneNumber}`,
            person: {
                name: name ?? "Contacto sin nombre",
                lastName: "",
                email: "",
                phone: phoneNumber,
                callingCode: `+${selectedCountrie.callingCodes[0]}`,
                country: selectedCountrie.country,
                aditionalFields: {},
                tags: "",
                processCode: "",
            }
        });
        
        if(res?.type === "error") {
            sendToast({type: "error", message: res.message})
        }
    
        if(res?.type === "process_sos") {
            const updatedMessages = [res.content[0]];
    
            if (res.content.length > 1) {
                await new Promise(resolve => setTimeout(resolve, 500));
                updatedMessages.push(res.content[1]);
            }

            setListMessagesPreview(prev => [...prev, ...updatedMessages]);
        }

        if(res?.type === "process_action_response" || res?.type === "procces_automatic_next_flow") {
            handleChatbotPreview(true)
        }

        if (["process_actions", "process_handle_wrong_answer"].includes(res?.type)) {
            for (const content of res.content) {
                setListMessagesPreview(prev => [...prev, content]);
        
                await new Promise(resolve => setTimeout(resolve, 1200));
            }
        }
             
    };    

    //# Function para enviar datos al endpoint del preview
    const chatbotPreview = async (data) => {
        try {
          const url = urlAPI_3 + "chatBot/preview";
          const options = { method: "POST", mode: "cors", body: JSON.stringify(data)};
          const response = await fetchWithAuth(url, options);
          const result = await response.json();
          return result
        } catch (error) {
          console.log(error);
        }
    };

    //# Function para eliminar estados
    const removeStatuses = async (loading = true, phone = null) => {
        setLoading(loading);

        const dataStatuses = {
          phoneNumber: `+${selectedCountrie.callingCodes[0]}${phone ?? phoneNumber}`,
          chatBotId: chatBot._id
        }
        const url = urlAPI_3 + "chatBot/removeStatusesPreview";
        const options = { method: "POST", mode: "cors", body: JSON.stringify(dataStatuses)};
        const response = await fetchWithAuth(url, options);
        await response.json();
        setListMessagesPreview([]);
        setMessagePreview(null);
        setLoading(false);
    }; 

    //# Function para cerrar conversacion
    const closeChat = async (phone = null) => {
        
        const object = {
            chatStatus: "2",
            user: userLogout,
            feeling: []
        };

        const phoneContact = selectedCountrie.callingCodes[0]+(phone ?? phoneNumber);
        try {
            setLoading(true);
            const url = urlAPI_1 + "assignChat/" + phoneContact;
            const options = { method: "POST", mode: "cors", body: JSON.stringify(object)};
            const response = await fetchWithAuth(url, options);
            await response.json();
            setLoading(false);

        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };
    
    useEffect(() => {
        if(!phoneNumber) {
            setShowPhoneNumber(true);
        }
    })

    useEffect(() => {
        if(showPhoneNumber) {
            setHiddenHeader(true);
        } else {
            setHiddenHeader(false);
        }
    }, [showPhoneNumber])

    useEffect(() => {
        if (chatRef.current) {
            chatRef.current.scrollTop = chatRef.current.scrollHeight;
        }
    }, [listMessagesPreview]);
    
    return (
        <>
            {loading ? (
                <div className="content-spinner">
                    <Spinner />
                </div>
            ) : null}

            <div
                className={`${animateClass}`} 
                style={{
                    width: '350px', 
                    height: '480px',
                    transition: 'height 0.5s ease', 
                    zIndex: 100,
                    margin: "10px",
                    position: "relative"
                }}
            >
                <section
                    className="module-chat chat mx-1 active"
                    style={{width: "100%", height: "100%"}}
                >
                    <header className="message d-flex justify-content-between" style={{height: "11%"}}>
                        <div className="message d-flex" style={{ alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                            <div className="d-flex align-items-center justify-content-between" style={{width: "100%",  visibility: hiddenHeader ? "hidden" : ""}}>
                                <div className="cursor-pointer d-flex align-items-center">
                                    <div className="logo-wsp">
                                        <IconList className="cont-avatar" style={{border: "3px solid #00a884", width: "49px", height: "49px", position: "relative"}}>
                                            <Avvvatars value={'CB'} />
                                        </IconList>
                                    </div>
                                    <div id="customer-name" className="ml-2 d-flex flex-column">
                                        <div className="d-flex align-items-center" data-tooltip-id="my-tooltip" data-tooltip-content="Mi Perfil">
                                            <h1 className='mr-2'>
                                                ChatBot
                                            </h1>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <p className="mb-0" style={{color: "rgb(91, 192, 222)"}}>
                                                Test ChatBot
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <button
                                        type="button"
                                        className="btn btn-left col-1-sm-3"
                                        style={{padding: "0px"}}
                                        onClick={async () => {
                                            await removeStatuses();
                                            await closeChat();
                                        }}
                                    >
                                        <MdOutlineRestartAlt
                                            data-tooltip-id="my-tooltip" 
                                            data-tooltip-content="Reiniciar Chat"
                                        />
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-left col-1-sm-3"
                                        style={{padding: "0px"}}
                                        onClick={handlePreview}
                                    >
                                        <IoClose
                                            data-tooltip-id="my-tooltip" 
                                            data-tooltip-content="Cerrar Preview"
                                        />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </header>

                    <form className="form-chat position-relative" style={{height: "79%"}}>
                        <div
                            id="cont-chat" 
                            ref={chatRef}
                            className="mb-1 px-4 pt-3 pb-4 conversation" 
                            style={{
                                height: "100%",
                                overflowY: "auto",
                                display: "flex",
                                flexDirection: "column-reverse", 
                            }}
                        >
                            <div>
                                {listMessagesPreview.length > 0 && listMessagesPreview.map((message, index) => {
                                    if (message.type === "interactive") {
                                        if (message.interactive.type === "button") {
                                            return (
                                                <div key={index} className="preview-interactive-buttons">
                                                    <div className="preview-interactive-buttons-body">
                                                        <p style={{margin: "0px 0px 5px 0px"}}>{message.interactive.body.text}</p>
                                                        <span className="preview-timestamp">{message.timestamp}</span>
                                                    </div>
                                                    {message.interactive.action.buttons.map((button, idx) => (
                                                        <div
                                                            key={idx}
                                                            className="preview-button-style"
                                                            onClick={async () => {
                                                                const newMessage = {
                                                                    type: 'interactive',
                                                                    interactive: { 
                                                                        type: 'button_reply',
                                                                        button_reply: {
                                                                            title: button.reply.title
                                                                        }
                                                                    },
                                                                    from: 'user',
                                                                    timestamp: dayjs().format('HH:mm')
                                                                };
                                                                await handleChatbotPreview(false, newMessage);
                                                            }}
                                                        >
                                                            {button.reply.title}
                                                        </div>
                                                    ))}
                                                </div>
                                            );
                                        } else if (message.interactive.type === "list") {
                                            return (
                                                <div key={index} className="preview-interactive-list-button">
                                                    <div className="preview-interactive-list-button-body">
                                                        <p style={{margin: "0px 0px 5px 0px"}}>{message.interactive.body.text}</p>
                                                        <span className="preview-timestamp">{message.timestamp}</span>
                                                    </div>
                                                    <div 
                                                        className="preview-interactive-list-button-style" 
                                                        onClick={async () => {
                                                            setOptionList(message.interactive.action.sections[0].rows);
                                                            setOpenList(true);
                                                        }}
                                                    >
                                                        {message.interactive.action.button}
                                                    </div>
                                                </div>
                                            );
                                        } else if(message.interactive.type === "button_reply") {
                                            return (
                                                <div key={index} className={`preview-message ${messageClasses[message.from] || ''}`}>
                                                    <p style={{margin: "0px 0px 5px 0px"}}>{message?.interactive?.button_reply?.title}</p>
                                                    <span className="preview-timestamp">{message.timestamp}</span>
                                                </div>
                                            );
                                        } else if(message.interactive.type === "list_reply") {
                                            return (
                                                <div key={index} className={`preview-message ${messageClasses[message.from] || ''}`}>
                                                    <p style={{margin: "0px 0px 5px 0px"}}>{message?.interactive?.list_reply?.title}</p>
                                                    <span className="preview-timestamp">{message.timestamp}</span>
                                                </div>
                                            );
                                        }
                                    } else {
                                        return (
                                            <div key={index} className={`preview-message ${messageClasses[message.from] || ''}`}>
                                                <p style={{margin: "0px 0px 5px 0px"}}>{message?.text?.body}</p>
                                                <span className="preview-timestamp">{message.timestamp}</span>
                                            </div>
                                        );
                                    }
                                })}
                            </div>
                        </div>
                    </form>
                    
                    <div 
                        className="content-btn-status d-flex px-2"
                        style={{position: "relative", height:"10%" }}
                    >
                        <textarea
                            placeholder="Escribe aquí"
                            className="form-control px-1"
                            name="name"
                            id="myTextarea"
                            value={messagePreview || ""}
                            onChange={(e) => {
                                setMessagePreview(e.target.value);
                            }}
                            onKeyDown={(e) => {
                                if(e.key == 'Enter') {
                                    handleChatbotPreview();
                                }
                            }}
                        ></textarea> 

                        <span 
                            className='d-flex ml-2 w-auto' 
                            style={{backgroundColor:"#25d366", padding:"8px 6px 8px 11px", borderRadius:"50%"}}
                        >
                        <IoMdSend 
                            id='icon-send-messages' 
                            className="send pointer" 
                            style={{ fontSize: "27px" }}
                            onClick={() => handleChatbotPreview()}
                            disabled={!messagePreview}
                        />
                        </span>
                    </div>
                </section>
                
                {showPhoneNumber && (
                    <div 
                        style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "102%",
                            height: "100%",
                            backgroundColor: "rgba(0, 0, 0, 0.4)",
                            borderRadius: "8px"
                        }}
                    >
                        <div 
                            style={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                                backgroundColor: isDark == 'true' ? '#111b21' : '#fff',
                                color: '#455a64',
                                padding: "20px",
                                borderRadius: "8px",
                                width: "280px",
                                height: errors.phone || errorCountrie ? "375px" : "350px"
                            }}
                        >   
                            <form onSubmit={handleSubmit(savePerson)}>
                                <p 
                                    style={{
                                        color: isDark === 'true' ? '#f1f1f1' : '#61676B',
                                        fontWeight: 600,
                                        fontSize: "10px",
                                    }}
                                >
                                    Para poder utilizar el ChatBot Preview, 
                                    introduce un número telefónico válido que esté
                                    registrado en SendingUp. Si el número que ingresará no 
                                    está registrado, por favor ingrese un nombre para guardarlo
                                    sino el sistema le asignara un nombre por defecto. 
                                </p>

                                <div className="su-with-prefix su-with-suffix su-segmentation-disable">
                                    <label htmlFor="phone">Teléfono: </label>
                                    <div className="su-control-group d-flex">
                                        <Select
                                            styles={isDark == 'true' ? customStylesDark : customStyles}
                                            className={selectedClass && selectedClass.length === 1 ? "form-control select-countries one" : selectedClass.length === 2 ? "form-control select-countries two" : "form-control select-countries" }
                                            placeholder="+123"
                                            value={selectedCountrie}
                                            onChange={handleChange}
                                            options={countries}
                                            getOptionLabel={(option) => (
                                            <div className="option countrie d-flex" style={{fontSize: "15px"}}>
                                                <img src={option.flag} alt={option.name} width="20" style={{width: "27px", marginRight: "3px"}} />(+{option.callingCodes}) {option.name} 
                                            </div>
                                            )}
                                            getOptionValue={(option) => option.name}
                                        />
                                        <input
                                            className="form-control"
                                            style={{width: "65%"}}
                                            id="phone"
                                            name="phone"
                                            type="number"
                                            min={0}
                                            {...register("phone", {
                                                required: {
                                                    value: true,
                                                    message: "El teléfono es requerido.",
                                                },
                                                pattern: {
                                                    value: /^[0-9]{7,11}$/i,
                                                    message: "El formato no es correcto.",
                                                },
                                                minLength: {
                                                    value: 7,
                                                    message: "Mínimo 7 dígitos.",
                                                },
                                                maxLength: {
                                                    value: 11,
                                                    message: "Máximo 11 dígitos.",
                                                },
                                            })}
                                        />
                                    </div>
                                    {errors.phone && <Alert>* {errors.phone.message}</Alert>}
                                    {errorCountrie && <Alert>* El código es requerido.</Alert>}
                                </div>   

                                <div className="mt-2 mb-4">
                                    <label htmlFor="name">Nombre: </label>
                                    <input
                                        className="form-control"
                                        id="name"
                                        name="name"
                                        type="text"
                                        {...register('name', {
                                            pattern: {
                                            value: /\S+/,
                                            message: "No puede ser solo espacios en blanco",
                                            }
                                        })}
                                    />
                                </div>
                                
                                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                    <a
                                        href="javascript:;"
                                        className="btn btn-lg btn-default mr-1"
                                        onClick={handlePreview}
                                    >
                                        Volver
                                    </a>
                                    <input style={{padding: "6px 8px"}} className="btn btn-lg btn-info" value="Guardar" name="submit" id="submit" type="submit"
                                        onClick={() => {
                                            if (selectedCountrie) {
                                                setErrorCountrie(false);
                                            } else {
                                                setErrorCountrie(true);
                                            }
                                        }}
                                    />
                                </div>
                            </form>
                        </div>
                    </div>
                )}

                {openList && (
                    <Box
                    sx={{
                        position: "absolute",
                        bottom: 0,
                        left: 0,
                        width: "100%",
                        height: "60%",
                        backgroundColor: isDark == 'true' ? '#111b21' : '#fcfcfc',
                        boxShadow: "0px -2px 10px rgba(0,0,0,0.1)",
                        borderTopLeftRadius: "10px",
                        borderTopRightRadius: "10px",
                        overflow: "hidden",
                        marginLeft: "5px"
                    }}
                    >
                        <Box
                            sx={{
                            padding: 2,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            height: "100%",
                            }}
                        >
                            <List sx={{ overflowY: "auto", flexGrow: 1 }}>
                                {optionsList.map((option) => (
                                    <ListItem
                                        key={option.id}
                                        button
                                        onClick={async () => {
                                            const newMessage = {
                                                type: 'interactive',
                                                interactive: { 
                                                    type: 'list_reply',
                                                    list_reply: {
                                                        title: option.title
                                                    }
                                                },
                                                from: 'user',
                                                timestamp: dayjs().format('HH:mm')
                                            };
                                            setOpenList(false);
                                            await handleChatbotPreview(false, newMessage);
                                        }}
                                        sx={{
                                            borderRadius: "8px", 
                                            transition: "background-color 0.3s ease", 
                                            "&:hover": {
                                                backgroundColor: "rgba(240, 240, 240, 0.2)", 
                                            }
                                        }}
                                    >
                                        <ListItemText
                                            primary={option.title}
                                            secondary={option.description || null} 
                                            primaryTypographyProps={{
                                                fontWeight: 600, 
                                                color: isDark == "true" ? "#E9EDEF" : "#333", 
                                            }}
                                            secondaryTypographyProps={{
                                                color: "#666",
                                                fontSize: "0.85rem",
                                            }}
                                        />
                                    </ListItem>
                                ))}
                            </List>
                        </Box>
                    </Box>
                )}
                
                <ToastContainer 
                    position="top-center"
                    autoClose={52000}
                    // autoClose={false}
                    newestOnTop={false}
                    closeOnClick={false}
                    rtl={false}
                    pauseOnFocusLoss
                    draggable={false}
                    // draggable
                    theme={JSON.parse(localStorage.getItem("THEME")) ? "dark" : "light"}
                    toastClassName="custom-toast" // Aplica estilos personalizados a cada toast individualmente
                    bodyClassName="custom-toast-body"
                />
            </div>
        </>
    )
}

export default Preview;

const IconList = styledComponent.div`
  margin-bottom: 0px;

  div {
    height: 35px;
    width: 35px;
    border-radius: 50%;
    // box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.7);
    margin: 4px auto;
  }

  p {
    font-size: 18px;
    font-weight: 600;
  }
`;

const Alert = styledComponent.span`
  color: red;
  display: block;
  width: 100%;
  // width: 200px;
`;