import React, { Fragment, useState, useEffect, useRef, useCallback } from "react";
import useAPI from "./useAPI";
import debounce from 'lodash/debounce';
import {sendToast} from "../components/plugins/toastAll";
import { ToastContainer } from "react-toastify";

const usePersonsFinder = ({
    pageSize = 300, 
  }) => {

  //# Barra de busqueda
  const [tablePersons, setTablePersons] = useState([]);
  const [persons, setPersons] = useState([]);
  const [loading, setLoading] = useState([]);

  //# Custoom Hooks
  const { urlAPI_1, fetchWithAuth} = useAPI();
  
  const roleId = localStorage.getItem("USER_ROLEID");
  const userId = localStorage.getItem("USER_ID");

  const page_api = useRef(0);
  const listContactsRef = useRef([]);
  const totalPages = useRef(1);
  const searchTerm = useRef("");


  useEffect(() => {
    fetchPersonsPaged();
  }, []);


  const fetchPersonsPaged = async (reset = false, filter = false, all = false) => {
    try {
      setLoading(true);
      let url;
      if(roleId != "6284fdd697029c6d9743ccb3" && roleId != "64886b1b250f9d31bf2ed68d"){
        // Si no es admin deshabilitamos wpp en caso de corresponder. 
        if (reset) {       
          page_api.current = 0; 
          url = urlAPI_1 + `fetchPersonsPaged/?assigns=${userId}&page=0&pageSize=${pageSize}&search=`;
        } else if (filter) {
          page_api.current = 0;
          url = urlAPI_1 + `fetchPersonsPaged/?assigns=${userId}&page=0&pageSize=${pageSize}&filter=` + filter;
        } else if (all) {
          page_api.current = 0;
          url = urlAPI_1 + `fetchPersonsPaged/?assigns=${userId}&page=0&pageSize=${pageSize}&all=${all}`;
        } else{
          url = urlAPI_1 + `fetchPersonsPaged/?assigns=${userId}&page=${page_api.current}&pageSize=${pageSize}&search=${searchTerm.current}`;
        }
      }else{
        if(reset){
          page_api.current = 0;
          url = urlAPI_1 + `fetchPersonsPaged/?page=0&pageSize=${pageSize}&search=`;
        } else if (filter) {
          page_api.current = 0;
          url = urlAPI_1 + `fetchPersonsPaged/?assigns=${userId}&page=0&pageSize=${pageSize}&filter=` + filter;
        } else if (all) {
          page_api.current = 0;
          url = urlAPI_1 + `fetchPersonsPaged/?assigns=${userId}&page=0&pageSize=${pageSize}&all=${all}`;
        } else{
          url = urlAPI_1 + `fetchPersonsPaged/?page=${page_api.current}&pageSize=${pageSize}&search=${searchTerm.current}`;
        }
      }
      
      const options = { method: "GET", mode: "cors"};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      setLoading(false);

      if (result.code === 0) {
        //sendToast(result)
        console.log("result: ", result);
      } else if (result.message === "Tu petición no tiene cabecera de autorización") {
        sendToast({type: "info", message: "Tu sesion ha caducado, verifica si alguien mas se ha conectado con tus credenciales"})
        navigate("/");
      } else if (!result.data) {
        setPersons([]);
      } else {
        let listPersons = [];        
        if (filter) { 
          listPersons = result.data;
        } else if (all) { 
          return result.data
        } else if(searchTerm.current || page_api.current == 0){
          listPersons = result.data;
        }else{
          listPersons = [...listContactsRef.current, ...result.data];  
        }

        listPersons = [...listPersons].sort((a, b) => new Date(b.created) - new Date(a.created));

        listContactsRef.current = listPersons;
        
        setPersons(listPersons);
        setTablePersons(listPersons);
        setLoading(false);
        page_api.current += 1
        totalPages.current = result.totalPages;
      }
    } catch (error) {
      console.log('error on actualizar', error);
      return null;
    }
  };

  const searchQuery = (reset = false) => {
    if (page_api.current > totalPages.current) {
      searchQueryLocal();
    } else {
      // busqueda en db
      fetchPersonsPaged(reset);
    }
  };

  const debouncedSearchQuery = useCallback(
    debounce((value) => {
      if (value === "") {
        searchQuery(true);
      } else {
        searchQuery();
      }
    }, 2000),
    []
  );
  
  const handleChangeSearch = (e) => {
    const value = e.target.value;
    if(searchTerm.current != value){
      page_api.current = 0;
    }
    searchTerm.current = value;
    debouncedSearchQuery(value);
  };

  //# Funcion que se encargar de filtrar por busqueda
  const searchQueryLocal = () => {
    let searchResults = tablePersons.filter((element) => {
      if (
        element.name
          .toString()
          .toLowerCase()
          .includes(searchTerm.current.toLowerCase()) ||
        element.phone
          .toString()
          .toLowerCase()
          .includes(searchTerm.current.toLowerCase())
      ) {
        return element;
      }
    });
    setPersons(searchResults);
  };

  return {
    persons,
    loading,
    handleChangeSearch,
    searchQuery
  };
};


export default usePersonsFinder;
