import React, { useEffect } from "react";
import { Handle, Position, useUpdateNodeInternals } from 'reactflow';
import ReactPlayer from "react-player";
import ReactAudioPlayer from "react-audio-player";

import { FiMessageSquare } from "react-icons/fi";
import { BsFillFileEarmarkFill, BsFillFileEarmarkPdfFill, BsFillFileEarmarkWordFill, BsFileEarmarkExcelFill } from "react-icons/bs";
import { IoIosWarning } from "react-icons/io";

const CustomNode = ({ data, isConnectable, id }) => {
    const updateNodeInternals = useUpdateNodeInternals();

    let type = null
    if(data.file && data.file.type) {
        type = data.file.type
    }

    useEffect(() => {
        updateNodeInternals(id);
    }, [id, updateNodeInternals, data]);

    return (
        <div className="rounded" style={{width: "180px", boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px", cursor: 'pointer'}}>
            {!data.first && data.saved && (
                
                <Handle 
                    type="target" 
                    position={Position.Top} 
                    isConnectable={isConnectable}  
                    id={`target-${id}`}
                    style={{backgroundColor: '#00A884'}}
                />
            )}
            {data.saved && (
                
                <Handle 
                    type="target" 
                    position={Position.Left} 
                    isConnectable={isConnectable}  
                    id={`target-return-${id}`}
                    style={{backgroundColor: '#00A884'}}
                />
            )}
            <div>
                <div 
                    className="d-flex align-items-center rounded-top justify-content-between" 
                    style={{height: "10px", backgroundColor: "#00A884", paddingBlock: "12px", paddingInline: "4px"}}
                >
                    <div className="d-flex align-items-center">
                        <FiMessageSquare className="mr-1" style={{fontSize: "12px", color: "#fff"}}/>
                        <span style={{fontSize: "10px", color: "#fff"}} >
                            {data.title ?? "Respuesta Básica"}
                        </span>
                    </div>
                    {data?.error && (
                        <IoIosWarning style={{fontSize: "12px", color: "#FFCC00"}}/>
                    )}
                </div>
                <div className="bg-white rounded-bottom" style={{padding: '8px'}}>
                    {data.user_response && (
                        <div 
                            className="p-1 mb-2" 
                            style={{
                                backgroundColor: "rgba(0, 168, 132, 0.3)",
                                borderRadius: "4px",
                                height: "auto"
                            }}
                        >   
                            <span style={{fontWeight: "bold", fontSize: "9px"}}>Respuesta del Cliente:</span>
                            <p style={{fontWeight: 'normal', fontSize: "9px"}} className="m-0">
                                {data.user_response}
                            </p>
                        </div>
                    )}
                    {data.bot_response && (
                        <div 
                            className="p-1" 
                            style={{
                                border: "1px solid #000",
                                borderRadius: "4px",
                                height: "auto"
                            }}
                        >   
                            <span style={{fontWeight: "bold", fontSize: "9px"}}>Respuesta del Bot:</span>

                            {data.file?.url && type && type.includes("image") && (
                                <img 
                                    src={data.file?.url} 
                                    alt="Imagen" 
                                    className="w-100"
                                    style={{borderRadius: "4px"}}
                                /> 
                            )}
                            {data.file?.url && type && type.includes("video") && (
                                <ReactPlayer
                                    url={data.file?.url}
                                    loop
                                    controls
                                    width="100%"
                                    height="100%"
                                    style={{borderRadius: "4px"}}
                                />
                            )}
                            {data.file?.url && type && type.includes("application") && (
                                <div>
                                    {type.includes("pdf") ? (
                                        <div className="d-flex align-items-center justify-content-between p-2" style={{background:"#f0f0f0", borderRadius: "4px"}}>
                                            <BsFillFileEarmarkPdfFill style={{fontSize: "18px",color: "red",}}/>
                                            <span 
                                                style={{
                                                    color: "#282828",
                                                    fontSize: "9px",
                                                    overflow: "hidden"
                                                }}
                                                className="ml-1"
                                            >
                                                {data.file.name}
                                            </span>
                                        </div>
                                    ) : type.includes("csv") || type.includes("xlsx") || type.includes("xls") ? (
                                        <div className="d-flex align-items-center justify-content-between p-2" style={{background:"#f0f0f0", borderRadius: "4px"}}>
                                            <BsFileEarmarkExcelFill style={{fontSize: "18px", color: "#00d591"}}/>
                                            <span 
                                                style={{
                                                    color: "#282828",
                                                    fontSize: "9px",
                                                    overflow: "hidden"
                                                }}
                                                className="ml-1"
                                            >
                                                {data.file.name}
                                            </span>
                                        </div>
                                    ) : type.includes("docx") || type.includes("doc") ? (
                                        <div className="d-flex align-items-center justify-content-between p-2" style={{background:"#f0f0f0", borderRadius: "4px"}}>
                                            <BsFillFileEarmarkWordFill style={{fontSize: "18px", color: "#31acf6"}}/>
                                            <span 
                                                style={{
                                                    color: "#282828",
                                                    fontSize: "9px",
                                                    overflow: "hidden"
                                                }}
                                                className="ml-1"
                                            >
                                                {data.file.name}
                                            </span>
                                        </div>
                                    ) : (
                                        <div className="d-flex align-items-center justify-content-between p-2" style={{background:"#f0f0f0", borderRadius: "4px"}}>
                                            <BsFillFileEarmarkFill style={{fontSize: "18px", color: "#5a5a5a"}} className="icon-file-earmark"/>
                                            <span 
                                                style={{
                                                    color: "#282828",
                                                    fontSize: "9px",
                                                    overflow: "hidden"
                                                }}
                                                className="ml-1"
                                            >
                                                {data.file.name}
                                            </span>
                                        </div>
                                    )}
                                </div>
                            )}
                            {data.file?.url && type && type.includes("audio") && (
                                <ReactAudioPlayer 
                                    src={data.file?.url}
                                    controls
                                    style={{
                                        width: "100%",
                                        height: "20px"
                                    }}
                                    className="custom-audio-player"
                                />
                            )}
                            <p style={{fontWeight: 500, fontSize: "9px"}} className="m-0 mt-1">
                                {data.bot_response}
                            </p>
                        </div>
                    )}
                    {data.options && data.options.length > 0 && (
                        <div 
                            className="p-1 mt-2" 
                            style={{
                                border: "1px solid #000",
                                borderRadius: "4px",
                            }}
                        >   
                            <span style={{fontWeight: "bold", fontSize: "9px"}}>Botones Interactivos</span>

                            <div>
                            {data.options.map((btns, index) => {
                                const handleId = `source-${btns.id}-${index}`;
                                return (
                                    <button
                                        key={index}
                                        type="button"
                                        style={{
                                            border: 0,
                                            borderRadius: "4px",
                                            color: "#000",
                                            cursor: "pointer",
                                            fontFamily: "Helvetica, Arial, sans-serif",
                                            fontSize: "8px",
                                            fontWeight: "bold",
                                            height: "15px",
                                            width: "150px",
                                            zIndex: 100,
                                            position: 'relative'
                                        }}
                                    >   
                                        {btns.label}
                                        <Handle 
                                            type="source" 
                                            position={Position.Right} 
                                            id={handleId}
                                            isConnectable={isConnectable}  
                                            style={{backgroundColor: '#44B26E', right: '-5px'}}
                                        />
                                    </button>
                                );
                            })}

                            {data?.advanced?.campaign_options?.map((btns, index) => {
                                const handleId = `source-${btns.id}-${index}`;
                                return (
                                    <div>
                                        {index == 0 && (
                                            <span style={{fontWeight: "bold", fontSize: "9px"}}>Botones de Campañas</span>
                                        )}
                                        <button
                                            key={`campaign-${index}`}
                                            type="button"
                                            style={{
                                                border: 0,
                                                borderRadius: "4px",
                                                backgroundColor: "rgba(0, 168, 132, 0.7)",
                                                color: "#000",
                                                cursor: "pointer",
                                                fontFamily: "Helvetica, Arial, sans-serif",
                                                fontSize: "8px",
                                                fontWeight: "bold",
                                                height: "15px",
                                                width: "150px",
                                                zIndex: 100,
                                                position: 'relative'
                                            }}
                                        >   
                                            {btns.label}
                                            <Handle 
                                                type="source" 
                                                position={Position.Right} 
                                                id={handleId}
                                                isConnectable={isConnectable}  
                                                style={{backgroundColor: '#44B26E', right: '-5px'}}
                                            />
                                        </button>
                                    </div>
                                );
                            })}
                            </div>
                        </div>
                    )}
                </div>
            </div>
            {data.saved && (
                <Handle 
                    type="source" 
                    position={Position.Bottom} 
                    id={`source-${id}`}
                    isConnectable={isConnectable}  
                    style={{backgroundColor: '#00A884'}}
                />
            )}
        </div>
    )
}

export default CustomNode;