import React, { useState } from 'react';
import { BsStars } from 'react-icons/bs';
import useAPI from '../../hooks/useAPI';
import { FaPencilAlt, FaSpinner } from 'react-icons/fa';
import { TbPencilStar, TbWriting } from "react-icons/tb";
import { is } from 'date-fns/locale';
import ListAnswerAI from './listAnswerAI';
import { DotLottieReact } from '@lottiefiles/dotlottie-react';
import { IoClose } from 'react-icons/io5';


const TextareaSendMessage = ({
  inputStr,
  inputRef,
  searchQuicklyAnswer,
  setContenMessage,
  setInputStr,
  setViewAnswer,
  setQuicklyAnswers,
  keyDown,
  msgIA,
  setMsgIA
}) => {

  const [isSuggestions, setIsSuggestions] = useState(false);
  const [listAnswer, setListAnswer] = useState([]);

  const { urlAPI_1, urlAPI_2, urlAPI_3, urlSocket, fetchWithAuth } = useAPI();

  //Función para buscar respuestas rapidas
  const searchQuickReply = (text) => {
    if (text[0] == "/") {
      setViewAnswer(true)
      var searchAnswer = searchQuicklyAnswer.filter((element) => {
        if (element.name.toString().toLowerCase().includes(text.slice(1).toLowerCase())) {
          return element;
        }
      });
      setQuicklyAnswers(searchAnswer)

    } else {
      setViewAnswer(false)
    }
  };

  const suggestAnswer = async () => {
    setIsSuggestions(true);
    setMsgIA(false)
    setListAnswer([])
    let phone_chat = localStorage.getItem("CHAT_SELECTED");
    console.log('Dentro de sugerir respuesta: ', phone_chat);
    const url = urlAPI_1 + "suggestAnswer/" + phone_chat;
    const options = { method: "GET", mode: "cors"};
    const response = await fetchWithAuth(url, options);
    const result = await response.json();
    setIsSuggestions(false);
    console.log('😊😀 Result sugerencias de respuestas:  ', result.data);
    setListAnswer(result.data)
    setMsgIA(true)
  }

  return (
    <div className="mt-0 su-control su-segmentation-disable d-flex align-items-center" style={{ width: "87%" }}>
      <div className="w-100 position-relative">
        <textarea
          placeholder="Escribe aquí o pulsa / para respuestas rápidas"
          className="form-control px-1"
          name="name"
          id="myTextarea"
          value={inputStr}
          onKeyDown={keyDown}
          ref={inputRef}
          maxLength="4096"
          onChange={(e) => {
            setContenMessage(e.target.value);
            setInputStr(e.target.value);
            searchQuickReply(e.target.value);
          }}
        ></textarea> 
        <div
          className="pointer"
          data-tooltip-id="my-tooltip" 
          data-tooltip-content={`⭐ ${isSuggestions ? "Generando" : "Sugerir"} respuestas...`} 
          style={{
            position: "absolute",
            right: "0.5rem",
            top: "0.5rem",
            fontSize: "1.5rem"
          }}
        >
        {isSuggestions ? (
          <div className="writing-container">
            <div className="writing-animation">
              <FaPencilAlt />
            </div>
            <div className="writing-line"></div>
          </div>
        ) : (
          <BsStars 
            className="icon-stars"
            onClick={() => {suggestAnswer()}}
          />
        )}

        </div>
        {msgIA && (
          <ListAnswerAI 
            listAnswer={listAnswer}
            setListAnswer={setListAnswer}
            setInputStr={setInputStr}
            inputRef={inputRef}
            setContenMessage={setContenMessage}
            setMsgIA={setMsgIA}
          />
        )}
      </div>
    </div>
  );
};

export default TextareaSendMessage;