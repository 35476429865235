import React from 'react';
import {sendToast} from '../plugins/toastAll';

const OpenConversationMessage = ({
  statusChat,
  setChatClose,
  onChatStatus,
  onChatSelected,
}) => {
  return (
    <>
      <p className="mb-0 text-center" style={{fontSize: "14px", fontWeight: "900"}}>
        💬 Por favor abre la conversación para chatear
      </p>
      <a
        href="javascript:;"
        className="btn btn-default mr-1 btn-status ml-3"
        onClick={() => {
          onChatStatus("1");
          const chatContext = localStorage.getItem("CHAT_SELECTED");
          console.log("calling onChatSelected from openConvesationMessage")
          onChatSelected(chatContext);
          if (statusChat) {
            setChatClose(true)
            sendToast({type: "success", message: "Conversación abierta con exito"})
          }
        }}
      >
        Abrir
      </a>
    </>
  );
};

export default OpenConversationMessage;