import React, { Fragment, useState, useEffect } from "react";
import dayjs from "dayjs";
import Pagination from "../plugins/Pagination";
import Spinner from "../plugins/Spinner";
import usePage from "../../hooks/usePage";
import useAPI from "../../hooks/useAPI";
import Header from "../layout/Header";

import { FaEye, FaAngleLeft } from "react-icons/fa";
import { BsSearch } from "react-icons/bs";
import {sendToast} from "../plugins/toastAll";
import { ToastContainer } from "react-toastify";

const ConsumptionByCompany = () => {
    const [loading, setLoading] = useState(false);
    const [invoices, setInvoices] = useState([]);
    const [tableInvoices, setTableInvoices] = useState([]);
    const [validation, setValidation] = useState(false);
    const [search, setSearch] = useState("");
    const [date, setDate] = useState("");
    const [viewDetails, setViewDetails] = useState(false);
    const [dataInvoice, setDataInvoice] = useState([]);
    const [totalInvoice, setTotalInvoice] = useState("");
    const [dayCreated, setDayCreated] = useState("");

    const [statusLoading, setStatusLoading] = useState(true);

    const { urlAPI_1, urlAPI_2, daysOverdue, fetchWithAuth } = useAPI();
    const { page, byPage, setPage, resetPage } = usePage();
    
    const roleDescription = localStorage.getItem("USER_ROLEDESCRIPTION");
    const user = localStorage.getItem("USER_NAME");

    useEffect(() => {
        getInvoices()
    },[])
    
    //#--> Obtener todas las facturas
    const getInvoices = async () => {
        try {
            if (statusLoading) {setLoading(true)}
            const url = urlAPI_1 + "getInvoices";
            const options = { method: "GET", mode: "cors"};
            const response = await fetchWithAuth(url, options);
            const result = await response.json();
            console.log("Lista de facturas", result);
            if (result.type === "error") {
                sendToast(result)
            }
            if (statusLoading) {setLoading(false)}
            setInvoices(result.data)
            setTableInvoices(result.data)
            setDate(dayjs(new Date()).locale("es").format("DD"))
        } catch (error) {
            console.log(error);
        }
    };

    //#--> Cargar el detalle de la factura seleccionada
    const getInvoice = (id) => {
        setViewDetails(true);
        const invoiceSelected = invoices.filter((invoice) => invoice.id == id);
        console.log("Seleccioanda", invoiceSelected);
        setDataInvoice(invoiceSelected[0].detail);
        setTotalInvoice(invoiceSelected[0]);
        var dayCreate = (dayjs(invoiceSelected[0].created).locale("es").format("DD"))
        setDayCreated(parseInt(dayCreate) + daysOverdue);
        
        console.log('fecha normal=>', invoiceSelected[0].paymentDate);
        var date = new Date(invoiceSelected[0].paymentDate)
        // Restar 5 horas
        date.setHours(date.getHours() + 5);
    };

    //#--> Enviar información de la factura seleccionada para generar link de pago
    const postPayment = async (id) => {
        console.log('Factura a pagar::::', id);
        setStatusLoading(false);
        try {
            setLoading(true);
            const url = urlAPI_1 + "paymentBill";
            const options = { method: "POST", mode: "cors", body: JSON.stringify(id)};
            const response = await fetchWithAuth(url, options);
            const result = await response.json();

            if (result.type === "error") {
                sendToast(result)
            } else {
                setLoading(false);
                getInvoices();
                setTotalInvoice(result.data)
                sendToast(result)
            }

        } catch (error) {
            console.log(error);
        }
    };

    //#--> Buscador por id, compañia, periodo y estado
    const searchQuery = (searchTerm) => {
        const searchResults = tableInvoices.filter((element) => {
            if (element.id.toString().toLowerCase().includes(searchTerm.toLowerCase()) 
                || element.company.toString().toLowerCase().includes(searchTerm.toLowerCase()) 
                || element.month.toString().toLowerCase().includes(searchTerm.toLowerCase())
                || (element.status ? "Pagada" : "Pendiente").toString().toLowerCase().includes(searchTerm.toLowerCase())
            ) {
                return element;
            }
        });
        setInvoices(searchResults);
        if (searchResults.length === 0) {
            setValidation(true);
        } else {
            setValidation(false);
        }
    };

    const formatInvoices = [...invoices].reverse();
    
    return (
        <Fragment>
            {loading ? (
                <div className="content-spinner">
                    <Spinner />
                </div>
            ) : null}
            {/* <Navigation user={user} roleDescription={roleDescription} /> */}

            {viewDetails ? (
                // <div className="mt-3 pb-5 superAdmin" style={{paddingLeft: "120px",paddingRight: "38px",paddingTop: "11px"}}>
                <div className="px-3">
                    <div className="row mx-auto container-fluid p-0 justify-content-between border-top-0 border-right-0 border-left-0 border-secondary mb-3">
                        <div style={{ height: "45px", lineHeight: "95px" }}>
                        <button
                            onClick={() => {
                                setViewDetails(false);
                                setStatusLoading(true);
                            }}
                            type="button"
                            className="btn btn-left col-1-sm-3 my-auto position-absolute d-flex align-items-center"
                        >
                            <FaAngleLeft className="mr-1" /> Volver
                        </button>
                        </div>

                        <Header />
                    </div>

                    <section className="module-order">
                        <form>
                            <div className="module-section">
                                <div className="row">
                                    <div className="col-sm-3 status-campaign">
                                        <h1 id="numOrder">{`Detalles de la factura # ${totalInvoice.id}`}</h1>
                                        <p className="mb-2 d-flex" style={{fontSize: "1.3em"}}>
                                        <h5 className="mr-2 mb-0">Periodo de facturación: </h5>
                                        {totalInvoice.month}
                                        </p>


                                        {totalInvoice.paymentDate &&
                                        <p className="mb-2 d-flex" style={{fontSize: "1.3em"}}>
                                            <h5 className="mr-2 mb-0">Fecha de pago:</h5>
                                            {
                                                dayjs(totalInvoice.paymentDate).locale("es").format("DD MMMM YYYY")
                                            }
                                        </p>
                                        }

                                        {totalInvoice.paymentMethod &&
                                        <p className="mb-2 d-flex" style={{fontSize: "1.3em"}}>
                                            <h5 className="mr-2 mb-0">Método de pago:</h5>
                                            {totalInvoice.paymentMethod}
                                        </p>
                                        }

                                        {totalInvoice.paymentReference &&
                                        <p className="mb-2 d-flex" style={{fontSize: "1.3em"}}>
                                            <h5 className="mr-2 mb-0">Referencia de pago: </h5>
                                            {totalInvoice.paymentReference}
                                        </p>
                                        }


                                        <p>
                                            <a href="javascript:;" className={totalInvoice.status ? "green" : date > dayCreated ? "red" : "gray" }>
                                                {totalInvoice.status ? "Pagada" : date > dayCreated ? "Vencida" : "Pendiente" }
                                            </a>
                                        </p>

                                    </div>

                                    <div className="col-sm-9">
                                        <div>
                                            <div className="table-responsive">
                                                <table className="table table-striped">
                                                <thead>
                                                    <tr>
                                                    <th>Tipo</th>
                                                    <th>Descripción</th>
                                                    <th>Cantidad</th>
                                                    <th>Valor unitario</th>
                                                    <th>Valor</th>
                                                    </tr>
                                                </thead>
                                                <tbody id="productsOrder">
                                                    {dataInvoice && dataInvoice.map((detail, k) => (
                                                        <tr key={k}>
                                                        <td>{detail.type}</td>
                                                        <td>{detail.description}</td>
                                                        <td>{detail.quantity}</td>
                                                        <td>
                                                            <pre> $USD {detail.price}</pre>
                                                        </td>
                                                        <td>
                                                            <pre>$USD {detail.total}</pre>
                                                        </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                                </table>
                                            </div>

                                            <table className="total">
                                                <tbody>
                                                    <tr className="lg">
                                                        <td>
                                                            <span style={{ fontWeight: "bold", fontSize: "1.6em" }}>
                                                                Total:
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <pre style={{ fontSize: "1.6em" }}>
                                                                {totalInvoice.total
                                                                    ? `$USD ${Intl.NumberFormat().format(totalInvoice.total)}`
                                                                    : "$USD 0"
                                                                }
                                                            </pre>
                                                        </td>
                                                    </tr>
                                                    {!totalInvoice.status &&
                                                        <tr>
                                                            <td></td>
                                                            <td>
                                                                <div className="mt-3" style={{textAlign: "end"}}>
                                                                    <a href="javascript:;" className="btn btn-lg btn-info mr-1" onClick={() => postPayment(totalInvoice.id)}>
                                                                        Pagada
                                                                    </a>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </form>
                    </section>
                </div>
                // </div>
            ) : (
                // <div className="mt-3 pb-5 superAdmin" style={{paddingLeft: "120px",paddingRight: "38px",paddingTop: "11px"}}>
                    <div className="m-auto container-fluid p-0 px-3">
                        <div className="col-xs-9 heading">
                            <div>
                                <h1>Consumos por compañia</h1>
                            </div>
                            <Header />
                        </div>

                        <header>
                            <div style={{ float: "right" }}>
                                <form className="form-inline">
                                    <div className="su-control mt-1">
                                    <input
                                        className="form-control"
                                        type="text"
                                        placeholder="Búsqueda"
                                        value={search}
                                        onChange={(e) => {
                                            setSearch(e.target.value);
                                            searchQuery(e.target.value);
                                            resetPage();
                                        }}
                                    />
                                    <button
                                        className="btn btn-info"
                                        type="button"
                                        id="buttonSearchProductBack"
                                        style={{ height: "34px" }}
                                    >
                                        <BsSearch />
                                    </button>
                                    </div>
                                </form>
                            </div>
                        </header>

                        <div className="container-fluid mt-3 px-0" style={{ height: "580px", overflow: "auto" }}>
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th>Detalles</th>
                                        <th>N° Factura</th>
                                        <th>Compañia</th>
                                        <th>Periodo</th>
                                        <th>Total</th>
                                        <th>Fecha de corte</th>
                                        <th>Estado</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {formatInvoices.length > 0 && formatInvoices.slice((page - 1) * byPage,(page - 1) * byPage + byPage).map((invoice, i) => {
                                        var dayBill = dayjs(invoice.created).locale("es").format("DD")
                                        var dayBillOverdue = parseInt(dayBill) + daysOverdue;
                                        return (
                                            <tr key={i}>
                                                <td>
                                                    <div className="d-flex align-items-center justify-content-center">
                                                    <div className="dropdown">
                                                        <button
                                                        className="border-0 bg-transparent d-block pointer mt-2"
                                                        onClick={() => getInvoice(invoice.id)}
                                                        >
                                                        <FaEye style={{ fontSize: "18px", color: "#ff9317" }} />
                                                        <span className="text-dark ml-2 f-14"></span>
                                                        </button>
                                                    </div>
                                                    </div>
                                                </td>
                                                <td>{invoice.id}</td>
                                                <td>{invoice.company}</td>
                                                <td>{invoice.month}</td>
                                                <td> $USD {invoice.total}</td>
                                                <td>{dayjs(invoice.created).locale("es").format("DD MMMM YYYY")}</td>
                                                <td className="status-campaign">
                                                    <p>
                                                        <a href="javascript:;" className={invoice.status ? "green" : date > dayBillOverdue ? "red" : "gray" }>
                                                            {invoice.status ? "Pagada" : date > dayBillOverdue ? "Vencida" : "Pendiente" }
                                                        </a>
                                                    </p>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                            {validation && (
                                <h1 className="text-center mt-3">
                                    No existen registros en el sistema
                                </h1>
                            )}
                        </div>

                        <footer>
                            <div className="row">
                                <div className="col-md-8"></div>
                                <div className="col-md-4">
                                    <Pagination
                                    totalData={formatInvoices.length}
                                    byPage={byPage}
                                    setPage={setPage}
                                    />
                                </div>
                            </div>
                        </footer>
                    </div>
                    // <footer className="px-3">
                    //     <img src={logoLogin} alt="Logo" style={{ width: "140px" }} />
                    // </footer>
                // </div>
            )}

            <ToastContainer 
                position="top-center"
                autoClose={52000}
                // autoClose={false}
                newestOnTop={false}
                closeOnClick={false}
                rtl={false}
                pauseOnFocusLoss
                draggable={false}
                // draggable
                theme={JSON.parse(localStorage.getItem("THEME")) ? "dark" : "light"}
                toastClassName="custom-toast" // Aplica estilos personalizados a cada toast individualmente
                bodyClassName="custom-toast-body"
            />
        </Fragment>
    )
};

export default ConsumptionByCompany;