import React, { Fragment, useState, useEffect } from "react";
import Spinner from "../plugins/Spinner";
import useAPI from "../../hooks/useAPI";
import Header from "../layout/Header";
import { useNavigate, useParams, Link } from "react-router-dom";
import styled from "styled-components";
import Modal from "../layout/Modal";

import { FaAngleLeft } from "react-icons/fa";
import { sendToast } from "../plugins/toastAll";
import { ToastContainer } from "react-toastify";

const ConsumptionPayments = () => {
  const [orderDetail, setOrderDetail] = useState([]);
  const [loading, setLoading] = useState(false);
  const [validation, setValidation] = useState(false);
  const [modalViewProduct, setModalViewProduct] = useState(false);
  const [productSelected, setProductSelected] = useState([]);
  const [numberContact, setNumberContact] = useState([]);
  const [codNumber, setCodNumber] = useState([]);

  const { id } = useParams();
  
  //# Custoom Hooks
  const { urlAPI_1, urlAPI_2, fetchWithAuth } = useAPI();
  
  useEffect(() => {
    getOrderDetail(id);
  }, []);
  
  const token = localStorage.getItem("USER_TOKEN");
  const navigate = useNavigate();
  
  //#--> Cargar el detalle de la factura seleccionada
  const getOrderDetail = async (id) => {
    console.log('----id---->', id)
    try {
      setLoading(true);
      const url = urlAPI_2 + "getOrder/" + id;
      const options = { method: "GET", mode: "cors"};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      console.log("Detalles del pedido.........", result);

      if (result.code === 0) {
        sendToast(result)
      } else if (result.data.length === 0) {
        setValidation(true);
        setOrderDetail([]);
      } else {
        setOrderDetail(result.data);
        setValidation(false);
        const numberText = result.data.phoneNumber.toString()
        const number = numberText.slice(-10);
        const cod = numberText.slice(0, -10)
        setNumberContact(number)
        setCodNumber(cod)
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  
  const handleSelectStatus = async (data) => {
    console.log('==>Recibiendo el cambio de estado:', data)
    let objData = {
      status: data
    };
    try {
      setLoading(true);
      const url = urlAPI_2 + "updateStatusOrder/" + id;
      const options = { method: "PUT", mode: "cors", body: JSON.stringify(objData)};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      console.log("Detalles del pedido.........", result);
      
      if (result.code === 0 || result.data?.length === 0) {
        setOrderDetail(result.data?.length === 0 ? [] : result.data);
      } else {
        setOrderDetail(result.data);
      }
      setLoading(false);
      sendToast(result);
    } catch (error) {
      console.log(error);
    }

  }

  return (
    <Fragment>
      {loading ? (
        <div className="content-spinner">
          <Spinner />
        </div>
      ) : null}
      <div className="row mx-auto container-fluid p-0 justify-content-between border-top-0 border-right-0 border-left-0 border-secondary mb-3">
        <div style={{ height: "45px", lineHeight: "95px" }}>
          <button
            onClick={() => navigate(`/home/orders`)}
            type="button"
            className="btn btn-left col-1-sm-3 my-auto position-absolute d-flex align-items-center"
          >
            <FaAngleLeft className="mr-1" /> Pedidos
          </button>
        </div>

        <Header />
      </div>

      <section className="module-order">
        <form>
          <div className="module-section">
            <div className="row">
              <div className="col-sm-3 status-campaign">
                <p>
                  <div className="mb-2">
                    <label className="mb-0 change-status">Cambiar estado: </label>
                    <div className="su-control-group d-flex">
                      <select className="form-control mb-1" onChange={(e) => handleSelectStatus(e.target.value)}>
                        <option value="" selected disabled>Selecione un estado</option>
                        {orderDetail.status === 4 && <option value="1">Pago notificado</option>}
                        {orderDetail.status === 4 || orderDetail.status === 1 ? <option value="2">Notificar envío</option> : null}
                        {orderDetail.status === 4 || orderDetail.status === 1 || orderDetail.status === 2 ? <option value="3">Cancelar orden</option> : null}
                      </select>
                    </div>
                  </div>
                </p>
                <h1 id="numOrder" className="mt-2">{`Pedido #${!loading ? orderDetail.id : "0"}`}</h1>
                <p>
                  <a href="javascript:;" className={
                      orderDetail.status == 4 ? "gray" 
                      : orderDetail.status == 1 ? "blue" 
                      : orderDetail.status == 3 ? "red" 
                      : "green" 
                    }
                  >
                    {
                      orderDetail.status === 4 ? "En proceso"
                      : orderDetail.status === 1 ? "Pago notificado"
                      : orderDetail.status === 3 ? "Orden cancelada"
                      : "Envio notificado"
                    }
                  </a>
                </p>
                <h1 id="numOrder" className="mt-4">Información del cliente</h1>
                <div className="info-client-order">
                  <h2 className="mt-3">
                    <Link
                      className="hover-link"
                      to={`/home/contact/${orderDetail.clientId}`}
                    >
                      {orderDetail.client}
                    </Link>
                  </h2>
                  <p className="d-flex align-items-center">
                    <strong>Teléfono:</strong>
                    <img src="/icon/flags-countries/co.svg" className="mx-1" style={{width:"1.3rem"}} /> 
                    {`(+${codNumber})  ${numberContact}`}
                  </p>
                  {orderDetail.email && <p><strong>Correo:</strong> {orderDetail.email}</p>}
                </div>
              </div>

              <div className="col-sm-9">
                <div>
                  <div className="table-responsive">
                    <table className="table table-striped content-table-detail-invoice">
                      <thead>
                        <tr>
                          <th>Articulo</th>
                          <th>Cantidad</th>
                          <th>Valor unitario</th>
                          <th>Valor</th>
                        </tr>
                      </thead>
                      <tbody id="productsOrder">
                        {orderDetail.orderDetails && orderDetail.orderDetails.map((detail, k) => (
                          <tr key={k}>
                            <td 
                              onClick={() => {
                                setModalViewProduct(true)
                                setProductSelected({
                                  name: detail.name,
                                  description: detail.description,
                                  price: detail.price,
                                  image_url: detail.image_url
                                })
                              }}
                            >
                              <span className="hover-span">{detail.name}</span>
                            </td>
                            <td>{detail.quantity}</td>
                            <td>{detail.price}</td>
                            <td>{detail.total}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    {validation && (
                      <h1 className="text-center mt-3">
                        No existe informacion en el sistema
                      </h1>
                    )}
                  </div>

                  <table className="total" style={{width:"30%"}}>
                    <tbody>
                      <tr className="lg">
                        <td>
                          <span
                            style={{ fontWeight: "bold", fontSize: "1.6em" }}
                          >
                            Total:
                          </span>
                        </td>
                        <td>
                          <pre style={{ fontSize: "1.6em" }}>
                            {orderDetail.total ? orderDetail.total : "$ 0"}
                          </pre>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </form>
      </section>

      {/* Modal para ver los detalles del producto seleccionado */}
      <Modal
        status={modalViewProduct}
        changeStatus={setModalViewProduct}
        title="Información del producto"
        width={"90vw"}
      >
        <Content>
          <form style={{ width: "100%" }}>
            <div>
              <div 
                className="mt-2 text-center d-flex justify-content-between"
                style={{overflow: "auto", height: "76vh"}}    
              >
                <div className="w-50 mr-1">
                  <img src={productSelected.image_url} className="w-100 h-100" style={{objectFit:"contain"}}/>
                </div>

                <div className="w-50 ml-1 px-2 text-justify">
                  <h2>{productSelected.name}</h2>
                  <h4 className="d-flex align-items-baseline">{productSelected.sale_price ? 
                    <>
                      <h5>
                        <span style={{color: "#a5a7a8", textDecoration: "line-through" }}>{productSelected.price}</span>
                      </h5> 
                      <span className="ml-1">
                        {productSelected.sale_price} 
                      </span> 
                    </>
                  : 
                    productSelected.price}
                  </h4>
                  <p>{productSelected.description}</p>
                </div>
              </div>
            </div>

            {/* <div className="row justify-content-end bd col-12 mt-4">
              <a 
                href="javascript:;" 
                className="btn btn-lg btn-default mr-1" 
                onClick={() => {
                  setModalViewProduct(false);
                }}
              >
                Cerrar
              </a>
            </div> */}
          </form>


        </Content>
      </Modal>

      <ToastContainer 
        position="top-center"
        autoClose={52000}
        // autoClose={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        // draggable
        theme={JSON.parse(localStorage.getItem("THEME")) ? "dark" : "light"}
        toastClassName="custom-toast" // Aplica estilos personalizados a cada toast individualmente
        bodyClassName="custom-toast-body"
      />
      
    </Fragment>
  );
};

export default ConsumptionPayments;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  h1 {
    font-size: 42px;
    font-weight: 700;
    margin-bottom: 10px;
    text-align: center;
  }
  li {
    padding: 10px 5px;
    border-bottom: 1px solid rgb(0, 0, 0, 0.05);
  }
  li:hover {
    background: rgb(0, 0, 0, 0.05);
  }
  p {
    font-size: 18px;
    margin-bottom: 0px;
  }
  input {
    padding: 10px;
    border-radius: 0px;
    font-size: 17px;
  }
  img.img-answer {
    cursor: pointer;
    width: auto;
    height: 85px;
    object-fit: scale-down;
  }
  .content-img {
    position: relative;
  }
  // .content-img .icon-eye {
  //   cursor: pointer;
  //   position: absolute;
  //   top: 50%;
  //   left: 50%;
  //   transform: translate(-50%, -50%);
  // }
  .content-img img:hover {
    opacity: 0.6;
  }
`;
