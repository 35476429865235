import React, { Fragment } from "react";
import NotFoundAnimation  from "../../imgs/animacion_not_found.gif";
import Spinner from "../plugins/Spinner";
import { useNavigate } from "react-router-dom";
import Header from "../layout/Header";

const NotFound404 = () => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/home');
    };

    return (
        <>
            <div className='container-fluid'>
                <div className="col-xs-9 heading mt-1">
                    <div className="title-prospects">
                        <h1>Página no encontrada</h1>
                    </div>
                    <Header />
                </div>
                <div className='row d-flex flex-row flex-md-column justify-content-center align-items-center' style={{height: "90vh"}}>
                    <div className='col-12 col-md-6 d-flex h-50 justify-content-center align-items-end'>
                        {/* <div className="bg-gradient-light"> */}
                        <img src={NotFoundAnimation} alt="Animación página no encontrada" className='ml-4 img-fluid' style={{height: "160px"}}/>

                        {/* </div> */}
                        {/* <div className="content-spinner"> */}
                            {/* <Spinner /> */}
                        {/* </div> */}
                    </div>
                    <div className='col-12 col-md-6 d-flex h-50 flex-column justify-content-start align-items-center'>
                        <h2>404</h2>
                        <h3 className="text-center">¡¡Upsss, ocurrió un error!!</h3>
                        <button 
                            className="btn btn-default"
                            onClick={handleClick}
                            >
                            Regresar
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default NotFound404;