import React, { useEffect } from "react";
import { Handle, Position, useUpdateNodeInternals } from 'reactflow';

import { FaBrain } from "react-icons/fa";
import { IoIosWarning } from "react-icons/io";

const AssitantAI = ({ data, isConnectable, id }) => {
    const updateNodeInternals = useUpdateNodeInternals();

    useEffect(() => {
        updateNodeInternals(id);
    }, [id, updateNodeInternals, data]);
    return (
        <div className="rounded" style={{width: "180px", boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px", cursor: 'pointer'}}>
            {data.saved && (
                <Handle 
                    type="target" 
                    position={Position.Top} 
                    isConnectable={isConnectable} 
                    id={`target-${id}`} 
                    style={{backgroundColor: '#A7ADA7'}}
                />
            )}
            <div>
                <div 
                    className="d-flex align-items-center rounded-top justify-content-between" 
                    style={{height: "10px", backgroundColor: "#A7ADA7", padding: "12px"}}
                >
                    <div className="d-flex align-items-center">
                        <FaBrain className="mr-1" style={{fontSize: "12px", color: "#fff"}}/>
                        <span style={{fontSize: "10px", color: "#fff"}} >
                            {data.title ?? "Asistente IA"}
                        </span>
                    </div>
                    {data?.error && (
                        <IoIosWarning style={{fontSize: "12px", color: "#FFCC00"}}/>
                    )}
                </div>
                <div className="bg-white rounded-bottom" style={{padding: '8px'}}>
                    {data.assistant && (
                        <div 
                            className="p-1 mb-2" 
                            style={{
                                border: "1px solid #000",
                                borderRadius: "4px",
                                height: "auto"
                            }}
                        >    
                            <div>
                                <span style={{fontWeight: "bold", fontSize: "9px"}}>
                                    Asistente:
                                </span>
                            </div>
                            <div className="d-flex align-items-center">
                                <p 
                                    style={{fontWeight: "bold", fontSize: "9px", backgroundColor: "#E6E6E6", borderRadius: "4px"}} 
                                    className="m-0 p-1 mb-1"
                                >
                                    {data.assistant.name}
                                </p>
                            </div>
                        </div>
                    )}
                    {data.user_response && (
                        <div 
                            className="p-1 mb-2" 
                            style={{
                                backgroundColor: "rgba(167, 173, 167, 0.3)",
                                borderRadius: "4px",
                                height: "auto"
                            }}
                        >   
                            <span style={{fontWeight: "bold", fontSize: "9px"}}>Respuesta del Cliente:</span>
                            <p style={{fontWeight: 'normal', fontSize: "9px"}} className="m-0">
                                {data.user_response}
                            </p>
                        </div>
                    )}
                    {data.bot_response && (
                        <div 
                            className="p-1 mb-2" 
                            style={{
                                border: "1px solid #000",
                                borderRadius: "4px",
                                height: "auto"
                            }}
                        >   
                            <span style={{fontWeight: "bold", fontSize: "9px"}}>Respuesta del Bot:</span>
                            <p style={{fontWeight: 'normal', fontSize: "9px"}} className="m-0">
                                {data.bot_response}
                            </p>
                        </div>
                    )}
                </div>
            </div>
            {data.saved && (
                <Handle 
                    type="source" 
                    position={Position.Bottom} 
                    id={`source-${id}`}
                    isConnectable={isConnectable}  
                    style={{backgroundColor: '#A7ADA7'}}
                />
            )}
        </div>
    )
}

export default AssitantAI;