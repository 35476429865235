import React, { useState, useEffect, useRef } from "react";
import useAPI from "../../hooks/useAPI";
import Header from "../layout/Header";
import Spinner from "../plugins/Spinner";
import { useForm } from "react-hook-form";
import { BsTrash3 } from "react-icons/bs";
import { TbCalendarCancel } from "react-icons/tb";
import styled from "styled-components";
import './css/historicalChat.css';
import {questionToast, sendToast} from "../plugins/toastAll";
import { ToastContainer } from "react-toastify";

const HistoricalChats = () => {
  const [companie, setCompanie] = useState([]);
  const [loading, setLoading] = useState(false);
  const [chatHistoricsValue, setChatHistoricsValue] = useState("");
  const [enabledHistorics, setEnabledHistorics] = useState([]);
  const enabledHistoricsRef = useRef(false);
  const { urlAPI_2, urlAPI_3, fetchWithAuth, fetchWithoutAuth } = useAPI();
  const {watch,register,handleSubmit,formState: { errors },reset,} = useForm({defaultValues: {}});
  const [formDataDownload, setFormDataDownload] = useState({
    type: 'csv',
    year: new Date().getFullYear(),  
    month: new Date().getMonth() + 1,
    day: 1
  });
  const token = localStorage.getItem("USER_TOKEN");

  useEffect(() => {
    getCompanieHours();
  }, []);

  useEffect(() => {
    console.log("enabledHistorics changed: ", enabledHistorics);
    enabledHistoricsRef.current = enabledHistorics;
  }, [enabledHistorics]);

  const getCompanieHours = async () => {
    const resultIdCompany = localStorage.getItem("ID_COMPANY");
    setLoading(true);
    try {
      const url = urlAPI_2 + "companies/" + resultIdCompany;
      const options = { method: "GET", mode: "cors"};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      if (result.code === 0) {
        sendToast(result)
      } else if (!result.data) {
        setCompanie([]);
      } else {
        setCompanie(result.data[0]);
        const {
          chatHistorics
        } = result.data[0];
        console.log("chatHistorics: ", chatHistorics);
        setChatHistoricsValue(chatHistorics.value);
        setEnabledHistorics(chatHistorics.enabled);
        enabledHistoricsRef.current = chatHistorics.enabled;
        reset({
            historicValue: chatHistorics.value,
            enabledHistorics: chatHistorics.enabled,
          }
        );
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const putCompanyHistorics = async (inputs) => {
    const { historicValue, enabledHistorics } = inputs;
    if(enabledHistoricsRef.current && !historicValue){
      sendToast({type: "info", message: "Debes seleccionar un límite para el guardado de los chats recientes."})
      return;
    }
    try {
      const id = companie._id;
      const data = {
        isFetch: true,
        fetch: {
          url: urlAPI_2 + "companies/" + id,
          options: { 
            method: "GET", 
            mode: "cors", 
            body: JSON.stringify({
              chatHistorics: {enabled: enabledHistoricsRef.current, value: historicValue}
            })
          }
        },
        title: "¡Atención!",
        text: "Esta acción moverá los mensajes a un almacenamiento de históricos según el ajuste seleccionado. Luego no podrás verlos en la pantalla del chat multiagente. ¿Deseas continuar?",
        icon: "warning",
        buttons: { success: "Aceptar", cancel: "Cancelar" }
      };
    
      console.log("Datos enviados a questionToast:", data);
      const result = await questionToast(data, setLoading);
    
      console.log("Result -------->: ", result);
      sendToast(result)
    } catch (error) {
      console.log(error);
    }

  };

  const handleInputChangeDownloadHistorics = (event) => {
    const { name, value } = event.target;
    setFormDataDownload({
      ...formDataDownload,
      [name]: value
    });
  };
  

  const handleSubmitDownloadHistorics = (event) => {
    event.preventDefault();
    downloadHistorics(formDataDownload);
  };

  const showMoreInfo = async (e) => {
    e.preventDefault();

    const data = {
      isFetch: false,
      fetch: {},
      title: "Más información",
      text: `Al habilitarse, los chats recientes comenzarán a almacenarse durante los últimos días seleccionados.
Por ejemplo, si selecciona 30 días solo podrá visualizar chats de los últimos 30 días en la sección de Chats.
Para acceder a los chats del resto de los días deberá descargarlos.`,
      icon: "info",
      buttons: { cancel: "Cerrar" }
    };
  
    console.log("Datos enviados a questionToast:", data);
    const result = await questionToast(data, setLoading);
  
    console.log("Result -------->: ", result);   
  }

  const checkFileReady = async (signedUrl) => {
    console.log("Verificando si el archivo está listo...");
    const limit_count = 60; 
    let i = 0;
    while (i < limit_count) {
      i += 1;
      try {
        const response = await fetchWithoutAuth(signedUrl, { method: "GET", mode: "cors" });
        if (response.ok) {
          console.log("El archivo está listo en S3, iniciando descarga...");
          downloadFile(signedUrl);
          break;
        }
      } catch (error) {
        console.log("El archivo aún no está disponible, reintentando en 2 segundos...");
      }
      
      await new Promise(resolve => setTimeout(resolve, 2000));
    }
  };

  
  const downloadFile = async (signedUrl) => {
    const response = await fetchWithoutAuth(signedUrl, { method: "GET", mode: "cors" });
    const blob = await response.blob();
    
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "chats-historicos.csv";
    document.body.appendChild(a);
    a.click();
    a.remove();
  
    console.log("Descarga completada.");
  };
  
  const downloadHistorics = async (inputs) => {
    const { type, year, month, day } = inputs;
    let data_post = { type, year, month, day };
    setLoading(true);
    try {
      const url = urlAPI_2 + "downloadHistorics";
      const options = { method: "POST", mode: "cors", body: JSON.stringify(data_post)};
      const response = await fetchWithAuth(url, options);
      if (!response.ok) {
        throw new Error('Error al realizar la solicitud.');
      }
  
      const { preSignedUrl } = await response.json();
      console.log("URL pre-firmada recibida:", preSignedUrl);
      await checkFileReady("https://s3.us-east-1.amazonaws.com/historical.messages/results/66a4d2c3-1e58-4dc9-8550-4e472454c685.csv");
    } catch (error) {
      console.log(error);
      sendToast({type: "error", message: "Hubo un problema al realizar la solicitud. Por favor, intenta de nuevo más tarde."})
    } finally {
      setLoading(false);
    }
  };  

  return (
    <>
      {loading ? (
        <div className="content-spinner">
          <Spinner />
        </div>
      ) : null}

      <div className="m-auto container-fluid p-0 h-100">
        <div className="col-xs-9 heading">
          <div>
            <h1>Chats históricos</h1>
          </div>

          <Header />
        </div>

        <div className="container-fluid mt-3 px-0 d-flex align-items-center" style={{ height: "90%" }}>
          <section id="content-historical-chats" className="h-100 w-100 mt-3" style={{overflow:"auto"}}>
            <section className="module-create-user myData" style={{ height: "auto", backgroundColor: "transparent", boxShadow: "none" }}>
              <div>
                <form onSubmit={handleSubmit(putCompanyHistorics)}>
                  <div className="sections-my-data">
                    <div className="d-flex align-items-center">
                      <h2 className="d-flex align-items-center" style={{ fontSize: "1.4em", padding: "20px 20px 0px" }}>
                        Configuración
                      </h2>
                      <input
                          name="messageCheck"
                          id="messageCheck"
                          type="checkbox"
                          value={enabledHistorics}
                          className="cmn-toggle cmn-toggle-round-flat"
                          checked={enabledHistorics}
                          {...register("enabledHistorics")}
                          onChange={(e) => {setEnabledHistorics(e.target.checked)}}
                      />
                      <label className="ml-4 mb-0" htmlFor="messageCheck"></label>
                    </div>
                    {enabledHistorics &&
                      <div className="module-section border-top-0" id="product-basic">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="row" style={{flexDirection: "column"}}>
                              <div className="col-md-6 my-0 mx-auto d-flex align-items-end">
                                <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable mt-0">
                                  <label htmlFor="select">Seleccione la cantidad de días para el guardado de chats recientes: </label>
                                  <select
                                    name="historicValue"
                                    id="historicValue"
                                    className="form-control"
                                    {...register("historicValue")}
                                    onChange={(e) => {setChatHistoricsValue(e.target.value)}}
                                  >
                                    <option value="" key="0" disabled selected>
                                      Seleccione...
                                    </option>
                                    <option value="7" key="7" >
                                      7 días.
                                    </option>
                                    <option value="15" key="15" >
                                      15 días.
                                    </option>
                                    <option value="30" key="30" >
                                      30 días.
                                    </option>
                                    <option value="60" key="60" >
                                      60 días.
                                    </option>
                                    <option value="90" key="90" >
                                      90 días.
                                    </option>
                                    <option value="180" key="180" >
                                      180 días.
                                    </option>                                                                   
                                  </select>
                                </div>

                                {chatHistoricsValue &&
                                  <div className="ml-2 d-flex align-items-end">
                                    <BsTrash3 className="text-danger pointer" style={{fontSize: "18px"}} onClick={() => {setChatHistoricsValue(""); reset({historicValue: "", enabledHistorics: false})}}/>
                                  </div>
                                }
                              </div>
                              <div className="col-md-6 my-0 mx-auto mt-1">
                                  <button className="custom-button" onClick={showMoreInfo}>
                                      Más información
                                  </button>
                              </div>                            
                            </div>
                          </div>
                        </div>
                      </div>
                    }
                    <footer className="mx-0">
                      <div className="row">
                        <div className="col-sm-6"></div>
                        <div className="col-sm-6 right">
                          <input
                            className="btn btn-lg btn-info"
                            value="Actualizar"
                            type="submit"
                          />
                        </div>
                      </div>
                    </footer>
                  </div>

                </form>
              </div>
            </section>
            <section className="module-create-user myData" style={{ height: "auto", backgroundColor: "transparent", boxShadow: "none" }}>
              <div>
                <form onSubmit={handleSubmitDownloadHistorics}>
                  <div className="sections-my-data">
                    <div className="d-flex align-items-center">
                      <h2 className="d-flex align-items-center" style={{ fontSize: "1.4em", padding: "20px 20px 0px" }}>
                        <TbCalendarCancel style={{ fontSize: "25px", marginRight: "10px" }} />
                        Descarga de chats
                      </h2>
                    </div>
                    <div className="module-section border-top-0" id="product-basic">
                        <div className="row" style={{flexDirection: "column"}}>
                            <div className="col-md-6 my-0 mx-auto d-flex align-items-center">
                              <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable mt-0">
                                <label htmlFor="select">Seleccione el año y mes: </label>
                                <div className="row mb-0 mt-1">
                                  {/* Selector de Año */}
                                  <div className="col-md-4 su-control su-with-prefix su-with-suffix su-segmentation-disable mb-0 mt-0">
                                    <label htmlFor="year">Año: </label>
                                    <div className="su-control-group" style={{width: "100%"}}>
                                      <select
                                        className="form-control"
                                        name="year"
                                        value={formDataDownload.year}
                                        onChange={handleInputChangeDownloadHistorics}
                                      >
                                        {Array.from({ length: new Date().getFullYear() - 2022 }, (_, i) => (
                                          <option key={i} value={2023 + i}>
                                            {2023 + i}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                  </div>
                                  {/* Selector de Mes */}
                                  <div className="col-md-4 su-control su-with-prefix su-with-suffix su-segmentation-disable mb-0 mt-0">
                                    <label htmlFor="month">Mes: </label>
                                    <div className="su-control-group" style={{width: "100%"}}>
                                      <select
                                        className="form-control"
                                        name="month"
                                        value={formDataDownload.month}
                                        onChange={handleInputChangeDownloadHistorics}
                                      >
                                        {[
                                          "Enero",
                                          "Febrero",
                                          "Marzo",
                                          "Abril",
                                          "Mayo",
                                          "Junio",
                                          "Julio",
                                          "Agosto",
                                          "Septiembre",
                                          "Octubre",
                                          "Noviembre",
                                          "Diciembre",
                                        ].map((month, index) => (
                                          <option key={index} value={index + 1}>
                                            {month}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                  </div>
                                  {/* Selector de Día */}
                                  <div className="col-md-4 su-control su-with-prefix su-with-suffix su-segmentation-disable mb-0 mt-0">
                                    <label htmlFor="day">Día: </label>
                                    <div className="su-control-group" style={{width: "100%"}}>
                                      <select
                                        className="form-control"
                                        name="day"
                                        value={formDataDownload.day}
                                        onChange={handleInputChangeDownloadHistorics}
                                      >
                                      {Array.from({ length: new Date(formDataDownload.year, formDataDownload.month, 0).getDate() }, (_, i) => (
                                        <option key={i + 1} value={i + 1}>
                                          {i + 1}
                                        </option>
                                      ))}
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6 my-0 mx-auto d-flex align-items-center mt-4">
                              <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable mt-0">
                                <label htmlFor="select">Seleccione el formato: </label>
                                <div className="row mb-0 mt-1">
                                  <div className="col-md-12 su-control su-with-prefix su-with-suffix su-segmentation-disable mb-0 mt-0">
                                    <select
                                      className="form-control"
                                      name="type"
                                      value={formDataDownload.type}
                                      onChange={handleInputChangeDownloadHistorics}
                                    >
                                      <option value="json">JSON</option>
                                      <option value="csv">CSV</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                            </div>                       
                        </div>
                      </div>
                    
                    <footer className="mx-0">
                      <div className="row">
                        <div className="col-sm-6"></div>
                        <div className="col-sm-6 right">
                          <input
                            className="btn btn-lg btn-info"
                            value="Descargar"
                            type="submit"
                          />
                        </div>
                      </div>
                    </footer>
                  </div>
                </form>
              </div>
            </section>
          </section>          
        </div>     
      </div>

      <ToastContainer 
        position="top-center"
        autoClose={52000}
        // autoClose={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        // draggable
        theme={JSON.parse(localStorage.getItem("THEME")) ? "dark" : "light"}
        toastClassName="custom-toast" // Aplica estilos personalizados a cada toast individualmente
        bodyClassName="custom-toast-body"
      />
    </>
  );
};

export default HistoricalChats;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  h1 {
    font-size: 42px;
    font-weight: 700;
    margin-bottom: 10px;
    text-align: center;
  }
  li {
    padding: 10px 5px;
    border-bottom: 1px solid rgb(0, 0, 0, 0.05);
  }
  li:hover {
    background: rgb(0, 0, 0, 0.05);
  }
  p {
    font-size: 18px;
    margin-bottom: 0px;
  }
  input {
    padding: 10px;
    border-radius: 0px;
    font-size: 17px;
  }
  img.img-answer {
    cursor: pointer;
    width: auto;
    height: 180px;
    object-fit: scale-down;
  }
  .content-img {
    position: relative;
  }
  // .content-img .icon-eye {
  //   cursor: pointer;
  //   position: absolute;
  //   top: 50%;
  //   left: 50%;
  //   transform: translate(-50%, -50%);
  // }
  .content-img img:hover {
    opacity: 0.6;
  }
`;

const Alert = styled.span`
  color: red;
  display: block;
  width: 250px;
`;
