import Avvvatars from "avvvatars-react";
import React, { Fragment, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import useAPI from "../../hooks/useAPI";
import Modal from "../layout/Modal";
import Spinner from "../plugins/Spinner";
import dayjs from "dayjs";
import Header from "../layout/Header";
import { FaAngleLeft, FaListUl } from "react-icons/fa";
import { BsPersonVcard, BsSearch } from "react-icons/bs";
import {sendToast} from "../plugins/toastAll";
import { ToastContainer } from "react-toastify";

const Person = () => {
  const { id } = useParams();

  const [navAdditionalData, setNavAdditionalData] = useState(false);
  const [navLists, setNavLists] = useState(false);

  const token = localStorage.getItem("USER_TOKEN");
  //?   console.log("ID =========================", id);

  const [person, setPerson] = useState(undefined);
  const [allLists, setAllLists] = useState([]);
  const [tableLists, setTableLists] = useState([]);
  const [search, setSearch] = useState("");
  const [listsModal, setListsModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [validationModal, setValidationModal] = useState(false);

  const [icon, setIcon] = useState("");

  const { urlAPI_1, urlAPI_2, fetchWithAuth } = useAPI();

  const {watch,register,handleSubmit,formState: { errors },reset} = useForm({ defaultValues: { dataSegmentCheck: [], userId: id } });

  useEffect(() => {
    getPerson();
    getLists();
    getListsByUser();
  }, []);

  const getPerson = async () => {
    setLoading(true);
    const url = urlAPI_1 + `persons/${id}`;
    const options = { method: "GET", mode: "cors"};
    const response = await fetchWithAuth(url, options);
    const result = await response.json();
    if (result) {
      setPerson(result);
      //# Destructuring despues de validación
      const { name, lastName } = result.data[0];
      const initialWords = name.split(" ");
      const firstWord = initialWords[0];
      const secondWord = initialWords[1];
      let icon = "";
      if (lastName) {
        const initialWordsLastName = lastName.split(" ");
        const firstWordLastName = initialWordsLastName[0];
        setIcon(firstWord[0] + firstWordLastName[0]);
      } else {
        if (secondWord === undefined) {
          setIcon(firstWord[0]);
        } else {
          setIcon(firstWord[0] + secondWord);
        }
      }

    }
    setLoading(false);
  };

  const getLists = async () => {
    console.log("listando...");
    try {
      setLoading(true);
      const url = urlAPI_1 + "lists";
      const options = { method: "GET", mode: "cors"};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      console.log("listas", result);
      if (result.code === 0) {
        sendToast(result)
      }else if (result.message === 'Tu petición no tiene cabecera de autorización') {
        sendToast({type: "info", message: "Tu sesion ha caducado, verifica si alguien mas se ha conectado con tus credenciales"})
        navigate('/');
      } else if (!result.data) {
        setValidationModal(true);
        setAllLists([]);
      } else {
        setValidationModal(false);
        setAllLists(result.data);
        setTableLists(result.data);
      }
      setLoading(false);
    } catch (error) {
      console.log("error on actualizar", error);
      return null;
    }
  };

  const getListsByUser = async () => {
    setLoading(true);
    try {
      const url = urlAPI_1 + "listByUser/" + id;
      const options = { method: "GET", mode: "cors"};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      console.log("Listas in person module", result);
      if (result.type != "success") {
        setValidationModal(true);
      } else {
        setValidationModal(false);
        setAllLists(result.data);
        setTableLists(result.data);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };


  //# Función de navegación
  const navigate = useNavigate();

  //# Validación de cuando el estado ya tenga los datos
  // if (!person) {
  //   return <Spinner />;
  // }


  // console.log(person.data[0])

  const searchQuery = (searchTerm) => {
    var searchResults = tableLists.filter((element) => {
      if (
        element.name.toString().toLowerCase().includes(searchTerm.toLowerCase())
      ) {
        return element;
      }
    });
    setAllLists(searchResults);
  };

  const addLists = async (inputs) => {
    console.log(inputs);
    try {
      setLoading(true);
      const url = urlAPI_1 + "addUserToList";
      const options = { method: "POST", mode: "cors", body: JSON.stringify(inputs)};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      console.log("Result of the API", result);
      await getPerson();
      setListsModal(false);
      setLoading(false);
      sendToast(result)
    } catch (error) {
      console.log(error);
    }
  };

  const onAditionalDates = () => {
    setNavLists(false);

    if (navAdditionalData) {
      console.log("trueeee");
      setNavAdditionalData(false);
    } else {
      console.log("Falseeee");
      setNavAdditionalData(true);
    }

    // var ht = document.getElementById('title-aditionalDates');
    //   ht.classList.toggle("active");
  };

  const onLists = () => {
    setNavAdditionalData(false);

    if (navLists) {
      setNavLists(false);
    } else {
      setNavLists(true);
    }

    // var ht = document.getElementById('title-lists');
    //   ht.classList.toggle("active");
  };

  return (
    <Fragment>
      {/* {loading ? (
        <div className="content-spinner">
          <Spinner />
        </div>
      ) : null} */}

      {person ? <>
        <div className="row mx-auto container-fluid p-0 justify-content-between border-top-0 border-right-0 border-left-0 border-secondary mb-3">
          <div style={{ height: "45px", lineHeight: "95px" }}>
            <button
              onClick={() => navigate("/home/contacts")}
              type="button"
              className="btn btn-left col-1-sm-3 my-auto position-absolute d-flex btn-angle-left"
            >
              <FaAngleLeft /> Contactos
            </button>
          </div>

          <Header />
        </div>

        <section className="module module-user">
          <form>
            <div className="module-section">
              <div className="row">
                <div className="col-md-4">
                  <Icon>
                    <Avvvatars value={icon} />
                  </Icon>
                  <h2>
                    <a href="?section=user">{person.data[0].name}{" "}{person.data[0].lastName}</a>
                  </h2>
                  <p>
                    <strong>Correo: </strong> {person.data[0].email} <br />
                    <strong>Telefono: </strong> {person.data[0].phone} <br />
                    <strong>Fecha de registro: </strong>{" "}
                    {dayjs(person.data[0].created).locale("es").format("DD MMMM YYYY")} <br />
                  </p>
                </div>

                <div className="col-md-8">
                  <div className="panel-group collapsible" id="user-collapsible">
                    <div className="panel panel-default">
                      <div className="panel-heading">
                        <h3 id="title-aditionalDates" className="panel-title">
                          <a
                            href="javascript:;"
                            className="d-flex align-items-center"
                            onClick={onAditionalDates}
                            style={
                              navAdditionalData
                                ? { background: "#70c8e2", color: "#fff" }
                                : null
                            }
                          >
                            <BsPersonVcard />
                            &nbsp;&nbsp; Datos adicionales
                          </a>
                        </h3>
                      </div>

                      {navAdditionalData ? (
                        <div id="email-welcome" className="panel-collapse">
                          <div className="panel-body">
                            <dl className="dl-horizontal">
                              <dt>Agregar nueva información</dt>
                              <dd>Sin tags</dd>
                            </dl>
                          </div>
                        </div>
                      ) : null}
                    </div>
                    <div className="panel panel-default mt-3">
                      <div className="panel-heading">
                        <h3 id="title-lists" className="panel-title">
                          <a
                            href="javascript:;"
                            className="d-flex align-items-center"
                            onClick={onLists}
                            style={
                              navLists
                                ? { background: "#70c8e2", color: "#fff" }
                                : null
                            }
                          >
                            <FaListUl />
                            &nbsp;&nbsp; Listas
                          </a>
                        </h3>
                      </div>

                      {navLists ? (
                        <>
                          <div id="email-welcome" className="panel-collapse">
                            <div className="panel-body">
                              <dl id="list-contact-lists" className="dl-horizontal" style={{height: "50vh", overflow:"auto"}}>
                                <dt className="mb-2">Listas del contacto</dt>
                                {person.data[0].lists.length > 0 ? (
                                  person.data[0].lists.map((list) => (
                                    <dd
                                      onClick={() =>
                                        navigate(`/home/list/${list._id}`)
                                      }
                                    >
                                      ● {list.name}
                                    </dd>
                                  ))
                                ) : (
                                  <p className="mt-2 f-18 f-w-600">
                                    No tiene listas asociadas
                                  </p>
                                )}
                              </dl>
                              <div className="Add-to-list">
                                {/* <button onClick={() => setListsModal(true)} className="btn bg-success col-1-sm-3 my-auto">+ Agregar a una lista</button> */}
                                <input
                                  onClick={() => setListsModal(true)}
                                  className="btn btn-sm btn-info"
                                  value="+ Agregar a una lista"
                                  name="submit"
                                  id="submit"
                                  type="button"
                                />
                              </div>
                            </div>
                          </div>
                        </>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </section>

      </>:
        <div className="content-spinner">
          <Spinner />
        </div>
      }

      <Modal
        status={listsModal}
        changeStatus={setListsModal}
        title={"Agregar a lista"}
        action={() => reset()}
      >
        <form className="form-inline justify-content-center mb-2 modal-addList">
          <div className="su-control mt-1">
            <input
              type="text"
              className="form-control"
              placeholder="Búsqueda"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
                searchQuery(e.target.value);
              }}
            />
            <button
              className="btn btn-info"
              type="button"
              id="buttonSearchProductBack"
              style={{ height: "34px" }}
            >
              <BsSearch />
            </button>
          </div>
        </form>

        {errors.dataSegmentCheck && (
          <p className="text-danger f-14 f-w-900">
            * {errors.dataSegmentCheck.message}
          </p>
        )}
        <form onSubmit={handleSubmit(addLists)}>
          <ModalContent className="list-users">
            {validationModal && (
              <div className="text-center mt-3">
                <div className="empty">
                  <FaListUl style={{ fontSize: "55px" }}/>
                  <h1 style={{ fontSize: "30px" }}>No tienes listas</h1>
                  <input
                    type="button"
                    className="btn btn-default"
                    value="Agregar Nueva"
                    style={{ fontFamily: "sans-serif" }}
                    onClick={() => {
                      navigate("/home/lists");
                    }}
                  />
                </div>
              </div>
            )}
            <ul className="o-auto d-flex flex-column justify-content-center align-items-center">
              {allLists &&
                allLists.map((list) => (
                  <li
                    key={list._id}
                    className="bg-light b-primary m-t-5 p-3 row w-90 align-items-center"
                  >
                    <span className="col-8">{list.name}</span>
                    <input
                      name={list.name}
                      id={list.name}
                      defaultChecked={list.inList}
                      type="checkbox"
                      value={list._id}
                      className="cmn-toggle cmn-toggle-round-flat"
                      {...register("dataSegmentCheck", {
                        required: {
                          value: true,
                          message: "Al menos una lista es obligatoria",
                        },
                      })}
                    />
                    <label className="ml-4" for={list.name}></label>
                  </li>
                ))}
            </ul>
          </ModalContent>
          <div className="row justify-content-end mt-3 pt-3">
            <div className="col-sm-6 right">
              <a
                href="javascript:;"
                className="btn btn-lg btn-default mr-1"
                onClick={() => setListsModal(false)}
              >
                Volver
              </a>
              {validationModal ? (
                <input
                  className="btn btn-lg btn-info"
                  value="Añadir"
                  type="submit"
                  disabled
                />
              ) : (
                <input
                  className="btn btn-lg btn-info"
                  value="Añadir"
                  type="submit"
                />
              )}
            </div>
          </div>
        </form>
      </Modal>
      <ToastContainer 
        position="top-center"
        autoClose={52000}
        // autoClose={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        // draggable
        theme={JSON.parse(localStorage.getItem("THEME")) ? "dark" : "light"}
        toastClassName="custom-toast" // Aplica estilos personalizados a cada toast individualmente
        bodyClassName="custom-toast-body"
      />
    </Fragment>
  );
};

export default Person;

const Lista = styled.ul`
  // border-bottom: 8px solid #3d4854;

  li {
    font-size: 20px;
    padding: 5px;
    border-bottom: 6px solid transparent;
    font-weight: 700;
    cursor: pointer;
    transition: all 0.3s ease;
    border-radius: 5px;
  }

  li:hover {
    border-bottom: 3px solid #fff;
  }

  .active {
    border-bottom: 3px solid #fff;
  }
`;

const Content = styled.div`
  padding: 8px;
  background: transparent;
  height: 100%;
  display: grid;

  .title {
    font-size: 24px;
    color: #323232;
    margin: 0px 15px 10px;
    border-bottom: 1px solid gray;
    padding: 10px 0;
    font-weight: 900;
  }

  .lists-container {
    max-height: 230px;
    overflow: auto;
  }

  p {
    color: gray;
    margin: 0 15px;
    font-weight: 600;
    font-size: 20px;
  }

  .add-user {
    width: 40%;
    color: white;
    margin-top: 10px;
    text-align: center;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5);
  }

  .add-user:hover {
    background: #0049e3;
  }
`;

const ListUser = styled.li`
  .list {
    color: gray;
    display: inline-block;
    cursor: pointer;
    transition: all 0.5s ease;
    padding: 3px;
  }

  .list:hover {
    color: #323232;
  }
`;

const ModalContent = styled.div`
  overflow: auto;
  height: 335px;

  .checkbox {
    padding: 25px;
    width: 25px;
    height: 25px;
  }
`;

const Icon = styled.div`
  margin-bottom: 20px;

  div {
    height: 125px;
    width: 125px;
    border-radius: 50%;
    box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.7);
    margin: 0 auto;
  }

  p {
    font-size: 40px;
    font-weight: 600;
  }
`;
