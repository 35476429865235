import React, { Fragment, useState, useEffect } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import useAPI from "../../hooks/useAPI";
import usePage from "../../hooks/usePage";
//#--> componentes
import Pagination from "../plugins/Pagination";
import Modal from "../layout/Modal";
import Header from "../layout/Header";
import Spinner from "../plugins/Spinner";
import { BiSolidLabel } from "react-icons/bi";
import { BsSearch, BsPencilFill, BsTrash3 } from "react-icons/bs";
import { FaEllipsisVertical } from "react-icons/fa6";
import { FaTags } from "react-icons/fa";
import {questionToast, sendToast} from "../plugins/toastAll";
import { ToastContainer } from "react-toastify";

const Tags = () => {
  const [loading, setLoading] = useState(false);
  const [listTags, setListTags] = useState([]);
  const [tableListTags, setTableListTags] = useState([]);
  const [modalCreateTags, setModalCreateTags] = useState(false);
  const [selectedColor, setSelectedColor] = useState("#84b6f4");
  const [selectedTag, setSelectedTag] = useState("");
  const [validation, setValidation] = useState(false);
  const [search, setSearch] = useState("");

  const [mode, setMode] = useState("Crear");

  //#--> Paginación
  const { page, setPage, byPage, resetPage } = usePage();
  const { urlAPI_1, urlAPI_2, fetchWithAuth } = useAPI();

  const {watch,register,handleSubmit,formState: { errors },reset,} = useForm({defaultValues: {title: "",description: "",color: ""}});
  
  useEffect(() => {
    getTags()
  },[])
  
  //#--> Listar todas las etiquetas
  const getTags = async () => {
    try {
      setLoading(true);
      const url = urlAPI_1 + `tags`;
      const options = { method: "GET", mode: "cors"};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      console.log("Data:::", result);
      if(result.data.length <= 0) {
        console.log("no hay data");
        setListTags([]);
        setValidation(true);
      } else {
        console.log("si hay lista");
        setListTags(result.data);
        setTableListTags(result.data);
        setValidation(false);
      }
      setLoading(false);
    } catch (error) {
      console.log("error:", error);
      return null;
    }
  }

  //#--> Obtener una etiqueta en especifico
  const getTag = async (id) => {
    console.log("id>>>>>>", id);
    const resultFilt = listTags.filter((tag) => tag._id == id ? tag : null)
    console.log('Result ====>', resultFilt);
    setModalCreateTags(true);
    reset({title: resultFilt[0].title, description: resultFilt[0].description, color: resultFilt[0].color})
    setSelectedColor(resultFilt[0].color);
    setSelectedTag(resultFilt[0]._id)
  };

  //#--> Crear nueva etiqueta
  const postTag = async (data) => {
    setModalCreateTags(false);
    try {
      setLoading(true);
      const values = {
        title: data.title,
        description: data.description,
        color: data.color,
      };

      const url = urlAPI_1 + "tags";
      const options = { method: "POST", mode: "cors", body: JSON.stringify(values)};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      console.log("Data Send", result);
      await getTags();
      
      setLoading(false);
      sendToast(result)
    } catch (error) {
      console.log(error);
    }
  }

  //#--> Actualizar etiqueta
  const putTag = async (data) => {
    setModalCreateTags(false);
    try {
      setLoading(true);
      const url = urlAPI_1 + `tags/${selectedTag}`;
      const options = { method: "PUT", mode: "cors", body: JSON.stringify(data)};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      console.log("Data Updated", result);

      await getTags();
      reset();
      sendToast(result)
    } catch (error) {
      console.log(error);
    }
  };

  //#--> Eliminar etiqueta
  const deleteTag = async (id) => {
    console.log('Id a eliminar==>', id)
    try {
      const data = {
        isFetch: true,
        fetch: {
          url: urlAPI_1 + `tags/${id}`,
          options: { 
            method: "DELETE", 
            mode: "cors"
          }
        },
        title: "¿Estas seguro que deseas eliminar esta etiqueta?",
        text: "No podrás recuperarla.",
        icon: "warning",
        buttons: { success: "Eliminar", cancel: "Cancelar" }
      };
    
      console.log("Datos enviados a questionToast:", data);
      const result = await questionToast(data, setLoading);
      console.log("Result -------->: ", result);

      //#--> Actualización de la tabla
      await getTags()
      //#--> Confirmación de la eliminación
      sendToast(result)
    } catch (error) {
      console.log(error);
    }
  };

  //#--> Funcion que se encargar de filtrar por busqueda
  const searchTag = (searchTerm) => {
    var searchResults = tableListTags.filter((element) => {
      if (
        element.title.toString().toLowerCase().includes(searchTerm.toLowerCase()) || 
        element.description.toString().toLowerCase().includes(searchTerm.toLowerCase())) 
      {
        return element;
      }
    });
    setListTags(searchResults);
    if (searchResults.length == 0) {
      setValidation(true);
    } else {
      setValidation(false);
    }
  };

  return(
    <Fragment>
      {loading ? (
        <div className="content-spinner">
          <Spinner />
        </div>
      ) : null}

      <>
        <div className="col-xs-9 heading">
          <div className="mt-2">
            <h1>Etiquetas</h1>
            <input
              type="button"
              className="btn btn-default"
              value="Agregar nueva"
              onClick={() => {
                setModalCreateTags(true);
                reset({title: "", description: "", color: ""});
                setMode("Crear")
              }}
            />
          </div>
          <Header />
        </div>

        <header>
          <div style={{ float: "right" }}>
            <form className="form-inline">
              <div className="su-control my-1">
                <input
                  className="form-control"
                  type="text"
                  placeholder="Búsqueda"
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                    searchTag(e.target.value);
                    resetPage(1);
                  }}
                />
                <button
                  className="btn btn-info"
                  type="button"
                  id="buttonSearchProductBack"
                  style={{ height: "34px" }}
                >
                  <BsSearch />
                </button>
              </div>
            </form>
          </div>
        </header>

        <div className="container-fluid mt-3 mb-1 px-0" style={{ height: "75%", overflow: "auto" }}>
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Acciones</th>
                <th>Titulo</th>
                <th>Descripción</th>
                <th>Color</th>
              </tr>
            </thead>
            <tbody>
              {listTags.length > 0 && listTags.slice((page - 1) * byPage, (page - 1) * byPage + byPage).map((tag, t) => {
                return (
                  <tr key={t}>
                    <td>
                      <div className="d-flex align-items-center justify-content-center">
                        <div className="dropdown">
                          <button
                            className="bg-transparent border-0"
                            type="button"
                            id="dropdownMenuButton"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <FaEllipsisVertical className="text-success f-24 pointer"/>
                          </button>

                          <div className="dropdown-menu p-2" aria-labelledby="dropdownMenuButton">
                            <button className="text-primary border-0 bg-transparent d-block pointer mt-2"
                              onClick={() => {
                                getTag(tag._id);
                                setMode("Editar");
                              }}
                            >
                              <BsPencilFill style={{ fontSize: "18px" }}/>
                              <span className="text-dark ml-2 f-14">Editar</span>
                            </button>

                            <button className="text-danger border-0 bg-transparent d-block pointer mt-2"
                              onClick={() => {
                                deleteTag(tag._id);
                              }}
                            >
                              <BsTrash3 style={{ fontSize: "18px" }}/>
                              <span className="text-dark ml-2 f-14">Eliminar</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>{tag.title}</td>
                    <td>{tag.description}</td>
                    <td className="tag-icon-table" >
                      <BiSolidLabel 
                        style={{
                          color: tag.color,
                          fontSize: "2.5em",
                        }}
                      />
                      {/* <label 
                        className="p-2" 
                        style={{
                          backgroundColor: tag.color, 
                          borderRadius: '6px',
                          width: '50px',
                          height: '25px',
                        }}
                      ></label> */}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          {validation && (
            <div className="text-center mt-3">
              <div className="empty">
                <FaTags style={{ fontSize: "55px" }} />
                <h1>No tienes etiquetas</h1>
                <input
                  type="button"
                  className="btn btn-default"
                  value="Agregar nueva"
                  onClick={() => {
                    setModalCreateTags(true);
                    reset({title: "", description: "", color: ""});
                    setMode("Crear")
                  }}
                />
              </div>
            </div>
          )}

        </div>

        <footer>
          <div className="row">
            <div className="col-md-8"></div>

            <div className="col-md-4">
              <Pagination
                totalData={listTags.length}
                byPage={byPage}
                setPage={setPage}
              />
            </div>
          </div>
        </footer>
      </>

      <Modal
        status={modalCreateTags}
        changeStatus={setModalCreateTags}
        title={`${mode} etiqueta`}
        width={"600px"}
      >
        <Content>
          <div className="justify-content-center">
            <form 
              onSubmit={handleSubmit(mode == "Crear" ? postTag : putTag)}
            >
              <div className="justify-content-center">
                <div className="row mb-3">
                  <div className="col-md-12">
                    <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable">
                      <label htmlFor="name">Titulo: </label>
                      <div className="su-control-group">
                        <input
                          className="form-control"
                          name="title"
                          id="title"
                          type="text"
                          {...register("title", {
                            required: {
                              value: true,
                              message: "El titulo es requerido.",
                            },
                            pattern: {
                              value: /\S+/,
                              message: "No puede ser solo espacios en blanco",
                            },
                          })}
                        />
                      </div>
                      {errors.title && <Alert>* {errors.title.message}</Alert>}
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable">
                      <label htmlFor="name">Descripción: </label>
                      <div className="su-control-group">
                        <input
                          className="form-control"
                          name="description"
                          id="description"
                          type="text"
                          {...register("description")}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable">
                      <label htmlFor="name">Color: </label>
                      <div className="su-control-group">
                        <input
                          type="color"
                          id="color"
                          name="color"
                          style={{
                            backgroundColor: selectedColor,
                            border: 'none',
                            borderRadius: '6px',
                            height: '35px',
                            width: '70px',
                            padding: '14px 34px',
                            cursor: 'pointer', 
                            boxShadow: '2px 2px 0px 0px #b7c6ca',
                          }}
                          value={selectedColor}
                          {...register("color", {
                            required: {
                              value: true,
                              message: "El color es requerido.",
                            },
                            pattern: {
                              value: /\S+/,
                              message: "No puede ser solo espacios en blanco",
                            },
                          })}
                          onChange={(e) => {setSelectedColor(e.target.value)}}
                        />

                      </div>
                      {errors.color && <Alert>* {errors.color.message}</Alert>}
                    </div>
                  </div>

                </div>
              </div>
              <div className="row justify-content-end">
                <a
                  href="javascript:;"
                  className="btn btn-lg btn-default mr-1"
                  onClick={() => setModalCreateTags(false)}
                >
                  Cancelar
                </a>
                <input
                  className="btn btn-lg btn-info"
                  value="Guardar"
                  name="submit"
                  id="submit"
                  type="submit"
                  // onClick={createOrEdit === "Crear" ? onAddNode : onEditNode}
                />
              </div>
            </form>
          </div>
        </Content>
      </Modal>
      <ToastContainer 
        position="top-center"
        autoClose={52000}
        // autoClose={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        // draggable
        theme={JSON.parse(localStorage.getItem("THEME")) ? "dark" : "light"}
        toastClassName="custom-toast" // Aplica estilos personalizados a cada toast individualmente
        bodyClassName="custom-toast-body"
      />
    </Fragment>
  )
};

export default Tags;


const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  h1 {
    font-size: 42px;
    font-weight: 700;
    margin-bottom: 10px;
    text-align: center;
  }
  li {
    padding: 10px 5px;
    border-bottom: 1px solid rgb(0, 0, 0, 0.05);
  }
  li:hover {
    background: rgb(0, 0, 0, 0.05);
  }
  p {
    font-size: 18px;
    margin-bottom: 0px;
  }
  input {
    padding: 10px;
    border-radius: 0px;
    font-size: 17px;
  }
`;

const Alert = styled.span`
  color: red;
  display: block;
  width: 250px;
`;
