import React, {useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import useAPI from "../../../../hooks/useAPI";

import styledComponent from "styled-components";
import Spinner from "../../../plugins/Spinner";
import Select from "react-select";
import { sendToast } from "../../../plugins/toastAll";
import { ToastContainer } from "react-toastify";

const RemoveStatuses = ({
    statusModal,
    editFlow,
    isDark,
    countries,
    customStyles,
    customStylesDark
}) => {
    const { urlAPI_3, fetchWithAuth } = useAPI(); 
    const {watch,register,handleSubmit,formState: { errors },reset,} = useForm({
        defaultValues: {
            phone: ""
        },
    });
    
    const [loading, setLoading] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState(null);
    const [selectedCountrie, setSelectedCountrie] = useState("");
    const [selectedClass, setSelectedClass] = useState("");
    const [errorCountrie, setErrorCountrie] = useState(false);

    // ------FUNCTIONS------

    //# Function para seleccionar codigo 
    const handleChange = (selectedCountrie) => {
        setErrorCountrie(false);
        setSelectedCountrie(selectedCountrie);
        setSelectedClass(selectedCountrie.callingCodes[0]);
    };

    //# Funcion para eliminar estados del bot
    const removeStatuses = async (data) => {
        setLoading(true);
        const dataStatuses = {
            phoneNumber: `+${selectedCountrie.callingCodes[0]}${data.phone}`,
            chatBotId: editFlow._id
        }
        const url = urlAPI_3 + "chatBot/removeStatuses";
        const options = { method: "POST", mode: "cors", body: JSON.stringify(dataStatuses)};
        const response = await fetchWithAuth(url, options);
        const result = await response.json();
        if(result.code == 1) {
            setLoading(false);
            sendToast({type: "error", message: result.message})
        } else {
            reset();
            setLoading(false);
            sendToast({type: "success", message: "Estados eliminados con éxito"})
        }
    }; 
    
    return (
        <>
            {loading ? (
                <div className="content-spinner">
                    <Spinner />
                </div>
            ) : null}

            <div 
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <form onSubmit={handleSubmit(removeStatuses)} style={{ width: "100%" }}>
                    <div className="su-with-prefix su-with-suffix su-segmentation-disable">
                        <label htmlFor="phone">Teléfono: </label>
                        <div className="su-control-group d-flex">
                            <Select
                                styles={isDark == 'true' ? customStylesDark : customStyles}
                                className={selectedClass && selectedClass.length === 1 ? "form-control select-countries one" : selectedClass.length === 2 ? "form-control select-countries two" : "form-control select-countries" }
                                placeholder="+123"
                                value={selectedCountrie}
                                onChange={handleChange}
                                options={countries}
                                getOptionLabel={(option) => (
                                <div className="option countrie d-flex" style={{fontSize: "15px"}}>
                                    <img src={option.flag} alt={option.name} width="20" style={{width: "27px", marginRight: "3px"}} />(+{option.callingCodes}) {option.name} 
                                </div>
                                )}
                                getOptionValue={(option) => option.name}
                            />
                            <input
                                className="form-control"
                                style={{width: "65%"}}
                                id="phone"
                                name="phone"
                                type="number"
                                min={0}
                                {...register("phone", {
                                    required: {
                                        value: true,
                                        message: "El teléfono es requerido.",
                                    },
                                    pattern: {
                                        value: /^[0-9]{7,11}$/i,
                                        message: "El formato no es correcto.",
                                    },
                                    minLength: {
                                        value: 7,
                                        message: "Mínimo 7 dígitos.",
                                    },
                                    maxLength: {
                                        value: 11,
                                        message: "Máximo 11 dígitos.",
                                    },
                                })}
                            />
                        </div>
                        {errors.phone && <Alert>* {errors.phone.message}</Alert>}
                        {errorCountrie && <Alert>* El código es requerido.</Alert>}
                    </div>  

                    <div className="row justify-content-end bd col-12 mt-4">
                        <a
                            href="javascript:;"
                            className="btn btn-lg btn-default mr-1"
                            onClick={() => {
                                reset();
                                statusModal(false);
                            }}
                        >
                            Volver
                        </a>
                        <input
                            className="btn btn-lg btn-info"
                            type="submit"
                            value="Limpiar"
                            onClick={() => {
                                if (selectedCountrie) {
                                    setErrorCountrie(false)
                                } else {
                                    setErrorCountrie(true)
                                }
                            }}
                        />
                    </div>
                </form>
            </div>

            <ToastContainer 
                position="top-center"
                autoClose={52000}
                // autoClose={false}
                newestOnTop={false}
                closeOnClick={false}
                rtl={false}
                pauseOnFocusLoss
                draggable={false}
                // draggable
                theme={JSON.parse(localStorage.getItem("THEME")) ? "dark" : "light"}
                toastClassName="custom-toast" // Aplica estilos personalizados a cada toast individualmente
                bodyClassName="custom-toast-body"
            />
        </>
    )
}

export default RemoveStatuses;

const Alert = styledComponent.span`
  color: red;
  display: block;
  width: 100%;
  // width: 200px;
`;