import React, { useState, useEffect } from "react";
import { Handle, Position, useUpdateNodeInternals } from 'reactflow';
import useAPI from "../../../../hooks/useAPI";

import { HiOutlineSave } from "react-icons/hi";
import { IoIosWarning } from "react-icons/io";
import { sendToast } from "../../../plugins/toastAll";
import { ToastContainer } from "react-toastify";

const SaveAnswer = ({ data, isConnectable, id}) => {
    const { urlAPI_2, fetchWithAuth } = useAPI(); 
    const [correspondence, setCorrespondence] = useState([]);
    const updateNodeInternals = useUpdateNodeInternals();

    const getCorresp = async () => {
        try {
            const url = urlAPI_2 + "getDataEmail";
            const options = { method: "GET", mode: "cors"};
            const response = await fetchWithAuth(url, options);
            const result = await response.json();
            let data = result.data;
            if(data.length) {
                data = data.filter(v => v.field !== "callingCode")
            }
            setCorrespondence(data);
            if (result.message === "Tu petición no tiene cabecera de autorización") {
                sendToast({type: "info", message: "Tu sesion ha caducado, verifica si alguien mas se ha conectado con tus credenciales"})
                navigate("/");
            }
        } catch (error) {
          console.log(error);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
            await Promise.all([
                getCorresp(),
            ]);
            } catch (error) {
            console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        updateNodeInternals(id);
    }, [id, updateNodeInternals, data]);
    
    return (
        <div className="rounded" style={{width: "180px", boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px", cursor: 'pointer'}}>
            {data.saved && (
                <Handle 
                    type="target" 
                    position={Position.Top} 
                    isConnectable={isConnectable} 
                    id={`target-${id}`} 
                    style={{backgroundColor: '#D89C7E'}}
                />
            )}
            <div>
                <div 
                    className="d-flex align-items-center rounded-top justify-content-between" 
                    style={{height: "10px", backgroundColor: "#D89C7E", padding: "12px"}}
                >
                    <div className="d-flex align-items-center">
                        <HiOutlineSave className="mr-1" style={{fontSize: "12px", color: "#fff"}}/>
                        <span style={{fontSize: "10px", color: "#fff"}} >
                            {data.title ?? "Guardar Respuesta"}
                        </span>
                    </div>
                    {data?.error && (
                        <IoIosWarning style={{fontSize: "12px", color: "#FFCC00"}}/>
                    )}
                </div>
                <div className="bg-white rounded-bottom" style={{padding: '8px'}}>
                    {data.variable && (
                        <div 
                            className="p-1" 
                            style={{
                                border: "1px solid #000",
                                borderRadius: "4px",
                                height: "auto"
                            }}
                        >    
                            <div>
                                <span style={{fontWeight: "bold", fontSize: "9px"}}>
                                    Guardar respuesta en las variables:
                                </span>
                            </div>
                            {Array.isArray(data.variable) ? 
                                data.variable.map((val, index) => (
                                    <div key={index} className="d-flex align-items-center">
                                        <p 
                                            style={{fontWeight: "bold", fontSize: "9px", backgroundColor: "#F0F0F0", borderRadius: "4px"}} 
                                            className="m-0 p-1 mb-1"
                                        >
                                            {val.fieldCorresp}
                                        </p>
                                    </div>
                                ))
                                : (
                                    <p 
                                        style={{
                                            fontWeight: 'bold', 
                                            fontSize: "9px", 
                                            backgroundColor: "#F0F0F0", 
                                            color: "#000",
                                            borderRadius: "4px"
                                        }} 
                                        className="m-0 p-1 text-center"
                                    >
                                        {data.variable.fieldCorresp}
                                    </p>
                                )
                            }
                        </div>
                    )}
                </div>
            </div>
            {data.saved && (
                <Handle 
                    type="source" 
                    position={Position.Bottom} 
                    id={`source-${id}`}
                    isConnectable={isConnectable}  
                    style={{backgroundColor: '#D89C7E'}}
                />
            )}
            {/* <ToastContainer 
                position="top-center"
                autoClose={52000}
                newestOnTop={false}
                closeOnClick={false}
                rtl={false}
                pauseOnFocusLoss
                draggable={false}
                theme={JSON.parse(localStorage.getItem("THEME")) ? "dark" : "light"}
                toastClassName="custom-toast" // Aplica estilos personalizados a cada toast individualmente
                bodyClassName="custom-toast-body"
            /> */}
        </div>
    )
}

export default SaveAnswer;