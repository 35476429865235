import React, { Fragment, useState, useEffect } from "react";
import dayjs from "dayjs";
import Pagination from "../plugins/Pagination";
import Spinner from "../plugins/Spinner";
import usePage from "../../hooks/usePage";
import useAPI from "../../hooks/useAPI";
import Header from "../layout/Header";
import { useNavigate, Link } from "react-router-dom";

import { FaEye } from "react-icons/fa";
import { BsSearch } from "react-icons/bs";
import {sendToast} from "../plugins/toastAll";
import { ToastContainer } from "react-toastify";

const ConsumptionPayments = () => {
  const [data, setData] = useState([]);
  const [dataTable, setDataTable] = useState([]);
  const [loading, setLoading] = useState(false);
  const [validation, setValidation] = useState(false);
  const [search, setSearch] = useState("");

  //# Custoom Hooks
  const { page, byPage, resetPage, setPage } = usePage();
  const { urlAPI_1, urlAPI_2, fetchWithAuth } = useAPI();

  useEffect(() => {
    getOrders();
  }, []);

  const token = localStorage.getItem("USER_TOKEN");
  const navigate = useNavigate();

  //#--> Obtener todas las ordenes
  const getOrders = async () => {
    try {
      setLoading(true);
      const url = urlAPI_2 + "getOrders";
      const options = { method: "GET", mode: "cors"};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      console.log("Lista de pedidos.........", result);

      if (result.code === 0) {
        sendToast(result)
      } else if (result.data.length === 0) {
        setValidation(true);
        setData([]);
        setDataTable([])
      } else {
        setData(result.data);
        setDataTable(result.data)
        setValidation(false);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  //#--> Busqueda de ordenes
  const searchQuery = (searchTerm) => {
    var searchResults = dataTable.filter((element) => {
      if (
        element.client.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
        element.phoneNumber.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
        element.id.toString().toLowerCase().includes(searchTerm.toLowerCase()) 
      ) {
        return element;
      }
    });
    setData(searchResults);
    if (searchResults.length === 0) {
      setValidation(true);
    } else {
      setValidation(false);
    }
  };

  const formatOrders = [...data].reverse();

  return (
    <Fragment>
      {loading ? (
        <div className="content-spinner">
          <Spinner />
        </div>
      ) : null}

      <div className="m-auto container-fluid p-0">
        <div className="col-xs-9 heading">
          <div className="mt-2">
            <h1>Pedidos</h1>
          </div>
          <Header />
        </div>

        <header>
          <div style={{ float: "right" }}>
            <form className="form-inline">
              <div className="su-control my-1">
                <input
                  className="form-control"
                  type="text"
                  placeholder="Búsqueda"
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                    searchQuery(e.target.value);
                    resetPage(1);
                  }}
                />
                <button
                  className="btn btn-info"
                  type="button"
                  id="buttonSearchProductBack"
                  style={{ height: "34px" }}
                >
                  <BsSearch />
                </button>
              </div>
            </form>
          </div>
        </header>

        <div className="container-fluid mt-3 px-0" style={{ height: "580px", overflow: "auto" }}>
          <table className="table table-striped invoiceList">
            <thead>
              <tr>
                <th>Detalle</th>
                <th>Número de orden</th>
                <th>Cliente</th>
                <th>Articulos</th>
                <th>Valor</th>
                <th>Valor envío</th>
                <th>Fecha</th>
                <th>Estado</th>
              </tr>
            </thead>
            <tbody>
              {formatOrders && formatOrders.map((order, d) => (
                <tr key={d}>
                  <td>
                    <div className="d-flex align-items-center justify-content-center">
                      <div className="dropdown">
                        <button
                          className="border-0 bg-transparent d-block pointer mt-2"
                          onClick={() => {navigate(`/home/order/${order._id}`)}}
                        >
                          <FaEye style={{ fontSize: "18px", color: "#ff9317" }} />
                          <span className="text-dark ml-2 f-14"></span>
                        </button>
                      </div>
                    </div>
                  </td>
                  <td>{order.id}</td>
                  <td>
                    <Link
                      className="hover-a"
                      to={`/home/contact/${order.clientId}`}
                    >
                      {order.client}
                    </Link>
                  </td>
                  <td>{order.articles}</td>
                  <td>{order.total}</td>
                  <td>$ 0</td>
                  <td>
                    {
                      dayjs(order.created)
                        .locale('es')
                        .format("DD MMMM YYYY")
                    }
                  </td>
                  <td className="status-campaign">
                    <p>
                      <a
                        href="javascript:;"
                        className={
                          order.status === 4 ? "gray"
                          : order.status === 1 ? "blue" 
                          : order.status === 3 ? "red" 
                          : "green" 
                        }
                      >
                        {
                          order.status === 4 ? "En proceso"
                          : order.status === 1 ? "Pago notificado" 
                          : order.status === 3 ? "Orden cancelada" 
                          : "Envio notificado"
                        }
                      </a>
                    </p>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {validation && (
            <h1 className="text-center mt-3">
              No existen registros en el sistema
            </h1>
          )}
        </div>

        <footer>
          <div className="row">
            <div className="col-md-8"></div>
            <div className="col-md-4">
              <Pagination
                totalData={data.length}
                byPage={byPage}
                setPage={setPage}
              />
            </div>
          </div>
        </footer>
      </div>

      <ToastContainer 
        position="top-center"
        autoClose={52000}
        // autoClose={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        // draggable
        theme={JSON.parse(localStorage.getItem("THEME")) ? "dark" : "light"}
        toastClassName="custom-toast" // Aplica estilos personalizados a cada toast individualmente
        bodyClassName="custom-toast-body"
      />

    </Fragment>
  );
};

export default ConsumptionPayments;