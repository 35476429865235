import React, { Fragment, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Spinner from "../plugins/Spinner";
import { useForm } from "react-hook-form";
import Pagination from "../plugins/Pagination";
import usePage from "../../hooks/usePage";
import useAPI from "../../hooks/useAPI";
import Header from "../layout/Header";
import { BsSearch } from "react-icons/bs";
import { FaEye } from "react-icons/fa";
import { FaEllipsisVertical } from "react-icons/fa6";
import { TbTemplate } from "react-icons/tb";
import {questionToast, sendToast} from "../plugins/toastAll";
import { ToastContainer } from "react-toastify";

const Flows = () => {
  //# Estados del Modulo (Modales, Spinner, etc)
  const [loading, setLoading] = useState(false);
  const [validation, setValidation] = useState(false);

  //# Estados para los servicios
  const [flows, setFlows] = useState([]);
  const [tableFlows, setTableFlows] = useState([]);
  const [search, setSearch] = useState("");
  
  const { page, setPage, byPage, resetPage } = usePage();
  const { urlAPI_1, urlAPI_2, fetchWithAuth } = useAPI();

  const navigate = useNavigate();

  //# React-hook-form para establecer validaciones
  const {watch, register, handleSubmit, formState: { errors }, reset,} = useForm({
    mode: "all",
    defaultValues: {
      name: "",
      type: "",
      content: ""
    },
  });

  useEffect(() => {
    getFlows();
  }, []);

  //#--> Listar flujos
  const getFlows = async () => {
    try {
      setLoading(true);
      const url = urlAPI_2 + "flows";
      const options = { method: "GET", mode: "cors"};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      console.log("LISTA FLUJOS ==========>>", result);
      setLoading(false);
      if (result.type === "error") {
        sendToast(result)
      }else if (result.message === 'Tu petición no tiene cabecera de autorización') {
        sendToast({type: "info", message: "Tu sesion ha caducado, verifica si alguien mas se ha conectado con tus credenciales"})
        navigate('/');
      }  else if (!result.data) {
        setValidation(true);
        setFlows([]);
      } else {
        setFlows(result.data);
        setTableFlows(result.data);
        setValidation(false);

      }
    } catch (error) {
      console.log(error);
    }
  };

  //#--> Sincronizar lista desde Facebook
  const getFlowsFacebook = async () => {
    try {
      const data = {
        isFetch: true,
        fetch: {
          url: urlAPI_2 + "syncFlows",
          options: { method: "GET", mode: "cors" }
        },
        title: "¿Estás seguro que deseas sincronizar los flujos?",
        text: "Se actualizará la lista.",
        icon: "warning",
        buttons: { success: "Aceptar", cancel: "Cancelar" }
      };
    
      console.log("Datos enviados a questionToast:", data);
      const result = await questionToast(data, setLoading);
    
      console.log("Result of removeNode:", result);
    
      switch (result.type) {
        case "error":
        case "info":
          sendToast(result);
          break;
        case "warning":
          console.log("Datos enviados a questionToast (warning):", result);
          await questionToast({
            isFetch: false,
            fetch: {},
            title: result.message,
            text: "¿Desea ir a configurar el campo?",
            icon: "warning",
            buttons: { success: "Sí", cancel: "Cancelar" }
          }, setLoading);
          window.location.href = "/#/home/myData";
          break;
        default:
          console.log("Dentro del último else");
          await getFlows();
          sendToast(result);
      }
    } catch (error) {
      console.log(error);
    }
  };

  //#--> Buscador
  const searchQuery = (searchTerm) => {
    var searchResults = tableFlows.filter((element) => {
    const filter = element.content.filter(tem => {if (tem.type === "BODY") return tem.text})
    const newContent = filter[0].text;
    
    if (
      element.name
        .toString()
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      element.categories
        .toString()
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      element.status
        .toString()
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    ) {
      return element;
    }
      
    });
    setFlows(searchResults);
    if (searchResults.length === 0) {
      setValidation(true);
    } else {
      setValidation(false);
    }
  };

  //#--> Ver detalles de un flujo
  const onViewFlow = async (id) => {
    console.log('----Recibiendo el ID :::::', id)
    try {
      setLoading(true);
      const url = urlAPI_2 + "flow/" + id;
      const options = { method: "GET", mode: "cors"};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      console.log("Detalles del Flujoooooo ==========>>", result.data);

      // Verificar si hay una URL en result.data antes de abrir una nueva pestaña
      if (result.data && result.data.preview_url) {
        const newTab = window.open(result.data.preview_url, '_blank');
        if (newTab) {
          //#--> La pestaña se abrió correctamente
          console.log('Se abrio correctamenteee.....');
          newTab.focus();
        } else {
          //#--> Bloqueador de ventanas emergentes podría haber impedido la apertura
          console.error('No se pudo abrir una nueva pestaña. Asegúrate de permitir ventanas emergentes.');
          sendToast({type: "warning", message: "Asegúrate de permitir ventanas emergentes en tu navegador"})
        }
      } else {
        console.error('La propiedad "preview_url" no está presente en result.data.');
        sendToast({type: "warning", message: "No es posible visualizar este flujo."})
      }

      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  }

  const formatFlows = [...flows];

  return (
    <Fragment>
      {loading ? (
        <div className="content-spinner">
          <Spinner />
        </div>
      ) : null}
      
      <div className="m-auto container-fluid p-0">
        <div className="col-xs-9 heading">
          <div>
            <h1>Flujos</h1>
            <input
              type="button"
              className="btn btn-default"
              value="Sincronizar"
              onClick={() => getFlowsFacebook()}
            />
          </div>

          <Header />
        </div>

        <header>
          <div style={{ float: "right" }}>
            <form className="form-inline">
              <div className="su-control my-1">
                <input
                  className="form-control"
                  type="text"
                  placeholder="Búsqueda"
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                    searchQuery(e.target.value);
                    resetPage();
                  }}
                />
                <button
                  className="btn btn-info"
                  type="button"
                  id="buttonSearchProductBack"
                  style={{ height: "34px" }}
                >
                  <BsSearch />
                </button>
              </div>
            </form>
          </div>
        </header>

        <div className="container-fluid mt-3 px-0" style={{ height: "580px", overflowY: "auto", overflowX: "none" }}>
          <table className="table table-striped">
            <thead style={{ position: "sticky", top: "-1px", zIndex: "99" }}>
              <tr>
                <th>Acciones</th>
                <th>Nombre</th>
                <th>Categoría</th>
                <th>Estado</th>
              </tr>
            </thead>
            <tbody>
              {formatFlows.length > 0 && formatFlows.slice((page - 1) * byPage, (page - 1) * byPage + byPage).map((flow, k) => {                 
                return(
                <tr key={k}>
                  <td>
                    <div className="d-flex align-items-center justify-content-center">
                      <div className="dropdown">
                        <button
                          className="bg-transparent border-0"
                          type="button"
                          id="dropdownMenuButton"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <FaEllipsisVertical className="text-success f-24 pointer"/>
                        </button>
                        <div
                          className="dropdown-menu p-2"
                          aria-labelledby="dropdownMenuButton"
                        >
                          <button
                            className="text-primary border-0 bg-transparent d-block pointer mt-2"
                            onClick={() => {
                              onViewFlow(flow.id)
                            }}
                          >
                            {/* <a href="https://business.facebook.com/wa/manage/flows/1400256774250432/preview/?token=0aead7d9-b144-4fe5-9856-03c0be32a965" target="_blank" rel="noreferrer"> */}
                              <FaEye style={{fontSize: "18px",color: "#ff9317",}} />
                              <span className="text-dark ml-2 f-14">
                                Ver
                              </span>
                            {/* </a> */}
                          </button>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>
                    {flow.name.length >= 35
                      ? flow.name.substr(0, 35) + "..."
                      : flow.name}
                  </td>
                  <td>{flow.categories}</td>
                  <td className="status-template">
                    <p>
                      <a href="javascript:;" className={flow.status === "PUBLICADA" ? "green" : "gray"}>
                        {flow.status}
                      </a>
                    </p>
                  </td>
                </tr>
              )})}
            </tbody>
          </table>
          {validation && (
            <div className="text-center mt-3">
              <div className="empty">
                <TbTemplate style={{ fontSize: "55px" }} />
                <h1>No tienes flujos</h1>
                <input
                  type="button"
                  className="btn btn-default"
                  value="Sincronizar"
                  onClick={() => getFlowsFacebook()}
                />
              </div>
            </div>
          )}
        </div>

        <footer>
          <div className="row">
            <div className="col-md-8"></div>

            <div className="col-md-4">
              {/* Creación de la paginación simple */}
              <Pagination
                totalData={formatFlows.length}
                byPage={byPage}
                setPage={setPage}
              />
            </div>
          </div>
        </footer>
      </div>

      <ToastContainer 
        position="top-center"
        autoClose={52000}
        // autoClose={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        // draggable
        theme={JSON.parse(localStorage.getItem("THEME")) ? "dark" : "light"}
        toastClassName="custom-toast" // Aplica estilos personalizados a cada toast individualmente
        bodyClassName="custom-toast-body"
      />

    </Fragment>
  );
};

export default Flows;
