import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import {sendToast} from './toastAll';

const NotFound = () => {
    
    const navigate = useNavigate();

    useEffect(() => {
        sendToast({type: "error", message: "Página NO encontrada."})
        navigate('/');
    }, [])

}

export default NotFound