import React, { useState ,useEffect } from "react";
import { Handle, Position, useUpdateNodeInternals } from 'reactflow';
import useAPI from "../../../../hooks/useAPI";

import { TiFlowMerge } from "react-icons/ti";
import { IoIosWarning } from "react-icons/io";
import { sendToast } from "../../../plugins/toastAll";
import { ToastContainer } from "react-toastify";

const FlowsWhatsApp = ({ data, isConnectable, id }) => {
    const updateNodeInternals = useUpdateNodeInternals();
    const { urlAPI_2, fetchWithAuth } = useAPI(); 
    const [flows, setFlows] = useState([]);

    const getFlows = async () => {
        try {
            const url = urlAPI_2 + "flows";
            const options = { method: "GET", mode: "cors"};
            const response = await fetchWithAuth(url, options);
            const result = await response.json();
            if (result.type === "error") {
                sendToast(result)
            }else if (result.message === 'Tu petición no tiene cabecera de autorización') {
                sendToast({type: "info", message: "Tu sesion ha caducado, verifica si alguien mas se ha conectado con tus credenciales"})
                navigate('/');
            }  else if (!result.data) {
                setFlows([]);
            } else {
                setFlows(result.data);
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
            await Promise.all([
                getFlows(),
            ]);
            } catch (error) {
            console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        updateNodeInternals(id);
    }, [id, updateNodeInternals, data]);

    let flow = ""
    if(flows.length) {
        const find = flows.find(v => v._id == data.flow)
        if(find) {
            flow = find.name
        } 
    }
    
    return (
        <div className="rounded" style={{width: "180px", boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px", cursor: 'pointer'}}>
            {data.saved && (
                <Handle 
                    type="target" 
                    position={Position.Top} 
                    isConnectable={isConnectable} 
                    id={`target-${id}`} 
                    style={{backgroundColor: '#B85C7C'}}
                />
            )}
            <div>
                <div 
                    className="d-flex align-items-center rounded-top justify-content-between" 
                    style={{height: "10px", backgroundColor: "#B85C7C", padding: "12px"}}
                >
                    <div className="d-flex align-items-center">
                        <TiFlowMerge className="mr-1" style={{fontSize: "12px", color: "#fff"}}/>
                        <span style={{fontSize: "10px", color: "#fff"}} >
                            {data.title ?? "Flujo de WhatsApp"}
                        </span>
                    </div>
                    {data?.error && (
                        <IoIosWarning style={{fontSize: "12px", color: "#FFCC00"}}/>
                    )}
                </div>
                <div className="bg-white rounded-bottom" style={{padding: '8px'}}>
                    {data.user_response && (
                        <div 
                            className="p-1 mb-2" 
                            style={{
                                backgroundColor: "rgba(91, 16, 77, 0.3)",
                                borderRadius: "4px",
                                height: "auto"
                            }}
                        >   
                            <span style={{fontWeight: "bold", fontSize: "9px"}}>Respuesta del Cliente:</span>
                            <p style={{fontWeight: 'normal', fontSize: "9px"}} className="m-0">
                                {data.user_response}
                            </p>
                        </div>
                    )}
                    {data.flow && (
                        <div
                            style={{
                                border: "1px solid #000",
                                borderRadius: "4px",
                                height: "auto"
                            }}
                        >
                            
                            <div className="px-1">    
                                <div>
                                    <span style={{fontWeight: "bold", fontSize: "9px"}}>
                                        Flujo:
                                    </span>
                                </div>
                                <p 
                                    style={{
                                        fontWeight: 'bold', 
                                        fontSize: "9px", 
                                        backgroundColor: "#F0F0F0", 
                                        color: "#000",
                                        borderRadius: "4px"
                                    }} 
                                    className="m-0 p-1 text-center"
                                >
                                    {flow}
                                </p>
                            </div>     
                            {data.flow_data?.header && (
                                <div 
                                    className="px-1" 
                                >   
                                    <span style={{fontWeight: "bold", fontSize: "9px"}}>Encabezado para enviar flujo:</span>
                                    <p 
                                        style={{
                                            fontWeight: 'bold', 
                                            fontSize: "9px", 
                                            backgroundColor: "#F0F0F0", 
                                            color: "#000",
                                            borderRadius: "4px"
                                        }} 
                                        className="m-0 p-1 text-center"
                                    >
                                        {data.flow_data.header}
                                    </p>
                                </div>
                            )}
                            {data.flow_data?.body && (
                                <div 
                                    className="px-1" 
                                >   
                                    <span style={{fontWeight: "bold", fontSize: "9px"}}>Cuerpo para enviar flujo:</span>
                                    <p 
                                        style={{
                                            fontWeight: 'bold', 
                                            fontSize: "9px", 
                                            backgroundColor: "#F0F0F0", 
                                            color: "#000",
                                            borderRadius: "4px"
                                        }} 
                                        className="m-0 p-1 text-center"
                                    >
                                        {data.flow_data.body}
                                    </p>
                                </div>
                            )}
                            {data.flow_data?.footer && (
                                <div 
                                    className="px-1" 
                                >   
                                    <span style={{fontWeight: "bold", fontSize: "9px"}}>Pie para enviar flujo:</span>
                                    <p 
                                        style={{
                                            fontWeight: 'bold', 
                                            fontSize: "9px", 
                                            backgroundColor: "#F0F0F0", 
                                            color: "#000",
                                            borderRadius: "4px"
                                        }} 
                                        className="m-0 p-1 text-center"
                                    >
                                        {data.flow_data.footer}
                            
                                    </p>
                                </div>
                            )}
                            {data.flow_data?.button && (
                                <div 
                                    className="px-1 pb-1" 
                                >   
                                    <span style={{fontWeight: "bold", fontSize: "9px"}}>Título del botón para enviar flujo:</span>
                                    <p 
                                        style={{
                                            fontWeight: 'bold', 
                                            fontSize: "9px", 
                                            backgroundColor: "#F0F0F0", 
                                            color: "#000",
                                            borderRadius: "4px"
                                        }} 
                                        className="m-0 p-1 text-center"
                                    >
                                        {data.flow_data.button}
                            
                                    </p>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
            {data.saved && (
                <Handle 
                    type="source" 
                    position={Position.Bottom} 
                    id={`source-${id}`}
                    isConnectable={isConnectable}  
                    style={{backgroundColor: '#B85C7C'}}
                />
            )}

            {/* <ToastContainer 
                position="top-center"
                autoClose={52000}
                // autoClose={false}
                newestOnTop={false}
                closeOnClick={false}
                rtl={false}
                pauseOnFocusLoss
                draggable={false}
                // draggable
                theme={JSON.parse(localStorage.getItem("THEME")) ? "dark" : "light"}
                toastClassName="custom-toast" // Aplica estilos personalizados a cada toast individualmente
                bodyClassName="custom-toast-body"
            /> */}
        </div>
    )
}

export default FlowsWhatsApp;