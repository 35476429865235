import React, { Fragment, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import useAPI from "../../hooks/useAPI";
import Spinner from "../plugins/Spinner";
import logoLogin from "../../imgs/logo-login.png";
import {sendToast} from "../plugins/toastAll"
import { ToastContainer } from "react-toastify";

const Reset = () => {
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const {
    watch,
    register,
    formState: { errors },
    reset,
    handleSubmit,
  } = useForm();

  const { token } = useParams();

  const { urlAPI, urlAPI_1, urlAPI_2, fetchWithAuth } = useAPI();

  const postReset = async (inputs) => {
    const password = inputs.password;
    const confirmPassword = inputs.confirmPassword;
    if (password === confirmPassword) {
      const values = {
        password,
        token,
      };
      try {
        setLoading(true);
        const url = urlAPI_2 + "updatePassword";
        const options = { method: "POST", mode: "cors", body: JSON.stringify(values)};
        const response = await fetchWithAuth(url, options);
        const result = await response.json();
        console.log("Data Send", result);

        setLoading(false);

        //# POST Completado, se reinician lo campos
        sendToast(result)
        navigate("/");
      } catch (error) {
        console.log(error);
      }
    } else {
      sendToast({type: "warning", message: "Las contraseñas no coinciden"})
    }
  };

  return (
    <>
      {loading ? (
        <div className="content-spinner">
          <Spinner />
        </div>
      ) : null}
      <div id="main-login">
        <div className="container-fluid">
          <main id="login-register" className="loggin">
            <header>
              <h1>
                <a href="#">
                  <img src={logoLogin} alt="Logo" />
                </a>
              </h1>
            </header>
            <section className="box login">
              <header>
                <h3>Crear contraseña</h3>
              </header>
              <div className="padd">
                <form onSubmit={handleSubmit(postReset)}>
                  <label>Contraseña</label>
                  <input
                    type="password"
                    id="password"
                    name="password"
                    className="form-control"
                    placeholder="Contraseña"
                    {...register("password", {
                      required: {
                        value: true,
                        message: "La contraseña es requerida.",
                      },
                    })}
                  />
                  {errors.password && (
                    <Alert>{errors.password.message}</Alert>
                  )}

                  <label>Contraseña</label>
                  <input
                    type="password"
                    id="confirmPassword"
                    name="confirmPassword"
                    className="form-control"
                    placeholder="Confirmar contraseña"
                    {...register("confirmPassword", {
                      required: {
                        value: true,
                        message: "Es obligatorio confirmar la contraseña.",
                      },
                    })}
                  />

                  {errors.confirmPassword && (
                    <Alert>{errors.confirmPassword.message}</Alert>
                  )}

                  <button type="submit" className="btn btn-primary btn-lg">
                    Crear
                  </button>
                  <p style={{ fontSize: "14px" }}>
                    <a
                      href="javascript:;"
                      onClick={() => navigate("/")}
                      title="Recuperar la contraseña"
                      rel="nofollow"
                    >
                      Iniciar sesión
                    </a>
                  </p>
                </form>
              </div>
            </section>
          </main>
        </div>
      </div>

      <ToastContainer 
        position="top-center"
        autoClose={52000}
        // autoClose={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        // draggable
        theme={JSON.parse(localStorage.getItem("THEME")) ? "dark" : "light"}
        toastClassName="custom-toast" // Aplica estilos personalizados a cada toast individualmente
        bodyClassName="custom-toast-body"
      />
    </>
  );
};

export default Reset;

const Navigate = styled.p`
  cursor: pointer;
`;

const Alert = styled.span`
  color: red;
  display: block;
  width: 200px;
`;
