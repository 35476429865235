import React, { Fragment, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Spinner from "../plugins/Spinner";
import { useForm } from "react-hook-form";
import Pagination from "../plugins/Pagination";
import usePage from "../../hooks/usePage";
import useAPI from "../../hooks/useAPI";
import Header from "../layout/Header";
import { BsCheckCircle, BsExclamationCircle, BsQuestionCircle, BsSearch, BsTrash3, BsXCircle } from "react-icons/bs";
import { FaEye } from "react-icons/fa";
import { FaEllipsisVertical } from "react-icons/fa6";
import { TbTemplate } from "react-icons/tb";
import {questionToast, sendToast} from "../plugins/toastAll";
import { ToastContainer } from "react-toastify";

const Templates = () => {
  //# Estados del Modulo (Modales, Spinner, etc)
  const [loading, setLoading] = useState(false);
  const [modeEdit, setModeEdit] = useState(false);
  const [validation, setValidation] = useState(false);
  const [isDark, setIsDark] = useState(false);

  //# Estados para los servicios
  const [templates, setTemplates] = useState([]);
  const [tableTemplates, setTableTemplates] = useState([]);

  const [search, setSearch] = useState("");

  const { page, setPage, byPage, resetPage } = usePage();
  const { urlAPI, urlAPI_1, urlAPI_2, fetchWithAuth } = useAPI();

  const navigate = useNavigate();

  //# React-hook-form para establecer validaciones
  const {watch, register, handleSubmit, formState: { errors }, reset,} = useForm({
    mode: "all",
    defaultValues: {
      name: "",
      type: "",
      content: "",
    },
  });

  useEffect(() => {
    const dark = localStorage.getItem("IS_DARK");
    console.log('DARK: ', dark);
    console.log('DARK 2: ', localStorage.getItem("THEME"));
    
    setIsDark(dark)
    getTemplates();
  }, []);

  //# Listar plantillas
  const getTemplates = async () => {
    try {
      setLoading(true);
      const url = urlAPI_2 + "templates";
      const options = { method: "GET", mode: "cors"};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      console.log("LISTA PLANTILLAS ==========", result);
      setLoading(false);
      if (result.type === "error") {
        await sendToast(result)
      }else if (result.message === 'Tu petición no tiene cabecera de autorización') {
        const obj = {type: "info", message: 'Tu sesion ha caducado, verifica si alguien mas se ha conectado con tus credenciales'}
        await sendToast(obj)
        navigate('/');
      }  else if (!result.data) {
        setValidation(true);
        setTemplates([]);
      } else {
        setTemplates(result.data);
        setTableTemplates(result.data);
        setValidation(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  //# Sincronizar lista desde Facebook
  const getTemplatesFacebook = async () => {
    const dark = localStorage.getItem("IS_DARK");    
    setIsDark(dark)
    try {
      const data = {
        isFetch: true,
        fetch: {
          url: urlAPI_2 + "syncTemplates",
          options: { 
            method: "GET", 
            mode: "cors"
          }
        },
        title: "¿Estas seguro que deseas sincronizar las plantillas?",
        text: "Se actualizara la lista.",
        icon: "warning",
        buttons: { success: "Aceptar", cancel: "Cancelar" }
      };
    
      console.log("Datos enviados a questionToast:", data);
      const result = await questionToast(data, setLoading);
      console.log("Result -------->: ", result);

      switch (result.type) {
        case "error":
        case "info":
          sendToast(result);
          break;
        case "warning":
          console.log("Datos enviados a questionToast (warning):", result);
          await questionToast({
            isFetch: false,
            fetch: {},
            title: result.message,
            text: "¿Desea ir a configurar el campo?",
            icon: "warning",
            buttons: { success: "Sí", cancel: "Cancelar" }
          }, setLoading);
          window.location.href = "/#/home/myData";
          break;
        default:
          console.log("Dentro del último else");
          await getTemplates();
          sendToast(result)
      }
    } catch (error) {
      console.log(error);
    }
  };

  //# Servicio DELETE para listar
  const deleteTemplate = async (id) => {
    const dark = localStorage.getItem("IS_DARK");    
    setIsDark(dark)
    try {
      const data = {
        isFetch: true,
        fetch: {
          url: urlAPI_2 + `template/${id}`,
          options: { 
            method: "DELETE", 
            mode: "cors"
          }
        },
        title: "¿Estas seguro que deseas eliminar esta plantilla?",
        text: "No podrás recuperarla.",
        icon: "warning",
        buttons: { success: "Eliminar", cancel: "Cancelar" }
      };
    
      console.log("Datos enviados a questionToast:", data);
      const result = await questionToast(data, setLoading);
      console.log("Result -------->: ", result);

      //# Actualización de la tabla
      await getTemplates();
      sendToast(result)
    } catch (error) {
      console.log(error);
    }
  };

  const cleanInputs = () => {
    reset({ name: "", type: "", content: "" });
  };

  const searchQuery = (searchTerm) => {
    var searchResults = tableTemplates.filter((element) => {
    const filter = element.content.filter(tem => {if (tem.type === "BODY") return tem.text})
    const newContent = filter[0].text;
    
    if (
      element.name
        .toString()
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      element.language
        .toString()
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      element.category
        .toString()
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      newContent
        .toString()
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      element.status
        .toString()
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    ) {
      return element;
    }
      
    });
    setTemplates(searchResults);
    if (searchResults.length === 0) {
      setValidation(true);
    } else {
      setValidation(false);
    }
  };

  // const formatTemplates = [...templates].reverse();


  function parseBoolean(value) {
    if (typeof value === 'boolean') {
      return value;
    } else if (typeof value === 'string') {
      const lowerCaseValue = value.toLowerCase();
      if (lowerCaseValue === 'true') {
        return true;
      } else if (lowerCaseValue === 'false') {
        return false;
      }
    }
    // Si no es ni booleano ni una cadena válida, devuelve false por defecto
    return false;
  }

  const handleTemplateIsActive = async (e, template) => {
    try {
      setLoading(true);
      const url = urlAPI_2 + `syncTemplates/${template.id}`;
      const options = { method: "PUT", mode: "cors", body: JSON.stringify({isActive:e.target.checked})};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      setLoading(false);      
      await sendToast(result)
    } catch (error) {
      console.log('Error al actualizar active de template:', error);
    }
  }

  const getQualityRatingDisplay = (qualityRating) => {
      switch (qualityRating) {
        case "GREEN":
          return { text: "Alta", color: "#28a745", icon: <BsCheckCircle /> };
        case "YELLOW":
          return { text: "Media", color: "#ffc107", icon: <BsExclamationCircle /> };
        case "RED":
          return { text: "Baja", color: "#dc3545", icon: <BsXCircle /> };
        default:
          return { text: "Desconocida", color: "#6c757d", icon: <BsQuestionCircle /> };
      }
    };

  return (
    <Fragment>
      {loading ? (
        <div className="content-spinner">
          <Spinner />
        </div>
      ) : null}
      <div className="m-auto container-fluid p-0 h-100">
        <div className="col-xs-9 heading">
          <div className="mt-2">
            <h1>Plantillas</h1>
            <input
              type="button"
              className="btn btn-default"
              value="Agregar nueva"
              onClick={() => navigate('/home/templateWhatsapp') }
            />
            <input
              type="button"
              className="btn btn-default ml-1"
              value="Sincronizar"
              onClick={() => getTemplatesFacebook()}
            />
          </div>

          <Header />
        </div>

        <header>
          <div style={{ float: "right" }}>
            <form className="form-inline">
              <div className="su-control my-1">
                <input
                  className="form-control"
                  type="text"
                  placeholder="Búsqueda"
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                    searchQuery(e.target.value);
                    resetPage();
                  }}
                />
                <button
                  className="btn btn-info"
                  type="button"
                  id="buttonSearchProductBack"
                  style={{ height: "34px" }}
                >
                  <BsSearch />
                </button>
              </div>
            </form>
          </div>
        </header>

        <div className="container-fluid mt-3 px-0" style={{ height: "75%", overflowY: "auto", overflowX: "none" }}>
          <table className="table table-striped">
            <thead style={{ position: "sticky", top: "-1px", zIndex: "99" }}>
              <tr>
                <th>Acciones</th>
                <th>Activar/Desactivar</th>
                <th>Nombre</th>
                <th>Contenido</th>
                <th>Idioma</th>
                <th>Categoría</th>
                <th>Estado</th>
                <th>Calidad</th>
              </tr>
            </thead>
            <tbody>
              {templates.length > 0 && templates.slice((page - 1) * byPage, (page - 1) * byPage + byPage).map((template, k) => { 
                const filter = template.content.filter(tem => {
                  if (tem.type === "BODY") return tem.text
                })
                const newContent = filter;
                
                return(
                <tr key={k}>
                  <td>
                    <div className="d-flex align-items-center justify-content-center">
                      <div className="dropdown">
                        <button
                          // onClick={() => getTemplate(template._id)}
                          className="bg-transparent border-0"
                          type="button"
                          id="dropdownMenuButton"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <FaEllipsisVertical className="text-success f-24 pointer"/>
                        </button>
                        <div
                          className="dropdown-menu p-2"
                          aria-labelledby="dropdownMenuButton"
                        >
                          <button
                            className="text-primary border-0 bg-transparent d-block pointer mt-2"
                            onClick={() => {
                              navigate(`/home/templateWhatsappEdit/${template._id}`);
                              setModeEdit(true);
                            }}
                          >
                            {/* <i
                              className="icon-pencil"
                              style={{ fontSize: "18px" }}
                            />
                            <span className="text-dark ml-2 f-14">
                              Editar
                            </span> */}
                            <FaEye style={{fontSize: "18px",color: "#ff9317",}} />
                            <span className="text-dark ml-2 f-14">
                              Ver
                            </span>
                          </button>
                          <button className="text-danger border-0 bg-transparent d-block pointer mt-2" onClick={() => deleteTemplate(template.id)}>
                            <BsTrash3 style={{ fontSize: "18px" }}/>
                            <span className="text-dark ml-2 f-14">
                              Eliminar
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex justify-content-center align-items-center">
                      <input
                        type="checkbox"
                        value={parseBoolean(template.isActive)}
                        className="cmn-toggle cmn-toggle-round-flat"
                        name={template.id}
                        id={template.id}
                        defaultChecked={parseBoolean(template.isActive)}
                        onChange={(e) => {
                          handleTemplateIsActive(e, template)
                        }}
                      />
                      <label className="m-0" htmlFor={template.id}></label>
                    </div>
                  </td>
                  <td>
                    {template.name.length >= 35
                      ? template.name.substr(0, 35) + "..."
                      : template.name}
                  </td>
                  <td>{
                    newContent[0].text.length >= 35 ? 
                      newContent[0].text.substr(0, 35) + "..."
                      : newContent[0].text}
                  </td>
                  <td>{template.language}</td>
                  <td>{template.category}</td>
                  <td className="status-template">
                    <p>
                      <a
                        href="javascript:;"
                        className={
                          template.status === "PENDIENTE"
                            ? "gray"
                            : template.status === "APROBADA"
                            ? "green"
                            : "red"
                        }
                      >
                        {template.status}
                      </a>
                    </p>
                  </td>
                  <td style={{textAlign: "-webkit-center"}}>
                    {(() => {
                      const { text, color, icon } = getQualityRatingDisplay(template.qualityScore);
                      return (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            backgroundColor: color,
                            color: "#fff",
                            borderRadius: "10px",
                            // padding: "5px 10px",
                            fontWeight: "bold",
                            // width: "50%",
                            // height: "2.5rem"
                            lineHeight: "30px"
                          }}
                        >
                          <span style={{ marginRight: "10px", fontSize: "16px", marginTop: "4px" }}>{icon}</span>
                          {text}
                        </div>
                      );
                    })()}
                  </td>
                </tr>
              )})}
            </tbody>
          </table>
          {validation && (
            <div className="text-center mt-3">
              <div className="empty">
                <TbTemplate style={{ fontSize: "55px" }} />
                <h1>No tienes plantillas</h1>
                <input
                  type="button"
                  className="btn btn-default"
                  value="Sincronizar"
                  onClick={() => getTemplatesFacebook()}
                />
              </div>
            </div>
          )}
        </div>

        <footer>
          <div className="row">
            <div className="col-md-8"></div>

            <div className="col-md-4">
              {/* Creación de la paginación simple */}
              <Pagination
                totalData={templates.length}
                byPage={byPage}
                setPage={setPage}
              />
            </div>
          </div>
        </footer>
      </div>

      <ToastContainer 
        position="top-center"
        autoClose={52000}
        // autoClose={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        // draggable
        theme={JSON.parse(localStorage.getItem("THEME")) ? "dark" : "light"}
        toastClassName="custom-toast" // Aplica estilos personalizados a cada toast individualmente
        bodyClassName="custom-toast-body"
      />
    </Fragment>
  );
};

export default Templates;
