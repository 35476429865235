import React, { useState } from 'react';
import Select from "react-select";
import ReactPlayer from "react-player";
import styled from "styled-components";
import { BsFillInfoCircleFill, BsFillTelephoneFill } from 'react-icons/bs';
import { TiExport } from 'react-icons/ti';
import { FaSpinner } from 'react-icons/fa';
import { IoIosAlarm  } from "react-icons/io";
import { FaCalendarDays } from "react-icons/fa6";
import DateFnsUtils from '@date-io/date-fns';
import { es } from "date-fns/locale"; // Importa el locale en español
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import {sendToast} from '../plugins/toastAll';
import { ToastContainer } from 'react-toastify';

const SendTemplate = ({
    formSendTemplate,
    contactNameSelected,
    contactName,
    isDark,
    templates,
    templateHeader,
    templateSelected,
    templateWhatsapp,
    contentHeader,
    error7,
    numberParams,
    listButtonsUrlDynamic,
    videoSelected,
    contentTextHeader,
    fileType,
    selectedFiles,
    content,
    date,
    listUserChats,
    listButtonsCallToAction,
    listButtonsQuickReply,
    timerConectionChat,
    loadingSendTemplate,
    onSendMessage,
    onFileInputTemplate,
    focusLastMessage,
    setIdTemplate,
    setContent,
    setNumberParams,
    setIsUrlDynamic,
    setIsUrlDynamic2,
    setListButtonsCallToAction,
    setListButtonsQuickReply,
    setTemplateSelected,
    setTemplateWhatsapp,
    setTemplateHeader,
    setContentHeader,
    setContentTextHeader,
    setListButtonsUrlDynamic,
    setNotChatSelected,
    setStartChat,
    setIsChatSelected,
    setIsTemplateSend,
    setMessageScheduleDate,
    messageScheduleDate,
    scheduleShipping,
    setScheduleShipping
}) => {

    // 
    const [modalProgramMessage, setModalProgramMessage] = useState(false);

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? 'lightblue' : '#fff',
            color: state.isSelected ? '#333' : '#333',
            '&:hover': {
            backgroundColor: 'lightgray',
            color: '#333',
            cursor: 'pointer',
            },
        }),
    };
    
    const customStylesDark = {
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#455a64' : '#202c33',
            color: state.isSelected ? 'white' : 'white',
            '&:hover': {
            backgroundColor: '#374954',
            color: 'white',
            cursor: 'pointer',
            },
        }),
    };

    //#--> Cuando seleccionamos una plantilla
    const handleChange = (event) => {
        setNumberParams([]);
        setIsUrlDynamic("");
        setIsUrlDynamic2("");
        setListButtonsCallToAction([]);
        setListButtonsQuickReply([]);
        setListButtonsUrlDynamic([]);
        setTemplateSelected([]);
        formSendTemplate.reset()

        const filterBody = event.content.filter(tem => {if (tem.type === "BODY") return tem});
        const contentBody = filterBody[0].text;

        setIdTemplate(event.id);
        setContent(contentBody);
        var template = [event]
        setTemplateSelected(template)

        console.log('=========template=====>', template[0]);
        if (template[0]) {
        setTemplateWhatsapp(true);

        //#--> Validamos que si tenga header la plantilla selecionada
        const filterHeader = template[0].content.filter(tem => {if (tem.type === "HEADER") return tem})
        console.log('filterHeader==============>', filterHeader);
        if (filterHeader.length != 0) {
            setTemplateHeader(true);
            setContentHeader(filterHeader[0].format)
            setContentTextHeader(filterHeader[0].text)
        } else {
            setTemplateHeader(false);
            setContentHeader("");
        }


        //#--> Validamos el Body de la plantilla selecionada
        const filterBody = template[0].content.filter(tem => {if (tem.type === "BODY") return tem})
        const body = filterBody[0].text;
        //#--> Convertimos a array el contenido para validar si tiene parametros
        const res = body.split(" ");
        // setBody(body);

        const listVariables = body.match(/{{\d+}}/g) || [];
        console.log('--lista de camposadicionales:', listVariables)
        setNumberParams(listVariables)

        //#--> Validamos que si tenga botones la plantilla selecionada
        const filterButtons = template[0].content.filter(tem => {if (tem.type === "BUTTONS") return tem});
        console.log('filterButtons======>', filterButtons);

        if (filterButtons.length != 0) {
            const listButtons = filterButtons[0].buttons;
            console.log('lista e botones ===>', listButtons);

            var listButtonsCallToAction = [];
            var listButtonsQuickReply = [];
            var listButtonsUrlDynamic = [];
            for (let b = 0; b < listButtons.length; b++) {
            const element = listButtons[b];

            if (element.type == "URL") {
                listButtonsCallToAction.push(element);

                if (element.example) {
                listButtonsUrlDynamic.push(element);
                setIsUrlDynamic(true);
                }

            } else if (element.type == "PHONE_NUMBER") {
                listButtonsCallToAction.push(element);

            } else if (element.type == "QUICK_REPLY") {
                listButtonsQuickReply.push(element);

            }
            }
            console.log('listButtonsCallToAction==>', listButtonsCallToAction);
            console.log('listButtonsQuickReply==>', listButtonsQuickReply);
            console.log('listButtonsUrlDynamic==>', listButtonsUrlDynamic);

            setListButtonsCallToAction(listButtonsCallToAction);
            setListButtonsQuickReply(listButtonsQuickReply);
            setListButtonsUrlDynamic(listButtonsUrlDynamic);

        }
        } else {
            setTemplateWhatsapp(false);
        }
    };

    const onCancel =  () => {
        setNotChatSelected((prevMessage) => { return false});
        setTemplateWhatsapp((prevMessage) => { return false});
        setStartChat((prevMessage) => { return false});
        setTemplateSelected([]);
        setIsChatSelected((prevMessage) => { return true});
        setScheduleShipping(false);
        setListButtonsUrlDynamic((prevMessage) => { return []});
        if (timerConectionChat >= 24) {
            setIsTemplateSend(false);
        } else {
            setIsTemplateSend(true);
        }
        
        const filterArray = listUserChats.filter(obj => obj.user === contactName)
        if(filterArray.length === 0){
            setIsChatSelected((prevMessage) => { return false});
            setNotChatSelected((prevMessage) => { return true});
        }
        
        setTimeout(() => {
            focusLastMessage();
        }, 100);
    }

    const handleFormSubmit = (data) => {
        setMessageScheduleDate();
        if (templateWhatsapp) {
            if(messageScheduleDate) {
                setModalProgramMessage(false);
                sendToast({type: "success", message: "El envío de la plantilla ha sido programado con éxito."})
            }
            onSendMessage(data);
        } else {
            sendToast({type: "info", message: "Debes seleccionar una plantilla."})
        }
      };

    const formatTemplates = [...templates].reverse();
  

  return (
    <section className="module-send-template module-chat mx-1 mb-0" style={{ width: "72%" }}>
        <form onSubmit={formSendTemplate.handleSubmit(handleFormSubmit)}>
        {/* <form onSubmit={formSendTemplate.handleSubmit(onSendMessage)}> */}
            <header className="message d-flex">
                <div className="message d-flex col-6" style={{ alignItems: "center" }}>
                    <h1 className="py-2">Enviar plantilla a  { contactNameSelected }</h1>
                </div>
            </header>

            <div 
                id="content-send-template" 
                className="select-template d-flex justify-content-center" 
                style={{ padding: "0 1em", height:"72vh", overflow:"auto" }}
            >
                <div className="field-template" style={{padding: "0 1em", width: "60%"}}>
                    <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable">
                    <label htmlFor="name">Plantillas: </label>
                    <p>Selecciona una plantilla para iniciar conversación.</p>
                    <Select
                        styles={isDark == 'true' ? customStylesDark : customStyles}
                        className="form-control select-template"
                        placeholder="Selecciona una plantilla"
                        onChange={handleChange}
                        options={formatTemplates}
                        getOptionLabel={(option) => (
                        <div className="option d-flex" style={{fontSize: "15px"}}>
                            {`${option.name} - (${option.language})`}
                        </div>
                        )}
                        getOptionValue={(option) => `${option.name} - ${option.language}`}
                    />
                    </div>

                    <div style={{ width: "80%", margin: "0 auto" }}>

                        {/* CARGAR ARCHIVOS  */}
                        {templateHeader && templateSelected.map((template, t) => {
                            return (
                            <div key={t}>
                            {contentHeader === "IMAGE" ? (
                                <div className="mt-3">
                                <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable">
                                    <label>Cargar imagen: </label>
                                    <div className="su-control-group">
                                    <input
                                        type="file"
                                        name="image"
                                        id="image"
                                        className="form-control"
                                        accept="image/gif,image/jpeg,image/jpg,image/png"
                                        {...formSendTemplate.register("image", {
                                            required: {
                                                value: true,
                                                message: "El medio es requerido.",
                                            },
                                        })}
                                        onChange={(e) => onFileInputTemplate(e, "image")}
                                    />
                                    {error7.image && (<Alert>* {error7.image.message}</Alert>)}
                                    </div>
                                </div>
                                </div>
                            ) : contentHeader === "VIDEO" ? (
                                <div className="mt-3">
                                <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable">
                                    <label>Cargar video: </label>
                                    <div className="su-control-group">
                                    <input
                                        type="file"
                                        name="video"
                                        id="video"
                                        className="form-control"
                                        placeholder="Add profile picture"
                                        accept="video/*"
                                        {...formSendTemplate.register("video", {
                                            required: {
                                                value: true,
                                                message: "El medio es requerido.",
                                            },
                                        })}
                                        onChange={(e) => onFileInputTemplate(e, "video")}
                                    />
                                    {error7.video && (<Alert>* {error7.video.message}</Alert>)}
                                    <div>
                                        <ul>
                                        <li style={{ padding: "5px" }}>
                                            <BsFillInfoCircleFill style={{fontSize: "17px",marginRight: "5px"}}/>
                                            El tamaño maximo permitido es de 15MB.
                                        </li>
                                        </ul>
                                    </div>
                                    </div>
                                </div>
                                </div>
                            ) : contentHeader === "DOCUMENT" ? (
                                <div className="mt-3">
                                <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable">
                                    <label>Cargar documento: </label>
                                    <div className="su-control-group">
                                    <input
                                        type="file"
                                        name="document"
                                        id="document"
                                        className="form-control"
                                        // accept="audio/*,.pdf,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                        accept="application/pdf"
                                        {...formSendTemplate.register("document", {
                                        required: {
                                            value: true,
                                            message: "El medio es requerido.",
                                        },
                                        })}
                                        onChange={(e) => onFileInputTemplate(e, "document")}
                                    />
                                    {error7.document && (<Alert>* {error7.document.message}</Alert>)}
                                    </div>
                                </div>
                                </div>
                            ) : contentHeader === "LOCATION" ? (
                                <div className="mt-3">
                                <div>
                                    <label>Datos de la ubicación:</label>
                                </div>
                                <div className="d-flex">
                                    <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable mb-0 mt-1 mr-2">
                                    <div className="su-control-group">
                                        <input type="number" name="latitud" className="form-control" placeholder="Latitud"
                                        {...formSendTemplate.register(`latitud`,{
                                            required:{
                                            value:true,
                                            message: "La latitud es requerida."
                                            },
                                            pattern: {
                                              value: /\S+/,
                                              message: "No puede ser solo espacios en blanco",
                                            },
                                        })}
                                        />
                                        {error7.latitud && (<Alert>* {error7.latitud.message}</Alert>)}
                                    </div>
                                    </div>
                                    <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable mb-0 mt-1 mr-2">
                                    <div className="su-control-group">
                                        <input type="number" name="longitud" className="form-control" placeholder="Longitud"
                                        {...formSendTemplate.register(`longitud`,{
                                            required:{
                                                value:true,
                                                message: "La longitud es requerida."
                                            },
                                            pattern: {
                                              value: /\S+/,
                                              message: "No puede ser solo espacios en blanco",
                                            },
                                        })}
                                        />
                                        {error7.longitud && (<Alert>* {error7.longitud.message}</Alert>)}
                                    </div>
                                    </div>
                                    <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable mb-0 mt-1 mr-2">
                                    <div className="su-control-group">
                                        <input type="text" name="nameLocation" className="form-control" placeholder="Nombre"
                                        {...formSendTemplate.register(`nameLocation`,{
                                            required:{
                                                value:true,
                                                message: "El nombre es requerido."
                                            },
                                            pattern: {
                                              value: /\S+/,
                                              message: "No puede ser solo espacios en blanco",
                                            },
                                        })}
                                        />
                                        {error7.nameLocation && (<Alert>* {error7.nameLocation.message}</Alert>)}
                                    </div>
                                    </div>
                                    <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable mb-0 mt-1">
                                    <div className="su-control-group">
                                        <input type="text" name="adress" className="form-control" placeholder="Dirección"
                                        {...formSendTemplate.register(`adress`,{
                                            required:{
                                                value:true,
                                                message: "La direccion es requerida."
                                            },
                                            pattern: {
                                              value: /\S+/,
                                              message: "No puede ser solo espacios en blanco",
                                            },
                                        })}
                                        />
                                        {error7.adress && (<Alert>* {error7.adress.message}</Alert>)}
                                    </div>
                                    </div>
                                </div>
                                </div>
                            ) : null}
                            </div>
                        )})}

                        {/* PINTAR CAMPOS PARA PARAMETROS DEL CONTENIDO*/}
                        {numberParams ? numberParams.map((param, k) => (
                            <div key={k} className="su-control su-with-prefix su-with-suffix su-segmentation-disable mb-0 mt-2">
                                <div className="su-control-group">
                                    <input type="text" name={`param${k}`} id={`param${k}`} className="form-control" placeholder={`Introducir contenido para {{${k+1}}}`}
                                        {...formSendTemplate.register(`param${k}`,{
                                            required:{
                                                value:true,
                                                message: "El parametro es requerido."
                                            },
                                            pattern: {
                                              value: /\S+/,
                                              message: "No puede ser solo espacios en blanco",
                                            },
                                        })}
                                    />
                                    {error7[`param${k}`] && (<Alert>* {error7[`param${k}`].message}</Alert>)}
                                </div>
                            </div>
                        )) : null  }

                        {/* PINTAR CAMPO PARA PARAMETRO DE URL DINAMICA */}
                        {listButtonsUrlDynamic && listButtonsUrlDynamic.map((btn, b) => (
                            <div key={b} className="su-control su-with-prefix su-with-suffix su-segmentation-disable mb-0 mt-2">
                            <div className="su-control-group">
                                <input type="text" name="urlParam" id="urlParam" className="form-control" placeholder="Valor para parametro de la url"
                                {...formSendTemplate.register(`urlParam`,{
                                    required:{
                                        value:true,
                                        message: "El parametro es requerido."
                                    },
                                    pattern: {
                                      value: /\S+/,
                                      message: "No puede ser solo espacios en blanco",
                                    },
                                })}
                                />
                                {error7.urlParam && (<Alert>* {error7.urlParam.message}</Alert>)}
                            </div>
                            </div>
                        ))}

                    </div>
                </div>


                {/* MODULO PARA PREVISUALIZAR LA PLANTILLA SELECCIONADA */}
                {templateSelected && templateSelected.map((template, t) => {
                    const contentFooter = template.content.filter(tem => {if (tem.type === "FOOTER") return tem})
                    return (
                    <div id="preview-template-selected" key={t} style={{width:"40%"}}>
                        <div className="colum ml-0">
                        <section className="section-preview p-3">
                            <h3>
                            <span>Vista previa</span>
                            </h3>
                            <div className="section-div1 ml-2 mr-5">
                            <div className="section-div2">
                                <div className="section-div3">
                                <div className="section-div4">
                                    <div
                                    className={
                                        contentHeader === "TEXT"
                                        ? "header-preview headerText"
                                        : videoSelected
                                        ? "video-selected"
                                        : "header-preview"
                                    }
                                    >
                                    {contentHeader && contentHeader === "TEXT" && contentTextHeader}

                                    {contentHeader && contentHeader === "IMAGE" ? (
                                        <>
                                            {fileType == "image" && selectedFiles.length ? (
                                            <img
                                                src={selectedFiles[0].src}
                                                className="w-100"
                                                style={{
                                                borderRadius: "5px",
                                                }}
                                            />
                                            ) : (
                                            <div className="medio-image custom-media"></div>
                                            )}
                                        </>
                                        ) : contentHeader === "VIDEO" ? (
                                        <>
                                            {fileType == "video" && selectedFiles.length ? (
                                            <ReactPlayer
                                                url={selectedFiles[0].src}
                                                loop
                                                controls
                                                width="100%"
                                                height="100%"
                                                className="custom"
                                            />
                                            ) : (
                                            <div className="medio-video custom-media"></div>
                                            )}
                                        </>
                                        ) : contentHeader === "DOCUMENT" ? (
                                        <>
                                            {fileType == "document" && selectedFiles.length ? (
                                            // <iframe
                                            //   src={documentt}
                                            //   download={documentName}
                                            //   sandbox="allow-same-origin allow-scripts allow-popups allow-forms allow-top-navigation"
                                            // ></iframe>
                                            <embed
                                                className="pdf-viewer"
                                                src={selectedFiles[0].src}
                                                type="application/pdf"
                                                width="100%"
                                                height="150px"
                                            />
                                            ) : (
                                            <div className="medio-document custom-media"></div>
                                            )}
                                        </>
                                        ) : contentHeader === "LOCATION" ? (
                                        <>
                                            <div className="medio-location custom-media"></div>
                                        </>
                                        ) : null
                                    }
                                    </div>

                                    {contentHeader === "LOCATION" &&
                                    <div className="data-location">
                                        <div className="location-name">{"{{Location name}}"}</div>
                                        <div className="location-adress">{"{{Adress}}"}</div>
                                    </div>
                                    }

                                    <div className="section-div5">
                                    <span className="span">
                                        <span>
                                        {content && content}
                                        </span>
                                    </span>
                                    </div>

                                    {contentFooter.length > 0 ? (
                                    <div className="content-footer span-color">
                                        {contentFooter[0].text}
                                    </div>
                                    ) : null}

                                    <time aria-hidden="true" className="_6xe5">
                                    {date}
                                    </time>
                                </div>

                                {/* LISTA DE BOTONES DE LLAMADA A LA ACCIÓN */}
                                {listButtonsCallToAction && listButtonsCallToAction.map((btn, b) => (
                                    <div key={b} className="content-botton">
                                    <div>
                                        {btn.type == "PHONE_NUMBER"
                                        ? <BsFillTelephoneFill className="mr-1" style={{ fontSize: "1.3rem"}}/>
                                        : <TiExport className="mr-1" style={{ fontSize: "1.3rem"}}/>}
                                        <span>
                                        {btn.text}
                                        </span>
                                    </div>
                                    </div>
                                ))}


                                </div>

                                {/* LISTA DE BOTONES DE RESPUESTA RAPIDA */}
                                {listButtonsQuickReply && listButtonsQuickReply.map((btn, b) => (
                                <div key={b} className="content-fastAnswer">
                                    <div>
                                    <span>{btn.text}</span>
                                    </div>
                                </div>
                                ))}

                            </div>
                            </div>
                        </section>
                        </div>
                    </div>
                )})}

            </div>

            {loadingSendTemplate && (
                <div className="loading-send-template d-flex justify-content-center align-items-center">
                    <span style={{ padding: "12px" }}>
                        <FaSpinner
                            className="fa-spin"
                            style={{ fontSize: "25px" }}
                            />
                    </span>
                </div>
            )}

            <div id="footer-buttons" className="col-sm-12 text-center mb-3" style={loadingSendTemplate ? {marginTop: "1px"} : {marginTop: "15px"} }>
                <a href="javascript:;" className="btn btn-lg btn-default mr-1" onClick={onCancel}>
                    Cancelar
                </a>
                {templateWhatsapp && <>
                    {
                        (!scheduleShipping || (timerConectionChat >= 24)) && (
                            <input 
                                type="submit" 
                                className="btn btn-lg btn-info mr-1" 
                                value="Enviar plantilla"
                                onClick={setScheduleShipping(false)}
                            />
                        )
                    }
                    {
                        (scheduleShipping && (timerConectionChat < 24)) && (
                            <Botton
                                type="button"
                                className="btn btn-lg btn-info" 
                                onClick={() => {
                                    setModalProgramMessage(true)
                                    setScheduleShipping(false)
                                }}
                            >   
                                <div className='d-flex align-items-center'>
                                    <span className='mr-1'>Programar Envío</span>
                                    <IoIosAlarm/>
                                </div>
                            </Botton>
                        )
                    }
                </>}
            </div>

            {modalProgramMessage ? (
                <div className="px-4 mx-auto content-date-send" style={{ width: "35%", padding: "25px 0 0 0" }}>
                    <div className="date-send">
                        <div className="row pb-3">
                            <div className="w-100 text-center">
                                <h4 className="mb-0">Programar envío</h4>
                            </div>
                            <div className="col col-12">
                                <div className="content-program d-flex pt-3 mt-1 justify-content-center">
                                    <div className="mr-3 d-inline-flex mb-1 mt-1">
                                        <div className="content-icon">
                                            <FaCalendarDays />
                                        </div>
                                    </div>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
                                        <DateTimePicker
                                            disablePast={true}
                                            animateYearScrolling={true}
                                            emptyLabel="Fecha y hora de envío"
                                            cancelLabel="Cancelar"
                                            okLabel="Aceptar"
                                            value={messageScheduleDate}
                                            onChange={setMessageScheduleDate}
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                            </div>

                            <div className="row justify-content-end bd col-12 mt-4 border-top-0">
                                <a
                                    href="javascript:;"
                                    className="btn btn-lg btn-default mr-2"
                                    onClick={() => {
                                        setModalProgramMessage(false);
                                        setMessageScheduleDate(null);
                                        setScheduleShipping(false);
                                    }}
                                >
                                    Cancelar
                                </a>
                                <input 
                                    type="submit" 
                                    className="btn btn-lg btn-info" 
                                    value="Programar Envío"
                                    onClick={
                                        setScheduleShipping(false)
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}
        </form>

        <ToastContainer 
            position="top-center"
            autoClose={52000}
            // autoClose={false}
            newestOnTop={false}
            closeOnClick={false}
            rtl={false}
            pauseOnFocusLoss
            draggable={false}
            // draggable
            theme={JSON.parse(localStorage.getItem("THEME")) ? "dark" : "light"}
            toastClassName="custom-toast" // Aplica estilos personalizados a cada toast individualmente
            bodyClassName="custom-toast-body"
        />
    </section>
  );
};

export default SendTemplate;

const Alert = styled.span`
  color: red;
  display: block;
  width: 200px;
`;

const Botton = styled.button`
  height: 43px
`;