import React, { useEffect } from "react";
import { Handle, Position, useUpdateNodeInternals } from 'reactflow';

import { BsDatabaseFillAdd } from "react-icons/bs";
import { IoIosWarning } from "react-icons/io";

const QueryApi = ({ data, isConnectable, id }) => {
    const updateNodeInternals = useUpdateNodeInternals();

    useEffect(() => {
        updateNodeInternals(id);
    }, [id, updateNodeInternals, data]);
    
    return (
        <div className="rounded" style={{width: "180px", boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px", cursor: 'pointer'}}>
            {data.saved && (
                <Handle 
                    type="target" 
                    position={Position.Top} 
                    isConnectable={isConnectable}  
                    id={`target-${id}`}
                    style={{backgroundColor: '#7ECCD8'}}
                />
            )}
            <div>
                <div 
                    className="d-flex align-items-center rounded-top justify-content-between" 
                    style={{height: "10px", backgroundColor: "#7ECCD8", padding: "12px"}}
                >
                    <div className="d-flex align-items-center">
                        <BsDatabaseFillAdd className="mr-1" style={{fontSize: "12px", color: "#fff"}}/>
                        <span style={{fontSize: "10px", color: "#fff"}} >
                            {data.title ?? "Consulta API"}
                        </span>
                    </div>
                    {data?.error && (
                        <IoIosWarning style={{fontSize: "12px", color: "#FFCC00"}}/>
                    )}
                </div>
                
                <div className="bg-white rounded-bottom" style={{padding: '8px'}}>
                    {data.user_response && (
                        <div 
                            className="p-1 mb-2" 
                            style={{
                                backgroundColor: "rgba(126, 204, 216, 0.3)",
                                borderRadius: "4px",
                                height: "auto"
                            }}
                        >   
                            <span style={{fontWeight: "bold", fontSize: "9px"}}>Respuesta del Cliente:</span>
                            <p style={{fontWeight: 'normal', fontSize: "9px"}} className="m-0">
                                {data.user_response}
                            </p>
                        </div>
                    )}
                    <div 
                        className="p-1 mb-2" 
                        style={{
                            border: "1px solid #000",
                            borderRadius: "4px",
                            height: "auto"
                        }}
                    >   
                        <div>
                            <span style={{fontWeight: "bold", fontSize: "9px"}}>Protocolo HTTP:</span>
                            {data.protocol && (
                                <p style={{fontWeight: 'normal', fontSize: "9px"}} className="m-0">
                                    {data.protocol.label}
                                </p>
                            )}
                        </div>
                        <div>
                          <hr
                            style={{margin: 0}}
                            role="separator"
                            aria-orientation="horizontal"
                            className="v-divider theme--light"
                          />
                        </div>
                        <div>
                            <span style={{fontWeight: "bold", fontSize: "9px"}}>Tipo de autenticación:</span>
                            {data.auth && (
                                <p style={{fontWeight: 'normal', fontSize: "9px"}} className="m-0">
                                    {data.auth.label}
                                </p>
                            )}
                        </div>
                        <div>
                          <hr
                            style={{margin: 0}}
                            role="separator"
                            aria-orientation="horizontal"
                            className="v-divider theme--light"
                          />
                        </div>
                        <div>
                            <span style={{fontWeight: "bold", fontSize: "9px"}}>URL API externa:</span>
                            {data.url_api && (
                                <p style={{
                                    wordWrap: "break-word",
                                    overflowWrap: "break-word",
                                    wordBreak: "break-all",
                                    whiteSpace: "pre-wrap",
                                    fontWeight: 'normal',
                                    fontSize: "9px"
                                }} className="m-0">
                                    {data.url_api}
                                </p>
                            )}
                        </div>
                    </div>
                    {data.bot_response && (
                        <div 
                            className="p-1 mb-2" 
                            style={{
                                border: "1px solid #000",
                                borderRadius: "4px",
                                height: "auto"
                            }}
                        >   
                            <span style={{fontWeight: "bold", fontSize: "9px"}}>Respuesta del Bot:</span>
                            <p style={{fontWeight: 'normal', fontSize: "9px"}} className="m-0">
                                {data.bot_response}
                            </p>
                        </div>
                    )}
                    <div 
                        className="p-1 mb-2" 
                        style={{
                            border: "1px solid #000",
                            borderRadius: "4px",
                            height: "80px"
                        }}
                    >   
                        <span style={{fontWeight: "bold", fontSize: "9px"}}>Estados de la solicitud:</span>

                        <div>
                            <button
                                type="button"
                                style={{
                                    border: 0,
                                    borderRadius: "4px",
                                    backgroundColor: "rgba(0, 128, 0, 0.3)",
                                    color: "#008000",
                                    cursor: "pointer",
                                    fontFamily: "Helvetica, Arial, sans-serif",
                                    fontSize: "8px",
                                    fontWeight: "bold",
                                    height: "20px",
                                    width: "150px",
                                    zIndex: 100,
                                    position: 'relative'
                                }}
                            >   
                                {data.succes}
                                <Handle 
                                    type="source" 
                                    position={Position.Right} 
                                    id={`source-queryApi-succes`}
                                    isConnectable={isConnectable}  
                                    style={{backgroundColor: '#7ECCD8', right: '-5px'}}
                                />
                            </button>
                            <button
                                type="button"
                                style={{
                                    border: 0,
                                    borderRadius: "4px",
                                    backgroundColor: "rgba(255, 0, 0, 0.3)",
                                    color: "#FF0000",
                                    cursor: "pointer",
                                    fontFamily: "Helvetica, Arial, sans-serif",
                                    fontSize: "8px",
                                    fontWeight: "bold",
                                    height: "20px",
                                    width: "150px",
                                    zIndex: 100,
                                    position: 'relative'
                                }}
                            >   
                                {data.failed}
                                <Handle 
                                    type="source" 
                                    position={Position.Right} 
                                    id={`source-queryApi-failde`}
                                    isConnectable={isConnectable}  
                                    style={{backgroundColor: '#7ECCD8', right: '-5px'}}
                                />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default QueryApi;