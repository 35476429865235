import React from "react";
import { toast } from 'react-toastify';
import useAPI from "../../hooks/useAPI";

// Mapa de tipos de toast
const toastType = {
    success: toast.success,
    warning: toast.warning,
    info: toast.info,
    error: toast.error,
};

// Mostrar modal que notifica estados como success, info, warning, etc
export const sendToast = (data) => {
    toast.dismiss();

    let time = data.time || 5000;

    const obj = {
        position: "top-center",
        autoClose: time,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        className: "custom-toast-container",
        bodyClassName: "custom-toast-body",
    }

    let msj = data.message || "Ocurrió un error inesperado.";
    
    if (data.strong) {
        msj = msj.replace(/\*/g, "<strong>").replace(/\+/g, "</strong>");
    } 

    // Aplicar negrita solo si `data.strong` está activo
    const FormattedMessage = () => <div dangerouslySetInnerHTML={{ __html: msj }} />;

     const showToast = toastType[data.type] || toast.error;
    
    // Validamos que `showToast` no sea `undefined` antes de llamarlo
    if (typeof showToast === "function") {
        showToast(<FormattedMessage />, obj);
    } else {
        console.error(`Tipo de toast inválido: "${data.type}"`);
    }
}

export const questionToast = (data, setLoading) => {
    return new Promise((resolve, reject) => {
        toast.dismiss();
        console.log("Datos recibidos en questionToast:", data);
        const { isFetch, fetch, title, text, icon, buttons } = data;

        const SplitButtons = ({closeToast}) => {
            
            const { fetchWithAuth } = useAPI();
            
            const handleReloadClick = async () => {
                try{
                    if (isFetch) {
                        setLoading(true)
                        const { url, options } = fetch;
                        console.log('--en if ---> ', options);
                        
                        const response = await fetchWithAuth(url, options);
                        const result = await response.json();
                        console.log("RESULT: SplitButtons:", result);
                        closeToast(false);
                        resolve(result)
                    } else {
                        console.log('--en else---> ');
                        closeToast(false);
                        resolve(true)
                    }
                }catch(error){
                    setLoading(false);
                } finally {
                    setLoading(false);
                };
            };
        
            return (
                <div className="grid grid-cols-[1fr_1px_80px] w-full">
                    <div className="flex flex-col p-4">
                        <h3 className="text-zinc-800 text-sm font-semibold">{title}</h3>
                        <p className="text-sm" style={{whiteSpace: "break-spaces"}}>{text}</p>
                    </div>
                    <div className="bg-zinc-900/20 h-full" />
                    <div className="d-flex justify-content-evenly mt-1 pt-1">
                        {buttons.cancel && (
                            <a
                            href="javascript:;"
                            className="btn btn-lg btn-default mr-1"
                            onClick={() => closeToast(false)}
                            >
                                {buttons.cancel}
                            </a>

                        )}
                        {buttons.success && (
                            <input
                                className="btn btn-lg btn-info"
                                value={buttons.success}
                                type="button"
                                onClick={handleReloadClick}
                            />
                        )}
                    </div>
                </div>
            );
        };

        (toastType[icon] || toast.error)(
            <SplitButtons />,
            {
                position: "top-center",
                autoClose: false,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: false,
                theme: "light",
                className: "custom-toast-container",
                bodyClassName: "custom-toast-body",
            }
        );
    });
};
