import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import useAPI from '../../hooks/useAPI';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import {sendToast} from '../plugins/toastAll';
import { ToastContainer } from 'react-toastify';

const Template = () => {

  const [template, setTemplate] = useState({});
  const [corresp, setCorresp] = useState([]);
  const [loading, setLoading] = useState(false);
  const [typeMessages, setTypeMessages] = useState([]);

  const {urlAPI, urlAPI_1, urlAPI_2, fetchWithAuth} = useAPI();

  const {watch,register,handleSubmit,formState: { errors },reset,} = useForm({mode:"all", defaultValues: { name: "", type: "", content: "" } });

  const {id} = useParams();

  const navigate = useNavigate()

  useEffect(() => {
    getTemplate();
    getCorresp();
    getTypeMessages();
  }, []);

  const getTemplate = async () => {
    const idUrl = id
    const url = urlAPI_2 + `templates/${idUrl}`;
    const options = { method: "GET", mode: "cors"};
    const response = await fetchWithAuth(url, options);
    const result = await response.json();
    setTemplate(result.data);
    console.log(result)

    const { name, type, content } = result.data;
    reset({name,type,content,},{ keepDefaultValues: true });
  };

  const getTypeMessages = async () => {
    try {
      const url = urlAPI + "messagetypes";
      const options = { method: "GET", mode: "cors"};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      console.log('Response of the API', result);
      setTypeMessages(result.data)
    } catch (error) {
      console.log(error)
    }
  }

  const getCorresp = async () => {
    try {
      const url = urlAPI + "getDataEmail"
      const options = { method: "GET", mode: "cors"};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      console.log('Data list', result)
      let data = result.data;
      if(data.length) {
        data = data.filter(v => v.field !== "callingCode")
      }
      setCorresp(data)
    } catch (error) {
      console.log(error)
    }
  }
  
  const putTemplate = async (values) => {
    const idUrl = id;
    try {
      const url = urlAPI + `templates/${idUrl}`;
      const options = { method: "PUT", mode: "cors", body: JSON.stringify(values)};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      // console.log("Data Updated", result);

      //# PUT Completado, se reinician lo campos
      navigate('/home/templates')
      reset();

      //# Confirmacion del cambio
      sendToast(result)
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (event) => {
    const optionValue = event.target.value;
    const {name, type, content} = watch();
    const addText = content + " " + optionValue + " ";
    reset({name, type, content: addText})
  }

  return (
    <div>
        <div>
            <button className='btn btn-danger f-w-900' onClick={() => navigate('/home/templates')}>Volver</button>
        </div>
        <FormContent>
          <form
            className="row justify-content-center"
            onSubmit={handleSubmit(putTemplate)}
          >
            <div className="left-content col d-flex flex-column align-items-center justify-content-center">
              <label htmlFor="name" className="text-center">
                Nombre de la plantilla
              </label>
              <input
                type="text"
                name="name"
                id="name"
                className="text-center"
                {...register("name", {
                  required: {
                    value: true,
                    message: "El nombre es requerido.",
                  },
                  pattern: {
                    value: /\S+/,
                    message: "No puede ser solo espacios en blanco",
                  },
                })}
              />
              {errors.name && <Alert>* {errors.name.message}</Alert>}
              <label htmlFor="name" className="text-center mt-3">
                Contenido de la plantilla
              </label>
              <textarea
                cols="30"
                rows="10"
                className=" mb-4"
                id="textarea-content"
                {...register("content", {
                  required: {
                    value: true,
                    message: "El contenido es requerido.",
                  },
                  pattern: {
                    value: /\S+/,
                    message: "No puede ser solo espacios en blanco",
                  },
                })}
              ></textarea>
              {errors.content && <Alert>* {errors.content.message}</Alert>}
              <LastContainer>
              <select
                onChange={handleChange}
              >
                <option value="" key="0" disabled selected>Personalizar formato</option>
                {corresp && corresp.map((element) => (
                  <option value={element.fieldCorresp} key={element.field}>{element.fieldName}</option>
                ))}
              </select>
              </LastContainer>
            </div>
            <div className="right-content col-lg-6">
              <div className="app-paper">
                <div className="radio-content">
                  {typeMessages.length > 0 && typeMessages.map(type => (
                    <>
                      <input
                      type="radio"
                      name={type._id}
                      id={type._id}
                      className="hidebx"
                      value={type._id}
                      {...register("type", {
                        required: {
                          value: true,
                          message: "El tipo de plantilla es requerido.",
                        },
                        pattern: {
                          value: /\S+/,
                          message: "No puede ser solo espacios en blanco",
                        },
                      })}
                      />
                      <label htmlFor={type._id} className="lbl-radio">
                        <div className="display-box">
                          <div className={`icon ${type.classColor}`}><i className={type.classIcon} aria-hidden="true"></i></div>
                          <div className="type">{type.description}</div>
                        </div>
                      </label>
                    </>
                  ))
                  }
                </div>
                {errors.type && <Alert>* {errors.type.message}</Alert>}
              </div>
              <div className="radio-content">
                <button
                  type="submit"
                  name="submit"
                  className="btn bg-success col-1-sm-3 my-auto"
                >
                  Editar
                </button>
                {/* <pre>{JSON.stringify(watch(), null, 3)}</pre> */}
              </div>
            </div>
          </form>
        </FormContent>
        <ToastContainer 
          position="top-center"
          autoClose={52000}
          // autoClose={false}
          newestOnTop={false}
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          // draggable
          theme={JSON.parse(localStorage.getItem("THEME")) ? "dark" : "light"}
          toastClassName="custom-toast" // Aplica estilos personalizados a cada toast individualmente
          bodyClassName="custom-toast-body"
        />
    </div>
  )
}

export default Template

const FormContent = styled.div`
  margin-top: 20px;
  height: 100%;
  width: 100%;

  .left-content label {
    display: block;
    font-size: 18px;
    font-weight: 900;
  }

  .left-content input {
    height: 50px;
    width: 80%;
    font-size: 22px;
    border-radius: 12px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    border: 2px solid gray;
  }

  .left-content textarea {
    font-size: 18px;
    height: 100px;
    width: 80%;
    border-radius: 12px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    border: 2px solid gray;
  }

  .right-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .app-paper {
    width: 100%;
    padding: 20px 0;
  }

  .hidebx {
    display: none;
  }

  .radio-content {
    width: 80%;
    display: flex;
    gap: 20px;
    margin: 0 auto;
    justify-content: center;
  }

  .radio-content button {
    font-size: 15px;
    font-weight: 900;
  }

  .lbl-radio {
    text-align: center;
    max-width: 150px;
    width: 100%;
    padding: 10px;
    background-color: rgb(0, 0, 0, 0.2);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    cursor: pointer;
    transition: all 0.3s ease;
    border-radius: 12px;
  }

  .display-box .icon {
    font-size: 60px;
    font-weight: 900;
    margin-bottom: 5px;
    letter-spacing: 0.5px;
  }

  .display-box .icon.wsp {
    color: #1ad924;
  }

  .display-box .icon.sms {
    color: #1e52e3;
  }

  .display-box .type {
    font-weight: 900;
    font-size: 24px;
  }

  input[type="radio"]:checked + .lbl-radio {
    background: rgb(39, 38, 51, 0.9);
    color: white;
  }
`;

const LastContainer = styled.div`
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70px;

    select {
      -webkit-appearance:none;
      -moz-appearance:none;
      -ms-appearance:none;
      appearance:none;
      outline:0;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
      border: 2px solid gray;
      // background: #5c6664;
      background-image: none;
      padding: 10px;
      color:#000;
      cursor:pointer;
      font-size: 1em;
      font-family: 'Open Sans', sans-serif;
      font-weight: 900;
      width: 100%;
      border-radius: 12px;
    }
`

const Alert = styled.span`
  color: red;
  display: block;
  width: 100%;
  text-align: center;
`;
