import React, { Fragment, useEffect, useState, useRef, useLayoutEffect } from "react";
import Header from "../layout/Header";
import Picker from "emoji-picker-react";
import styled from "styled-components";
import Avvvatars from "avvvatars-react";
import Modal from "../layout/Modal";
import imgMap from "../../imgs/img-map.png";
import useAPI from "../../hooks/useAPI";
import usePersonsFinder from "../../hooks/usePersonsFinder";
import { useForm } from "react-hook-form";
import "react-voice-recorder/dist/index.css";
import { Howl } from "howler";
import toneNotification from "../../sounds/sound-notification.mp3";
import toneNotifyPending from "../../sounds/bubble.mp3";
import Spinner from "../plugins/Spinner";
import SpinnerNew from "../plugins/SpinnerNew";
import dayjs from "dayjs";
import { useNavigate, useLocation } from "react-router-dom";
import { BsSearch, BsFillFileEarmarkTextFill, BsFillCheckCircleFill, BsBoxSeam, BsBoxes } from "react-icons/bs";
import { FaEye, FaImage, FaUser } from "react-icons/fa";
import { FaRegFaceLaugh } from "react-icons/fa6";
import { MdOutlineAddCircleOutline } from "react-icons/md";
import { IoClose } from "react-icons/io5";
import { upload_s3 } from './UploaderS3';
import './css/chat.css';
import './css/login.css';
import { v4 as uuidv4 } from 'uuid';

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

//#--> Componentes del chat
import ImagePreview from "../others/ImagePreview";
import SendTemplate from "../others/SendTemplate";
import NoChatSelected from "../others/NoChatSelected";
import ListContacts from "../others/ListContacts";
import ListConversations from "../others/ListConversations";
import InactivityPopup from "../others/InactivityPopup";
import LoadingMessages from "../others/LoadingMessages";
import MessagesContentHeader from "../others/MessagesContentHeader";
import OpenConversationMessage from "../others/OpenConversationMessage";
import AssingConversationMessage from "../others/AssingConversationMessage";
import MessageSendTemplate from "../others/MessageSendTemplate";
import PreviewMediaToSend from "../others/PreviewMediaToSend";
import ContactInfo from "../others/ContactInfo";
import TextareaSendMessage from "../others/TextareaSendMessage";
import ShippingOptions from "../others/ShippingOptions";
import QuickAnswerList from "../others/QuickAnswerList";
import SendMessageOrRecordAudio from "../others/SendMessageOrRecordAudio";
import MessageListContents from "../others/MessageListContents";

import { ToastContainer, toast, ToastContentProps } from 'react-toastify';
import {showToast} from "../plugins/toastBuyPackage";
import {questionToast, sendToast} from "../plugins/toastAll";
import { Tooltip } from "react-tooltip";

const Chat = () => {
  var listValuesGlob = [];
  var listUserValuesGlob = [];

  const [loading, setLoading] = useState(false); // loading general, rocket image
  const [loadingMessages, setLoadingMessages] = useState(false);  // spinner de mensajes
  const [loadingChats, setLoadingChats] = useState(false);  // spinner de conversaciones
  const activeLoadings = useRef(0);
  const activeLoadingsChats = useRef(0);

  const [fieldsList, setFieldsList] = useState([]);
  const [personsList, setPersonsList] = useState([]);
  //const [searchPersonsList, setSearchPersonslist] = useState([]);
  const [showPicker, setShowPicker] = useState(false);
  const [closeEmojis, setCloseEmojis] = useState(false);
  const [showPicker2, setShowPicker2] = useState(false);
  const [closeEmojis2, setCloseEmojis2] = useState(false);
  const [inputStr, setInputStr] = useState("");
  const [showContactInfo, setShowContactInfo] = useState(false);
  const [listAdvisers, setListAdvisers] = useState([]);
  const [listAreas, setListAreas] = useState([]);
  const [searchAdvisers, setSearchAdvisers] = useState([]);
  const [modalTransferAgent, setModalTransferAgent] = useState(false);
  const [searchChats, setSearchChats] = useState("");
  const [searchModal, setSearchModal] = useState("");
  const [validationChats, setValidationChats] = useState(false);
  const [validationModal, setValidationModal] = useState(false);
  const [quicklyAnswers, setQuicklyAnswers] = useState("");
  const [contenMessage, setContenMessage] = useState("");
  const [createAnswer, setCreateAnswer] = useState(false);

  const [filter, setFilter] = useState("mine");
  const [search, setSearch] = useState(false);

  const [listChats, setListChats] = useState([]);
  const [listUserChats, setListUserChats] = useState([]);
  const listUserChatsRef = useRef([])
  const [listMessages, setListMessages] = useState([]);
  const listMessagesRef = useRef([]);
  const [isSavedChat, setIsSavedChat] = useState(false);

  //   Estados para el chat seleccionado
  const [isChatSelected, setIsChatSelected] = useState(false);
  const chatEndRef = useRef(null);
  const contChatRef = useRef(null);
  const [notChatSelected, setNotChatSelected] = useState(true);
  const [contactName, setContactname] = useState("");
  const [nameInitials, setNameInitials] = useState("");
  const [phoneContact, setPhoneContact] = useState("");
  const [emailContact, setEmailContact] = useState("");

  const [listStatus, setListStatus] = useState([]);
  const [statusChat, setStatusChat] = useState("");

  const [newChat, setNewChat] = useState(false);
  const [startChat, setStartChat] = useState(false);
  const [searchPersons, setSearchPersons] = useState("");
  const [templates, setTemplates] = useState([]);
  const [templateWhatsapp, setTemplateWhatsapp] = useState(false);
  const [templateSelected, setTemplateSelected] = useState([]);
  const [correspondence, setCorrespondence] = useState([]);
  const [numberParams, setNumberParams] = useState([]);
  const [content, setContent] = useState("");
  const [isUrlDynamic, setIsUrlDynamic] = useState("");
  const [isUrlDynamic2, setIsUrlDynamic2] = useState("");
  const [activeNotification, setActiveNotification] = useState(true);
  const [listNotes, setListNotes] = useState([]);
  const [scheduleShipping, setScheduleShipping] = useState(false);

  const [filterState, setFilterState] = useState(false);

  const [date, setDate] = useState("");

  const [idChatSelected, setIdChatSelected] = useState("");

  const [isViewImage, setIsViewImage] = useState(false);
  const [viewSelectedImage, setViewSelectedImage] = useState("");

  const [modalTemplateSend, setModalTemplateSend] = useState(false);
  const [isTemplateSend, setIsTemplateSend] = useState(true); // Si es false entonces se debe enviar template.
  const [chatClose, setChatClose] = useState(true);  // Para controlar estado de mensaje: cerrado. Si es true entonces el chat está cerrado.
  const [chatCloseInactivity, setChatCloseInactivity] = useState(false);  // Para controlar estado de mensaje cerrado por inactividad.
  const [isAssignedAgent, setIsAssignedAgent] = useState(false); // Si es false entonces tiene agente asignado (bot y chatbot se consideran true)
  // Si isAssignedAgent = true && isTemplateSend == false --> Se muestra boton de "asignar agente".
  // Si isAssignedAgent == true && isTemplateSend = true --> Se muestra boton de "abrir"
  const isTemplateSendRef = useRef(true);
  const isAssignedAgentRef = useRef(false);

  const [idTemplate, setIdTemplate] = useState("");
  const [numberPage, setNumberPage] = useState(1);
  const [totalPages, setTotalPages] = useState(2);
  const [numberPageConversations, setNumberPageConversations] = useState(1);
  const [totalPagesConversations, setTotalPagesConversations] = useState(2);
  const [listChatsAll, setListChatsAll] = useState([]);

  const [variablesQuickResponses, setVariablesQuickResponses] = useState({});
  const [showQuickResponses, setShowQuickResponses] = useState(false);
  /* Estado para tener en contexto el chat seleccionado */
  const [chatSelected, setChatSelected] = useState([]);
  const socketConn = useRef(null);
  const socketConnTimestamp = useRef(null);

  //#--> Estado para el header de la plantilla
  const [templateHeader, setTemplateHeader] = useState(false);
  const [contentHeader, setContentHeader] = useState("");
  const [contentTextHeader, setContentTextHeader] = useState("");

  //#--> Estado para los botones de la plantilla
  const [listButtonsCallToAction, setListButtonsCallToAction] = useState([]);
  const [listButtonsQuickReply, setListButtonsQuickReply] = useState([]);
  const [listButtonsUrlDynamic, setListButtonsUrlDynamic] = useState([]);

  //#--> Cuando selecionamos un emoji y hacer focus en el input de envio
  const [selectedEmoji, setSelectedEmoji] = useState(false);
  const inputRef = useRef(null);

  //#--> Estados para el formulario de respuestas rápidas
  const [inputStr2, setInputStr2] = useState("");
  const [answerTitle, setAnswerTitle] = useState("");
  const [answerArea, setAnswerArea] = useState("");
  const [answerAreaError, setAnswerAreaError] = useState(false);
  const [answerAgent, setAnswerAgent] = useState("");
  const [answerAgentError, setAnswerAgentError] = useState(false);
  const [inputStr2Error, setInputStr2Error] = useState(false);
  const [answerTitleError, setAnswerTitleError] = useState(false);
  const [editAnswer, setEditAnswer] = useState(false);
  const [selectedAnswerId, setSelectedAnswerId] = useState("");
  const [viewAnswer, setViewAnswer] = useState(false);
  const [searchQuicklyAnswer, setSearchQuicklyAnswer] = useState([]);

  //#--> Estado para el loading y saber cuando mostrar el mensaje cuando se carga un archivo
  const [statusLoading, setStatusLoading] = useState(false);

  //#--> Estado para Tags
  const [listTagsAll, setListTagsAll] = useState([]);
  const [listTags, setListTags] = useState([]);
  const [tableListTags, setTableListTags] = useState([]);
  const [validationModalTags, setValidationModalTags] = useState(false);

  //#Estado paraaaaaaaa Stages
  const [listStages, setListStages] = useState([]);

  //#--> Estado para pedidos
  const [listOrders, setListOrders] = useState([]);
  const [ordersFilteredInfo, setOrdersFilteredInfo] = useState([]);

  //#--> Estado para el agente seleccionado
  const [responsibleContact, setResponsibleContact] = useState("");

 //#--> Estado para saber si hay una imagen cargada de respuesta rápida
  const [isImgAnswer, setIsImgAnswer] = useState(false);
  const [imgAnswer, setImgAnswer] = useState("");
  const [viewImgAnswer, setViewImgAnswer] = useState(false);
  const [typeFileAnswer, setTypeFileAnswer] = useState("");
  const [mediaNameAnswer, setMediaNameAnswer] = useState("");

  //#--> Estado del campo texto de una imagen o pdf
  const [textDocument, setTextDocument] = useState("");

  //#--> Estado para el modal de inactividad
  const [inactivityPopup, setInactivityPopup] = useState(false);
  const popupTimer = useRef(null);
  const closedByInactivity = useRef(false);
  const closedByUnmount = useRef(false);
  const agentAssigned = useRef(true);
  const { urlAPI_1, urlAPI_2, urlAPI_3, urlSocket, fetchWithAuth } = useAPI();
  const token = localStorage.getItem("USER_TOKEN");
  const roleId = localStorage.getItem("USER_ROLEID");
  const userLogout = localStorage.getItem("USER_NAME");
  const userId = localStorage.getItem("USER_ID");
  const companyId = localStorage.getItem("COMPANY_ID");
  const userArea = localStorage.getItem("USER_AREA");
  const navigate = useNavigate();
  const location = useLocation();
  const parametroo = location.state && location.state.parametro;
  const ParamIdContact = location.state && location.state.idContact;
  const isChatContact = location.state && location.state.isChat;


  //Funciones para controlar el record-audio
  const [recording, setRecording] = useState(false);
  const [isRecor, setIsRecor] = useState(false);
  const [audioDetails, setAudioDetails] = useState({
    url: null,
    blob: null,
    chunks: null,
    duration: {h: 0,m: 0,s: 0, ms:0},
  });

  //Estados para las listas
  const [allLists, setAllLists] = useState([]);
  const [listsContact, setListsContact] = useState([]);
  const [idContactSelected, setIdContactSelected] = useState("");

  const [viewAnswers, setViewAnswers] = useState(false);
  const [searchArea, setSearchArea] = useState([]);

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [fileType, setFileType] = useState("");
  const [isMedia, setIsMedia] = useState("");
  const dataFilesRef = useRef([]);
  const [videoSelected, setVideoSelected] = useState("");
  const focusLastMessageControl = useRef(false);
  const focusLastMessageControlByInputFocus = useRef(false);
  const focusScrollMessageControl = useRef(false);
  const focusScrollMessageHeight = useRef(0);

  const conversationsListRef = useRef(null);
  const previousScrollHeightRef = useRef(0);
  const previousScrollTopRef = useRef(0);
  const sectionChat = useRef(null);

  const [textConversations, setTextConversations] = useState("");
  const filterRef = useRef("mine");
  const [showMaxLengthError, setShowMaxLengthError] = useState(false);

  const [selectedRadio, setSelectedRadio] = useState("")

  const [classChat, setClassChat] = useState(false);
  const [classInfo, setClassInfo] = useState(false);

  const [isDark, setIsDark] = useState(false);
  const [modalTransferNote, setModalTransferNote] = useState(false);
  const [agentSelected, setAgentSelected] = useState("");
  const [contactNameSelected, setContactNameSelected] = useState("")
  const [timerConectionChat, setTimerConectionChat] = useState("")

  const [modalListContacts, setModalListContacts] = useState(false);
  const [listContacts, setListContacts] = useState([]);

  const [isMediaAnswer, setIsMediaAnswer] = useState("");
  const [isMediaTypeAnswer, setIsMediaTypeAnswer] = useState("");
  const [idAnswerSelected, setIdAnswerSelected] = useState("");

  const [searchConversation, setSearchConversation] = useState(false);

  const [modalFlowSend, setModalFlowSend] = useState(false);
  const [modalFlowSelected, setModalFlowSelected] = useState(false);
  const [headerSelected, setHeaderSelected] = useState("");
  const [medioSelected, setMedioSelected] = useState("");
  const [excelFile, setExcelFile] = useState(null);
  const [textHeaderFlow, setTextHeaderFlow] = useState("");
  const [textBodyFlow, setTextBodyFlow] = useState("");
  const [textFooterFlow, setTextFooterFlow] = useState("");
  const [closeEmojis3, setCloseEmojis3] = useState(false);
  const [showPicker3, setShowPicker3] = useState(false);
  const controlUpdateRead = useRef(false);
  const [isIphone, setIsIphone] = useState(false);

  const [flows, setFlows] = useState([])
  const [flowsValidate, setFlowsValidate] = useState(false)
  const [flowIdSelected, setFlowIdSelected] = useState("")
  const [textBottonFlow, setTextBottonFlow] = useState("")
  const [modalSendProducts, setModalSendProducts] = useState(false)
  const [selectProductsSend, setSelectProductsSend] = useState(false)
  const [selectedTypeProducts, setSelectedTypeProducts] = useState("1")
  const [catalog, setCatalog] = useState([])
  const [listProducts, setListProducts] = useState([])
  const [listProducts2, setListProducts2] = useState([])
  const [listProductsTable, setListProductsTable] = useState([])
  const [senFlowOrProducts, setSenFlowOrProducts] = useState(null)
  const [modalCatalogSend, setModalCatalogSend] = useState(false)
  const [textHeaderCatalog, setTextHeaderCatalog] = useState("")
  const [modalGenerateLink, setModalGenerateLink] = useState(false)
  const [searchProducts, setSearchProducts] = useState("");
  const [productsValidate, setProductsValidate] = useState(false);
  const [validationModalProducts, setValidationModalProducts] = useState(false);
  const [listSets, setListSets] = useState([])
  const [selectedSets, setSelectedSets] = useState("")
  const [searchSets, setSearchSets] = useState("");
  const [listSetsTable, setListSetsTable] = useState([])
  const [validationSets, setValidationSets] = useState(false);

  const [modalViewProductContext, setModalViewProductContext] = useState(false);
  const [productContextSelected, setProductContextSelected] = useState({});
  const [modalViewOrder, setModalViewOrder] = useState(false)
  const [quantityOrder, setQuantityOrder] = useState("")
  const [totalPriceOrder, setTotalPriceOrder] = useState("")
  const [orderSelected, setOrderSelected] = useState([])
  const [conversationsPendingCount, setConversationsPendingCount] = useState(0);
  const [conversationsAssignedCount, setConversationsAssignedCount] = useState(0);

  const [selectedImg, setSelectedImg] = useState(null);
  const [documentView, setDocumentView] = useState(null);
  const [mediaSelected, setMediaSelected] = useState(0);

  const [loadingSendTemplate, setLoadingSendTemplate] = useState(false);

  const [isMethodActive, setIsMethodActive] = useState(false);
  const [methodsPayment, setMethodsPayment] = useState([]);

  const focusLastMessageContext = useRef(false);
  const [idMessageContext, setIdMessageContext] = useState("");

  const [urlImgContext, setUrlImgContext] = useState("")

  const [scrollPosition, setScrollPosition] = useState(0)

  const listsByCompany = useRef([])
  
  const fetchMessagesControl = useRef(false);
  const fetchMessagesUrlControl = useRef("");
  const [isBlurred, setIsBlurred] = useState(false);
  const onchatBlurControl = useRef(false);

  const [loadingNew, setLoadingNew] = useState(false);
  
  const [messageScheduleDate, setMessageScheduleDate] = useState(null);
  const listContactFull = useRef("");
  const updateConversationsQueue = useRef([]);
  const updateConversationsQueueControl = useRef(false);
  const [loadingContacts, setLoadingContacts] = useState(false);

  const [feeling, setFeeling] = useState([]);
  const [isFeeling, setIsFeeling] = useState(false);
  const [listFeelings, setListFeelings] = useState([]);

  const [interestedBuying, setInterestedBuying] = useState([]);
  const [isInterestedBuying, setIsInterestedBuying] = useState(false);
  const filterByStatusAndTagsRef = useRef('');
  const [listInterestedBuying, setListInterestedBuying] = useState([]);
  const [permissions, setPermissions] = useState([]);
  
  const [msgIA, setMsgIA] = useState(false);
  
  const { persons, loading: isLoadingContacts, handleChangeSearch: onSearchPersons, searchQuery } = usePersonsFinder({
    pageSize: 300,
  });

  useEffect(() => {
    setPersonsList(persons)
    listContactFull.current = persons;
  }, [persons]);

  useEffect(() => {
    setLoadingContacts(isLoadingContacts)
  }, [isLoadingContacts]);

  const radioOptions = [
    {id: "1", name: "Todos"},
    {id: "2", name: "Agente"},
    {id: "3", name: "Área"},
  ]

  const filterByState = [
    {id: 1,name: "Abierto"},
    {id: 2,name: "Cerrado"},
    {id: 3,name: "En espera"},
  ];

  const optSendProducts = [
    {id: "1", name: "Productos"},
    {id: "2", name: "Conjuntos"},
  ]

  //# React-hook-form para establecer validaciones
  const {watch,register,handleSubmit,formState: { errors },reset,setValue} = useForm({
    mode: "all",
    defaultValues: {
      email: "",
    },
    note: "",
    segment: [],
    name: "",
    lastName: "",
    phone: "",
    email: "",
    answerName: "",
    answerContent: "",
    tags:[],
  });

  const formSendFlow = useForm();
  const formSendFlow2 = useForm();
  const formSendCatalog = useForm();
  const formSendCatalog2 = useForm();
  const formGenerateLink = useForm();
  const formSendTemplate = useForm();
  const formPutPerson = useForm();
  const error2 = formSendFlow.formState.errors;
  const error3 = formSendFlow2.formState.errors;
  const error4 = formSendCatalog.formState.errors;
  const error5 = formSendCatalog2.formState.errors;
  const error6 = formGenerateLink.formState.errors;
  const error7 = formSendTemplate.formState.errors;
  const error8 = formPutPerson.formState.errors;


  const code_msg_admin = "0000000000";
  const code_msg_user = "1111111111";
  const code_msg_dashboard = "2222222222";
  const code_msg_system = "3333333333";

  useLayoutEffect(() => {
    if (isChatSelected) {
      scrollToBottom("auto");
    }
  }, [isChatSelected]);

  useEffect(() => {
    console.log("parametroo 🔢: ", parametroo);
    console.log("ParamIdContact👩‍🦱: ", ParamIdContact);
    console.log("isChatContact🆕: ", isChatContact);
    const interval = setInterval(() => {
      checkForUpdateConversations();
    }, 1000);

    const userAgent = window.navigator.userAgent;
    setIsIphone(userAgent.match(/iPhone/i));
    localStorage.setItem("STATE_NOT", "true");
    localStorage.setItem("CHAT_SELECTED", "");
    // Inicia el temporizador por primera vez
    resetTimer();

    // Eventos para detectar la actividad del usuario
    const eventos = ['mousemove', 'keydown'];
    const resetByActivity = () => resetTimer();

    eventos.forEach((evento) => {
      document.addEventListener(evento, resetByActivity);
    });

    const handleVisibilityChange = () => {
      if (!document.hidden) {
        eventos.forEach((evento) => {
          document.removeEventListener(evento, resetByActivity);
        });

        eventos.forEach((evento) => {
          document.addEventListener(evento, resetByActivity);
        });
  
        if (!socketConn.current || (socketConnTimestamp.current + 1000 * 60 * 8) < Date.now()) {
          console.log("La pestaña está activa nuevamente. Restaurando eventos...");
          if(socketConn.current){
            socketConn.current.close();
            updateConversationsQueue.current.push("connectI");
          }else{
            connectDispatcher(true); 

          }
        }
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    const fetchData = async () => {
      try {
        console.log('-------------🎇isChatContact🎇---------------', isChatContact)
        if (isChatContact) {
          setLoadingNew(true)
        }
        await Promise.all([
          getConversationsAsign(),
          getProducts(),
          getSets(),
          getMethods(),
          getFields(),
          //getPersons(),
          getTemplates(),
          getAgents(),
          getCorrespondence(),
          setFilterState(filterByState),
          connectDispatcher(false),
          getQuickReply(),
          getTags(),
          getAreas(),
          getFlows(),
          getAllLists(),
          getOrders(),
          getConversationsCount(),
          getStages(),
          // getCatalog(),
        ]);

        if (parametroo) {
          console.log('--------------------⌛⌛parametro⌛⌛----------------')
          setClassChat(true)
          await onChatSelected(parametroo);
          setLoadingNew(false)
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
    // Limpieza de eventos al desmontar el componente
    return () => {
      eventos.forEach((evento) => {
        document.removeEventListener(evento, resetByActivity);
      });
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      clearTimeout(popupTimer);
      if(socketConn.current){
        closedByUnmount.current = true;
        socketConn.current.close();
        console.log("clearing socket connection")
      }
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    updateListUserChatsRef(listMessages);
    listMessagesRef.current = listMessages;
    /*
    console.log("focusLastMessageContext.current: ", focusLastMessageContext.current);
    console.log("focusLastMessageControl.current: ", focusLastMessageControl.current);
    console.log("focusLastMessageControlByInputFocus.current: ", focusLastMessageControlByInputFocus.current);
    console.log("focusScrollMessageControl.current: ", focusScrollMessageControl.current);
    console.log("onchatBlurControl.current: ", onchatBlurControl.current);
    */
    if(focusLastMessageContext.current) {
      const messageElement = document.getElementById(`message-${idMessageContext}`);
      if(messageElement) {
        messageElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        messageElement.classList.add('focused-message');
        setTimeout(() => {
          messageElement.classList.remove('focused-message');
        }, 3000);
        focusLastMessageContext.current = false;
      } else {
        console.log(":::::: No se puede ver el mensaje :::::: ");
        sendToast({type: "info", message: "Al parecer el mensaje no es reciente, te recomendamos buscarlo manualmente."})
        focusLastMessageContext.current = false;
      }
    } else {
      if(focusLastMessageControl.current){
          focusLastMessageControl.current = false;
      }else if(focusLastMessageControlByInputFocus.current){
        // Cuando esta abajo de todo o un poco por encima hace scroll hacia abajo. 
        focusLastMessageControlByInputFocus.current = false;
        setTimeout(function(){
          scrollToBottom("auto");
        }, 800);
      } else if(focusScrollMessageControl.current /*&& onchatBlurControl.current*/){
          //onchatBlurControl.current = false;
          focusScrollMessageControl.current = false;
          setTimeout(function(){
              focusOnScrollMessage();
              //setIsBlurred(false);
          }, 0);
      }
    }
    if(onchatBlurControl.current){
      // Cuando se cargan los mensajes al hacer click en la conversacion.
      onchatBlurControl.current = false;
      setTimeout(function(){
        scrollToBottom("auto");
        setIsBlurred(false);
      }, 800);
    }

  }, [listMessages]);

  useEffect(() => {
    console.log("Se actualizó la lista de chats listUserChats: ", listUserChats);
    if(listUserChats.length){
      console.log("listUserChats[0].userAsign", listUserChats[0].userAsign);
      console.log("listUserChats[0].statusConv", listUserChats[0].statusConv);
    }
    listUserChatsRef.current = listUserChats;
  }, [listUserChats]);

  useEffect(() => {
    if (isChatSelected) {
      scrollToBottom("auto");
    }
  }, [isChatSelected]);

  useEffect(() => {
    if(loadingChats){
      activeLoadingsChats.current += 1;  
      setTimeout(() => {
        activeLoadingsChats.current -= 1;
        if (activeLoadingsChats.current <= 0) {
          setLoadingChats(false);
        }
      }, 10000);
    }
  }, [loadingChats]);

  useEffect(() => {
    if(loadingChats){
      activeLoadings.current += 1;
      setTimeout(() => {
        activeLoadings.current -= 1;
        if (activeLoadings.current <= 0) {
          setLoading(false);
        }
      }, 10000);
    }
  }, [loading]);


  useEffect(() => {
    isTemplateSendRef.current = isTemplateSend;
  }, [isTemplateSend]);

  useEffect(() => {
    isAssignedAgentRef.current = isAssignedAgent;
  }, [isAssignedAgent]);


  const resetTimer = () => {
    clearTimeout(popupTimer.current);
    popupTimer.current = setTimeout(() => {
      console.log("Dentro del popup")
      setInactivityPopup(true);
    }, 240 * 60 * 1000); // 240 minutos en milisegundos (4 horas)
  };

  const scrollToBottom = (behavior = "smooth") => {
    behavior = "auto";
    chatEndRef.current?.scrollIntoView({ behavior });
  };


  const callData = async (phoneId, play=true, permissions = []) => {
    try{
      if (permissions.includes("interestedBuying")) {
        setIsInterestedBuying(true)
      };
      if (permissions.includes("feelings")) setIsFeeling(true);
      
      const url = urlAPI_1 + "fetchMessagesByPhone/" + phoneId;
      const options = { method: "GET", mode: "cors"};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();

      if (permissions.includes("interestedBuying")) setIsInterestedBuying(false);
      if (permissions.includes("feelings")) setIsFeeling(false);

      if(!result.dataRet || !result.dataRet.dataConversation.length){
        setListMessages((prevMessage) => { return [] });
        setIsBlurred(false);
        setLoadingMessages(false);
        return;
      }
  
      if (permissions.includes("feelings")) setFeeling(result.dataRet.dataConversation[0].feeling)
      if (permissions.includes("interestedBuying")) setInterestedBuying([]);

      const listMessagess = result.dataRet.dataConversation[0].messagesList;
      const chatContext = localStorage.getItem("CHAT_SELECTED");
      if (phoneId.slice(-10) == chatContext.slice(-10)) {
        console.log('😎😎😎😎😎😎😎😎😎😎😎😎😎😎: ', result.dataRet.dataConversation[0]);
        if (result.dataRet.dataConversation[0].statusConv == 1 && permissions.includes("interestedBuying")) {
          detectInterestedBuying()
        }

        if (result.dataRet.dataConversation[0].statusConv == 1 && permissions.includes("feelings")) {
          detectFeelings()
        }
        try{
          await mergeMessages(phoneId, listMessagess, result.dataRet.dataConversation[0].userAsign, result.dataRet.dataConversation[0].statusConv);
          if(listMessagess[listMessagess.length - 1].originMessage == "user"){
            setTimerConectionChat(0);
          }
        } catch (error) {
            console.error("Error callData:", error);
            setIsBlurred(false);
        }
      } else {
        console.log("Chat diferente: ", phoneId, chatContext);
      }
      if(play){
          playSound("highVol");
      }
      let need_update = listUserChatsRef.current.filter(row => row.phoneNumber.slice(-10) == phoneId.slice(-10));
      if(!need_update.length){
        updateConversationsQueue.current.push("callData");
        //updateConversations();
      }else{
        const updatedChats = listUserChatsRef.current.map((row) => {
          // Actualizo los valores necesarios (para no pisar tags, userAssign y demás... )
          if(row.phoneNumber.slice(-10) === phoneId.slice(-10)){
            row.typeMessUlt = result.dataRet.dataConversation[0].typeMessUlt;
            row.messUlt = result.dataRet.dataConversation[0].messUlt;
            row.timerConection = result.dataRet.dataConversation[0].timerConection;
            row.lastTimestamp = result.dataRet.dataConversation[0].lastTimestamp;
            row.lastDate = result.dataRet.dataConversation[0].lastDate;
            row.messagesList = result.dataRet.dataConversation[0].messagesList;
            row.statusConv = result.dataRet.dataConversation[0].statusConv;
            row.feeling = result.dataRet.dataConversation[0].feeling;
          }
          return row;  
        });

        setListUserChats(updatedChats);
      }
    }catch(error){
      console.log("Error: ", error);
      setLoadingMessages(false);
    }
  };

  const replaceMediaFiles = async (data, phoneNumber, reference) => {
    let array__media_urls = [];
    for(let file of data){
      array__media_urls.push(file.url);
    }
    if(array__media_urls.length == 0){
      return;
    }
    
    if(array__media_urls.length == 1){
      array__media_urls = array__media_urls[0];
    }

    let aux = listUserChatsRef.current.filter(row => row.phoneNumber.slice(-10) == phoneNumber.slice(-10));
    if(!aux.length){
        return;
    }

    let messages = [];
    aux[0].messagesList.forEach((msg) => {
        if(msg.reference === reference){
            msg.bodyMedia = array__media_urls;
        }
        messages.push(msg);
    });

    let current_number = localStorage.getItem("CHAT_SELECTED");
    if(current_number.slice(-10) == phoneNumber.slice(-10)){
      setListMessages((prevMessage) => { return messages });
    }
  }


  const mergeMessages = async (phoneId, listMessagess, userAsign, statusConv) => {
      let aux = listUserChatsRef.current.filter(row => row.phoneNumber.slice(-10) == phoneId.slice(-10));
      if(!aux.length){
          return;
      }
      let phone_chat = localStorage.getItem("CHAT_SELECTED");
      let last_message = listMessagess.slice(-1).pop();
      if(phone_chat.slice(-10) == phoneId.slice(-10)){
        // PRIMERO
        // elimino los mensajes sin messageId (son los que se agregan sin saber la confirmacion de exito)
        // que tengan un timestamp mayor a 30 segundos.
        let messages = [];
        let date_aux = new Date();
        let timestamp_aux = Math.floor(date_aux.getTime());
        /*
        console.log("------------------------------------------------------------------------------------------------");
        console.log("listMessagess: ", listMessagess);
        console.log("aux[0].messagesList: ", aux[0].messagesList);
        console.log("timestamp_aux: ", timestamp_aux);
        console.log("------------------------------------------------------------------------------------------------");
        */
        aux[0].messagesList.forEach((msg) => {
            if(msg.messageId || msg.timestamp + 30 < timestamp_aux){
                messages.push(msg);
            }
        });

        // SEGUNDO
        // Se eliminan los mensajes que tienen messageId duplicado.
        let mergedArray = [...listMessagess, ...messages].reduce((uniqueMessages, message) => {
          const isDuplicate = uniqueMessages.some(
            (msg) => msg.messageId === message.messageId && message.messageId !== undefined && message.messageId !== ""
          );

          if (!isDuplicate) {
            uniqueMessages.push(message);
          }

          return uniqueMessages;
        }, []);
        

        let groupedImages_pending = mergedArray.filter((msg) => {
          return msg.bodyType == "groupedImages" && !msg.messageId;
        });

        let groupedImages = mergedArray.filter((msg) => {
          return msg.bodyType == "groupedImages" && msg.messageId;
        });

        let other_messages = mergedArray.filter((msg) => {
          return msg.bodyType != "groupedImages";
        });

        let dict_urls_media = [];
        groupedImages.forEach((msg) => {
          // Chequeao y elimino elementos de groupedImages_pending
          groupedImages_pending = groupedImages_pending.filter((pendingMsg) => {
            // Verifico que todos los elementos de msg.bodyMedia estan en pendingMsg.bodyMedia
            const allMatch = msg.bodyMedia.every((media) => pendingMsg.bodyMedia.includes(media));
            // Verifico que tengan la misma longitud
            const sameLength = msg.bodyMedia.length === pendingMsg.bodyMedia.length;
            // Si todos coinciden y tienen la misma longitud, lo elimino.
            return !(allMatch && sameLength);
          });
          msg.bodyMedia.forEach((media_url) =>{
            dict_urls_media.push(media_url);
          })
        });


        groupedImages_pending.forEach((msg) => {
          // Chequeao y elimino elementos de groupedImages
          groupedImages = groupedImages.filter((groupedImage) => {
            // si un elemento está en groupedImages entonces lo elimino de groupedImages porque aun no esta completo...
            const match = msg.bodyMedia.some((media) => groupedImage.bodyMedia.includes(media));
            return !match;
          });
        });


        // Obtenemos todas las urls de las imagenes agrupadas.
        let dict_urls_media_pending = [];
        groupedImages_pending.forEach((msg) => {
          msg.bodyMedia.forEach((media_url) =>{
            dict_urls_media_pending.push(media_url);
          })
        })

        mergedArray = [...groupedImages_pending, ...groupedImages, ...other_messages];
        console.log("mergedArray: ", mergedArray);
        console.log("dict_urls_media: ", dict_urls_media);
        messages = mergedArray.filter((msg, index, self) => {
          // Si la imagen ya está en groupedImages la descarto
          if(msg.bodyType == "image" && !msg.messageId && dict_urls_media.includes(msg.bodyMedia)){
            return false;
          }
          if(msg.bodyType == "image" && msg.messageId){
            if(dict_urls_media_pending.includes(msg.bodyMedia)){
              return false;
            }
            if(dict_urls_media.includes(msg.bodyMedia)){
              return false;
            }
            console.log("msg.bodyMedia: ", msg.bodyMedia)
          }

          if(msg.bodyType == "groupedImages" && !msg.messageId){
            return true
          }

          // Filtro solo los mensajes con reference undefined o ""
          if (msg.reference === undefined || msg.reference === "" || msg.messageId) {
            // Si no tiene reference, lo mantengo. Lo mismo si tiene messageId.
            return true;
          } else {
            // Obtengo los mensajes duplicados basados en reference
            const duplicates = self.filter((m) => m.reference === msg.reference);
            // Si hay más de uno con la misma referencia, busco el que tenga messageId y mantenerlo
            if (duplicates.length > 1) {
              //const msgWithId = duplicates.find((m) => m.messageId !== undefined);
              //return msgWithId === msg; // Mantener solo el mensaje con messageId
              return false;
            } else {
              // Todavia no llego el mensaje guardado...
              return true;
            }
          }
        });

        
        // filtro los que estan programados y repetidos. 
        let messages_filtered = messages.filter((msg, index, self) => {
          if(msg.messageId.includes("wamid_pending_by_scheduled") && msg.reference){
            // busco duplicados por reference
            const duplicates = self.filter((m) => m.reference === msg.reference);
            if (duplicates.length > 1) {
              return false;
            } else {
              return true;
            }
          }else{
            return true;
          }
        });

        messages_filtered.sort((a, b) => {
          if (a.timestampInt < b.timestampInt) {
            return -1;
          }
          if (a.timestampInt > b.timestampInt) {
            return 1;
          }
          if (a.timestampInt === b.timestampInt) {
            if (a._id < b._id) {
              return -1;
            }
            return 1;
          }
        });

        console.log("messages: ", messages);
        let messages_filtered_current_phone = messages_filtered.filter(row => row.phoneNumber.slice(-10) === phoneId.slice(-10));

        setListMessages((prevMessage) => { return messages_filtered_current_phone });
        last_message = messages_filtered_current_phone.slice(-1).pop();
        aux[0].messagesList = messages_filtered;
      }
      else{
        aux[0].messagesList = [last_message];
      }

      // Actualizo fecha de mensaje y ordeno arriba
      aux[0].lastDate = last_message.timestamp;
      if(last_message.bodyType == "text" || last_message.bodyType == "transfer"){
        aux[0].messUlt = last_message.bodytext;
        aux[0].typeMessUlt = "text";
      }else if(last_message.bodyType == "document"){
        aux[0].messUlt = last_message.bodytext ? last_message.bodytext : "Document";
        aux[0].typeMessUlt = "document";
      }else if(last_message.bodyType == "image"){
        aux[0].messUlt = last_message.bodytext ? last_message.bodytext : "Imagen";
        aux[0].typeMessUlt = "image";
      }else if(last_message.bodyType == "video"){
        aux[0].messUlt = last_message.bodytext ? last_message.bodytext : "Video";
        aux[0].typeMessUlt = "video";
      }else if(last_message.bodyType == "audio"){
        aux[0].typeMessUlt = "audio";
        aux[0].messUlt = last_message.bodytext ? last_message.bodytext : "Audio";
      }

      // Actualizo estado y agente asignado en las conversaciones:
      aux[0].userAsign = userAsign;
      aux[0].statusConv = statusConv;

      listUserChatsRef.current.forEach((row)=>{
        if(row.phoneNumber != phoneId){
          aux.push(row);
        }
      })
      setListUserChats((listUserChats) => { return aux });
  }

  const callDataSend = async (phoneId) => {
    const url = urlAPI_1 + "fetchMessagesByPhone/" + phoneId;
    const options = { method: "GET", mode: "cors"};
    const response = await fetchWithAuth(url, options);
    const result = await response.json();
    try{
      if(!result.dataRet || !result.dataRet.dataConversation.length){
        setListMessages((prevMessage) => { return [] });
        setIsBlurred(false);
        setLoadingMessages(false);
        return;
      }
      const listMessagess = result.dataRet.dataConversation[0].messagesList;
      await mergeMessages(phoneId, listMessagess, result.dataRet.dataConversation[0].userAsign, result.dataRet.dataConversation[0].statusConv);
    }catch(error){
      setLoadingMessages(false);
    }

  };

  const callDataUpdateStatusAndAssign = async (data) => {
    console.log("data: ", data);
    console.log("listUserChatsRef.current: ", listUserChatsRef.current);
    console.log('-----------------------------------✅callDataUpdateStatusAndAssign✅-------------------------------------------')
    let new_array_conversations_filtered = [];

    listUserChatsRef.current.forEach((row) => {
      if(data.phoneNumber.slice(-10) == row.phoneNumber.slice(-10)){
        row.statusConv = data.status;
        if(data.status == "2"){
          row.userAsign = "";
        }
      }
      new_array_conversations_filtered.push(row);
    })

    if(filterRef.current == "mine" || filterRef.current == "pending"){
      new_array_conversations_filtered = new_array_conversations_filtered.filter((row)=>{
        if(row.statusConv != "2" && row.userAsign != "chatbot" && row.userAsign != "bot"){
          return true;
        }else{
          return false;
        }
      })
    }

    new_array_conversations_filtered.sort((a, b) => {
      if (a.lastTimestamp > b.lastTimestamp){
          return -1;
      }
      return 1;
    });

    let array_lists_chats = [
      {name: listChats, set: setListChats},
      {name: listUserChats, set: setListUserChats},
      {name: listChatsAll, set: setListChatsAll}
    ]
    array_lists_chats.forEach((elem) => {
        elem.set((prevMessage) => { return new_array_conversations_filtered });
    });

    const url = urlAPI_1 + "fetchStatusAndAssignments/" + numberPage;

    try {
      const options = { method: "GET", mode: "cors"};
      const response = await fetchWithAuth(url, options);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      let phone_chat = localStorage.getItem("CHAT_SELECTED").slice(-10);
      const { dataRet } = await response.json();
      const dataByPhone = dataRet.dataByPhone;
      let timerConection = 0;
      let updatedListUserChats = listUserChatsRef.current.map((row) => {
        if (row.phoneNumber.slice(-10) in dataByPhone) {
          const {
            userAsign,
            statusConv,
            areaAsign
          } = dataByPhone[row.phoneNumber.slice(-10)];
          if (phone_chat == row.phoneNumber.slice(-10)){
            timerConection = row.timerConection;
          }

          return {
            ...row,
            userAsign,
            statusConv,
            areaAsign,
          };
        }
        return row;
      });

      if (phone_chat in dataByPhone) {
        // Seteo el status.
        setStatusChat((prevMessage) => { return dataByPhone[phone_chat].statusConv });
        if(dataByPhone[phone_chat].statusConv == "1"){
          setChatClose((prevMessage) => { return true});
        }else if(dataByPhone[phone_chat].statusConv == "2"){
          setChatClose((prevMessage) => { return false});
        }

        // Seteo si debe enviar template o no.
        if (timerConection >= 24) {
          setIsTemplateSend((prevMessage) => { return false});
        } else {
          setIsTemplateSend((prevMessage) => { return true});
        }

        // Seteo si tiene agente asignado o no.
        if (dataByPhone[phone_chat].userAsign && dataByPhone[phone_chat].userAsign != "chatbot" && dataByPhone[phone_chat].userAsign != "bot") {
          setIsAssignedAgent((prevMessage) => { return false});
        } else {
          setIsAssignedAgent((prevMessage) => { return true});
          setIsTemplateSend((prevMessage) => { return false});
        }
      }
      if(filterRef.current == "pending"){
        // si estan cerrados o sin asignar los sacamos de pendientes
        let role_admin = (roleId == "6284fdd697029c6d9743ccb3" || roleId == "64886b1b250f9d31bf2ed68d");
        let role_agent = !role_admin;
        let agent_id = localStorage.getItem("USER_ID");
        updatedListUserChats = updatedListUserChats.filter((row) => {
          if(role_admin && row.statusConv == '3' && row.userAsign !== 'chatbot' && row.userAsign != 'bot'){
            return true;
          }else if(role_agent && row.statusConv == '3' && (row.userAsign == '' || row.userAsign == agent_id)){
            return true;
          }else{
            return false;
          }
        })
      }
      setListUserChats((prevMessage) => { return updatedListUserChats });

      /*
      if(phone_chat == data.phoneNumber.slice(-10)){
        onChatSelected(data.phoneNumber);
      }
      */

      getConversationsCount();
    } catch (error) {
      console.error("Error:", error);
    }
  };


  const checkForUpdateConversations = async () => {
    if(updateConversationsQueue.current.length){
      console.log("updateConversationsQueue.current: ", updateConversationsQueue.current);
    }
    if(updateConversationsQueue.current.length && !updateConversationsQueueControl.current){
      updateConversationsQueueControl.current = true;
      try{
        if (updateConversationsQueue.current.includes("onMessageProcess")) {
          updateConversationsQueue.current = [];
          await updateConversations(true);
          updateConversationsQueueControl.current = false;
        }else{
          updateConversationsQueue.current = [];
          await updateConversations();
          updateConversationsQueueControl.current = false;
        }
      } catch (error) {
        updateConversationsQueueControl.current = false;
        console.error("Error:", error);
      }     
    }
  }

  const updateConversations = async (play_sound=false) => {
    console.log("(updateConversations) filterByStatusAndTagsRef.current: ", filterByStatusAndTagsRef.current);
    let url = urlAPI_1 + "fetchMessages" + "?page=1" + "&origin=frontend-v2";
    switch(filterRef.current) {
      case 'mine':
        url = urlAPI_1 + "fetchMessages" + "?page=1&list=asign" + "&origin=frontend-v2";
        break;
      case 'all':
        url = urlAPI_1 + "fetchMessages" + "?page=1" + "&origin=frontend-v2";
        break;            
      case 'onHold':
        url = urlAPI_1 + "fetchMessages" + "?page=1&list=noAsign" + "&origin=frontend-v2";
        break;
      case 'bots':
        url = urlAPI_1 + "fetchMessages" + "?page=1&list=bot" + "&origin=frontend-v2";
        break;
      case 'pending':
        url = urlAPI_1 + "fetchMessages" + "?page=1&list=pending" + "&origin=frontend-v2";
        break
      default:
        url = urlAPI_1 + "fetchMessages" + "?page=1" + "&origin=frontend-v2";
        break;
    }
    if (filterByStatusAndTagsRef.current) {
      url += `&filter=${filterByStatusAndTagsRef.current}`;
    };
    await fetchConversations(url, false);
    if(play_sound){
      playSound("highVol");
    }
  }

  const getContactConversation = async (phoneNumber) => {
    const url = urlAPI_1 + "fetchMessages?phoneNumber=" + `${phoneNumber}` + "&origin=frontend-v2";
    const result = await fetchConversations(url, false);
    return result;
  }

  //Funcion que obtiene las conversaciones con sus primeros chats.
  const getConversations = async (other_filter = false) => {
    try{
      console.log("other_filter: ", other_filter);
      setLoading(true);
      setLoadingChats(true);
      while(updateConversationsQueueControl.current) return;
      updateConversationsQueueControl.current = true;
      setFilter("all");
      filterRef.current = "all";
      // Variables para utilizar en html, guardaran el mismo status.
      setNumberPageConversations(1);
      setTotalPagesConversations(2);
      let url = urlAPI_1 + "fetchMessages" + "?page=1" + "&origin=frontend-v2";
      if(other_filter){
        url += "&filter=" + other_filter;
      }
      setListUserChats((listUserChats) => { return [] });
      await fetchConversations(url);
      setLoadingChats(false);
      updateConversationsQueueControl.current = false;
    } catch (error) {
      setLoadingChats(false);
      console.error("Error:", error);
      updateConversationsQueueControl.current = false;
    }finally{
      setLoading(false);
    }
    return;
  };

  //Funcion que obtiene la lista de chats asignados a mi
  const getConversationsAsign = async (other_filter = false) => {
    try{
      setLoading(true);
      setLoadingChats(true);
      while(updateConversationsQueueControl.current) return;
      updateConversationsQueueControl.current = true;    
      console.log("Ejecutando getConversations asign.......");
      setFilter("mine");
      filterRef.current = "mine";
      // Variables para utilizar en html, guardaran el mismo status.
      setNumberPageConversations(1);
      setTotalPagesConversations(2);
      let url = urlAPI_1 + "fetchMessages" + "?page=1&list=asign" + "&origin=frontend-v2";
      if(other_filter){
        url += "&filter=" + other_filter;
      }
      setListUserChats((listUserChats) => { return [] });
      await fetchConversations(url);
      updateConversationsQueueControl.current = false;
    } catch (error) {
      console.error("Error:", error);
      updateConversationsQueueControl.current = false;
    }finally{
      setLoading(false);
      setLoadingChats(false);
    }      
    return;
  };

  const getConversationsPending = async (other_filter = false) => {
    try{
      setLoading(true);
      setLoadingChats(true);
      while(updateConversationsQueueControl.current) return;
      updateConversationsQueueControl.current = true;    
      // listUserChatsRef.current = [];
      console.log("........................................");
      console.log("Ejecutando getConversations pending.......");
      console.log("........................................");
      setFilter("pending");
      filterRef.current = "pending";
      // Variables para utilizar en html, guardaran el mismo status.
      setNumberPageConversations(1);
      setTotalPagesConversations(2);
      let url = urlAPI_1 + "fetchMessages" + "?page=1&list=pending" + "&origin=frontend-v2";
      if(other_filter){
        url += "&filter=" + other_filter;
      }
      setListUserChats((listUserChats) => { return [] });
      await fetchConversations(url);
      updateConversationsQueueControl.current = false;
    } catch (error) {
      console.error("Error:", error);
      updateConversationsQueueControl.current = false;
    }finally{
      setLoading(false);
      setLoadingChats(false);
    } 
    return;
  };

  const getConversationsNoAsign = async (other_filter = false) => {
    try{
      setLoading(true);
      setLoadingChats(true);
      while(updateConversationsQueueControl.current) return;
      updateConversationsQueueControl.current = true;
      //listUserChatsRef.current = [];
      console.log("Ejecutando getConversations No asign.......");
      setFilter("onHold");
      filterRef.current = "onHold";
      // Variables para utilizar en html, guardaran el mismo status.
      setNumberPageConversations(1);
      setTotalPagesConversations(2);
      let url = urlAPI_1 + "fetchMessages" + "?page=1&list=noAsign" + "&origin=frontend-v2";
      if(other_filter){
        url += "&filter=" + other_filter;
      }
      setListUserChats((listUserChats) => { return [] });
      await fetchConversations(url);
      updateConversationsQueueControl.current = false;
    } catch (error) {
      console.error("Error:", error);
      updateConversationsQueueControl.current = false;
    }finally{
      setLoading(false);
      setLoadingChats(false);
    }    
    return;
  };

  const getConversationsBots = async (other_filter = false) => {
    try{
      setLoading(true);
      setLoadingChats(true);
      while(updateConversationsQueueControl.current) return;
      updateConversationsQueueControl.current = true;    
      //listUserChatsRef.current = [];
      console.log("Ejecutando getConversations Bots.......");
      setFilter("bots");
      filterRef.current = "bots";
      // Variables para utilizar en html, guardaran el mismo status.
      setNumberPageConversations(1);
      setTotalPagesConversations(2);
      let url = urlAPI_1 + "fetchMessages" + "?page=1&list=bot" + "&origin=frontend-v2";
      if(other_filter){
        url += "&filter=" + other_filter;
      }
      setListUserChats((listUserChats) => { return [] });
      await fetchConversations(url);
      updateConversationsQueueControl.current = false;
    } catch (error) {
      console.error("Error:", error);
      updateConversationsQueueControl.current = false;
    }finally{
      setLoading(false);
      setLoadingChats(false);
    } 
    return;
  };


  const fetchConversations = async (url, updatePages=true) => {
    if(conversationsListRef.current){
      previousScrollTopRef.current = conversationsListRef.current.scrollTop;
      previousScrollHeightRef.current = conversationsListRef.current.scrollHeight;
    }
    /*
    // Control para evitar múltiples llamadas. 
    if(!fetchMessagesControl.current){
      fetchMessagesControl.current = true;
      fetchMessagesUrlControl.current = url;
    }else if(fetchMessagesUrlControl.current != url){
      fetchMessagesControl.current = true;
      fetchMessagesUrlControl.current = url;
    }
    else{
      return []; 
    }
    */
    
    try {
      const options = { method: "GET", mode: "cors"};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      fetchMessagesControl.current = false;
      fetchMessagesUrlControl.current = "";
      let pageMatch = url.match(/page=(\d+)/);
      if (pageMatch) {
          let page = parseInt(pageMatch[1]);
          if(page > numberPageConversations){
            console.log("page > numberPageConversation!!");
            return;
          }
      }
      if (result.code === 0) {
        sendToast(result)
      } else if (!result.dataRet) {
        setListChats([]);
        setListUserChats((listUserChats) => { return [] });
      } else {
        let pager = result.dataRet.pager;
        appendNewChats(result.dataRet.dataConversation);
        setListStatus(result.dataRet.statuses);
        if(updatePages){
          setNumberPageConversations((numberPageConversations) => { return (numberPageConversations + 1)});
          setTotalPagesConversations((totalPagesConversations) => { return result.dataRet.pager.totalPages });
          let text_conversations = `${pager.page * pager.pageSizeConversations}` + " de " + `${pager.totalDocs}` + " conversaciones";
          if(pager.page * pager.pageSizeConversations > pager.totalDocs){
            text_conversations = `${pager.totalDocs}` + " de " + `${pager.totalDocs}` + " conversaciones";
          }
          setTextConversations((textConversations) => { return text_conversations});
        }
        return result.dataRet.dataConversation;
      }
    } catch (error) {
      console.log("Error en fetchConversations: ", error);
      fetchMessagesControl.current = false;
      fetchMessagesUrlControl.current = "";
      return []
    } finally {
      setLoadingChats(false);
    }
    getConversationsCount();
  };

  const appendNewChats = async (dataConversation) => {
        let phone_chat = localStorage.getItem("CHAT_SELECTED");
        let array_lists_chats = [
            {name: listChats, set: setListChats},
            {name: listUserChats, set: setListUserChats},
            {name: listChatsAll, set: setListChatsAll}
        ]
        if(!listUserChatsRef.current.length){
            // Esto seria al principio, carga inicial.
            array_lists_chats.forEach((elem) => {
                elem.set((prevMessage) => { return dataConversation });
            });
            return;
        }
        let data_to_update = [];
        const chats_set_numbers = new Set();
        // para la conversacion actualmente abierta actualiza ListMessages.
        dataConversation.forEach((row) => {
            chats_set_numbers.add(row.phoneNumber);
            if(row.phoneNumber.slice(-10) != phone_chat.slice(-10)){
                data_to_update.push(row);
            }else{
                // En el caso en que tengamos que actualizar los mensajes de la conversacion actual.
                let aux = listUserChatsRef.current.filter(row => row.phoneNumber.slice(-10) == phone_chat.slice(-10));
                if(!aux.length){
                  // Cuando es una nueva conversacion iniciada desde el agente/admin...
                  data_to_update.push(row);
                  return;
                }
                // elimino los mensajes sin messageId (son los que se agregan sin saber la confirmacion de exito)
                let messages = [];

                aux[0].messagesList.forEach((msg) => {
                  if(msg.messageId){
                      messages.push(msg);
                  }else{
                    let date_aux = new Date();
                    let timestamp_aux = Math.floor(date_aux.getTime());
                    // Si pasaron mas de 10 segundos lo elimino...
                    // Si pasaron menos de 10 segundos lo agrego y luego reviso si lo mantengo segun el reference.
                    if(msg.timestamp + 20 < timestamp_aux){
                      messages.push(msg);
                    }
                  }
                });

                console.log("messages: ", messages);
                console.log("row.messagesList: ", row.messagesList);
                const mergedArray = [...messages, ...row.messagesList].reduce((uniqueMessages, message) => {
                  const isDuplicate = uniqueMessages.some(
                    (msg) => msg.messageId === message.messageId && message.messageId !== undefined && message.messageId !== ""
                  );

                  if (!isDuplicate) {
                    uniqueMessages.push(message);
                  }

                  return uniqueMessages;
                }, []);

                console.log("mergedArray: ", mergedArray);

                let groupedImages = mergedArray.filter((msg) => {
                  return msg.bodyType == "groupedImages" && msg.messageId;
                });

                let dict_urls_media = [];
                groupedImages.forEach((msg) => {
                  msg.bodyMedia.forEach((media_url) =>{
                    dict_urls_media.push(media_url);
                  })
                })

                messages = mergedArray.filter((msg, index, self) => {
                  // Filtro solo los mensajes con reference undefined o ""
                  // Si la imagen ya está en groupedImages la descarto!!!
                  if(msg.bodyType == "image" && dict_urls_media.includes(msg.bodyMedia)){
                    return false;
                  }
                  if (msg.reference === undefined || msg.reference === "" || msg.messageId) {
                    // Si no tiene reference, lo mantengo. Lo mismo si tiene messageId.
                    return true;
                  } else {
                    // Obtengo los mensajes duplicados basados en reference
                    const duplicates = self.filter((m) => m.reference === msg.reference);
                    // Si hay más de uno con la misma referencia, busco el que tenga messageId y mantenerlo
                    if (duplicates.length > 1) {
                      const msgWithId = duplicates.find((m) => m.messageId !== undefined);
                      return msgWithId === msg; // Mantener solo el mensaje con messageId
                    } else {
                      // Todavia no llego el mensaje guardado...
                      return true;
                    }
                  }
                });

                

                row.messagesList = messages;
                console.log("Se va a actualizar listMessages: ", messages);
                const textarea = document.getElementById('myTextarea');
                // Verifico si el textarea tiene el foco (cursor dentro)
                if (document.activeElement === textarea) {
                  // En ese caso hago focus en el ultimo mensaje. En otro caso no.
                  console.log("Focusing in input");
                  focusLastMessageControlByInputFocus.current = true;
                }
                // Reviso la posicion del scroll.
                checkScrollPosition();

                if(messages[messages.length - 1].originMessage == "user"){
                  setTimerConectionChat(0)
                }

                let messages_filtered = messages.filter(row => row.phoneNumber.slice(-10) === phone_chat.slice(-10));
                setListMessages((prevMessage) => { return messages_filtered });
                data_to_update.push(row);
            }
        })


        let new_array_conversations = [];
        listUserChatsRef.current.forEach((row_conv) => {
          if(chats_set_numbers.has(row_conv.phoneNumber)){
            return;
          }else{
            new_array_conversations.push(row_conv);
          }
        })

        new_array_conversations = [...new_array_conversations, ...data_to_update];

        let new_array_conversations_filtered = [];
        new_array_conversations.forEach((row) => {
          // Si no es admin filtramos por area y asignacion.
          if(roleId != "6284fdd697029c6d9743ccb3" && roleId != "64886b1b250f9d31bf2ed68d" && row.userAsign != "chatbot"){
            if((row.areaAsign && userArea && row.areaAsign == userArea) || (!row.areaAsign || !userArea)){
                // Filtra por area cuando tiene definido un area tanto el chat como el agente y matchean.
                // Pero tambien si el chat no tiene area asignado o el usuario no tiene area asignada.
                if(row.userAsign && row.userAsign == userLogout){
                  new_array_conversations_filtered.push(row);
                }else if(!row.userAsign){
                  // conversacion sin asignar pero que son de area.
                  new_array_conversations_filtered.push(row);
                }
            }
          }else{
            new_array_conversations_filtered.push(row);
          }

        });

        new_array_conversations_filtered.sort((a, b) => {
          if (a.lastTimestamp > b.lastTimestamp){
              return -1;
          }
          return 1;
        });
        console.log("Updating from appendNewChats...")
        console.log("new_array_conversations_filtered: ", new_array_conversations_filtered);
        array_lists_chats.forEach((elem) => {
            elem.set((prevMessage) => { return new_array_conversations_filtered });
        });
  };

  const removeConversationByAgentAreaChange = async(data) => {
    let new_array_conversations_filtered = [];
    let phone_current_chat = localStorage.getItem("CHAT_SELECTED");
    let agent_id = localStorage.getItem("USER_ID");

    listUserChatsRef.current.forEach((row) => {
      if(data.phoneNumber.slice(-10) == row.phoneNumber.slice(-10)){
        row.userAsign = data.userAsign;
        row.areaAsign = data.areaAsign;
      }

      if(roleId != "6284fdd697029c6d9743ccb3" && roleId != "64886b1b250f9d31bf2ed68d" && data.phoneNumber.slice(-10) == row.phoneNumber.slice(-10)){
        // uso data en lugar de row porque como posiblemente haya cambiado de agente o area va a tener informacion d
        if((data.areaAsign && userArea && data.areaAsign == userArea) || (!data.areaAsign || !userArea)){
            // Filtra por area cuando tiene definido un area tanto el chat como el agente y matchean.
            // Pero tambien si el chat no tiene area asignado o el usuario no tiene area asignada.            
            if(data.userAsignId && data.userAsignId == agent_id){
              new_array_conversations_filtered.push(row);
            }else if(!data.userAsignId){
              // conversacion sin asignar pero que son de area.
              new_array_conversations_filtered.push(row);
            }else{
              if(row.phoneNumber.slice(-10) == phone_current_chat.slice(-10)){
                // Debo sacarlo de la conversacion.
                setStartChat((prevMessage) => { return false });
                setTemplateSelected((prevMessage) => { return [] });
                setIsChatSelected((prevMessage) => { return false });
                setNotChatSelected((prevMessage) => { return true });
                setShowContactInfo((prevMessage) => { return false });
                setIsBlurred((prevMessage) => { return false });
                sendToast({type: "info", message: "La conversación fue tomada por otro agente"})
              }
            }
            
        }else{
          if(row.phoneNumber.slice(-10) == phone_current_chat.slice(-10)){
            // Debo sacarlo de la conversacion.
            setStartChat((prevMessage) => { return false });
            setTemplateSelected((prevMessage) => { return [] });
            setIsChatSelected((prevMessage) => { return false });
            setNotChatSelected((prevMessage) => { return true });
            setShowContactInfo((prevMessage) => { return false });
            setIsBlurred((prevMessage) => { return false });

            sendToast({type: "info", message: "La conversación fue tomada por otro agente"})
          }
        }
      }else{
        new_array_conversations_filtered.push(row);
      }
    })
    console.log("new_array_conversations_filtered: ", new_array_conversations_filtered);
    new_array_conversations_filtered.sort((a, b) => {
      if (a.lastTimestamp > b.lastTimestamp){
          return -1;
      }
      return 1;
    });

    let array_lists_chats = [
      {name: listChats, set: setListChats},
      {name: listUserChats, set: setListUserChats},
      {name: listChatsAll, set: setListChatsAll}
    ]
    array_lists_chats.forEach((elem) => {
        elem.set((prevMessage) => { return new_array_conversations_filtered });
    });
  }

  //Funcion que obtiene la lista de agentes segun el area
  // Se modifica para traer administradores tambien
  const getAgents = async () => {
    try {
      const url = urlAPI_2 + "usersbyArea";
      const options = { method: "GET", mode: "cors"};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      if (result.code === 0) {
        sendToast(result)
      } else if (result.message === "Tu petición no tiene cabecera de autorización") {
        sendToast({type: "info", message: "Tu sesion ha caducado, verifica si alguien mas se ha conectado con tus credenciales"})
        navigate("/");
      } else if (result.data.length <= 0) {
        // setValidationModal(true);
        setListAdvisers([]);
        setSearchAdvisers([]);
      } else {
        setListAdvisers(result.data);
        setSearchAdvisers(result.data);
        // setValidationModal(false);
        localStorage.setItem("ID_COMPANY", result.idCompany);
      }
    } catch (error) {
      console.log(error);
    }
  };

  //Funcion que obtiene los campos adicionales
  const getFields = async () => {
    try {
      const url = urlAPI_2 + "aditionalFields";
      const options = { method: "GET", mode: "cors"};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      if(!result.data) {
        setFieldsList([]);
      }else{
        const res = result.data;
        console.log("📑Respuesta de getFields:", result);
        setFieldsList(res);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Función que obtiene todos los usuarios iterando sobre TODA la paginación con manejo de errores
  // No es una simple funcion que llama a una lambda paginada. Itera para ir obteniendo el total de manera paginada. 
/*   const getPersons = async (limit = 300) => {
    try {
      setLoadingContacts(true);
      let USER_ROLEID = localStorage.getItem("USER_ROLEID");
      let USER_ID = localStorage.getItem("USER_ID");
      const url = urlAPI_1 + "personsByAgent";

      let allContacts = [];
      let offset = 0;
      let totalContacts = 0;
      let finished = false;
      let maxIterations = 100; // Límite máximo de iteraciones para evitar bucles infinitos
      let attempts = 0;

      // Bucle para iterar sobre todas las páginas hasta obtener TODOS los contactos
      while (!finished && attempts < maxIterations) {
        try {
          const options = { method: "POST", mode: "cors",
          body: JSON.stringify({
            USER_ROLEID: USER_ROLEID,
            USER_ID: USER_ID,
            limit: limit,
            offset: offset,
          })};
          const response = await fetchWithAuth(url, options);
          const result = await response.json();

          if (result.code === 0) {
            sendToast(result)
            break;
          } else if (result.message === "Tu petición no tiene cabecera de autorización") {
            sendToast({type: "info", message: "Tu sesión ha caducado"})
            navigate("/");
            return;
          } else if (!result.data) {
            break;
          } else {
            allContacts = [...allContacts, ...result.data];
            totalContacts = result.total;          
            offset += limit;
            if (allContacts.length >= totalContacts) {
              finished = true;
            }
          }
        } catch (error) {
          console.log("Error en la solicitud de datos:", error);
          // Opción para reintentar o romper el bucle si hay un error
          sendToast({type: "info", message: "Se produjo un error al obtener los contactos. Inténtalo de nuevo."})
          return;
        }

        attempts++; // Incrementamos el contador de intentos para controlar el máximo de iteraciones
      }

      if (attempts >= maxIterations) {
        console.log("Se alcanzó el límite máximo de iteraciones. La lista no se cargó completamente.");
      }
      setPersonsList(allContacts);
      setSearchPersonslist(allContacts);
      listContactFull.current = allContacts;
      setLoadingContacts(false);
    } catch (error) {
      setLoadingContacts(false);
      console.log("Error general:", error);
      sendToast({type: "info", message: "Ocurrió un error inesperado. Por favor, intenta nuevamente."})
    }
  }; */

  //Funcion que obtiene las plantillas
  const getTemplates = async () => {
    try {
      const url = urlAPI_2 + "templates";
      const options = { method: "GET", mode: "cors"};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      console.log("Plantillas========>>", result);
      const listTemplates = result.data;
      if (result.type !== "success") {
        setTemplates([]);
      } else {
        var templatesList = [];
        const isTemplates = listTemplates.filter(tem => {
          if(tem.status === "APROBADA" && tem.isActive === true){
            return templatesList.push(tem)
          }
        })
        console.log('Lista modificada de plantillas=====>', isTemplates)
        setTemplates(isTemplates);
      }
    } catch (error) {
      console.log("error on actualizar", error);
      return null;
    }
  };

  //Función que obtiene la correspondencia
  const getCorrespondence = async () => {
    try {
      const url = urlAPI_2 + "getDataEmail";
      const options = { method: "GET", mode: "cors"};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      let data = result.data;
      if(data.length) {
        data = data.filter(v => v.field !== "callingCode")
      }
      setCorrespondence(data);
    } catch (error) {
      console.log(error);
    }
  };

  //Evento para agregar emojis al chat
  const onEmojiClick = (event, emojiObject) => {
    setInputStr((prevInput) => prevInput + emojiObject.emoji);
    if (emojiObject) {
      inputRef.current.focus();
      setSelectedEmoji(true);
    }else{
      setSelectedEmoji(false);
    }
  };

  //Evento para agregar emojis a respuestas rápidas
  const onEmojiClick2 = (event, emojiObject) => {
    console.log('emojiObject::', emojiObject)
    setInputStr2((prevInput) => prevInput + emojiObject.emoji);
    // setShowPicker(false);
  };

  // Evento para agregar variable al contenido de la respuesta rápida.
  const onQuickResponse = ({target}) => {
    if (target.value == "") return;
    setInputStr2((prevValue) => prevValue + `{{${target.value}}}`);
    setValue('answerContent', inputStr2 + `{{${target.value}}}`);
  };

  //Evento para agregar emojis a body de flujo
  const onEmojiClick3 = (event, emojiObject) => {
    console.log('emojiObject:::::', emojiObject)
    setTextBodyFlow((prevInput) => prevInput + emojiObject.emoji);
    // setShowPicker(false);
  };

  const connectDispatcher = (update_conversations=true) => {
    if(roleId == "6284fdd697029c6d9743ccb3" || roleId == "64886b1b250f9d31bf2ed68d"){
        connectI(code_msg_admin, update_conversations);
    }else{
      connectI(code_msg_user, update_conversations);
    }
  }

  const connectI = (i, update_conversations=true) => {
    if (socketConn.current) {
      socketConn.current.close();
    }
    let socketConnAux = new WebSocket(urlSocket);
    socketConnAux.addEventListener("open", (e) => {
      console.log("Conectandose a los sockets...");
      const payload = {
        Action: "message",
        Msg: i,
      };
      try {
        socketConnAux.send(JSON.stringify(payload));
      } catch (error) {
        console.log("error webswockets", error);
      }
    });

    socketConnAux.onmessage = async function (event) {
        onMessageProcess(i, event);
    };

    socketConnAux.onclose = function (event) {
      console.log("Cerrando websocket. Estado del websocket: ", event.target.readyState);
      console.log("Condicion para volver a reconectar: ", !closedByInactivity.current && !closedByUnmount.current);
      if(!closedByInactivity.current && !closedByUnmount.current){
        connectDispatcher();
      }
      closedByUnmount.current = false;
    };
    socketConn.current = socketConnAux
    socketConnTimestamp.current = Date.now();
    // Actualizamos conversaciones por si se perdieron actualizaciones en el medio...
    if(update_conversations){
      updateConversationsQueue.current.push("connectI");
      //updateConversations();
    }
  };

  const onMessageProcess = async (i, event) => {
      if(!(event.data)){
        console.log("No se puede procesar el mensaje!!");
        return;
      }
      const dataMessIn = JSON.parse(event.data);
      console.log('-----------------------------------✅onMessageProcess✅-------------------------------------------')
      console.log(dataMessIn);
      const dataType = dataMessIn.type;
      if (dataType) {
        let dataPhone = dataMessIn.phoneNumber;
        if (dataType === "status") {
          const chatContext = localStorage.getItem("CHAT_SELECTED");
          if (dataPhone.slice(-10) === chatContext.slice(-10)) {
            let aux = listUserChatsRef.current.filter(row => row.phoneNumber.slice(-10) == dataPhone.slice(-10));
            if(!aux.length){
                return;
            }
        
            let messages = [];
            aux[0].messagesList.forEach((msg) => {
                if(msg.messageId === dataMessIn.messageId){
                    msg.status = dataMessIn.status;
                }
                messages.push(msg);
            });
            setListMessages((prevMessage) => { return messages });
          }
        } else if (dataType === "message") {
          const chatContext = localStorage.getItem("CHAT_SELECTED");
          console.log('chatContext---->',chatContext);
          
          if (dataPhone.slice(-10) === chatContext.slice(-10)) {
            // detectFeelings()
            if(!(isAssignedAgentRef.current && !isTemplateSendRef.current)){
              setIsTemplateSend(true);
            }
            setChatCloseInactivity((chatClose) => { return false });
            callData(dataPhone, false); // dentro ejecuta tambien updateConversations...
            const textarea = document.getElementById('myTextarea');
            // Verifico si el textarea tiene el foco (cursor dentro)
            if (document.activeElement === textarea) {
              updateRead();
            }else{
              // Verifica la posicion del scroll.
              // Si esta abajo de todo o apenas unos pixeles por encima entonces hago update de los ultimos mensajes leidos.
              checkScrollPosition();
              if(focusLastMessageControlByInputFocus.current && !controlUpdateRead.current){
                controlUpdateRead.current = true;
                updateRead();
                // Evitamos que se dispare un updateRead muchas veces seguidas.
                setTimeout(() => {
                  controlUpdateRead.current = false;
                }, 2000);
              }
            }
          }else{
            updateConversationsQueue.current.push("onMessageProcess")
            //updateConversations(true);
          }
          getConversationsCount();
        } else if (dataType === "app-notification") {
            processNotification(dataMessIn);
        }
      } else {
        const msgSend = {};
        msgSend.connectionId = `${event.data}`;
        msgSend.phone = i;
        msgSend.agent = localStorage.getItem("USER_ID");
        msgSend.status = true;
        console.log("Estableciendo nueva sesión: ", msgSend);
        const url = urlAPI_2 + "saveConnection";
        const options = { method: "POST", mode: "cors", body: JSON.stringify(msgSend)};
        const response = await fetchWithAuth(url, options);
        await response.json();
      }
  }

  const processNotification = async (dataMessIn) => {
    let current_number = "";
    switch(dataMessIn.typeNotification) {
        case 'asignacionConversacion':
          updateConversationsQueue.current.push("processNotification:asignacionConversacion");
          //await updateConversations();
          break;
        case 'changeStatus':
          if(loadingChats){
            while(loadingChats) continue;
            // estaba dando conflicto cuando se cambiaba filterRef.current por actualizacion de chats 
            // y no terminaba de actualizar todos los chats y se llamaba a callDataUpdateStatusAndAssign
            setTimeout(() => {
              callDataUpdateStatusAndAssign(dataMessIn);
            }, 2000);
          }else{
            updateConversationsQueue.current.push("processNotification:changeStatus");
            //await updateConversations();
            await callDataUpdateStatusAndAssign(dataMessIn);
          }
          break;            
        case 'actualizacionDeChats':
            // Actualiza chat segun el estado del mensaje enviado...
            current_number = localStorage.getItem("CHAT_SELECTED");
            if(current_number == dataMessIn.phoneNumber){
                callDataSend(dataMessIn.phoneNumber);
            }
            break;
        case 'removeConversationByAgentAreaChange':
          await removeConversationByAgentAreaChange(dataMessIn);
          updateConversationsQueue.current.push("processNotification:removeConversationByAgentAreaChange");
          //await updateConversations();
          break;
        case 'aditionalFieldsModified': 
          const listContactLocal = listContactFull.current;
          let contacts = [];
          if (personsList.length > 0) {
            contacts = personsList;
          }else{
            contacts = listContactLocal;
          }
          const person = contacts.filter((element) => element.phone.slice(-10) == dataMessIn.phoneNumber.slice(-10));
          if(!person.length){
            break; 
          }
          console.log("persona a actualizar aditionalFields: ", person);
          console.log("dataMessIn: ", dataMessIn);
          for(const key_name in dataMessIn.updated_fields){
            person[key_name] = dataMessIn.updated_fields[key_name];
          }
          showData(person[0]);

          if (contacts === personsList) {
            const updatedPersonsList = personsList.map((contact) =>
              contact.phone.slice(-10) === dataMessIn.phoneNumber.slice(-10)
                ? { ...contact, ...dataMessIn.updated_fields }
                : contact
            );
            setPersonsList(updatedPersonsList);
          } else {
            listContactFull.current = listContactLocal.map((contact) =>
              contact.phone.slice(-10) === dataMessIn.phoneNumber.slice(-10)
                ? { ...contact, ...dataMessIn.updated_fields }
                : contact
            );
          }
          break


        default:
          updateConversationsQueue.current.push("processNotification:default");
          //await updateConversations();
          break;
    }
    checkForTabSwitch();
    getConversationsPendingCount();
    getConversationsAssignedCount();
    return;
  }

  const detectFeelings = async () => {
    setIsFeeling(true)
    let phone_chat = localStorage.getItem("CHAT_SELECTED");
    const url = urlAPI_1 + "detectFeelings/" + phone_chat;
    const options = { method: "GET", mode: "cors"};
    const response = await fetchWithAuth(url, options);
    const result = await response.json();
    console.log('Resul Analisis de sentimientos 😏😏😏😏😏😏 : ', result.data);
    setIsFeeling(false)
    setFeeling(result.data)
  }

  const detectInterestedBuying = async () => {
    setIsInterestedBuying(true);
    let phone_chat = localStorage.getItem("CHAT_SELECTED");
    const url = `${urlAPI_1}interestedBuying/${phone_chat}`;
    const options = { method: "GET", mode: "cors"};
    const response = await fetchWithAuth(url, options);
    const result = await response.json();
    console.log('Analisis de compra ✅✅✅✅✅', result.data);
    setIsInterestedBuying(false);
    setInterestedBuying(result.data);
  };

  //Evento al seleccionar un chat..........................................
  const onChatSelected = async (i, dataMessagesSelParam = [], statusL = false) => {
    try{
        setInputStr('');
        dataFilesRef.current = [];
        focusLastMessageContext.current = false;
        focusLastMessageControl.current = true;
        setIsBlurred(true);
        setMsgIA(false);
        onchatBlurControl.current = true;
        setIdChatSelected(i);
        localStorage.setItem("CHAT_SELECTED", i);
        let dataMessagesSel = [];
        if(!dataMessagesSelParam.length){
          dataMessagesSel = listUserChatsRef.current.filter(row => row.phoneNumber.slice(-10) === i.slice(-10));
        }else{
          dataMessagesSel = dataMessagesSelParam;
        }
        console.log("dataMessagesSel: ", dataMessagesSel);
        if(dataMessagesSel.length){
          localStorage.setItem("CONVERSATION_SELECTED", dataMessagesSel[0].conversationId);
        }else{
          // La conversacion no está cargada.
          console.log("NO Tiene conversacion!!");
          onStartChat(ParamIdContact); // este parametro viene cuando se llama de la seccion de contactos (persons.js)
          setIsBlurred(false);
          return;
        }
        if(dataMessagesSel[0].amount){
          setConversationsAssignedCount((prev) => {prev - 1})
          dataMessagesSel[0].amount = "";
        }

        setNumberPage((prevPage) => {return 1});
        var valContact = {};

        var contact = [];
        var contactObject = {};
        // Validamos si el chat ya tiene o no un agente asignado......
        const {phoneNumber,user,isSaved,statusConv,timerConection,pager,messagesList} = dataMessagesSel[0];
        setContactNameSelected(user)
        setTimerConectionChat(timerConection)
        console.log("timerConection: ", timerConection);
        //#--> Validamos si el chat tiene o no agente asignado
        if (dataMessagesSel[0].userAsign && dataMessagesSel[0].userAsign != "chatbot" && dataMessagesSel[0].userAsign != "bot") {
          setIsAssignedAgent(false);
          if (timerConection >= 24) {
            setIsTemplateSend(false);
            setChatCloseInactivity((chatClose) => { return true });
          } else {
            setIsTemplateSend(true);
            setChatCloseInactivity((chatClose) => { return false });
          }
        } else {
          setIsTemplateSend(false);
          setIsAssignedAgent(true);
        }
        // Validando el permiso de sentimientos o interés de compra
        const permissionsData = dataMessagesSel[0].permissions;
        let permissionsArr = [];

        if (permissionsData.length > 0) {
          permissionsArr = permissionsData.map(permission => {
            return permission.value;
          });

          setPermissions(permissionsArr);
          
          if (permissionsArr.includes("interestedBuying")) setListInterestedBuying(dataMessagesSel[0].interestedBuying);
          if (permissionsArr.includes("feelings")) setListFeelings(dataMessagesSel[0].feelings);
        };

        //Para pintar los datos personales del usuario seleccionado
        const userData = dataMessagesSel[0].userData;
        getLists(userData._id)
        showData(userData);
        formatDataInfo(userData);
        console.log("userData.feelingss: ", userData.feelings);
        
        if (userData.lists) {
          setListsContact(userData.lists)
        }
        setIdContactSelected(userData._id)
        setChatSelected(dataMessagesSel);

        if (listOrders.length > 0) {
          const ordersFiltered = listOrders.filter(({phoneNumber: phoneUserOrder}) => {
            return phoneUserOrder == phoneNumber; 
          });
          setOrdersFilteredInfo(ordersFiltered);
        }

        if (listValuesGlob.length) {
          valContact = listValuesGlob.filter((element) => element.phoneNumber === i);
        } else {
          valContact = listChats.filter(
            (element) => element.phoneNumber === i
          );
        }

        if (
          dataMessagesSel[0].statusConv == "Cerrado" ||
          dataMessagesSel[0].statusConv == "2" ||
          dataMessagesSel[0].statusConv == "En espera" ||
          dataMessagesSel[0].statusConv == "3"
        ) {
          setChatClose(false);
        } else {
          setChatClose(true);
        }
        if (dataMessagesSel.length > 0) {
          contactObject.id = dataMessagesSel[0].phoneNumber;
          contactObject.isSaved = dataMessagesSel[0].isSaved;
          contactObject.status = dataMessagesSel[0].statusConv;
          contactObject.timerConection = dataMessagesSel[0].timerConection;
          contactObject.user = dataMessagesSel[0].user;
        } else {
          if (listUserValuesGlob) {
            contactObject.id = listUserValuesGlob.id;
            contactObject.isSaved = listUserValuesGlob.isSaved;
            contactObject.status = listUserValuesGlob.status;
            contactObject.timerConection = listUserValuesGlob.timerConection;
            contactObject.user = listUserValuesGlob.user;
          }
        }
        contactObject.data = valContact;
        contact.push(contactObject);
        setIsSavedChat(isSaved);
        setStatusChat(statusConv);

        const initialWords = user.split(" ");
        const firstWord = initialWords[0];
        const secondWord = initialWords[1];
        let iconName = "";
        if (secondWord === undefined) {
          iconName = firstWord[0];
        } else {
          iconName = firstWord[0] + secondWord;
        }
        setIsChatSelected(true);
        scrollToBottom("auto");
        setNotChatSelected(false);
        setStartChat(false);
        setNameInitials(iconName);
        setContactname(user);

        setPhoneContact(phoneNumber);
        setTotalPages(pager.totalPages);
        getNotes(phoneNumber);

        setLoadingMessages(true);
        // detectFeelings()
        await callData(i, false, permissionsArr);
        setLoadingMessages(false);
        updateRead();
        if(search){getConversations()}
        setSearchChats("")
        setSearch(false);
        if (sectionChat.current) {
          sectionChat.current.focus();
        }
    }catch(error){
      console.log("Error in 'onChatSelected'");
      setIsBlurred(false);
    }

  };

  const updateRead = async () => {
    if(controlUpdateRead.current){
      return;
    }
    controlUpdateRead.current = true;
    // Evitamos que se dispare un updateRead muchas veces seguidas.
    setTimeout(() => {
      controlUpdateRead.current = false;
    }, 2000);
    console.log("Ejecutando updateRead");
    // update read in getConversations de forma rapida y offline
    updateReadGetConversations();
    // Se actualizan los mensajes despues...
    let phone_chat = localStorage.getItem("CHAT_SELECTED");
    if(!phone_chat){
        return;
    }
    const url = urlAPI_1 + "updateRead/" + phone_chat;
    const options = { method: "GET", mode: "cors"};
    const response = await fetchWithAuth(url, options);
    const result = await response.json();
    getConversationsAssignedCount();

  };

  const updateReadGetConversations = async () => {
    let phone_chat = localStorage.getItem("CHAT_SELECTED");
    let array_lists_chats = [
        {name: listChats, set: setListChats},
        {name: listUserChats, set: setListUserChats},
        {name: listChatsAll, set: setListChatsAll}
    ]
    let new_data = [];
    // eliminamos los chats sin leer.
    listUserChatsRef.current.forEach((row) => {
        if(row.phoneNumber == phone_chat){
            row["amount"] = "";
        }
        new_data.push(row);
    })
    listUserChatsRef.current = new_data;
    array_lists_chats.forEach((elem) => {
        elem.set((prevMessage) => { return new_data });
    });
  };

  const focusOnScrollMessage = (from_var = "default") => {
    let div = document.getElementById("cont-chat");
    if (div) {
      let offset = 40;
      div.scrollTop = div.scrollHeight - focusScrollMessageHeight.current + offset;
    }
  };

  // Verifica la posicion del scroll. Se llama cuando llega un mensaje nuevo.
  // Si esta abajo de todo o apenas unos pixeles por encima entonces
  const checkScrollPosition = () => {
    let div = document.getElementById("cont-chat");
    if (div) {
      const isScrolledToBottom = div.scrollHeight - div.clientHeight <= div.scrollTop + 240;
      if (isScrolledToBottom) {
        focusLastMessageControlByInputFocus.current = true;
      }else{
        focusLastMessageControlByInputFocus.current = false;
      }
    }
  };

  //Evento al hacer scroll dentro de chat para cargar mas mensajes
  const onScrollTop = async (page) => {
    var div = document.getElementById("cont-chat");
    // console.log("Hizo scroll div ..............................", div)
    const currentScrollPos = div.scrollTop;
    if (currentScrollPos === 0 || page === "context" && !focusScrollMessageControl.current) {
      let n = 0;
      if (numberPage < totalPages) {
        console.log("Hizo scroll TOP ..............................")
        console.log(" ..............numberPage actual................", numberPage)
        console.log(" ..............totalPages................", totalPages)
        if (numberPage == 1) {n = 15} else 
        if (numberPage == 2) {n = 30} else 
        if (numberPage == 3) {n = 45} else
        if (numberPage == 4 && page === "context") {
          focusLastMessageContext.current = false;
          sendToast({type: "info", message: "Al parecer el mensaje no es reciente, te recomendamos buscarlo manualmente."})
          return
        }
        setLoadingMessages(true);
        //setIsBlurred(true);
        //onchatBlurControl.current = true;
        focusScrollMessageControl.current = true;
        let new_number_page = numberPage + 1;
        setNumberPage(new_number_page);
        focusScrollMessageHeight.current = div.scrollHeight;
        try {
          let phoneNumber = localStorage.getItem("CHAT_SELECTED");
          // Nos aseguramos de que no intente pedir mensajes de paginas superiores cuando no tiene suficientes mensajes.
          // Esto corrije bug de conversaciones vacias hasta ir a otro y volver al chat. 
          listUserChatsRef.current.forEach((row, index) => {
            if (row.phoneNumber.slice(-10) === phoneNumber.slice(-10)) {
              if(row.messagesList.length <= 10){
                page = 1;
              }
              return;
            }
          });
          if(!listMessages.length){
            console.log("NOT listMessages.length")
            new_number_page = 1; 
            setNumberPage(new_number_page);
          }
          const url = urlAPI_1 + "fetchMessagesByPhone/" + phoneNumber + "?page=" + `${page === "context" ? page+"&n="+n : new_number_page}`;
          console.log("url ..............................", url)
          const options = { method: "GET", mode: "cors"};
          const response = await fetchWithAuth(url, options);
          const result = await response.json();
          setLoadingMessages(false);
          if(!result.dataRet){
            console.log("--------------------> No hay mensajes! (Posible error de perdida de mensajes por paginado)");
            focusScrollMessageControl.current = false;
            setIsBlurred(false);
            onchatBlurControl.current = false;
            setTotalPages((prevPage) => {return totalPages - 1});
            new_number_page -= 1;
            setNumberPage(new_number_page);
            return null;
          }
          const listMessagess = result.dataRet.dataConversation[0].messagesList;
          const mergedArray = [...listMessagess, ...listMessages];
          let mergedArrayFiltered = mergedArray.filter(row => row.phoneNumber.slice(-10) == phoneNumber.slice(-10));
          setListMessages(mergedArrayFiltered);
          setTotalPages(result.dataRet.dataConversation[0].pager.totalPages);
        } catch (error) {
          setLoadingMessages(false);
          console.log("error en actualizar", error);
          focusScrollMessageControl.current = false;
          setIsBlurred(false);
          onchatBlurControl.current = false;
          return null;
        }
      }
      div.scrollTop = 1;
    }else{
      checkScrollPosition();
      if(focusLastMessageControlByInputFocus.current){
        updateRead();
      }

    }
  };
  
  //Función para crear variables de respuestas rápidas. 
  const formatDataInfo = (userData) => {
    let data = {};
    const invalidKeys = ['company', 'created', 'lists', 'tags', '_id'];
    const requiredKeys = {
      email: {value: 'Correo'}, 
      name: {value: 'Nombre'}, 
      lastName: {value: 'Apellido'}, 
      phone: {value: 'Celular'}
    };

    for (const key in userData) {
      if (!invalidKeys.includes(key.toLowerCase())) {
        if (key in requiredKeys) {
          data[requiredKeys[key].value] = userData[key];
        } else {
          data[key] = userData[key];
        }
      }
    }
    setVariablesQuickResponses(data);
  }

  // Reemplaza las variables de las plantillas
  const replaceQuickResponses = (text) => {
    const regex = /\{\{(.*?)\}\}/g;
    let textModified = text.replace(regex, (match, key) => {
      return variablesQuickResponses[key]?.trim() || '';
    });

    return textModified;
  }

  //Funcion para pintar los datos personales de cada usuario
  const showData = (data) => {
    console.log('data:', data);
    let objectData = {};
    let keys = Object.keys(data);
    // Añadir todos los datos recibidos en data a objectData
    for (let i = 0; i < keys.length; i++) {
        let key = keys[i];
        objectData[key] = data[key];
    }
    // Asegurarte de que todos los campos de fieldsList están presentes en objectData
    fieldsList.forEach(field => {
        if (!objectData.hasOwnProperty(field.name)) {
            objectData[field.name] = ''; // Asignar un valor vacío si no está en data
        }
    });
    objectData["userId"] = data._id;
    formPutPerson.reset(objectData, { keepDefaultValues: true });
    setResponsibleContact(data.responsible);
  };

  const getPerson = async (person_id) => {
    try{
      setLoading(true);
      const url = urlAPI_1 + `persons/${person_id}`;
      const options = { method: "GET", mode: "cors"};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      if (result) {
        return result.data;
      }
    }catch(error){
      return null;
    }finally{
      setLoading(false);
    }
  };

  //Evento al seleccionar una persona para iniciar chat
  const onStartChat = async (id, repull=false) => {
/*     const listContactLocal = listContactFull.current;
    var contacts = [];
    if (personsList.length > 0) {
      contacts = personsList
    }else{
      contacts = listContactLocal;
    }
    const person = contacts.filter((element) => element._id == id); */
    let person = await getPerson(id);
    if(!person || (!person && !person.length)){
      if(repull){
        sendToast({type: "info", message: "La persona seleccionada no se encuentra. Reprocesando..."})
        //await getPersons();
        //await onStartChat(id, true);
      }else{
        console.log("La persona seleccionada no se encuentra.")
        console.log("id: ", id);
        sendToast({type: "error", message: "La persona seleccionada no se encuentra."})
      }
      return; 
    }
    console.log("persona seleccionada: ", person);
    const { name, phone, email, callingCode } = person[0];
    setContactNameSelected(name)

    let dataMessagesSel = await getContactConversation(phone);
    //let dataMessagesSel = listUserChatsRef.current.filter(row => row.phoneNumber.slice(-10) === phone.slice(-10));
    getTagContact(callingCode+phone);
    if(dataMessagesSel.length){
      await onChatSelected(callingCode+phone, dataMessagesSel);
    }else{
      console.log('No tiene conversación::::');
      const initialWords = name.split(" ");
      const firstWord = initialWords[0];
      const secondWord = initialWords[1];
      let iconName = "";
      if (secondWord === undefined) {
        iconName = firstWord[0];
      } else {
        iconName = firstWord[0] + secondWord;
      }

      showData(person[0]);
      setStartChat(true);
      setNotChatSelected(false);
      setIsChatSelected(false);
      setContactname(name);
      setEmailContact(email);
      setPhoneContact(callingCode+phone);
      localStorage.setItem("CHAT_SELECTED", callingCode+phone);
      setNameInitials(iconName);
      setNumberParams([]);
      setContent("");
      setIsUrlDynamic("");
      setIsUrlDynamic2("");
      setTemplateSelected([]);
      setTemplateWhatsapp(false);
    }

    setNewChat(false);
  };

  //Evento al presionar enter para enviar el mensaje
  const keyDown = (e) => {
    if (inputStr) {
      if (e.key === 'Enter' && e.shiftKey) {
        // Inserta un salto de línea
        const textarea = document.getElementById("myTextarea");
        textarea.value += '\n';
        textarea.style.height = "auto";
        e.preventDefault();
      } else if (e.keyCode === 13) {
        e.preventDefault();
        onSendMessage({});
        scrollToBottom("auto");
        const textarea = document.getElementById("myTextarea");
        textarea.style.height = "auto";
      }
    }
  };

  function addMsgInListMessageSync(value) {
    value["agentAsign"] = localStorage.getItem("USER_NAME");
    value["botInfo"] = "";
    console.log("value in addMsgInListMessageSync📂📂📂", value);
    Promise.resolve().then(() => {
      console.log("Se va a actualizar listMessages: ", [value]);
      setListMessages((prevMessage) => prevMessage.concat([value]));
      setTimeout(() => {
        scrollToBottom("auto");
      }, 100);
    });
  }

  function markLastMessageAsFailed(uniqueId) {
    const new_messages = listMessagesRef.current.map((row) => {
      if (row.reference && row.reference === uniqueId) {
        return { ...row, status: "failed" };
      }
      return row;
    });
    setListMessages(new_messages);
  }
  
  //evento para enviar mensajes escritos, audios, plantillas o mensajes de respuesta rápida.
  const onSendMessage = async (idAnswer) => {
    const uniqueId = uuidv4();
    setViewAnswers(false);
    setShowPicker(false);
    setCloseEmojis(false);
    setSelectedEmoji(false);
    const today = new Date();
    let time = dayjs(today).locale("es").format("hh:mm a");
    //#------> Validamos si esta tratando de enviar un mensaje diferente a plantilla despues de 24 horas
    if (timerConectionChat >= 24 && templateSelected.length == 0) {
      setInputStr("")
      sendToast({type: "warning", message: "Recuerda que por políticas de META es necesaria una respuesta del contacto para poder continuar la conversación."})
    } else {
      const {urlParam,urlParam2} = formSendTemplate.watch();
      const fieldsParams = formSendTemplate.watch();
      let ob = {};
      let phoneCopy = phoneContact;
      let contactNameCopy = contactName;
      let idTemplateCopy = idTemplate;
      let objData = {};
      let textMedio = "";
      let arrFields = [];
      let copyInputStr = inputStr;
      try {
        if (idAnswer.length > 0) {
          const ansFilter = quicklyAnswers.filter(quickly => quickly._id == idAnswer)
          const {content, mediaType, media, mediaName} = ansFilter[0];
          let copyContent = '';
          if (content) {
            copyContent = replaceQuickResponses(content);
          }

          if (media) {
            if (mediaType == "image") {
              objData = {
                  to: phoneCopy,
                  user: contactNameCopy,
                  nameMedia: mediaName,
                  type: "image",
                  url: media,
                  fileText: copyContent
                };
            } else {
              objData = {
                to: phoneCopy,
                user: contactNameCopy,
                nameMedia: mediaName,
                type: "document",
                url: media,
                fileText: copyContent
              };
            }
          }else {
            console.log('Sin medio')
            objData = {
              to: phoneCopy,
              user: contactNameCopy,
              type: "text",
              text: {
                body: copyContent
              },
            };
          }
          if(listMessages.length){
            let typeMedia = ""
            if(mediaType === "pdf") {
              typeMedia = "document"
            } else {
              typeMedia = "image"
            }
            var new_message = JSON.parse(JSON.stringify(listMessages[0]));
            new_message["originMessage"] = "agent";
            new_message["bodytext"] = copyContent;
            new_message["bodyType"] = typeMedia;
            new_message["bodyMedia"] = media;
            new_message["nameMedia"] = mediaName;
            new_message["messageId"] = "";
            new_message["date"] = new Date();
            new_message["timestamp"] = Math.floor(new_message["date"].getTime());
            new_message["timestampInt"] = Math.floor(new_message["date"].getTime());
            new_message["status"] = "pending";
            new_message["reference"] = uniqueId;
            addMsgInListMessageSync(new_message);
          }
        }else{
          let au = "";
          if (isRecor && dataFilesRef.current.length) {
            au = dataFilesRef.current[0].url;
            if (au && au.includes("blob")) {
              setIsRecor(false);
              setDate(time);
              setInputStr("");
              setContenMessage("");

              if(listMessages.length && dataFilesRef.current.length){
                let new_message = JSON.parse(JSON.stringify(listMessages[0]));
                new_message["originMessage"] = "agent";
                new_message["bodytext"] = textMedio;
                new_message["bodyType"] = dataFilesRef.current[0].type;
                new_message["bodyMedia"] = dataFilesRef.current[0].url;
                new_message["nameMedia"] = dataFilesRef.current[0].name;
                new_message["messageId"] = "";
                new_message["date"] = new Date();
                new_message["timestamp"] = Math.floor(new_message["date"].getTime());
                new_message["timestampInt"] = Math.floor(new_message["date"].getTime());
                new_message["status"] = "pending";
                new_message["reference"] = uniqueId;
                addMsgInListMessageSync(new_message);
              }

            }
          } else {
            // En qué caso entraria acá?
            console.log('🌞🌞🌞🌞🌞', inputStr);
            if (inputStr){
              copyInputStr = replaceQuickResponses(inputStr);
              if (copyInputStr.startsWith('/')) {
                const nameToSearch = copyInputStr.slice(1).toLowerCase();
                const matchingAnswer = quicklyAnswers.find(quickly => quickly.name.toLowerCase() === nameToSearch);
                // console.log('⏱️⏱️⏱️⏱️', matchingAnswer);
                if (matchingAnswer && matchingAnswer.mediaType === "") {
                  copyInputStr = matchingAnswer.content;
                  setViewAnswer(false);
                } else if (matchingAnswer && matchingAnswer.mediaType !== ""){
                  setIsMedia(true);
                  setIsMediaAnswer(matchingAnswer.media);
                  setIsMediaTypeAnswer(matchingAnswer.mediaType);
                  setTextDocument(matchingAnswer.content);
                  setViewAnswer(false);
                  setInputStr("");
                  setIdAnswerSelected(matchingAnswer._id);
                  return;
                } else {
                  setViewAnswer(false);
                }
              }

              ob = { content: copyInputStr };
              if(listMessages.length){
                var new_message = JSON.parse(JSON.stringify(listMessages[0]));
                new_message["originMessage"] = "agent";
                new_message["bodytext"] = copyInputStr;
                new_message["bodyType"] = "text";
                new_message["bodyMedia"] = "";
                new_message["messageId"] = "";
                new_message["date"] = new Date();
                new_message["timestamp"] = Math.floor(new_message["date"].getTime());
                new_message["timestampInt"] = Math.floor(new_message["date"].getTime());
                new_message["status"] = "pending";
                new_message["reference"] = uniqueId;
                addMsgInListMessageSync(new_message);
              }
              setIsRecor(false);
              setDate(time);
              setInputStr("");
              setContenMessage("");
            }
          }

          if( (templateSelected.length > 0 )) {
            setLoading(true);
            setLoadingSendTemplate(true);
            // En teoria habria solo un mensaje en dataFilesRef.current
            const filteredTemplates = templates.filter(tem => tem.id === idTemplateCopy);
            if(filteredTemplates.length){
              const filterBody = filteredTemplates[0].content.filter(tem => {if (tem.type === "BODY") return tem})
              if(filterBody.length){
                textMedio = filterBody[0].text;
                arrFields = textMedio.match(/{{\d+}}/g) || [];
                for (let i = 0; i < arrFields.length; i++) {
                  textMedio = textMedio.replace(arrFields[i], fieldsParams[`param${i}`])
                }
              }
            }
            // console.log("::::::::::::::::::::dataFilesRef.current: ", dataFilesRef.current);
            // console.log("::::::::::::::::::::dataFilesRef.current: ", dataFilesRef.current.length);
            if(dataFilesRef.current.length){
              dataFilesRef.current.forEach((data_media) => {
                let new_message = {};
                if(listMessages.length){
                  new_message = JSON.parse(JSON.stringify(listMessages[0]));
                }
                new_message["originMessage"] = "agent";
                new_message["bodytext"] = textMedio;
                new_message["bodyType"] = dataFilesRef.current[0].type;
                new_message["bodyMedia"] = dataFilesRef.current[0].url;
                new_message["nameMedia"] = dataFilesRef.current[0].name;
                new_message["messageId"] = "";
                new_message["idTemplate"] = idTemplateCopy;
                new_message["date"] = new Date();
                new_message["timestamp"] = Math.floor(new_message["date"].getTime());
                new_message["timestampInt"] = Math.floor(new_message["date"].getTime());
                new_message["status"] = "pending";
                new_message["reference"] = uniqueId;
                new_message["phoneNumber"] = localStorage.getItem("CHAT_SELECTED");
                addMsgInListMessageSync(new_message);
              })
            }else{
              let new_message = {};
              if(listMessages.length){
                new_message = JSON.parse(JSON.stringify(listMessages[0]));
              }
              new_message["originMessage"] = "agent";
              new_message["bodytext"] = textMedio;
              new_message["bodyType"] = "template";
              new_message["bodyMedia"] = "";
              new_message["nameMedia"] = "";
              new_message["messageId"] = "";
              new_message["idTemplate"] = idTemplateCopy;
              new_message["date"] = new Date();
              new_message["timestamp"] = Math.floor(new_message["date"].getTime());
              new_message["timestampInt"] = Math.floor(new_message["date"].getTime());
              new_message["status"] = "pending";
              new_message["reference"] = uniqueId;
              new_message["phoneNumber"] = localStorage.getItem("CHAT_SELECTED");
              addMsgInListMessageSync(new_message);
            }
          }

          // Si hay archivos multimedia los subo a S3 y obtengo sus datos:
          let data_file = {};
          if(dataFilesRef.current.length){
            // Primero subo los archivos a S3 y obtengo las urls.
            let result_upload = await handleFileUpload(dataFilesRef.current);
            data_file = result_upload.data_files[0];          
            if(result_upload.errors){
              console.log("result_upload: ", result_upload);
              const newListMessages = listMessages.filter((message) => {
                return !result_upload.files_errors.some((fileError) => fileError.name === message.nameMedia);
              });

              Promise.resolve().then(() => {
                console.log("Se va a actualizar listMessages para eliminar archivos con error: ", newListMessages);
                setListMessages((prevMessage) => {return newListMessages});
                setTimeout(() => {
                  scrollToBottom("auto");
                }, 100);
              });

            }
            

          }
          if (au && au.includes("blob")) {
            objData = {
              to: phoneCopy,
              user: contactNameCopy,
              nameMedia: data_file.name,
              type: "audio",
              url: data_file.url
            };
          } else if (templateSelected.length > 0) {
            setNotChatSelected(false);
            setStartChat(false);
            setModalTemplateSend(false);
            setIsTemplateSend(true);
            setIsChatSelected(true);

            let objParamsBody = [];
            if (arrFields) {
              for (let i = 0; i < arrFields.length; i++) {
                objParamsBody.push({type: "text", text: fieldsParams[`param${i}`], number: arrFields[i]})
              }
            }

            let objParamsMedia = {};

            if(fileType == "image" || fileType == "video" || fileType == "document"){
              let data_media = {urlFile: data_file.url, nameFile: data_file.name, sizeFile: data_file.file.size};
              console.log("data_media: ", data_media)
              if (fileType == "image") {
                objParamsMedia = { image: data_media };
              } else if (fileType == "video") {
                objParamsMedia = { video: data_media };
              } else if (fileType == "document") {
                objParamsMedia = {
                  document: data_media,
                };
              }
            }

            let varUrl = "";
            if (isUrlDynamic) {
              varUrl = urlParam
            } else if (isUrlDynamic2) {
              varUrl = urlParam2
            }

            objData = {
              to: phoneCopy,
              user: contactNameCopy,
              type: "template",
              template: {
                id: idTemplate,
                parametersMedia: objParamsMedia,
                parametersBody: objParamsBody,
                parameterUrl: varUrl,
              },
            };
            console.log('--->OBJDATA:', objData)

          } else if (flowIdSelected) {
            const dataFlow = formSendFlow2.watch()
            //#--> Generamos el objeto de interactive para el envio del flow
            let objInteractive = {}
            let objViewData = []

            //#--> Validamos si tiene header y que tipo va a enviar
            if (dataFlow.headerFlow == "1") {
              objInteractive.header = {
                type: "text",
                text: dataFlow.headerTextFlow
              }
              objViewData.push({header: objInteractive.header})
            } else if (dataFlow.headerFlow == "2") {
              if (dataFlow.medioTypeFlow == "1") {
                objInteractive.header = {
                  type: "image",
                  image: {
                    link: data_file.url
                  }
                }
                objViewData.push({header: objInteractive.header})
              } else {
                objInteractive.header = {
                  type: "document",
                  document: {
                    link: data_file.url,
                    filename: data_file.name
                  }
                }
                objViewData.push({header: objInteractive.header})
              }
            }

            //#--> Agregamos el body
            objInteractive.body = {text: dataFlow.bodyFlow}
            objViewData.push({body: objInteractive.body})

            //#--> Validamos si existe footer
            if (dataFlow.footerFlow) {
              objInteractive.footer = { text: dataFlow.footerFlow}
              objViewData.push({footer: objInteractive.footer})
            }

            objViewData.push({button: dataFlow.bottonTextFlow})

            const objTextFlow = JSON.stringify(objViewData);


            // console.log('_____:______:_______: archivos :::::::::------::::::: ----->', dataFilesRef.current)
            if(data_file.length > 0){
              let new_message = {};
              if(listMessages.length){
                new_message = JSON.parse(JSON.stringify(listMessages[0]));
              }
              new_message["phoneNumber"] = phoneCopy;
              new_message["originMessage"] = "agent";
              new_message["bodytext"] = objTextFlow;
              new_message["bodyType"] = "interactive";
              new_message["bodyMedia"] = data_file.url;
              new_message["nameMedia"] = data_file.name;
              new_message["messageId"] = "";
              new_message["idTemplate"] = flowIdSelected;
              new_message["date"] = new Date();
              new_message["timestamp"] = Math.floor(new_message["date"].getTime());
              new_message["timestampInt"] = Math.floor(new_message["date"].getTime());
              new_message["status"] = "pending";
              new_message["reference"] = uniqueId;
              addMsgInListMessageSync(new_message);
            }else{
              let new_message = {};
              if(listMessages.length){
                new_message = JSON.parse(JSON.stringify(listMessages[0]));
              }
              new_message["phoneNumber"] = phoneCopy;
              new_message["originMessage"] = "agent";
              new_message["bodytext"] = objTextFlow;
              new_message["bodyType"] = "interactive";
              new_message["bodyMedia"] = "";
              new_message["nameMedia"] = "";
              new_message["messageId"] = "";
              new_message["idTemplate"] = flowIdSelected;
              new_message["date"] = new Date();
              new_message["timestamp"] = Math.floor(new_message["date"].getTime());
              new_message["timestampInt"] = Math.floor(new_message["date"].getTime());
              new_message["status"] = "pending";
              new_message["reference"] = uniqueId;
              addMsgInListMessageSync(new_message);
            }

            objInteractive.type = "flow"
            objInteractive.action = {
              name: "flow",
              parameters: {
                flow_message_version: "3",
                flow_token: "unused",
                flow_id: flowIdSelected,
                flow_cta: dataFlow.bottonTextFlow,
                flow_action: "navigate",
                flow_action_payload: {
                  screen: "QUESTION_ONE",
                }
              }
            }

            //#--> Asignamos los valores al objeto de envío
            objData = {
              to: phoneCopy,
              user: contactNameCopy,
              type: "interactive",
              interactive: objInteractive
            };
            setFlowIdSelected("")
          } else if (selectProductsSend) {
            const { isProducts  } = formSendCatalog.watch()
            const { bodyCatalog, footerCatalog, headerTextCatalog } = formSendCatalog2.watch()
            //#--> Generamos el objeto de interactive para el envio de los productos
            let objInteractive = {}
            let objViewData = []
            let arrIdsProduct = [];
            let objIdProduct = {};
            let listIdsProd = [];
            let nameSet = "";
            let productSetsArray = [];
            let textHeaderMsj = "";
            let textBodyMsj = "";

            //#--> Creamos array con los ids de los productos a enviar
            if (selectedTypeProducts == 2) {
              const setSend = listSets.find(set => set.id == selectedSets)
              nameSet = setSend?.name;

              let productSet = {
                title: nameSet,
                product_items: []
              };

              if (setSend.products.length > 0) {
                for (let proId of setSend.products) {
                  const idProduct = proId.id;

                  listIdsProd.push(idProduct)

                  objIdProduct = {product_retailer_id: idProduct}
                  productSet.product_items.push(objIdProduct)
                }
                productSetsArray.push(productSet);

                textHeaderMsj = nameSet.toUpperCase()
                textBodyMsj = nameSet

              } else {
                for (let proId of listProducts) {

                  listIdsProd.push(proId.retailer_id)

                  objIdProduct = {product_retailer_id: proId.retailer_id}
                  productSet.product_items.push(objIdProduct)
                }
                productSet.title = "Catálogo"
                productSetsArray.push(productSet);

                textHeaderMsj = productSet.title.toUpperCase()
                textBodyMsj = productSet.title

              }



            } else {
              if (listSets.length > 1) {
                let productSet2 = {
                  title: "Otros",
                  product_items: []
                };
                for (let set of listSets) {
                  if (set.name !== "Todos los productos") {
                    let productSet = {
                        title: set.name,
                        product_items: []
                    };

                    for (let productId of isProducts) {
                        if (set.products.some((product) => product.id === productId)) {
                            productSet.product_items.push({ "product_retailer_id": productId });
                        }
                    }
                    if (productSet.product_items.length > 0) {
                      productSetsArray.push(productSet);
                    }
                  }
                }

                for (let productId of isProducts) {
                  let encontrado = productSetsArray.some((productSet) =>
                      productSet.product_items.some((elemento) => elemento.product_retailer_id === productId)
                  );

                  if (!encontrado) {
                      productSet2.product_items.push({ "product_retailer_id": productId });
                  }
                }

                if (productSet2.product_items.length > 0) {
                    productSetsArray.push(productSet2);
                }

              } else {
                let productSet = {
                  title: "Catálogo",
                  product_items: []
                };
                for (let productId of isProducts) {
                  productSet.product_items.push({ "product_retailer_id": productId });
                }
                productSetsArray.push(productSet);
              }

              console.log('Grupos de sets:', productSetsArray);
              listIdsProd = isProducts

              textHeaderMsj = headerTextCatalog
              textBodyMsj = bodyCatalog
            }

            //#--> Agregamos los ids de los productos a enviar para poderlos filtrar y pintar
            objViewData.push({productsId: listIdsProd})

            //#--> Agregamos el header
            objInteractive.header = {type: "text", text: textHeaderMsj}
            objViewData.push({header: objInteractive.header})

            //#--> Agregamos el body
            objInteractive.body = {text: textBodyMsj}
            objViewData.push({body: objInteractive.body})

            //#--> Validamos si existe footer
            if (footerCatalog) {
              objInteractive.footer = { text: footerCatalog}
              objViewData.push({footer: objInteractive.footer})
            }

            const objTextCatalog = JSON.stringify(objViewData);
            console.log

            let new_message = {};
            if(listMessages.length){
              new_message = JSON.parse(JSON.stringify(listMessages[0]));
            }
            new_message["phoneNumber"] = phoneCopy;
            new_message["originMessage"] = "agent";
            new_message["bodytext"] = objTextCatalog;
            new_message["bodyType"] = "interactive";
            new_message["bodyMedia"] = "";
            new_message["nameMedia"] = "";
            new_message["messageId"] = "";
            new_message["idTemplate"] = flowIdSelected;
            new_message["date"] = new Date();
            new_message["timestamp"] = Math.floor(new_message["date"].getTime());
            new_message["timestampInt"] = Math.floor(new_message["date"].getTime());
            new_message["status"] = "pending";
            new_message["reference"] = uniqueId;
            addMsgInListMessageSync(new_message);

            objInteractive.type = "product_list"
            objInteractive.action = {
              catalog_id: catalog,
              sections: productSetsArray
              // sections: [
              //   {
              //     title: nameSet,
              //     product_items: arrIdsProduct
              //   }
              // ]
            }

            //#--> Asignamos los valores al objeto de envío
            objData = {
              to: phoneCopy,
              user: contactNameCopy,
              type: "interactive",
              interactive: objInteractive
            };
          } else {
            console.log("no se grabo nada");
            objData = {
              to: phoneCopy,
              user: contactNameCopy,
              type: "text",
              text: {
                body: copyInputStr,
              },
            };
          }
        }

        // limpio los valores.
        setTemplateSelected((templateSelected) => { return []})
        setTextDocument("");
        setIsMedia((isMedia) => { return false });
        setFileType((fileType) => { return "" });
        setSelectedFiles((prevMessage) => { return []})
        setIdTemplate((prevMessage) => { return ""});
        setSelectProductsSend((prevMessage) => { return false});
        setFlowIdSelected((prevMessage) => { return ""});
        setLoadingSendTemplate((prevMessage) => { return false})
        formSendTemplate.reset()
        objData.reference = uniqueId;
        let timestamp_schedule = "";
        if(messageScheduleDate){
          timestamp_schedule = new Date(messageScheduleDate).getTime().toString();
        }
        objData.messageScheduleDate = timestamp_schedule;
        const url = urlAPI_1 + "saveAgentMessages";
        const options = { method: "POST", mode: "cors", body: JSON.stringify(objData)};
        const response = await fetchWithAuth(url, options);
        const result = await response.json();
        console.log('--------------->', result)
        if (result.type === "error") {
          markLastMessageAsFailed(uniqueId);
        }
        if (result.type === "info") {
          console.log('Desde el envio de medios......dark.......', isDark);
          console.log('Desde el envio de medios......dark local.......', localStorage.getItem("THEME"));
          showToast(result.message, setLoading, localStorage.getItem("THEME"))
        } else {
          scrollToBottom("auto");
          setAudioDetails((prevMessage) => { return {url: null, blob: null, chunks: null, duration: {h: 0, m: 0, s: 0, ms: 0}}})
          if (result.isConv) {
            await callData(phoneCopy, false);
          }
        }
      } catch (error) {
        console.log(error);
      }finally{
        setLoading(false);
      }
    }
  };

  //Funcion para enviar mensajes multimedia exclusivamente desde el boton importar. Soporta el envio múltiple.
  const onSendMessageMedia = async (idAnswer) => {
    setViewAnswers(false);
    setShowPicker(false);
    setCloseEmojis(false);
    setSelectedEmoji(false);
    const uniqueId = uuidv4();
    //#------> Validamos si esta tratando de enviar un mensaje diferente a plantilla despues de 24 horas
    if (timerConectionChat >= 24) {
      if(listMessages.length && templateSelected.length == 0){
        let new_message = JSON.parse(JSON.stringify(listMessages[0]));
        new_message["originMessage"] = "agent";
        new_message["bodytext"] = "⚠️Recuerda que por políticas de META es necesaria una respuesta del contacto para poder continuar la conversación.⚠️";
        new_message["bodyType"] = "warning";
        new_message["bodyMedia"] = "";
        new_message["nameMedia"] = "";
        new_message["messageId"] = "";
        new_message["idTemplate"] = "";
        new_message["date"] = new Date();
        new_message["timestamp"] = Math.floor(new_message["date"].getTime());
        new_message["timestampInt"] = Math.floor(new_message["date"].getTime());
        new_message["status"] = "pending";
        new_message["reference"] = uniqueId;

        addMsgInListMessageSync(new_message);

        scrollToBottom("auto");
        setSelectedFiles((prevMessage) => { return []})
        setTextDocument("");
        setIsMedia((isMedia) => { return false });
        setFileType((fileType) => { return "" });
      }

    } else {
      // Me copio los valores por si durante la funcion cambia debido a interacciones del usuario con el front.
      let textDocumentCopy = replaceQuickResponses(textDocument);
      let phoneCopy = phoneContact;
      let contactNameCopy = contactName;
      let isConv = false;
      if (idAnswer) {
        setIsMedia((isMedia) => { return false });
        setIsMediaAnswer((isMediaAnswer) => { return "" });
        setIsMediaTypeAnswer((isMediaTypeAnswer) => { return "" });
        setIdAnswerSelected((isAnswerSelected) => { return "" });

        const ansFilter = quicklyAnswers.filter(quickly => quickly._id == idAnswer)
        console.log('Respuesta rapida seleccionada==>', ansFilter)
        const {content, mediaType, media, mediaName} = ansFilter[0];
        let objData = {}
        if (mediaType == "image") {
          objData = {
              to: phoneCopy,
              user: contactNameCopy,
              nameMedia: mediaName,
              type: "image",
              url: media,
              fileText: textDocumentCopy
            };
        } else {
          objData = {
            to: phoneCopy,
            user: contactNameCopy,
            nameMedia: mediaName,
            type: "document",
            url: media,
            fileText: textDocumentCopy
          };
        }
        if(listMessages.length){
          let typeMedia = ""
          if(mediaType === "pdf") {
            typeMedia = "document"
          } else {
            typeMedia = "image"
          }
          var new_message = JSON.parse(JSON.stringify(listMessages[0]));
          new_message["originMessage"] = "agent";
          new_message["bodytext"] = textDocumentCopy;
          new_message["bodyType"] = typeMedia;
          new_message["bodyMedia"] = media;
          new_message["nameMedia"] = mediaName;
          new_message["messageId"] = "";
          new_message["date"] = new Date();
          new_message["timestamp"] = Math.floor(new_message["date"].getTime());
          new_message["timestampInt"] = Math.floor(new_message["date"].getTime());
          new_message["status"] = "pending";
          new_message["reference"] = uniqueId;
          addMsgInListMessageSync(new_message);
        }
        objData.reference = uniqueId;
        console.log('✅✅✅',objData);
        try {
          const url = urlAPI_1 + "saveAgentMessages";
          const options = { method: "POST", mode: "cors", body: JSON.stringify(objData)};
          const response = await fetchWithAuth(url, options);
          const result = await response.json();
          scrollToBottom("auto");

          if (result.type === "info") {
            console.log('Desde el envio de medios.............')
            showToast(result)
          } else if (result.type === "error") {
            markLastMessageAsFailed(uniqueId);
          }

          if (result.isConv) {
            isConv = true;
          }
        } catch (error) {
          console.log(error);
        }

      } else {
        // Envio los mensajes al chat inmediatamente, sin enviar por Whatsaap.
        // Valido si todos los archivos son image: 
        let all_images = true;
        dataFilesRef.current.forEach((data_media) => {
          if(data_media.type != "image"){
            all_images = false;
          }
        });
        if (dataFilesRef.current.length >= 2 && all_images) {
          if (listMessages.length) {
            let arrayImgs = []
            for (let img of dataFilesRef.current) {
              arrayImgs.push(img.src)
            }
            let new_message = JSON.parse(JSON.stringify(listMessages[0]));
            new_message["originMessage"] = "agent";
            new_message["bodytext"] = textDocumentCopy;
            new_message["bodyType"] = "groupedImages";
            new_message["bodyMedia"] = arrayImgs;
            new_message["nameMedia"] = "";
            new_message["messageId"] = "";
            new_message["idTemplate"] = "";
            new_message["date"] = new Date();
            new_message["timestamp"] = Math.floor(new_message["date"].getTime());
            new_message["timestampInt"] = Math.floor(new_message["date"].getTime());
            new_message["status"] = "pending";
            new_message["reference"] = uniqueId;
            addMsgInListMessageSync(new_message);
          }
        } else {
          dataFilesRef.current.forEach((data_media) => {
            if (listMessages.length) {
              let new_message = JSON.parse(JSON.stringify(listMessages[0]));
              new_message["originMessage"] = "agent";
              new_message["bodytext"] = textDocumentCopy;
              new_message["bodyType"] = data_media.type;
              new_message["bodyMedia"] = data_media.src;
              new_message["nameMedia"] = data_media.name;
              new_message["messageId"] = "";
              new_message["idTemplate"] = "";
              new_message["date"] = new Date();
              new_message["timestamp"] = Math.floor(new_message["date"].getTime());
              new_message["timestampInt"] = Math.floor(new_message["date"].getTime());
              new_message["status"] = "pending";
              new_message["reference"] = uniqueId;
              addMsgInListMessageSync(new_message);
            }
          })
        }
        setSelectedFiles((prevMessage) => { return []})
        setTextDocument("");
        setIsMedia((isMedia) => { return false });
        setFileType((fileType) => { return "" });
        // Envio los mensajes por whatsapp.
        // Primero subo los archivos a S3 y obtengo las urls.
        let result_upload = await handleFileUpload(dataFilesRef.current);
        // Ahora sí los envio por whatsapp.
        // Reemplazo new_message["bodyMedia"] por las urls nuevas. 
        await replaceMediaFiles(result_upload.data_files, phoneCopy, uniqueId);

        if(result_upload.errors){
          const newListMessages = listMessages.filter((message) => {
            return !result_upload.files_errors.some((fileError) => fileError.name === message.nameMedia);
          });

          Promise.resolve().then(() => {
            console.log("Se va a actualizar listMessages para eliminar archivos con error: ", newListMessages);
            setListMessages((prevMessage) => {return newListMessages});
            setTimeout(() => {
              scrollToBottom("auto");
            }, 100);
          });

        }
        
        const uploadPromises = result_upload.data_files.map(async (data_media) => {
          const objData = {
            to: phoneCopy,
            user: contactNameCopy,
            nameMedia: data_media.name,
            type: data_media.type,
            url: data_media.url,
            fileText: textDocumentCopy,
          };
          objData.reference = uniqueId;
          try {
            const url = urlAPI_1 + "saveAgentMessages";
            const options = { method: "POST", mode: "cors", body: JSON.stringify(objData)};
            const response = await fetchWithAuth(url, options);
            const result = await response.json();
            scrollToBottom("auto");

            if (result.type === "info") {
              console.log('Desde el envio de medios.............')
              showToast(result)
            } else if (result.type === "error") {
              markLastMessageAsFailed(uniqueId);
            }
            if (result.isConv) {
              isConv = true;
            }
          } catch (error) {
            console.log(error);
          }
        });

        // Esperar a que todas las promesas se resuelvan
        try {
          await Promise.all(uploadPromises);
        } catch (error) {
          console.log("Error in uploadPromises:", error);
        }
      }
      if (isConv) {
        await callData(phoneCopy, false);
      }
    }

    scrollToBottom("auto");
    setAudioDetails((prevMessage) => { return {url: null, blob: null, chunks: null, duration: {h: 0, m: 0, s: 0, ms: 0}}})
  };

  //Función que controla el tono de notificaciones
  const playSound = (parVol) => {
    const notificationsState = localStorage.getItem("STATE_NOT")
    // if (activeNotification) {
    if (notificationsState == "true") {
      console.log("in nitif");
      // const sound = new Howl({
      //   src: [toneNotification],
      //   html5: true,
      // });
      var vol = 0.0;
      if (parVol == "highVol") {
        vol = 0.9;
      }

      const sound = new Howl({
        src: [toneNotification],
        volume: vol,
        preload: true,
        autoplay: true,
        html5: true
      });

      console.log("play");
      sound.play();
    } else {
      console.log("Notificaciones apagadas.");
      return null;
    }
  };

  // /*********************************************** */ CONVERTIR MEDIOS A BASE64 *******/
  // Convertir audio grabado en base64
  const convertRecorBase64 = (file) => {
    return new Promise((res, rej) => {
      var fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        res(fileReader.result);
      };
      fileReader.onerror = (error) => {
        rej(error);
      };
    });
  };

  const handleRemoveFile = (index) => {
    if (selectedFiles.length == 1) {
      setMediaSelected((mediaSelected) => { return 0 })
      setSelectedImg((selectedImg) => { return "" })
      setTextDocument((textDocument) => { return "" });
      setIsMedia((isMedia) => { return false });
      setFileType((fileType) => { return "" });
      setSelectedFiles((selectedFiles) => { return [] })
      dataFilesRef.current = [];

      setTimeout(function(){
         scrollToBottom("auto");
      }, 200);
    } else {
      const updatedFiles = selectedFiles.filter((_, i) => i !== index);
      setMediaSelected(0)
      setSelectedImg(updatedFiles[0].src);
      setDocumentView(updatedFiles[0])
      setSelectedFiles(updatedFiles);
      dataFilesRef.current = dataFilesRef.current.filter((_, i) => i !== index);
    }
  };

  const onFileInput = async (e, type_file, isPasteImg) => {
    const max_files = 6;
    const conversionFunctions = {
      image: convertBase64,
      video: async (file) => URL.createObjectURL(file),
      document: convertDocumentBase64,
      audio: convertAudioBase64,
    };

    const extAccepted = {
      image: ['gif', 'jpeg', 'jpg', 'png'],
      video: ['mp4', 'mov', 'avi', 'wmv', 'mkv', 'flv', 'webm'],
      document: ['pdf', 'csv', 'xlsx', 'xls', 'doc', 'docx', 'txt'],
      audio: ['mp3', 'wav', 'aac', 'ogg', 'flac', 'm4a', 'wma', 'webm']
    };

    // Defino la función para convertir los datos segun sea image, video, document o audio.
    const conversionFunction = conversionFunctions[type_file];
    if (conversionFunction) {
      const filesFiltered = Array.from(e.target.files).filter(file => {
        const extName = file.name.slice(file.name.lastIndexOf('.') + 1).toLowerCase();
        return extName && extAccepted[type_file].includes(extName);
      });

      setFileType(type_file);
      setIsMedia(true);
      const selectedFiles = [];
      let files = isPasteImg ? isPasteImg : filesFiltered;
      if(files.length > max_files){
        sendToast({type: "info", message: `La cantidad máxima de archivos permitida es: ${max_files}. Solo se adjuntaran los primeros ${max_files}`})
        
        const newArray = Object.fromEntries(
          Object.entries(files).slice(0, 6)
        );
        files = Object.values(newArray);
        // files = files.slice(0, max_files);
      }
      for (const file of files) {
        const base64 = await conversionFunction(file);
        if (base64) {
          selectedFiles.push({
            src: base64,
            name: file.name,
            size: `${((file.size * 1) / 1000000).toFixed(2)} MB`,
            file: file,
            type: type_file
          });
        }
      }
      setSelectedImg(selectedFiles[0].src);
      setDocumentView(selectedFiles[0])
      setSelectedFiles(selectedFiles);
      dataFilesRef.current = selectedFiles;
    }
  };

  const onFileInputTemplate = async (e, type_file) => {
    const conversionFunctions = {
      image: convertBase64,
      video: async (file) => URL.createObjectURL(file),
      document: convertDocumentBase64,
      audio: convertAudioBase64,
    };

    // Defino la función para convertir los datos segun sea image, video, document o audio.
    const conversionFunction = conversionFunctions[type_file];
    if (conversionFunction) {
      setFileType(type_file);
      // setIsMedia(true);
      const selectedFiles = [];
      const files = e.target.files;
      for (const file of files) {
        if(type_file == "video"){
          let base64_video = await convertVideoBase64(file);
          setVideoSelected(base64_video);
        }
        const base64 = await conversionFunction(file);
        if (base64) {
          selectedFiles.push({
            src: base64,
            name: file.name,
            size: `${((file.size * 1) / 1000000).toFixed(2)} MB`,
            file: file,
            type: type_file
          });
        }
      }
      setSelectedImg(selectedFiles[0].src ?? "");
      setDocumentView(selectedFiles[0])
      setSelectedFiles(selectedFiles);
      dataFilesRef.current = selectedFiles;
    }
  };

  //Convertit medio de flujo
  const onFileFlow = async (e) => {
    var file = e.target.files[0];
    const imageUrl = URL.createObjectURL(file)
    formSendFlow2.reset({medioFlow: imageUrl.replace(/^blob:/, '')})
    const base64 = await convertDocumentBase64(file);
    if (base64) {
      dataFilesRef.current = [{
        src: base64,
        name: file.name,
        size: `${((file.size * 1) / 1000000).toFixed(2)} MB`,
        file: file,
        type: 'document'
      }];
    }
    scrollToBottom("auto");
  };

  //Convertit imagen de respuesta rápida
  const onFileAnswer = async (e) => {
    var file = e.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file)
      setImgAnswer(imageUrl);
      if (file.type == "image/jpeg" || file.type == "image/png" || file.type == "image/gif" || file.type == "image/bmp" || file.type == "image/svg+xml") {
        setTypeFileAnswer("image");
      } else {
        setTypeFileAnswer("application/pdf");
      }
      setIsImgAnswer(true);
      setViewSelectedImage(imageUrl);
      const base64 = await convertDocumentBase64(file);
      if (base64) {
        dataFilesRef.current = [{
          src: base64,
          name: file.name,
          size: `${((file.size * 1) / 1000000).toFixed(2)} MB`,
          file: file,
          type: 'document'
        }];
      }

    }else{
      setIsImgAnswer(false);
    }
    scrollToBottom("auto");
  };

  const convertBase64 = (file) => {
    return new Promise((res, rej) => {
      var fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        res(fileReader.result);
      };
      fileReader.onerror = (error) => {
        rej(error);
      };
    });
  };

  const convertDocumentBase64 = (file) => {
    return new Promise((res, rej) => {
      var fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        res(fileReader.result);
      };
      fileReader.onerror = (error) => {
        rej(error);
      };
    });
  };

  const convertVideoBase64 = (file) => {
    return new Promise((res, rej) => {
      var fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        res(fileReader.result);
      };
      fileReader.onerror = (error) => {
        rej(error);
      };
    });
  };

  const convertAudioBase64 = (file) => {
    return new Promise((res, rej) => {
      var fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        res(fileReader.result);
      };
      fileReader.onerror = (error) => {
        rej(error);
      };
    });
  };

  //Ver imagen grande
  const onViewImage = (id) => {
    setIsViewImage(true);
    const filt = listMessages.filter((element) => element.messageId === id);
    setViewSelectedImage(filt);
    const div = document.getElementById('cont-chat');
    if (div) {
      setScrollPosition(div.scrollTop);
    }
  };

  // /*********************************************** */ NOTAS *******/
  //Funcion que obtiene la lista de notas
  const getNotes = async (phoneUser) => {
    try {
      const url = urlAPI_1 + "notes/" + phoneUser;
      const options = { method: "GET", mode: "cors"};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      if (result) {
        if (result.code === 0) {
          sendToast(result)
        }
      }
      
      if (result.code === 0) {
        sendToast(result)
      } else if (result.type === "info") {

        setListNotes([]);
      } else {
        setListNotes(result.data);
      }
      setListNotes(result.data);
    } catch (error) {
      console.log(error);
    }
  };

  // /*********************************************** */ USUARIO *******/

  const getLists = async (userId) => {
    setAllLists([]);
    try {
      const list_return = listsByCompany.current
      .filter(element => element.persons)
      .map(element => {
          const personslistSplit = element.persons.split(',');
          const count = personslistSplit.length;
          const inList = personslistSplit.includes(userId);
          
          return {
              _id: element._id,
              company: element.company,
              description: element.description,
              name: element.name,
              created: element.created,
              count,
              inList,
          };
      });
      setAllLists(list_return);
    }catch (error) {
      console.log(error);
    }
  };

  // /*********************************************** */ ASIGNAR AGENTE */
  //Funcion que envia el agente que se asigno al chat
  const onAssingAgent = async (id, name, take_by_self=false) => {
    const {contentNote} = watch()
    var objFinal = {
      agentId: id,
      chatId: localStorage.getItem("CONVERSATION_SELECTED"),
      transferNote: contentNote,
      agentAssigned: agentAssigned.current,
    };
    try {
      setLoading(true);
      const url = urlAPI_1 + "assignChat/" + phoneContact;
      const options = { method: "POST", mode: "cors", body: JSON.stringify(objFinal)};
      const response = await fetchWithAuth(url, options);
      agentAssigned.current = true;
      const result = await response.json();
      //updateConversations();
      if (result.type === "error") {
        sendToast(result)
        setListAreas([])
        setSearchArea([])
      }else{
        // INICIO: Abrimos la conversación de forma inmediata
        // Solo cuando aprieta "Tomar conversación"
        const chatContext = localStorage.getItem("CHAT_SELECTED");
        if(take_by_self){
          await onChatStatus("1", false);
          for(let row_chat of listUserChatsRef.current){
            if(row_chat.phoneNumber.slice(-10) == chatContext.slice(-10)){
              row_chat.statusConv = "1";
            }
          }
          await onChatSelected(chatContext);
          if (statusChat) {
            setChatClose(true);
          }
        }
        // FIN: Abrimos la conversación de forma inmediata 
        setIsAssignedAgent(false);
        //setIsTemplateSend(true);
        setModalTransferNote(false);
        console.log('🙂🙂🙂 listUserChatsRef 🙂🙂🙂: ', listUserChatsRef.current);
        let dataMessagesSel = listUserChatsRef.current.filter(row => row.phoneNumber.slice(-10) === chatContext.slice(-10));
        console.log('❌❌❌❌❌❌❌❌❌❌❌❌: ', dataMessagesSel);
        if (dataMessagesSel[0]) {
          dataMessagesSel[0].userAsign = id;
        } else {
            dataMessagesSel[0] = { userAsign: "" };
        }
        console.log('❌❌❌❌❌❌ 2 ❌❌❌❌❌❌: ', dataMessagesSel);
        reset({contentNote: ""});
        setAgentSelected("");
        sendToast(result)

        for(let row_chat of listUserChatsRef.current){
          if(row_chat.phoneNumber.slice(-10) == chatContext.slice(-10)){
            row_chat.userAsign = name;
            break;
          }
        }

        checkForTabSwitch();
      }
      
    } catch (error) {
      console.log(error);
    }finally{
      setLoading(false);
    }
  };

  //Funcion que enviar y actualizar el estado del chat
  const onChatStatus = async (e, checkForTabSwitchControl = true) => {
    setStatusChat(e);
    console.log('status: ', e);
    
    var objFinal = {
      chatStatus: e,
      user: userLogout
    };
    if (e === "2") {
      objFinal.feeling = feeling;
      objFinal.interestedBuying = interestedBuying;
    }
    try {
      const url = urlAPI_1 + "assignChat/" + phoneContact;
      const options = { method: "POST", mode: "cors", body: JSON.stringify(objFinal)};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      //updateConversations();
      if (result.type == "success" && (objFinal.chatStatus === "2" || objFinal.chatStatus === "3")) {
        setChatClose(false);
        console.log("chatClose(false)");
      } else {
        setChatClose(true);
      }
      const chatContext = localStorage.getItem("CHAT_SELECTED");
      for(let row_chat of listUserChatsRef.current){
        if(row_chat.phoneNumber.slice(-10) == chatContext.slice(-10)){
          row_chat.statusConv = e;
          break;
        }
      }
      if(checkForTabSwitchControl){
        checkForTabSwitch();
      }
    } catch (error) {
      console.log(error);
    }
  };
  //Buscador para los agentes
  const onSearchAdvisers = (searchTerm) => {
    var searchResults = searchAdvisers.filter((element) => {
      if (element.name.toString().toLowerCase().includes(searchTerm.toLowerCase())) {
        return element;
      }
    });
    setListAdvisers(searchResults);
    if (searchResults.length > 0) {
      setValidationModal(false);
    } else {
      setValidationModal(true);
    }
  };

  // /********** */ RESPUESTAS RAPIDAS */
  //Obtener lista de respuestas rapidas
  const getQuickReply = async () => {
    try {
      const url = urlAPI_1 + "quicklyAnswers";
      const options = { method: "GET", mode: "cors"};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      // if (result.config.length > 0) {
      //   setSelectedRadio(result.config)
      // }
      if (result.code === 0) {
        sendToast(result)
        setQuicklyAnswers([]);
      } else if (result.data.length <= 0) {
        setQuicklyAnswers([]);
      } else {
        setQuicklyAnswers(result.data);
        setSearchQuicklyAnswer(result.data)
      }
    } catch (error) {
      console.log(error);
    }
  }
  //Funcion para crear una respuesta
  const postQuickReply = async () => {
    const {fileAnswer} = watch()
    if (!answerTitle) {
      setAnswerTitleError(true)
      if (!inputStr2) {
        setInputStr2Error(true)
      }
    }else{
      setAnswerTitleError(false)
      if (!inputStr2) {
        setInputStr2Error(true)
      }else{
        if (selectedRadio === "2") {
          if (!answerAgent) {
            setAnswerAgentError(true)
            return;
          }
        } else if (selectedRadio === "3") {
          if (!answerArea) {
            setAnswerAreaError(true)
            return;
          }
        }
        try {
          setLoading(true);  
          let result_upload = await handleFileUpload(dataFilesRef.current);
          let data_file = result_upload.data_files[0];
          let urlImage = "";
          if (fileAnswer.length > 0) {
            urlImage = data_file.url;
          }

          let type = "";
          if (typeFileAnswer === "application/pdf"){ type = "pdf" }
          else if (typeFileAnswer.length <= 0){ type = "" }
          else { type = "image" }

          let objAnswer = {
            name: answerTitle,
            content: inputStr2,
            media: urlImage,
            mediaType: type,
            answerSettings: selectedRadio
          };
          if(data_file){
            objAnswer.mediaName = data_file.name
          }

          if (selectedRadio === "1") {
            objAnswer.user = "";
            objAnswer.area = "";
          } else if (selectedRadio === "2") {
            objAnswer.area = "";
            objAnswer.user = answerAgent;
          } else {
            objAnswer.area = answerArea;
            objAnswer.user = "";
          }

          const url = urlAPI_1 + "quicklyAnswers";
          const options = { method: "POST", mode: "cors", body: JSON.stringify(objAnswer)};
          const response = await fetchWithAuth(url, options);
          const result = await response.json();

          setShowQuickResponses(false);
          setCreateAnswer(false);
          setIsImgAnswer(false);
          getQuickReply();
          reset({ answerName: "", answerContent: "" });
          sendToast(result)
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
        }
      }
    }
  };
  //Función para eliminar una respuesta rápida
  const deleteQuickReply = async (id) => {
    setViewAnswers(false)
    try {
      const data = {
        isFetch: true,
        fetch: {
          url: urlAPI_1 + `quicklyAnswers/${id}`,
          options: { 
            method: "DELETE", 
            mode: "cors",
          },
        },
        title: "¿Estas seguro que deseas eliminar esta respuesta?",
        text: "No podrás recuperarla.",
        icon: "warning",
        buttons: {
          success: "Eliminar",
          cancel: "Cancelar"
        }        
      }
      console.log("Datos enviados a questionToast:", data);
      const result = await questionToast(data, setLoading)
      
      console.log("Result of removeNode:", result);

      await getQuickReply();
      sendToast(result)
    } catch (error) {
      console.log(error);
    }
  };
  //Función para editar una respuesta rápida
  const putQuickReply = async () => {
    try {
      const {answerName, answerContent} = watch()
      setLoading(true);
      let result_upload = await handleFileUpload(dataFilesRef.current);
      let data_file = result_upload.data_files[0];
      var type = "";
      if(typeFileAnswer === "application/pdf" || typeFileAnswer === "pdf"){type = "pdf"}
      else if (typeFileAnswer.length <= 0){ type = "" }
      else {type = "image"}

      var name = "";
      if(data_file){
        name = data_file.name
      }else{
        name = mediaNameAnswer;
      }

      let urlMedio = imgAnswer;
      if (data_file) {
        urlMedio = data_file.url;
      }

      var newObject = {
        name: answerName,
        content: answerContent,
        media: urlMedio,
        mediaType: type,
        mediaName: name,
        answerSettings: selectedRadio
      };

      if (roleId === "6284fdd697029c6d9743ccb3" || roleId === "64886b1b250f9d31bf2ed68d") {
        if (selectedRadio === "1") {
          newObject.user = answerAgent;
          newObject.area = answerArea;
        } else {
          newObject.area = answerArea;
          newObject.user = "";
        }
      }
    
      const url = urlAPI_1 + `quicklyAnswers/${selectedAnswerId}`;
      const options = { method: "PUT", mode: "cors", body: JSON.stringify(newObject)};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();

      setShowQuickResponses(false);
      reset({ answerName: "", answerContent: "" });
      setEditAnswer(false);
      setCreateAnswer(false);
      setIsImgAnswer(false);
      getQuickReply();
      sendToast(result)
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    };

  };

  const getSignedUrl = async (key_name, size) => {
    try {
      key_name = encodeURIComponent(key_name);
      const url = urlAPI_1 + `getSignedURL/${key_name}/${size}`;
      const options = { method: "GET", mode: "cors"};
      const response = await fetchWithAuth(url, options);
      if (response.status === 200) {
        const url_signed = await response.json();
        if (url_signed.error) {
          return false;
        }
        return url_signed;
      } else {
        console.error('Error getting signed URL');
        return false;
      }
    } catch (error) {
      console.error('Error getting signed URL:', error);
      return false;
    }
  };

  const uploadToS3 = async (file) => {
    try {
      console.log(file);
      const max_size = checkMaxSize(file.file);
      if (max_size) {
        return {error: true, description: "tam_limit_error", data: {name: file.name, size: file.size, file: file.file, type: file.type}};
      } else {
        const signedUrl = await getSignedUrl(file.name, file.file.size);
        console.log("signedUrl: ", signedUrl);
        if (signedUrl) {
          const url = await upload_s3(file.file, signedUrl);
          return {error: false,  data: {url: url, name: file.name, size: file.size, file: file.file, type: file.type}};
        }
        return {error: true, description: "signed_url_error", data: {name: file.name, size: file.size, file: file.file, type: file.type}};
      }
    } catch (error) {
      console.error('Error uploading file...', error);
      return {error: true, description: "unknown_error", data: error, data: {name: file.name, size: file.size, file: file.file, type: file.type}};
    }
  };

  const checkMaxSize = (file) => {
    let max_size = false;
    if(file.type.includes("audio") && file.size > ((1024 * 1024) * 16)){
      max_size = true;
      sendToast({type: "info", message: `El archivo ${file.name} supera el peso máximo permitido para audio: 16MB. Intente enviarlo como documento una vez finalizado el proceso de carga.`})
    }
    if(file.type.includes("video") && file.size > ((1024 * 1024) * 16)){
      max_size = true;
      sendToast({type: "info", message: `El archivo ${file.name} supera el peso máximo permitido para video: 16MB. Intente enviarlo como documento una vez finalizado el proceso de carga.`})
    }
    if(file.type.includes("image") && file.size > ((1024 * 1024) * 5)){
      max_size = true;
      sendToast({type: "info", message: `El archivo ${file.name} supera el peso máximo permitido para imagen: 5MB. Intente enviarlo como documento una vez finalizado el proceso de carga.`})
    }
    if(file.type.includes("document") && file.size > ((1024 * 1024) * 100)){
      max_size = true;
      sendToast({type: "info", message: `El archivo ${file.name} supera el peso máximo permitido para documentos: 100MB. Intente enviarlo como documento una vez finalizado el proceso de carga.`})
    }
    return max_size;
  }

  const handleFileUpload = async (files) => {
    dataFilesRef.current = [];
    let results = {errors: 0, success: 0, error_size: 0, data_files: [], files_errors:[]};
    try {
      const uploadPromises = files.map(async file => {
        return await uploadToS3(file);
      });
      const uploadedUrls  = await Promise.all(uploadPromises);
      uploadedUrls.forEach((result) => {
        if(result.error){
          results.errors += 1;
          results.files_errors.push(result.data.file);
          if(result.description === "tam_limit_error"){
            results.error_size += 1;
          }
        }else{
          results.success += 1;
          results.data_files.push(result.data);
        }
      })
    } catch (error) {
      console.error('Error uploading files...', error);
    }
    return results;
  };

  // /*********************************************** */ ETIQUETAS *******/
  //#--> Listar las etiquetas
  const getTags = async () => {
    try {
      setLoading(true);
      const url = urlAPI_1 + `tags`;
      const options = { method: "GET", mode: "cors"};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      if(!result.data) {
        console.log("no hay data");
        setListTagsAll([]);
        // setValidationTags(true);
      } else {
        console.log("si hay lista");
        setListTagsAll(result.data);
      }
    } catch (error) {
      console.log("error:", error);
      return null;
    } finally {
      setLoading(false);
    };
  }

  const getOrders = async () => {
    try {
      const url = urlAPI_2 + 'getOrders';
      const options = { method: "GET", mode: "cors"};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      if(!result.data) {
        console.log("no hay data");
        setListOrders([]);
      } else {
        console.log("si hay lista");
        setListOrders(result.data);
      }
    } catch (error) {
      console.log("error:", error);
      return null;
    }
  };

  //#--> Lista de etiquetas del contacto
  const getTagContact = async (i) => {
    const idContact = i.slice(2)
    try {
      const url = urlAPI_1 + `getTagContact/${idContact}`;
      const options = { method: "GET", mode: "cors"};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      if(!result.data) {
        setListTags([]);
        setValidationModalTags(true);
      } else {
        const sortedTags = result.data.sort((a, b) => (a.tagActive === b.tagActive) ? 0 : a.tagActive ? -1 : 1);
        setListTags(sortedTags);
        setTableListTags(result.data);
        setValidationModalTags(false);
      }
    } catch (error) {
      console.log("error:", error);
      return null;
    }
  }

  // /*********************************************** */ AREAS *******/
  //#--> Obtener lista de areas
  const getAreas = async () => {
    try {
      const url = urlAPI_2 + "getAreasByCompany";
      const options = { method: "GET", mode: "cors"};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      if (result.code === 0) {
        sendToast(result)
        setListAreas([])
        setSearchArea([])
      }else{
        setListAreas(result.data);
        setSearchArea(result.data)
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangeQuiclyReply = (e) => {
    const inputValue = e.target.value;
    if (e.target.value) {
      setInputStr2Error(false)
    }else {
      setInputStr2Error(true)
    }
    setInputStr2(e.target.value.slice(0, 4096));
    if (inputValue.length >= 4096) {
      // Muestra el mensaje de error
      setShowMaxLengthError(true);
      // Oculta el mensaje de error después de 3 segundos
      setTimeout(() => {
        setShowMaxLengthError(false);
      }, 3000);
    }else{
      setShowMaxLengthError(false);
    }
  };

  //#--> Metodos para ajustar color del select de listas en la info del contacto
  const getIsDark = () => {
    const dark = localStorage.getItem("THEME");
    setIsDark(dark);
  }

  const updateListUserChatsRef = (messages) => {
    if(messages.length){
      // No use localstorage.get('CHAT_SELECTED') porque puede provocar que agregue los mensajes en un chat diferente.
      let phone_chat = messages[0].phoneNumber;
      let auxIndex = -1;
      listUserChatsRef.current.forEach((row, index) => {
        if (row.phoneNumber.slice(-10) === phone_chat.slice(-10)) {
          auxIndex = index;
          return;
        }
      });
      if (auxIndex !== -1) {
        listUserChatsRef.current[auxIndex].messagesList = messages;
      }
      console.log("listUserChatsRef.current: ", listUserChatsRef.current);
    }
  };

  // /*********************************************** */ FLUJOS *******/
  //#--> Listar flujos
  const getFlows = async () => {
    try {
      const url = urlAPI_2 + "flows";
      const options = { method: "GET", mode: "cors"};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      if (!result.data) {
        setFlows([]);
        setFlowsValidate(true)
      } else {
        setFlows(result.data);
        setFlowsValidate(false)
      }
    } catch (error) {
      console.log(error);
    }
  };

  //#--> Guardar id del flujo seleccionado a enviar
  const saveIdFlow = async (data) => {
    setFlowIdSelected(data.idFlow)
    setModalFlowSend(true);
    setModalFlowSelected(false)
  }

  //#--> Enviar mensaje tipo flujo
  const sendFlow = async (data) => {
    setModalFlowSend(false)
    onSendMessage({})
  }

  //#--> Función que ayuda a cargar y validar la importación de los archivos para el header del flujo
  const uploadFileFlow = (e) => {
    //#--> Definiendo el tipo de archivo permitido
    const fileType = [
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      ".pdf",
      "application/pdf",
      ".csv",
      "text/plain",
      ".doc",
      ".docx",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "image/gif",
      "image/jpeg",
      "image/jpg",
      "image/png"
    ];

    let selectedFile = e.target.files[0];
    // formSendFlow2.reset({medioFlow: selectedFile})
    if (selectedFile) {
      if (selectedFile && fileType.includes(selectedFile.type)) {
        let reader = new FileReader();
        reader.readAsArrayBuffer(selectedFile);
        reader.onload = (e) => {
          setExcelFile(null);
          setExcelFile(e.target.result);
        };
      } else {
        setExcelFile(null);
        sendToast({type: "warning", message: "Por favor elige el formato correcto."})
      }
    }
    onFileFlow(e)
  };

  // /*********************************************** */ CATALOGO *******/
  //#--> Listar productos
  const getProducts = async () => {
    try {
      setLoading(true);
      const url = urlAPI_2 + "products";
      const options = { method: "GET", mode: "cors"};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      if (result.type === "error") {
        sendToast(result)
      } else if (result.data.length === 0) {
        setListProducts([]);
        setListProducts2([]);
        setListProductsTable([]);
        // setValidationModalProducts(true)
        setProductsValidate(true)
      } else {
        setCatalog(result.data[0].catalogId)
        setListProducts(result.data)
        setListProducts2(result.data)
        setListProductsTable(result.data)
        // setValidationModalProducts(false)
        setProductsValidate(false)
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    };
  };

  //#--> Listar sets
  const getSets = async () => {
    try {
      const url = urlAPI_2 + "sets";
      const options = { method: "GET", mode: "cors"};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      if (result.data.length === 0) {
        setListSets([]);
        setListSetsTable([]);
        setSelectedSets([]);
        setValidationSets(true)
      } else {
        setListSets(result.data)
        setListSetsTable(result.data)
        setSelectedSets(result.data[0].id)
        setValidationSets(false)

      }
    } catch (error) {
      console.log(error);
    }
  };

  //#--> Listar Stages
  const getStages = async () => {
    try {
      setLoading(true);
      const url = urlAPI_3 + 'stages';
      const options = { method: "GET", mode: "cors"};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      if(result.length <= 0) {
        setListStages([]);
      } else {
        setListStages(result);
      }
    } catch (error) {
      console.log("error:", error);
      return null;
    } finally {
      setLoading(false);
    };
  };

  //#--> Buscador en la lista de productos
  const onSearchProducts = (searchTerm) => {
    var searchResults = listProductsTable.filter((element) => {
      if (element.name.toString().toLowerCase().includes(searchTerm.toLowerCase())) {
        return element;
      }
    });
    setListProducts2(searchResults);
    if (searchResults.length === 0) {
      setValidationModalProducts(true);
    } else {
      setValidationModalProducts(false);
    }
  };

  //#--> Guardar productos seleccionados
  const saveTypeSendProducts = async (data) => {
    setModalSendProducts(false);
    if (selectedTypeProducts == 2) {
      setSelectedTypeProducts("1")
      onSendMessage({})
    } else {
      setModalCatalogSend(true)
    }
  }

  //#--> Enviar los productos al cliente
  const sendProducts = async (data) => {
    setModalCatalogSend(false)
    setSelectedTypeProducts("1")
    onSendMessage({})
  }

  //#--> Buscador en la lista de conjuntos
  const onSearchSets = (searchTerm) => {
    var searchResults = listSetsTable.filter((element) => {
      if (element.name.toString().toLowerCase().includes(searchTerm.toLowerCase())) {
        return element;
      }
    });
    setListSets(searchResults);
    if (searchResults.length === 0) {
      setValidationSets(true);
    } else {
      setValidationSets(false);
    }
  };

  //#--> Hacer focus al mensaje que enviaron en contexto
  const onViewMenssageContext = async (messageId, messageContextType, textContext) => {
    const messageElement = document.getElementById(`message-${messageId}`);
    if (messageElement) {
      messageElement.scrollIntoView({ behavior: 'smooth', block: 'center' });

      messageElement.classList.add('focused-message');
      setTimeout(() => {
        messageElement.classList.remove('focused-message');
      }, 1000);

    } else if (messageContextType == "image") {
      setIsViewImage(true)
      setUrlImgContext(textContext)
    } else {
      onScrollTop("context")
      focusScrollMessageControl.current = false;
      focusLastMessageControlByInputFocus.current = false
      focusLastMessageControl.current = false
      focusScrollMessageHeight.current = 0
      focusLastMessageContext.current = true;
      setIdMessageContext(messageId)
      setNumberPage(4);
    }
  }

  const getAllLists = async () => {
    try {
        const url = `${urlAPI_1}lists`;
        const options = { method: "GET", mode: "cors"};
        const response = await fetchWithAuth(url, options);
        const result = await response.json();

        if (result.type === "success") {
          listsByCompany.current = result.data;
        } else {
          listsByCompany.current = [];
        }
    } catch (error) {
        console.error("Error al obtener todas las listas:", error);
        listsByCompany.current = [];
    }
  };

  const checkForTabSwitch = async () => {
    try {
      console.log("checkForTabSwitch...");
      const chatContext = localStorage.getItem("CHAT_SELECTED");
      const user_name = localStorage.getItem("USER_NAME");
      let chat_status = null;
      for(let row_chat of listUserChatsRef.current){
        if(row_chat.phoneNumber.slice(-10) == chatContext.slice(-10)){
          chat_status = row_chat;
        }
      }
      if(!chat_status || !chatContext){
        return;
      }
      setStatusChat(chat_status.statusConv);
      if (chat_status.userAsign && chat_status.userAsign != "chatbot" && chat_status.userAsign != "bot") {
        setIsAssignedAgent(false);
      }else{
        setIsAssignedAgent(true);
      }
      if(filterRef.current == "mine"){
        /*
        Si está en la solapa "mios" y es admin: 
         1- El chat seleccionado tiene status "abierto" y está asignado al agente actual: No hace nada, retorna. 
         2- El chat seleccionado tiene status "pendiente" entonces se debe switchear a la solapa "pendientes". 
         3- En cualquier otro caso se debe switchear a "todos". 
        Si está en la solapa "mios" y no es admin:
         1- El chat seleccionado tiene status "abierto" y está asignado al agente actual: No hace nada, retorna. 
         2- El chat seleccionado tiene status "pendiente" y está asignado al agente actual entonces se debe switchear a la solapa "pendientes". 
         3- En cualquier otro caso se debe switchear a "todos". 
        */
        if(chat_status.userAsign == user_name && chat_status.statusConv == "1"){
          return
        }
        if(roleId == "6284fdd697029c6d9743ccb3" || roleId == "64886b1b250f9d31bf2ed68d"){
          if(chat_status.statusConv == "3" && chat_status.userAsign != "chatbot" && chat_status.userAsign != "bot"){
            console.log("Pasando a solapa pendientes...");
            getConversationsPending();
          }else if(chat_status.statusConv == "2"){
              // Debo sacarlo de la conversacion.
              setStartChat((prevMessage) => { return false });
              setTemplateSelected((prevMessage) => { return [] });
              setIsChatSelected((prevMessage) => { return false });
              setNotChatSelected((prevMessage) => { return true });
              setShowContactInfo((prevMessage) => { return false });
              setIsBlurred((prevMessage) => { return false });
          }else{
            console.log("Pasando a solapa todos...");
            getConversations();
          }
        }else{
          if(chat_status.statusConv == "3" && (chat_status.userAsign == user_name || chat_status.userAsign == "")){
            console.log("Pasando a solapa pendientes...");
            getConversationsPending()
          }else if(chat_status.userAsign == user_name){
            console.log("Pasando a solapa todos...");
            getConversations();
          } else {
            // mostrar logo. 
          }
        }
      }else if(filterRef.current == "all"){
        /*
        Si está en la solapa "todos" y es admin: 
         1- El chat seleccionado tiene status "abierto" y está asignado al agente actual: No hace nada, retorna. 
         2- El chat seleccionado tiene status "pendiente" entonces se debe switchear a la solapa "pendientes". 
         3- En cualquier otro caso se debe switchear a "todos". 
        Si está en la solapa "mios" y no es admin:
         1- El chat seleccionado tiene status "abierto" y está asignado al agente actual: No hace nada, retorna. 
         2- El chat seleccionado tiene status "pendiente" y está asignado al agente actual entonces se debe switchear a la solapa "pendientes". 
         3- En cualquier otro caso se debe switchear a "todos". 
        */
        return; 
        if(chat_status.statusConv == "2"){
          console.log("chat_status.statusConv: ", chat_status.statusConv);
          return
        }
        if(roleId == "6284fdd697029c6d9743ccb3" || roleId == "64886b1b250f9d31bf2ed68d"){
          if(chat_status.statusConv == "3" && chat_status.userAsign != "chatbot" && chat_status.userAsign != "bot"){
            console.log("Pasando a solapa pendientes...");
            getConversationsPending()
          } else if(chat_status.statusConv == "1" && chat_status.userAsign == user_name ){
            console.log("Pasando a solapa mios...");
            getConversationsAsign()
          } 
          /*
          else {
              console.log("Sacando de conversacion");
              // Debo sacarlo de la conversacion.
              setStartChat((prevMessage) => { return false });
              setTemplateSelected((prevMessage) => { return [] });
              setIsChatSelected((prevMessage) => { return false });
              setNotChatSelected((prevMessage) => { return true });
              setShowContactInfo((prevMessage) => { return false });
              setIsBlurred((prevMessage) => { return false });
          }
          */
        }else{

          if(chat_status.statusConv == "3" && (chat_status.userAsign == "" || chat_status.userAsign == user_name)){
            console.log("Pasando a solapa pendientes...");
            getConversationsPending();
          }else if(chat_status.statusConv == "1" && chat_status.userAsign == user_name ){
            console.log("Pasando a solapa mios...");
            getConversationsAsign();
          }else{
              console.log("Sacando de conversacion");
              // Debo sacarlo de la conversacion.
              setStartChat((prevMessage) => { return false });
              setTemplateSelected((prevMessage) => { return [] });
              setIsChatSelected((prevMessage) => { return false });
              setNotChatSelected((prevMessage) => { return true });
              setShowContactInfo((prevMessage) => { return false });
              setIsBlurred((prevMessage) => { return false });
          }
        }
      }else if(filterRef.current == "pending"){
        /*
        Si está en la solapa "mios" y es admin: 
         1- El chat seleccionado tiene status "abierto" y está asignado al agente actual: No hace nada, retorna. 
         2- El chat seleccionado tiene status "pendiente" entonces se debe switchear a la solapa "pendientes". 
         3- En cualquier otro caso se debe switchear a "todos". 
        Si está en la solapa "mios" y no es admin:
         1- El chat seleccionado tiene status "abierto" y está asignado al agente actual: No hace nada, retorna. 
         2- El chat seleccionado tiene status "pendiente" y está asignado al agente actual entonces se debe switchear a la solapa "pendientes". 
         3- En cualquier otro caso se debe switchear a "todos". 
        */
        if(roleId == "6284fdd697029c6d9743ccb3" || roleId == "64886b1b250f9d31bf2ed68d"){
          if(chat_status.statusConv == "3" && chat_status.userAsign != "chatbot" && chat_status.userAsign != "bot"){
            return
          }
          if(chat_status.statusConv == "1"){
            console.log("Pasando a solapa mios...");
            getConversationsAsign();
          }else{
            console.log("Pasando a solapa todos...");
            getConversations();
          }
        }else{
          if(chat_status.statusConv == "3" && (chat_status.userAsign == "" || chat_status.userAsign == user_name)){
            return
          }
          if(chat_status.statusConv == "1" && chat_status.userAsign == user_name){
            console.log("Pasando a solapa mios...");
            getConversationsAsign();
          }else if(chat_status.userAsign == user_name){
            console.log("Pasando a solapa todos...");
            getConversations();
          } else{
              // Debo sacarlo de la conversacion.
              setStartChat((prevMessage) => { return false });
              setTemplateSelected((prevMessage) => { return [] });
              setIsChatSelected((prevMessage) => { return false });
              setNotChatSelected((prevMessage) => { return true });
              setShowContactInfo((prevMessage) => { return false });
              setIsBlurred((prevMessage) => { return false });
          }
        }
      }

      return;
    } catch (error) {
        console.error("Error checkForTabSwitch:", error);
    }
  };

  //#--> Obtener metodos de pago configurados.
  const getMethods = async () => {
    try {
      setLoading(true);
      const url = urlAPI_2 + "getPaymentMethods";
      const options = { method: "GET", mode: "cors"};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();
      if (result.type == "info") {
        setIsMethodActive(false)
        setMethodsPayment([]);
      } else {
        if (result.data.methods.length > 0) {
          const methodsActive = result.data.methods.filter(method => method.isActive);
          setIsMethodActive(methodsActive.length > 0);
          setMethodsPayment(methodsActive);
        } else {
          setIsMethodActive(false)
          setMethodsPayment([]);
        }
      }
    } catch (error) {
      console.log('Error---->', error)
    } finally {
      setLoading(false);
    };
  }

  //#--> Generar link de cobro para enviar el cliente
  const generatePaymentLink = async (data) => {
    console.log('data::', data);

    try {
      setLoading(true);
      const url = urlAPI_2 + "generatePaymentLink";
      if (methodsPayment.length === 1) {
        data.methodPayment = methodsPayment[0].platform;
      }
      data.totalOrder = data.totalOrder.replace('$', '');
      const options = { method: "POST", mode: "cors", body: JSON.stringify(data)};
      const response = await fetchWithAuth(url, options);
      const result = await response.json();

      setModalGenerateLink(false)
      if (result.status == "error") {
        sendToast(result)
      } else {
        const qr = result.data.data.routeQr;
        const link = result.data.data.routeLink;
        const textLink =  `En el siguiente enlace puede realizar el pago: ${link}`
        setInputStr(textLink)
        if (inputRef.current) {
          inputRef.current.focus();
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    };
  };

  const handlePaste = async (event) => {
    const items = event.clipboardData.items;
    if (items[0].type.indexOf('image') !== -1) {
      const listImgPaste = await Promise.all(Array.from(items).map(item => {
        if (item.type.indexOf('image') !== -1) {
          return new Promise((resolve, reject) => {
            const blob = item.getAsFile();
            resolve(blob);
          });
        }
      }));
  
      const validImgPaste = listImgPaste.filter(Boolean);
      onFileInput(event, "image", validImgPaste)
    }
  };

  let lastPendingCount = 0;
  const getConversationsPendingCount = async () => {
    let url = urlAPI_1 + "getConversationsCount" + "?list=pending";
    const options = { method: "GET", mode: "cors"};
    const response = await fetchWithAuth(url, options);
    const result = await response.json();
    console.log("result Pending Count: ", result.data);
    
    console.log("---> lastPendingCount 🔢: ", lastPendingCount);
    if (result.data > lastPendingCount) {
      console.log("🚀Ejecutando notificación🚀");
      console.log(" 🔜🔙🔜🔙🔜🔙🔜🔙🔜🔙🔜🔙🔜🔙 ");
      var vol = 0.0;
      var parVol = "highVol"
      if (parVol == "highVol") {
        vol = 0.9;
      }

      const sound = new Howl({
        src: [toneNotifyPending],
        volume: vol,
        preload: true,
        autoplay: true,
        html5: true
      });

      console.log("📣📣play Pendientes...");
      sound.play();
    }
    lastPendingCount = result.data;
    setConversationsPendingCount(result.data);
    return;
  };

  const getConversationsAssignedCount = async () => {
    let url = urlAPI_1 + "getConversationsCount" + "?list=asign";
    const options = { method: "GET", mode: "cors"};
    const response = await fetchWithAuth(url, options);
    const result = await response.json();
    console.log("Result assigned Count: ", result.data);
    setConversationsAssignedCount(result.data);
    return;
  };

  const getConversationsCount = async () => {
    getConversationsPendingCount();
    getConversationsAssignedCount();
  };

  return (
    <Fragment>
      {loading && (
        <div className="content-spinner">
          <Spinner statusLoading={statusLoading} />
        </div>
      )}
      {loadingNew && (
        <div className="content-spinner">
          <SpinnerNew />
        </div>
      )}

      {/*-------- popup de inactividad ----------*/}
      {inactivityPopup && (
        <InactivityPopup setInactivityPopup={setInactivityPopup} connectDispatcher={connectDispatcher} socketConn={socketConn} closedByInactivity={closedByInactivity}/>
      )}

      <div className="d-flex p-1 h-100">
        <section className={`module-chat lists ${classChat && "active"}`} style={{ width: "27%" }}>
          {newChat ? (
            <ListContacts 
              setNewChat = {setNewChat}
              setSearchConversation = {setSearchConversation}
              setSearchPersons = {setSearchPersons}
              setClassChat = {setClassChat}
              searchConversation = {searchConversation}
              searchPersons = {searchPersons}
              persons = {persons}
              onSearchPersons = {onSearchPersons}
              onStartChat = {onStartChat}
              getIsDark = {getIsDark}
              loadingContacts = {loadingContacts}
              searchQuery = {searchQuery}
            />
          ) : (
            <ListConversations 
              setNewChat = {setNewChat}
              setSearchConversation = {setSearchConversation}
              setActiveNotification = {setActiveNotification}
              setClassChat = {setClassChat}
              activeNotification = {activeNotification}
              numberPageConversations = {numberPageConversations}
              totalPagesConversations = {totalPagesConversations}
              filterRef = {filterRef}
              filter = {filter}
              filterState = {filterState}
              listTagsAll = {listTagsAll}
              validationChats = {validationChats}
              listUserChats = {listUserChats}
              listUserChatsRef = {listUserChatsRef}
              loadingChats = {loadingChats}
              textConversations = {textConversations}
              onChatSelected = {onChatSelected}
              getTagContact = {getTagContact}
              getConversationsAsign = {getConversationsAsign}
              getConversations = {getConversations}
              getConversationsPending = {getConversationsPending}
              getConversationsNoAsign = {getConversationsNoAsign}
              getConversationsBots = {getConversationsBots}
              conversationsListRef = {conversationsListRef}
              fetchConversations = {fetchConversations}
              previousScrollHeightRef = {previousScrollHeightRef}
              previousScrollTopRef = {previousScrollTopRef}
              conversationsPendingCount = {conversationsPendingCount}
              conversationsAssignedCount = {conversationsAssignedCount}
              filterByStatusAndTagsRef = {filterByStatusAndTagsRef}
            />
          )}
        </section>

        {isChatSelected && (
          <section 
            className={`module-chat chat mx-1 ${classChat && "active"} ${classInfo && "info"}`} 
            style={showContactInfo ? { width: "46%" } : { width: "73%" }}
            onPaste={handlePaste}
            ref={sectionChat}
          >
            <MessagesContentHeader 
              showContactInfo = {showContactInfo}
              classInfo = {classInfo}
              idChatSelected = {idChatSelected}
              nameInitials = {nameInitials}
              contactName = {contactName}
              isAssignedAgent = {isAssignedAgent}
              statusChat = {statusChat}
              isSavedChat = {isSavedChat}
              listStatus = {listStatus}
              listAreas = {listAreas}
              listTags = {listTags}
              tableListTags = {tableListTags}
              searchArea = {searchArea}
              validationModalTags = {validationModalTags}
              phoneContact = {phoneContact}
              feeling = {feeling}
              isFeeling = {isFeeling}
              interestedBuying = {interestedBuying}
              isInterestedBuying= {isInterestedBuying}
              permissions = {permissions}
              token = {token}
              setLoading = {setLoading}
              setClassChat = {setClassChat}
              setShowContactInfo = {setShowContactInfo}
              setClassInfo = {setClassInfo}
              setNotChatSelected = {setNotChatSelected}
              setIsChatSelected = {setIsChatSelected}
              setModalTransferAgent = {setModalTransferAgent}
              setListAreas = {setListAreas}
              setListTags = {setListTags}
              setValidationModalTags = {setValidationModalTags}
              onChatSelected = {onChatSelected}
              onChatStatus = {onChatStatus}
              getIsDark = {getIsDark}
              getTagContact = {getTagContact}
              updateConversations = {updateConversations}
            />

            {isMedia ? (
              <PreviewMediaToSend 
                isMediaAnswer = {isMediaAnswer}
                isMediaTypeAnswer = {isMediaTypeAnswer}
                selectedImg = {selectedImg}
                fileType = {fileType}
                documentView = {documentView}
                mediaSelected = {mediaSelected}
                selectedFiles = {selectedFiles}
                textDocument = {textDocument}
                inputRef = {inputRef}
                idAnswerSelected = {idAnswerSelected}
                setSelectedImg = {setSelectedImg}
                setMediaSelected = {setMediaSelected}
                setTextDocument = {setTextDocument}
                setIsMedia = {setIsMedia}
                setFileType = {setFileType}
                setDocumentView = {setDocumentView}
                setContenMessage = {setContenMessage}
                setSelectedFiles = {setSelectedFiles}
                dataFilesRef = {dataFilesRef}
                focusLastMessage = {scrollToBottom}
                handleRemoveFile = {handleRemoveFile}
                keyDown = {keyDown}
                onSendMessageMedia = {onSendMessageMedia}
              />
            ) : (<>
              <form className="form-chat position-relative" style={{height: "91%"}}>
                <LoadingMessages loadingMessages = {loadingMessages} isIphone = {isIphone} focusLastMessage = {scrollToBottom}/>

                <div 
                  id="cont-chat" 
                  className={`mb-1 px-4 pt-3 pb-4 conversation ${!isTemplateSend && !isAssignedAgent && chatClose ? "isSend" : null } ${!isTemplateSend && isAssignedAgent ? "asignAgent" : null} ${chatCloseInactivity && "is-send-template-form"}`} 
                  onScroll={() => onScrollTop()} 
                  style={{height:"90%"}}
                  ref={contChatRef}
                >
                  <div className={`- ${isBlurred ? 'blurred' : ''}`}>
                    <MessageListContents 
                      listMessages = {listMessages}
                      setListMessages  = {setListMessages}
                      templates = {templates}
                      imgMap = {imgMap}
                      setListContacts = {setListContacts}
                      setModalListContacts = {setModalListContacts}
                      showContactInfo = {showContactInfo}
                      videoSelected = {videoSelected}
                      listProducts = {listProducts}
                      formGenerateLink = {formGenerateLink}
                      onViewMenssageContext = {onViewMenssageContext}
                      setLoading = {setLoading}
                      onViewImage = {onViewImage}
                      setModalViewProductContext = {setModalViewProductContext}
                      setProductContextSelected = {setProductContextSelected}
                      setModalViewOrder = {setModalViewOrder}
                      setQuantityOrder = {setQuantityOrder}
                      setTotalPriceOrder = {setTotalPriceOrder}
                      setOrderSelected = {setOrderSelected}
                    />
                    <div ref={chatEndRef}></div>
                  </div>
                </div>

                {isTemplateSend ? (
                  <>
                    <div className={isRecor ? "d-flex px-2 justify-content-end align-items-center isRecor" : "content-btn-status d-flex px-2"} style={isRecor ? {height:"10%" } : {position: "relative", height:"10%" }}> 

                      {chatClose ? (<>

                        {!recording && !isRecor && (
                          <div className="d-flex align-items-center">
                            {closeEmojis && (
                              <IoClose
                                className="icon-cancel ml-1 d-flex pointer"
                                style={{
                                  fontSize: "2rem",
                                  alignItems: "center",
                                  zIndex: "999"
                                }}
                                onClick={() => {
                                  setShowPicker((val) => !val);
                                  setCloseEmojis(false);
                                }}
                              />
                            )}
                            <FaRegFaceLaugh id="emojis-chat" className={showPicker ? "icon-smile icon-chat active d-flex mx-1" : "icon-smile icon-chat d-flex mx-1"}
                              style={{ fontSize: "28px", alignItems: "center", zIndex: "998" }}
                              onClick={() => {
                                setShowPicker((val) => !val);
                                if (closeEmojis) {
                                  setCloseEmojis(false);
                                } else {
                                  setCloseEmojis(true);
                                }
                              }}
                              data-tooltip-id="my-tooltip" 
                              data-tooltip-content="Enviar emojis"
                            />
                            {/* Mostrar lista de emojis */}
                            {showPicker && (
                              <Picker
                                pickerStyle={{
                                  width: "100%",
                                  left: "0px",
                                  position: "absolute",
                                  zIndex: "1",
                                  bottom: "4.9em",
                                }}
                                onEmojiClick={onEmojiClick}
                                groupNames={{
                                  smileys_people: "Emoticones y personas",
                                  animals_nature: "Animales y naturaleza",
                                  food_drink: "Alimentos y bebidas",
                                  travel_places: "Viajes y lugares",
                                  activities: "Actividades",
                                  objects: "Objetos",
                                  symbols: "Símbolos",
                                  flags: "Banderas",
                                  recently_used: "Recientes",
                                }}
                              />
                            )}

                            {/* Mostrar opciones de envios */}
                            <ShippingOptions 
                              viewAnswers = {viewAnswers}
                              quicklyAnswers = {quicklyAnswers}
                              formSendFlow = {formSendFlow}
                              formSendFlow2 = {formSendFlow2}
                              formSendCatalog = {formSendCatalog}
                              formSendCatalog2 = {formSendCatalog2}
                              formGenerateLink = {formGenerateLink}
                              setViewAnswers = {setViewAnswers}
                              setStartChat = {setStartChat}
                              setModalTemplateSend = {setModalTemplateSend}
                              setIsChatSelected = {setIsChatSelected}
                              setNotChatSelected = {setNotChatSelected}
                              setIsTemplateSend = {setIsTemplateSend}
                              setContent = {setContent}
                              setIsUrlDynamic = {setIsUrlDynamic}
                              setIsUrlDynamic2 = {setIsUrlDynamic2}
                              setIdTemplate = {setIdTemplate}
                              setNumberParams = {setNumberParams}
                              setTemplateSelected = {setTemplateSelected}
                              setListButtonsUrlDynamic = {setListButtonsUrlDynamic}
                              setModalFlowSelected = {setModalFlowSelected}
                              setHeaderSelected = {setHeaderSelected}
                              setTextHeaderFlow = {setTextHeaderFlow}
                              setMedioSelected = {setMedioSelected}
                              setTextBodyFlow = {setTextBodyFlow}
                              setTextFooterFlow = {setTextFooterFlow}
                              setTextBottonFlow = {setTextBottonFlow}
                              setSenFlowOrProducts = {setSenFlowOrProducts}
                              setModalSendProducts = {setModalSendProducts}
                              setSelectProductsSend = {setSelectProductsSend}
                              setTextHeaderCatalog = {setTextHeaderCatalog}
                              setSelectedTypeProducts = {setSelectedTypeProducts}
                              setModalGenerateLink = {setModalGenerateLink}
                              setEditAnswer = {setEditAnswer}
                              setCreateAnswer = {setCreateAnswer}
                              setSelectedAnswerId = {setSelectedAnswerId}
                              reset = {reset}
                              setInputStr2 = {setInputStr2}
                              setAnswerAgent = {setAnswerAgent}
                              setAnswerArea = {setAnswerArea}
                              setSelectedRadio = {setSelectedRadio}
                              setIsImgAnswer = {setIsImgAnswer}
                              setImgAnswer = {setImgAnswer}
                              setTypeFileAnswer = {setTypeFileAnswer}
                              setMediaNameAnswer = {setMediaNameAnswer}
                              setAnswerAgentError = {setAnswerAgentError}
                              setAnswerAreaError = {setAnswerAreaError}
                              setAnswerTitleError = {setAnswerTitleError}
                              setInputStr2Error = {setInputStr2Error}
                              setAnswerTitle = {setAnswerTitle}
                              setViewSelectedImage = {setViewSelectedImage}
                              setTemplateWhatsapp = {setTemplateWhatsapp}
                              onFileInput = {onFileInput}
                              onSendMessage = {onSendMessage}
                              getIsDark = {getIsDark}
                              deleteQuickReply = {deleteQuickReply}
                              setScheduleShipping = {setScheduleShipping}
                            />

                            {/* Mostrra lista de respuestas rápidas al escribir / en el input */}
                            {viewAnswer && (
                              <QuickAnswerList 
                                quicklyAnswers = {quicklyAnswers}
                                setIsMedia = {setIsMedia}
                                setIsMediaAnswer = {setIsMediaAnswer}
                                setIsMediaTypeAnswer = {setIsMediaTypeAnswer}
                                setTextDocument = {setTextDocument}
                                setViewAnswer = {setViewAnswer}
                                setInputStr = {setInputStr}
                                setIdAnswerSelected = {setIdAnswerSelected}
                                inputRef = {inputRef}
                                getQuickReply = {getQuickReply}
                              />
                            )}
                          </div>
                        )}

                        {/* Campo textarea para enviar mensajes o buscar respuestas rápidas */}
                        {!recording && !isRecor && (
                          <TextareaSendMessage 
                            inputStr = {inputStr}
                            inputRef = {inputRef}
                            searchQuicklyAnswer = {searchQuicklyAnswer}
                            setContenMessage = {setContenMessage}
                            setInputStr = {setInputStr}
                            setViewAnswer = {setViewAnswer}
                            setQuicklyAnswers = {setQuicklyAnswers}
                            keyDown = {keyDown}
                            msgIA = {msgIA}
                            setMsgIA = {setMsgIA}
                          />
                        )}

                        <SendMessageOrRecordAudio 
                          recording = {recording}
                          contenMessage = {contenMessage}
                          selectedEmoji = {selectedEmoji}
                          isRecor = {isRecor}
                          audioDetails = {audioDetails}
                          dataFilesRef = {dataFilesRef}
                          setIsMedia = {setIsMedia}
                          setFileType = {setFileType}
                          setIsRecor = {setIsRecor}
                          setAudioDetails = {setAudioDetails}
                          setRecording = {setRecording}
                          setSelectedFiles = {setSelectedFiles}
                          onSendMessage = {onSendMessage}
                          convertRecorBase64 = {convertRecorBase64}
                          focusLastMessage = {scrollToBottom}
                        />

                      </>): (
                        <OpenConversationMessage 
                          statusChat = {statusChat}
                          setChatClose = {setChatClose}
                          onChatStatus = {onChatStatus}
                          onChatSelected = {onChatSelected}
                        />
                      )}

                    </div>
                  </>
                ) : ( 
                  <>
                    {isAssignedAgent ? (
                      <AssingConversationMessage
                        roleId = {roleId}
                        agentAssigned = {agentAssigned}
                        setPhoneContact = {setPhoneContact}
                        setModalTransferAgent = {setModalTransferAgent}
                        onAssingAgent = {onAssingAgent}
                      />
                    ):(
                      <div id={chatCloseInactivity && "is-send-template-btn"} className="content-btn-send-template d-flex px-2 justify-content-center align-items-center text-center" style={{ height: "10%", marginBottom: "6px" }}>
                        {chatClose ? (
                          <>
                            {chatCloseInactivity ? (
                              <MessageSendTemplate 
                                showContactInfo = {showContactInfo}
                                setStartChat = {setStartChat}
                                setModalTemplateSend = {setModalTemplateSend}
                                setIsChatSelected = {setIsChatSelected}
                                setNotChatSelected = {setNotChatSelected}
                                setIsTemplateSend = {setIsTemplateSend}
                                setContent = {setContent}
                                setIsUrlDynamic = {setIsUrlDynamic}
                                setIsUrlDynamic2 = {setIsUrlDynamic2}
                                setIdTemplate = {setIdTemplate}
                                setNumberParams = {setNumberParams}
                                setTemplateSelected = {setTemplateSelected}
                                getIsDark = {getIsDark}
                              />
                            ): <></>}
                          </>
                        ):(
                          <OpenConversationMessage 
                            statusChat = {statusChat}
                            setChatClose = {setChatClose}
                            onChatStatus = {onChatStatus}
                            onChatSelected = {onChatSelected}
                          />
                        )}
                      </div>
                    )}
                  </>
                )}

              </form>
              {isViewImage && (
                <ImagePreview 
                  setIsViewImage={setIsViewImage}
                  setViewImgAnswer={setViewImgAnswer}
                  focusLastMessage={scrollToBottom}
                  viewImgAnswer={viewImgAnswer}
                  viewSelectedImage={viewSelectedImage}
                  imgAnswer={imgAnswer}
                  scrollPosition={scrollPosition}
                  urlImgContext={urlImgContext}
                />
              )}
            </>)}
          </section>
        )}

        {notChatSelected && (
          <NoChatSelected showContactInfo = {showContactInfo} />
        )}

        {startChat && (
          <SendTemplate 
            formSendTemplate = {formSendTemplate}
            contactNameSelected = {contactNameSelected}
            contactName = {contactName}
            isDark = {isDark}
            templates = {templates}
            templateHeader = {templateHeader}
            templateSelected = {templateSelected}
            templateWhatsapp = {templateWhatsapp}
            contentHeader = {contentHeader}
            error7 = {error7}
            numberParams = {numberParams}
            listButtonsUrlDynamic = {listButtonsUrlDynamic}
            videoSelected = {videoSelected}
            contentTextHeader = {contentTextHeader}
            fileType = {fileType}
            selectedFiles = {selectedFiles}
            content = {content}
            date = {date}
            listUserChats = {listUserChats}
            listButtonsCallToAction = {listButtonsCallToAction}
            listButtonsQuickReply = {listButtonsQuickReply}
            timerConectionChat = {timerConectionChat}
            loadingSendTemplate = {loadingSendTemplate}
            onSendMessage = {onSendMessage}
            onFileInputTemplate = {onFileInputTemplate}
            focusLastMessage = {scrollToBottom}
            setIdTemplate = {setIdTemplate}
            setContent = {setContent}
            setNumberParams = {setNumberParams}
            setIsUrlDynamic = {setIsUrlDynamic}
            setIsUrlDynamic2 = {setIsUrlDynamic2}
            setListButtonsCallToAction = {setListButtonsCallToAction}
            setListButtonsQuickReply = {setListButtonsQuickReply}
            setTemplateSelected = {setTemplateSelected}
            setTemplateWhatsapp = {setTemplateWhatsapp}
            setTemplateHeader = {setTemplateHeader}
            setContentHeader = {setContentHeader}
            setContentTextHeader = {setContentTextHeader}
            setListButtonsUrlDynamic = {setListButtonsUrlDynamic}
            setNotChatSelected = {setNotChatSelected}
            setStartChat = {setStartChat}
            setIsChatSelected = {setIsChatSelected}
            setIsTemplateSend = {setIsTemplateSend}
            setMessageScheduleDate = {setMessageScheduleDate}
            messageScheduleDate = {messageScheduleDate}
            scheduleShipping = {scheduleShipping} 
            setScheduleShipping = {setScheduleShipping}
          />
        )}

        {showContactInfo && (
          <ContactInfo 
            isDark = {isDark}
            userLogout = {userLogout}
            classInfo = {classInfo}
            nameInitials = {nameInitials}
            contactName = {contactName}
            phoneContact = {phoneContact}
            fieldsList = {fieldsList}
            listNotes = {listNotes}
            isSavedChat = {isSavedChat}
            listsContact = {listsContact}
            allLists = {allLists}
            responsibleContact = {responsibleContact}
            listTags = {listTags}
            listUserChats = {listUserChats}
            idContactSelected = {idContactSelected}
            setShowContactInfo = {setShowContactInfo}
            setClassInfo = {setClassInfo}
            setContactname = {setContactname}
            setContactNameSelected = {setContactNameSelected}
            setNameInitials = {setNameInitials}
            setListsContact = {setListsContact}
            chatSelected = {chatSelected}
            formPutPerson = {formPutPerson}
            error8 = {error8}
            getNotes = {getNotes}
            listStages = {listStages}
            getStages = {getStages}
            orders = {ordersFilteredInfo}
            feeling = {feeling}
            setListFeelings = {setListFeelings}
            listFeelings = {listFeelings}
            listInterestedBuying = {listInterestedBuying}
          />
        )}
      </div>
      
      <Tooltip 
        id="my-tooltip" 
        style={{ zIndex: 9999, backgroundColor: "black", color: "white", padding: "5px 10px", borderRadius: "5px", whiteSpace: "pre-line", maxWidth: "500px" }} 
      />

      {/* Modal de agregar información para enviar y generar link de cobro */}
      <Modal
        status={modalGenerateLink}
        changeStatus={setModalGenerateLink}
        title= "Generar link de cobro"
        width={"600px"}
      >
        <Content>
          <form style={{ width: "100%" }} onSubmit={formGenerateLink.handleSubmit(generatePaymentLink)}>
            <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable scrollable-content scrollable-content-overflow">

              {isMethodActive ? <>
                <div className="mb-2 col-md-12">
                  <label>Título de la transacción: </label>
                  <div className="su-control-group d-flex">
                    <input
                      className="form-control"
                      type="text"
                      name="answerContent"
                      {...formGenerateLink.register("titleOrder", {
                        required: {
                          value: true,
                          message:"El título es requerido.",
                        },
                        pattern: {
                          value: /\S+/,
                          message: "No puede ser solo espacios en blanco",
                        },
                      })}
                    />
                  </div>
                  {error6.titleOrder && <Alert className="w-100">* {error6.titleOrder.message}</Alert>}
                </div>

                <div className="mb-2 col-md-12">
                  <label>Descripción de la transacción: </label>
                  <div className="su-control-group d-flex">
                    <textarea
                      className="form-control p-2"
                      type="text"
                      name="answerContent"
                      {...formGenerateLink.register("descriptionOrder", {
                        required: {
                          value: true,
                          message:"La descripción es requerida.",
                        },
                        pattern: {
                          value: /\S+/,
                          message: "No puede ser solo espacios en blanco",
                        },
                      })}
                    ></textarea>
                  </div>
                  {error6.descriptionOrder && <Alert className="w-100">* {error6.descriptionOrder.message}</Alert>}
                </div>

                <div className="mb-2 col-md-12">
                  <label>Total: </label>
                  <div className="su-control-group d-flex">
                    <input
                      className="form-control"
                      type="text"
                      name="answerContent"
                      onInput={(e) => {
                        if (!e.target.value) return;
                        let value = e.target.value.replace(/[^0-9]/g, "");
                        const formattedValue = `$${value}`;
                        formGenerateLink.setValue('totalOrder', formattedValue);
                      }}
                      {...formGenerateLink.register("totalOrder", {
                        required: {
                          value: true,
                          message:"El total es requerido.",
                        },
                        pattern: {
                          value: /\S+/,
                          message: "No puede ser solo espacios en blanco",
                        },
                      })}
                    />
                  </div>
                  {error6.totalOrder && <Alert className="w-100">* {error6.totalOrder.message}</Alert>}
                </div>

                {
                  methodsPayment.length > 1 && (
                    <div className="col-md-12">
                      <label>Opción para generar link de cobro:</label>
                      <div className="su-control mt-1 d-flex">
                        {methodsPayment.map((radio, k) => (
                          <div key={k} className="d-flex pointer radio">
                            <input
                              type="radio"
                              name="methodPayment"
                              {...formGenerateLink.register('methodPayment', {
                                required: {
                                  value: true,
                                  message:"El método es requerido.",
                                },
                              })}
                              id={k}
                              value={radio.platform}
                              className="pointer"
                            />
                            <label htmlFor={k} className="d-flex mb-0 ml-1 pointer radio-label" key={k} style={{ padding: "5px 2px 5px" }}>
                              {radio.platform}
                            </label>
                          </div>
                        ))}
                      </div>
                      {error6.methodPayment && <Alert className="w-100">* {error6.methodPayment.message}</Alert>}
                    </div>
                  )
                }
              </>: 
                <div className="text-center">
                  <label className="su-prefix font-weight-normal">No cuentas con métodos de pago configurados o activos para poder generar el link.</label>
                </div>
              }
            </div>

            <div className="row justify-content-end bd col-12 mt-4 ml-1">
              <a
                href="javascript:;"
                className="btn btn-lg btn-default mr-1"
                onClick={() => {
                  setModalGenerateLink((prevMessage) => { return false});
                }}
              >
                Cancelar
              </a>
              {isMethodActive ? <input
                className="btn btn-lg btn-info"
                type="submit"
                value="Generar"
              />
              : 
                <input
                  className="btn btn-lg btn-info"
                  type="button"
                  value="Ir a configurar"
                  onClick={() => window.location.href = "/#/home/paymentMethods"}
                />
              }
            </div>
          </form>
        </Content>
      </Modal>

      {/* Modal para enviar productos productos */}
      <Modal
        status={modalSendProducts}
        changeStatus={setModalSendProducts}
        title= "Seleccionar productos"
        width={"500px"}
      >
        <Content>
          <form style={{ width: "100%" }} onSubmit={formSendCatalog.handleSubmit(saveTypeSendProducts)}>
            <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable mt-0">
              {productsValidate ?
                <div className="text-center">
                  <label className="su-prefix font-weight-normal">No cuentas con productos, debes sincronizar para tenerlos disponible.</label>
                </div>
              : <>
                <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable mt-0">
                    <div>
                      <span><strong>Importante:</strong> No olvides sincronizar tu catálogo si has conectado uno nuevo, o si has realizado modificaciones en productos o conjuntos.</span>
                    </div>
                    <div className="su-control my-2 d-flex justify-content-evenly">
                      {optSendProducts.length > 0 && optSendProducts.map((rad, k) => (
                        <div key={k} className="d-flex pointer">
                          <input
                            hidden
                            type="radio"
                            name="typeProducts"
                            id={rad.id}
                            value={rad.id}
                            checked={selectedTypeProducts === rad.id}
                            className="pointer"
                            onChange={(e) => {
                              console.log('-------rad--------', e.target.value)
                              setSelectedTypeProducts(e.target.value);
                            }}
                          />
                          <label
                            className={`d-flex mb-0 ml-1 pointer ${selectedTypeProducts === rad.id ? 'checked-label' : ''} d-flex align-items-center`}
                            key={rad.id} style={{ padding: "5px 2px 5px" }}
                          >
                            {rad.id == 1 ? <BsBoxSeam className="mr-1"/> : <BsBoxes className="mr-2" /> }
                            {rad.name}
                          </label>
                        </div>
                      ))}
                    </div>

                </div>

                {selectedTypeProducts == 1 ? <>

                  <div className="form-inline justify-content-center mb-2 modal-addList">
                    <div className="su-control mt-1 d-flex">
                      <input
                        type="text"
                        className="form-control"
                        style={{height:"34px"}}
                        placeholder="Búsqueda"
                        value={searchProducts}
                        onChange={(e) => {
                          setSearchProducts(e.target.value);
                          onSearchProducts(e.target.value);
                        }}
                      />
                      <button
                        className="btn btn-info"
                        type="button"
                        id="buttonSearchProductBack"
                        style={{ height: "34px" }}
                      >
                        <BsSearch />
                      </button>
                    </div>
                  </div>

                  <ul className="content-list-contacts mt-3" style={{height:"300px", overflow:"auto"}}>
                    {error4.isProducts && <Alert className="w-100 text-center">* {error4.isProducts.message}</Alert>}
                    {listProducts2 && listProducts2.map(pro => (
                      <li key={pro._id} className="d-flex justify-content-between">
                        <div className="text-center col-3">
                          <img src={pro.image_url} style={{height:"3rem"}}/>
                        </div>

                        <div className="col-6">
                          <h5>
                            {pro.name}
                          </h5>

                          {pro.sale_price ?
                            <div className="d-flex">
                              <span>
                                <span style={{textDecoration: "line-through" }}>{pro.price}</span>
                              </span>
                              <span className="span-color ml-1" >
                                {pro.sale_price}
                              </span>
                            </div>
                          :
                          <div className="span-color" >
                            {pro.price}
                          </div>
                          }
                        </div>

                        <div className="col-3">
                          <input
                            type="checkbox"
                            value={pro.retailer_id}
                            className="cmn-toggle cmn-toggle-round-flat"
                            name={pro.name}
                            id={pro.retailer_id}
                            // defaultChecked={!!pro.tagActive}
                            {...formSendCatalog.register("isProducts", {
                              required: {
                                value: true,
                                message:"Debes seleccionar como minimo un producto.",
                              }
                            })}
                          />
                          <label className="ml-4" htmlFor={pro.retailer_id}></label>
                        </div>
                      </li>
                    ))}

                    {validationModalProducts && (
                      <div className="text-center mt-3">
                        <div className="empty">
                          <h1 style={{ fontSize: "30px" }}>No tienes productos</h1>
                        </div>
                      </div>
                    )}
                  </ul>

                </> : <>
                  <div className="form-inline justify-content-center mb-2 modal-addList">
                    <div className="su-control mt-1 d-flex">
                      <input
                        type="text"
                        className="form-control"
                        style={{height:"34px"}}
                        placeholder="Búsqueda"
                        value={searchSets}
                        onChange={(e) => {
                          setSearchSets(e.target.value);
                          onSearchSets(e.target.value);
                        }}
                      />
                      <button
                        className="btn btn-info"
                        type="button"
                        id="buttonSearchProductBack"
                        style={{ height: "34px" }}
                      >
                        <BsSearch />
                      </button>
                    </div>
                  </div>

                  <ul className="content-list-contacts mt-3" style={{height:"300px", overflow:"auto"}}>
                    {error4.isSet && <Alert className="w-100 text-center">* {error4.isSet.message}</Alert>}
                    {listSets && listSets.map((set, k) => (
                      <li key={k}>
                        <div className="text-center pointer">
                          <input
                            hidden
                            type="radio"
                            name="typeProducts"
                            id={set.id}
                            value={set.id}
                            checked={selectedSets == set.id}
                            className="pointer"
                            {...formSendCatalog.register("isSet", {
                              required: {
                                value: true,
                                message:"Debes seleccionar como minimo un conjunto.",
                              }
                            })}
                            onChange={(e) => {
                              console.log('-------set--------', e.target.value)
                              setSelectedSets(e.target.value);
                            }}
                          />
                          <h5>
                            <label
                              className={`mb-0 ml-1 pointer ${selectedSets == set.id ? 'checked-label' : ''}`}
                              key={set.id} style={{ padding: "5px 2px 5px", fontSize:"1.6rem", fontWeight:"100" }}
                            >
                              {set.name}
                            </label>
                          </h5>
                        </div>
                      </li>
                    ))}

                    {validationSets && (
                      <div className="text-center mt-3">
                        <div className="empty">
                          <h1 style={{ fontSize: "30px" }}>No tienes conjuntos</h1>
                        </div>
                      </div>
                    )}
                  </ul>
                </>}
              </>}

            </div>

            <div className="row justify-content-end bd col-12 mt-4 ml-1">
              <a
                href="javascript:;"
                className="btn btn-lg btn-default mr-1"
                onClick={() => {
                  setModalSendProducts((prevMessage) => { return false});
                  setSelectProductsSend((prevMessage) => { return false});
                  formSendCatalog.reset();
                }}
              >
                Cancelar
              </a>
              {productsValidate ?
                <input
                  className="btn btn-lg btn-info"
                  type="button"
                  value="Ir a sincronizar"
                  onClick={() => window.location.href = "/#/home/catalog"}
                />
              :
                <input
                  className="btn btn-lg btn-info"
                  type="submit"
                  value={selectedTypeProducts == 1 ? "Continuar" : "Enviar"}
                />
              }
            </div>
          </form>
        </Content>
      </Modal>

      {/* Modal para seleccionar flujo a enviar */}
      <Modal
        status={modalFlowSelected}
        changeStatus={setModalFlowSelected}
        title= "Enviar Flujo"
        width={"600px"}
      >
        <Content>
          <form style={{ width: "100%" }} onSubmit={formSendFlow.handleSubmit(saveIdFlow)}>
            <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable">
              {flowsValidate ?
                <div className="text-center">
                  <label className="su-prefix font-weight-normal">No cuentas con flujos, debes sincronizar para tenerlos disponible.</label>
                </div>
              :
                <div>
                  <label>Selecciona el flujo a enviar: </label>
                  <div className="su-control-group">
                    <select
                      className="form-control mb-1"
                      {...formSendFlow.register("idFlow", {
                        required: {
                          value: true,
                          message:"Debes seleccionar un flujo.",
                        },
                      })}
                    >
                      <option value="" key="0" disabled selected>Seleccione...</option>
                      {flows.length !== 0 && flows.map((flow, i)=>(
                        <option key={i} value={flow.id}>{flow.name}</option>
                      ))}
                    </select>
                    {error2.idFlow && <Alert>* {error2.idFlow.message}</Alert>}
                  </div>
                </div>
              }
            </div>

            <div className="row justify-content-end bd col-12 mt-4 ml-1">
              <a
                href="javascript:;"
                className="btn btn-lg btn-default mr-1"
                onClick={() => {
                  setModalFlowSelected((prevMessage) => { return false});
                }}
              >
                Cancelar
              </a>
              {flowsValidate ?
                <input
                  className="btn btn-lg btn-info"
                  type="button"
                  value="Ir a sincronizar"
                  onClick={() => window.location.href = "/#/home/flows"}
                />
              :
                <input
                  className="btn btn-lg btn-info"
                  type="submit"
                  value="Siguiente"
                />
              }
            </div>
          </form>
        </Content>
      </Modal>

      {/* Modal para agregar información y enviar flujo */}
      <Modal
        status={senFlowOrProducts === 1 ? modalFlowSend : modalCatalogSend}
        changeStatus={senFlowOrProducts === 1 ? setModalFlowSend : setModalCatalogSend}
        title= {senFlowOrProducts === 1 ? "Enviar Flujo" : "Enviar productos"}
        width={"810px"}
      >
        <Content>
          <form style={{ width: "100%" }} onSubmit={senFlowOrProducts === 1 ? formSendFlow2.handleSubmit(sendFlow) : formSendCatalog2.handleSubmit(sendProducts)}>
            <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable scrollable-content scrollable-content-overflow">
              <div className="mb-2">
                <label>{`Encabezado${senFlowOrProducts === 1 ? "(Opcional)":""}: `}</label>
                <div className={`su-control-group ${senFlowOrProducts === 1 && "d-flex"}`}>
                  {senFlowOrProducts === 1 ? <>
                    <select
                      className="form-control mb-1"
                      style={{width:"30%"}}
                      {...formSendFlow2.register("headerFlow")}
                      onChange={(e) => {
                        setHeaderSelected(e.target.value)
                        setExcelFile(null)
                        setMedioSelected("")
                        setTextHeaderFlow("")
                        formSendFlow2.reset({medioFlow: "", medioTypeFlow: "", headerTextFlow: ""})
                      }}
                    >
                      <option value="" selected>Ninguna</option>
                      <option value="1">Mensaje de texto</option>
                      <option value="2">Medios</option>
                    </select>

                    {headerSelected == "1" ?
                      <div style={{width:"70%"}}>
                        <div className="d-flex">
                          <input
                            className="form-control ml-2 mb-1"
                            type="text"
                            name="answerContent"
                            value={textHeaderFlow}
                            maxLength="60"
                            {...formSendFlow2.register("headerTextFlow", {
                              required: {
                                value: true,
                                message:"El encabezado es requerido.",
                              },
                              pattern: {
                                value: /\S+/,
                                message: "No puede ser solo espacios en blanco",
                              },
                            })}
                            onChange={(e) => {
                              setTextHeaderFlow(e.target.value)
                            }}
                          />
                          <span className={`total-characters ${textHeaderFlow.length == 60 && 'total-red'} ml-2 d-flex align-items-center`}>{`${textHeaderFlow.length}/60`}</span>
                        </div>
                        {error3.headerTextFlow && <Alert className="w-100 ml-2">* {error3.headerTextFlow.message}</Alert>}
                      </div>
                    : headerSelected == "2" ?
                      <div style={{width: medioSelected ? "35%" : "70%"}}>
                        <select
                          className="form-control mb-1 ml-2"
                          {...formSendFlow2.register("medioTypeFlow", {
                            required: {
                              value: true,
                              message:"El tipo de medio es requerido.",
                            },
                          })}
                          onChange={(e) => {
                            setMedioSelected(e.target.value)
                            setExcelFile(null)
                            formSendFlow2.reset({medioFlow: ""})
                          }}
                        >
                          <option value="" selected>Seleccione medio...</option>
                          <option value="1">Imagen</option>
                          <option value="2">Documento</option>
                        </select>
                        {error3.medioTypeFlow && <Alert className="w-100 ml-2">* {error3.medioTypeFlow.message}</Alert>}
                      </div>
                    : null }

                    {medioSelected == "1" || medioSelected == "2" ?
                      <div className="d-grid text-center pointer" style={{width: "35%"}}>
                        <label
                          id="label-upload-medio"
                          className="ml-2"
                        >
                          {excelFile ? <>
                            <BsFillCheckCircleFill style={{ color: "#5cb85c" }} className="mr-1 w-100"/>
                            {medioSelected == "1" ? <span>Imagen cargada</span> : <span>Documento cargado</span> }
                          </> : <>
                            {medioSelected == "1" ? <FaImage className="mr-1 w-100"/> : <BsFillFileEarmarkTextFill className="mr-1 w-100"/> }
                            {medioSelected == "1" ? <span>Cargar imagen</span> : <span>Cargar documento</span> }
                          </>}
                        </label>

                        <input
                          type="file"
                          id="file"
                          accept={medioSelected == "1"
                            ? "image/gif,image/jpeg,image/jpg,image/png"
                            : ".pdf,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, text/plain, .doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                          }
                          style={{ display: "none" }}
                          {...formSendFlow2.register('medioFlow', {
                            validate: {
                              fileRequired: (value) => {

                                if (!value.length <= 0) {
                                  return undefined;
                                } else {
                                  return medioSelected == "1" ? "Debes cargar una imagen" : "Debes cargar un documento";
                                }
                              }
                            },
                          })}
                          onChange={(e) => uploadFileFlow(e)}
                        />
                        {error3.medioFlow && <Alert className="w-100 ml-2">* {error3.medioFlow.message}</Alert>}
                      </div>
                    : null}

                  </> : <>
                    <div className="d-flex">
                      <input
                        className="form-control mb-1"
                        type="text"
                        name="answerContent"
                        value={textHeaderCatalog}
                        maxLength="60"
                        {...formSendCatalog2.register("headerTextCatalog", {
                          required: {
                            value: true,
                            message:"El encabezado es requerido.",
                          },
                          pattern: {
                            value: /\S+/,
                            message: "No puede ser solo espacios en blanco",
                          },
                        })}
                        onChange={(e) => {
                          setTextHeaderCatalog(e.target.value)
                        }}
                        />
                      <span className={`total-characters ${textHeaderCatalog.length == 60 && 'total-red'} ml-2 d-flex align-items-center`}>{`${textHeaderCatalog.length}/60`}</span>
                    </div>
                    {error5.headerTextCatalog && <Alert className="w-100 ml-2">* {error5.headerTextCatalog.message}</Alert>}
                  </>}
                </div>
              </div>

              <div className="mb-2">
                <label>Cuerpo: </label>
                <div className="su-control-group">
                  <div className="d-flex">
                    <textarea
                      className="form-control"
                      type="text"
                      name="answerContent"
                      value={textBodyFlow}
                      maxLength="1024"
                      {...(senFlowOrProducts === 1
                        ? formSendFlow2.register("bodyFlow", {
                          required: {
                            value: true,
                            message:"El cuerpo es requerido.",
                          },
                          pattern: {
                            value: /\S+/,
                            message: "No puede ser solo espacios en blanco",
                          },
                        })
                        : formSendCatalog2.register("bodyCatalog", {
                          required: {
                            value: true,
                            message:"El cuerpo es requerido.",
                          },
                          pattern: {
                            value: /\S+/,
                            message: "No puede ser solo espacios en blanco",
                          },
                        })
                      )}
                      onChange={(e) => setTextBodyFlow(e.target.value)}
                    ></textarea>
                    <span className={`total-characters ${textBodyFlow.length == 1024 && 'total-red'} ml-2 d-flex align-items-center`}>{`${textBodyFlow.length}/1024`}</span>
                  </div>

                  <div className="d-flex position-relative content-emojis mb-1">
                    {closeEmojis3 && (
                      <IoClose
                        className="icon-cancel ml-1 mr-2 d-flex pointer"
                        style={{
                          fontSize: "2rem",
                          alignItems: "center",
                        }}
                        onClick={() => {
                          setShowPicker3((val) => !val);
                          setCloseEmojis3(false);
                        }}
                      />
                    )}
                    <FaRegFaceLaugh
                      className={
                        showPicker3
                          ? "icon-smile icon-chat active d-flex mt-1"
                          : "icon-smile icon-chat d-flex mt-1"
                      }
                      style={{ fontSize: "25px", alignItems: "center" }}
                      onClick={() => {
                        setShowPicker3((val) => !val);
                        if (closeEmojis3) {
                          setCloseEmojis3(false);
                        } else {
                          setCloseEmojis3(true);
                        }
                      }}
                    />

                    {showPicker3 && (
                      <Picker
                        pickerStyle={{
                          width: "100%",
                          left: "0px",
                          position: "absolute",
                          zIndex: "999",
                          top: "37px",
                        }}
                        onEmojiClick={onEmojiClick3}
                        groupNames={{
                          smileys_people: "Emoticones y personas",
                          animals_nature: "Animales y naturaleza",
                          food_drink: "Alimentos y bebidas",
                          travel_places: "Viajes y lugares",
                          activities: "Actividades",
                          objects: "Objetos",
                          symbols: "Símbolos",
                          flags: "Banderas",
                          recently_used: "Recientes",
                        }}
                      />
                    )}
                  </div>
                </div>
                {senFlowOrProducts === 1
                  ?  error3.bodyFlow && <Alert className="w-100">* {error3.bodyFlow.message}</Alert>
                  :  error5.bodyCatalog && <Alert className="w-100">* {error5.bodyCatalog.message}</Alert>
                }
              </div>

              <div className="mb-2">
                <label>Pie de página: </label>
                <div className="su-control-group d-flex">
                  <input
                    className="form-control"
                    type="text"
                    name="answerContent"
                    value={textFooterFlow}
                    maxLength="60"
                    {...(senFlowOrProducts === 1
                      ? formSendFlow2.register("footerFlow", {
                        required: {
                          value: true,
                          message:"El footer es requerido.",
                        },
                        pattern: {
                          value: /\S+/,
                          message: "No puede ser solo espacios en blanco",
                        },
                      })
                      : formSendCatalog2.register("footerCatalog")
                    )}
                    onChange={(e) => setTextFooterFlow(e.target.value)}
                  />
                  <span className={`total-characters ${textFooterFlow.length == 60 && 'total-red'} ml-2 d-flex align-items-center`}>{`${textFooterFlow.length}/60`}</span>
                </div>
                {error3.footerFlow && <Alert className="w-100">* {error3.footerFlow.message}</Alert>}
              </div>

              {senFlowOrProducts === 1 &&
                <div className="mb-2">
                  <label>Texto del botón: </label>
                  <div className="su-control-group d-flex">
                    <input
                      className="form-control"
                      type="text"
                      name="answerContent"
                      value={textBottonFlow}
                      maxLength="20"
                      {...formSendFlow2.register("bottonTextFlow", {
                        required: {
                          value: true,
                          message:"El texto del botón es requerido.",
                        },
                        pattern: {
                          value: /\S+/,
                          message: "No puede ser solo espacios en blanco",
                        },
                      })}
                      onChange={(e) => setTextBottonFlow(e.target.value)}
                    />
                    <span className={`total-characters ${textBottonFlow.length == 20 && 'total-red'} ml-2 d-flex align-items-center`}>{`${textBottonFlow.length}/20`}</span>
                  </div>
                  {error3.bottonTextFlow && <Alert className="w-100">* {error3.bottonTextFlow.message}</Alert>}
                </div>
              }

            </div>

            <div className="row justify-content-end bd col-12 mt-4">
              <a
                href="javascript:;"
                className="btn btn-lg btn-default mr-1"
                onClick={() => {
                  senFlowOrProducts === 1
                  ? setModalFlowSend((prevMessage) => { return false})
                  : setModalCatalogSend((prevMessage) => { return false})
                }}
              >
                Cancelar
              </a>
              <input
                className="btn btn-lg btn-info"
                // type="button"
                type="submit"
                value="Enviar"
              />
            </div>
          </form>
        </Content>
      </Modal>

      {/* Modal para mostrar contactos enviados por el cliente */}
      <Modal
        status={modalListContacts}
        changeStatus={setModalListContacts}
        title= {listContacts.length > 1 ? `${listContacts.length} contactos` : "Ver contacto"}
        width={"460px"}
      >
        <Content>
          <form style={{ width: "100%" }}>
            <div>
              <div
                className="content-list-contacts su-control mt-2 text-center"
                style={{overflow: "auto", height: "50vh"}}
              >
                <ul>
                  {listContacts?.map((contact, c) => (
                    <li key={c}>
                      <div className="d-flex align-items-center">
                        <div className="ml-1" style={{border: "2px solid", borderRadius: "50%", backgroundColor: "#6a7175"}}>
                          <FaUser style={{margin: "7px", fontSize: "2rem"}}/>
                        </div>
                        <div className="ml-3 pointer text-left">
                          <p id="name-contact">
                            {contact.name}
                          </p>
                          <p>
                          {contact.phone}
                          </p>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <div className="row justify-content-end bd col-12 mt-4">
              <a
                href="javascript:;"
                className="btn btn-lg btn-default mr-1"
                onClick={() => {
                  setModalListContacts((prevMessage) => { return false});
                }}
              >
                Cerrar
              </a>
            </div>
          </form>


        </Content>
      </Modal>

      {/* Modal para transferir un agente */}
      <Modal
        status={modalTransferAgent}
        changeStatus={setModalTransferAgent}
        title="Transferir a un agente"
        width={"450px"}
      >
        <Content>
          <div className="w-100">
            {/* --- BUSCADOR --- */}
            <form className="form-inline my-3 justify-content-center">
              <div className="su-control mt-1">
                <input
                  className="form-control"
                  type="text"
                  placeholder="Búsqueda"
                  value={searchModal}
                  onChange={(e) => {
                    setSearchModal(e.target.value);
                    onSearchAdvisers(e.target.value);
                  }}
                />
              </div>
            </form>
            <div className="list-advisers" style={{overflow: "auto",height: "366px"}}>
              <ul>
                {listAdvisers.length > 0 ? listAdvisers.map((adviser, k) => {
                  const initialWords = adviser.name.split(" ");
                  const firstWord = initialWords[0];
                  const secondWord = initialWords[1];
                  let icon = "";
                  if (secondWord === undefined) {
                    icon = firstWord[0];
                  } else {
                    icon = firstWord[0] + secondWord;
                  }
                  return (
                    <li
                      key={k}
                      style={{ alignItems: "center" }}
                      className="pointer d-flex align-items-center"
                      onClick={() => {
                        if (userId === adviser._id) {
                          onAssingAgent(userId, adviser.name, true);
                        } else {
                          setModalTransferNote(true);
                          setAgentSelected(adviser);
                        }
                        setModalTransferAgent(false);
                      }}
                    >
                      <IconList>
                        <Avvvatars value={icon} />
                      </IconList>
                      <div className="d-block">
                        <p className="ml-3 span-color">{adviser.name}</p>
                        <strong className="ml-3">{adviser.area}</strong>
                      </div>
                    </li>
                  )})
                : <div className="text-center">
                    <h4 className="text-center">No existen agentes</h4>
                    <input
                      type="button"
                      className="btn btn-default"
                      value="Crear agente"
                      style={{borderRadius: "4px", fontSize: "14px", padding: "6px 12px"}}
                      onClick={() => navigate("/home/agents")}
                    />
                  </div>
                }
              </ul>

            </div>
            {roleId == "6284fdd697029c6d9743ccb3" &&
              <div className="text-center mt-2 pt-3" style={{borderTop: "1px solid #ebebeb"}}>
                <input
                  type="button"
                  value="Tomar conversación"
                  className="btn btn-lg btn-info"
                  onClick={() => {
                    setModalTransferAgent(false);
                    onAssingAgent(userId, localStorage.getItem("USER_NAME"), true)
                  }}
                />
              </div>
            }
          </div>
        </Content>
      </Modal>

      {/* Modal para agregar una nota de transferencia */}
      <Modal
        status={modalTransferNote}
        changeStatus={setModalTransferNote}
        title= "Agregar nota de transferencia"
        width={"600px"}
      >
        <Content>
          <form style={{ width: "100%" }}>
            <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable scrollable-content scrollable-content-overflow">
              <label>Puedes enviar si lo deseas un mensaje de transferencia (Opcional): </label>
              <div className="su-control-group">
                <textarea
                  className="form-control"
                  type="text"
                  name="answerContent"
                  maxLength="4096"
                  {...register("contentNote")}
                ></textarea>
              </div>
            </div>

            <div className="row justify-content-end bd col-12 mt-4">
              <a
                href="javascript:;"
                className="btn btn-lg btn-default mr-1"
                onClick={() => {
                  setModalTransferNote((prevMessage) => { return false});
                  setAgentSelected(((prevMessage) => { return ""}));
                  reset({contentNote: ""})
                }}
              >
                Cancelar
              </a>
              <input
                className="btn btn-lg btn-info"
                type="button"
                // type="submit"
                value="Transferir"
                onClick={() =>  onAssingAgent(agentSelected._id, agentSelected.name)}
              />
            </div>
          </form>


        </Content>
      </Modal>

      {/* Modal para crear o editar una respuesta rápida */}
      <Modal
        status={createAnswer}
        changeStatus={setCreateAnswer}
        title={`${editAnswer ? "Editar" : "Nueva"} respuesta rápida`}
        width={"600px"}
      >
        <Content>
          <form
            // onSubmit={handleSubmit(postQuickReply)}
            style={{ width: "100%" }}
          >
            <div className="scrollable-content scrollable-content-overflow">
              <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable">
                <label>Titulo: </label>
                <div className="su-control-group">
                  <input
                    className="form-control"
                    type="text"
                    name="answerName"
                    // value={answerTitle}
                    {...register("answerName")}
                    onChange={(e) => {
                      if (e.target.value) {
                        setAnswerTitleError(false)
                      }else {
                        setAnswerTitleError(true)
                      }
                      setAnswerTitle(e.target.value);
                    }}
                  />
                </div>
                {errors.answerName && <Alert>* {errors.answerName.message}</Alert>}
                {answerTitleError && <Alert>* El titulo es requerido</Alert>}
              </div>

              <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable">
                <label>Contenido: </label>
                <div className="su-control-group">
                  <textarea
                    className="form-control p-l-5"
                    type="text"
                    name="answerContent"
                    value={inputStr2}
                    maxLength="4096"
                    {...register("answerContent")}
                    onChange={handleChangeQuiclyReply}
                  ></textarea>
                  {showMaxLengthError && (
                    <div style={{ color: 'red' }}>
                      La cantidad máxima de caracteres es 4096
                    </div>
                  )}
                  <div className="d-flex position-relative content-emojis">
                    {closeEmojis2 && (
                      <IoClose
                        className="icon-cancel ml-1 mr-2 d-flex pointer"
                        style={{
                          fontSize: "2rem",
                          alignItems: "center",
                        }}
                        onClick={() => {
                          setShowPicker2((val) => !val);
                          setCloseEmojis2(false);
                        }}
                      />
                    )}
                    <FaRegFaceLaugh
                      className={
                        showPicker2
                          ? "icon-smile icon-chat active d-flex mt-1"
                          : "icon-smile icon-chat d-flex mt-1"
                      }
                      style={{ fontSize: "25px", alignItems: "center" }}
                      onClick={() => {
                        setShowPicker2((val) => !val);
                        setShowQuickResponses(false);
                        if (closeEmojis2) {
                          setCloseEmojis2(false);
                        } else {
                          setCloseEmojis2(true);
                        }
                      }}
                    />
                    <div className="d-flex align-items-center ml-2">
                      <MdOutlineAddCircleOutline
                        className="icon-chat icon-quick-variables d-flex"
                        style={{
                          fontSize: "29px", alignItems: "center", marginTop: '2px'
                        }}
                        onClick={() => {
                          setShowQuickResponses((value) => !value);
                        }} 
                      />
                      <span
                        className="pointer d-none d-sm-block"
                        style={{
                          marginTop: '2px'
                        }} 
                        onClick={() => {
                          setShowQuickResponses((value) => !value);
                        }}
                      >
                        Variable
                      </span>
                    </div>
                    {
                      showQuickResponses && (
                        <div className="w-50 position-absolute z-3" style={{left: '25%'}}>
                          <select
                            name="select"
                            className="form-control"
                            onChange={onQuickResponse}
                          >
                            <option value="" key="0">
                              Selecciona...
                            </option>
                            {Object.keys(variablesQuickResponses).map((key,index) => (
                              <option 
                                value={key} 
                                key={index}
                              >
                                {key}
                              </option>
                            ))}
                          </select>
                        </div>
                      )
                    }

                    {showPicker2 && (
                      <Picker
                        pickerStyle={{
                          width: "100%",
                          left: "0px",
                          position: "absolute",
                          zIndex: "999",
                          top: "37px",
                        }}
                        onEmojiClick={onEmojiClick2}
                        groupNames={{
                          smileys_people: "Emoticones y personas",
                          animals_nature: "Animales y naturaleza",
                          food_drink: "Alimentos y bebidas",
                          travel_places: "Viajes y lugares",
                          activities: "Actividades",
                          objects: "Objetos",
                          symbols: "Símbolos",
                          flags: "Banderas",
                          recently_used: "Recientes",
                        }}
                      />
                    )}
                  </div>
                </div>
                {errors.answerContent && <Alert>* {errors.answerContent.message}</Alert>}
                {inputStr2Error && <Alert>* El contenido es requerido</Alert>}
              </div>

              <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable">
                <label>Cargar achivo: </label>
                <div className={isImgAnswer && "d-flex align-items-center justify-content-evenly"}>
                  <div className="su-control-group mr-2">
                    <input
                      type="file"
                      name="image"
                      id="fileAnswer"
                      className="form-control"
                      accept="image/gif,image/jpeg,image/jpg,image/png,application/pdf"
                      {...register("fileAnswer")}
                      onChange={(e) => onFileAnswer(e)}
                    />
                  </div>

                  {isImgAnswer && (typeFileAnswer === "application/pdf" || typeFileAnswer === "pdf") ? (
                    <div
                      className="content-img text-center my-2"
                      id="pdf-viewer"
                      style={{
                        textAlign: "center",
                        position: "relative"
                      }}>
                      <embed id="embed-pdf" className="" src={imgAnswer} type="application/pdf" width="80%" height="100px" />
                      <a className="pdf-link" href={imgAnswer} alt="pdf" target="_blank" rel="noreferrer">
                        <FaEye style={{color: "#ff9317"}} />
                      </a>
                    </div>
                  ) : isImgAnswer && typeFileAnswer === "image" ? (
                    <div className="content-img text-center my-2 w-50" id="img-viewer">
                      <img
                        className="img-answer w-100"
                        src={imgAnswer}
                        style={{margin:"0 auto"}}
                      />
                      <span 
                        className="img-link pointer"  
                        onClick={() => {
                          setIsViewImage(true); 
                          setViewImgAnswer(true);
                        }}
                      >
                        <FaEye style={{color: "#ff9317"}} />
                      </span>
                    </div>
                  ) : null}
                </div>


              </div>

              <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable">
                <label>Configuración: </label>
                <label className="f-w-100" >Selecciona como deseas que se guarde y muestre la respuesta rápida, por agente, por área o para todos</label>
                <div className="su-control mt-2 d-flex justify-content-evenly">
                  {radioOptions.length > 0 && radioOptions.map((radio, k) => (
                    <div key={k} className="d-flex pointer radio">
                      <input
                        type="radio"
                        name="answerSettings"
                        id={radio.id}
                        value={radio.id}
                        checked={selectedRadio === radio.id}
                        className="pointer"
                        onChange={(e) => {
                          console.log('-------radio--------', e.target.value)
                          setSelectedRadio(e.target.value);
                        }}
                      />
                      <label htmlFor={radio.id} className="d-flex mb-0 ml-1 pointer radio-label" key={radio.id} style={{ padding: "5px 2px 5px" }}>
                        {radio.name}
                      </label>
                    </div>
                  ))}
                </div>
              </div>

              { selectedRadio === "3" ? (
                  <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable">
                    <label>Asignar a área: </label>
                    <div className="su-control-group">
                      <select
                        name="area"
                        id="area"
                        className="form-control mb-1"
                        value={answerArea}
                        style={{ width: "100%" }}
                        {...register("area")}
                        onChange={(e) => {
                          if (e.target.value) {
                            setAnswerAreaError(false)
                          }else {
                            setAnswerAreaError(true)
                          }
                          setAnswerArea(e.target.value);
                        }}
                      >
                        <option value="" key="0" disabled selected>
                          Seleccione...
                        </option>
                        {listAreas && listAreas.map((area, a) => (
                          <option value={area._id} key={a}>
                            {area.description}
                          </option>
                        ))}
                      </select>
                    </div>
                    {answerAreaError && <Alert>* El área es requerida</Alert>}
                  </div>
              ) : selectedRadio === "2" ? (
                  <div className="su-control su-with-prefix su-with-suffix su-segmentation-disable">
                    <label>Asignar a agente: </label>
                    <div className="su-control-group">
                      <select
                        name="agent"
                        id="agent"
                        className="form-control mb-1"
                        value={answerAgent}
                        style={{ width: "100%" }}
                        {...register("agent")}
                        onChange={(e) => {
                          if (e.target.value) {
                            setAnswerAgentError(false)
                          } else {
                            setAnswerAgentError(true)
                          }
                          console.log('------> list agents <------', listAdvisers)
                          console.log('------> list areas <------', listAreas)
                          const value = e.target.value;
                          const nameArea = listAdvisers.filter(adviser => adviser._id === value)[0].area;
                          var valArea = ""
                          if (nameArea) {
                            const idArea = listAreas.filter(area => area.description === nameArea)[0]._id
                            valArea = idArea;
                          }
                          setAnswerAgent(value);
                          setAnswerArea(valArea)
                        }}
                      >
                        <option value="" key="0" disabled selected>
                          Seleccione...
                        </option>
                        {listAdvisers && listAdvisers.map((agent, a) => (
                          // <option value={agent._id} key={a}>
                          <option value={agent._id} key={a}>
                            {agent.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    {answerAgentError && <Alert>* El agente es requerido</Alert>}
                  </div>
              ) : null
              }
            </div>

            <div className="row justify-content-end bd col-12 mt-4">
              <a
                href="javascript:;"
                className="btn btn-lg btn-default mr-1"
                onClick={() => {
                  setCreateAnswer((prevMessage) => { return false});
                  setIsImgAnswer((prevMessage) => { return false});
                }}
              >
                Volver
              </a>
              <input
                className="btn btn-lg btn-info"
                type="button"
                // type="submit"
                value="Aceptar"
                onClick={() =>  editAnswer ? putQuickReply() : postQuickReply()}
              />
            </div>
          </form>


        </Content>
      </Modal>

      {/* Modal para ver los detalles del producto seleccionado en contexto */}
      <Modal
        status={modalViewProductContext}
        changeStatus={setModalViewProductContext}
        title="Información del producto"
        width={"90vw"}
        >
          <Content>
              <form style={{ width: "100%" }}>
                  <div>
                      <div className="conten-info-product mt-2 text-center d-flex justify-content-between scrollable-content scrollable-content-overflow" style={{overflow: "auto", height: "76vh"}} >
                          <div className="w-50 mr-1 img-product">
                              <img src={productContextSelected.image_url} className="w-100 h-100" style={{objectFit:"contain"}}/>
                          </div>

                          <div className="w-50 ml-1 px-2 text-justify info-product">
                              <h2>{productContextSelected.name}</h2>
                              <h4 className="d-flex align-items-baseline">{productContextSelected.sale_price ?
                                  <>
                                      <h5>
                                          <span style={{color: "#a5a7a8", textDecoration: "line-through" }}>{productContextSelected.price}</span>
                                      </h5>
                                      <span className="ml-1">
                                          {productContextSelected.sale_price}
                                      </span>
                                  </>
                              :
                                  productContextSelected.price
                              }
                              </h4>
                              <p>{productContextSelected.description}</p>

                              <div className="text-center mt-3">
                                  <a
                                      href={productContextSelected.url}
                                      target="_blank"
                                      rel="noreferrer"
                                      className="btn btn-lg btn-default mr-1"
                                  >
                                      Visitar página web
                                  </a>
                              </div>
                          </div>
                      </div>
                  </div>
              </form>
          </Content>
      </Modal>

      {/* Modal para mostrar orden enviada por el cliente */}
      <Modal
          status={modalViewOrder}
          changeStatus={setModalViewOrder}
          title="Detalles de la orden"
          width={"500px"}
      >
          <Content>
              <form style={{ width: "100%" }}>
                  <div>
                      <div>
                          <div>
                          <h5 className="mb-0">{quantityOrder > 1 ? `${quantityOrder} elementos` : `${quantityOrder} elemento`}</h5>
                          </div>
                          <div>
                          <span className="span-color">$ {totalPriceOrder.toLocaleString('es-CO')} (estimado)</span>
                          </div>
                      </div>

                      <div
                          className="content-list-contacts su-control mt-2 text-center"
                          style={{overflow: "auto", height: "50vh"}}
                      >
                          <ul>
                          {orderSelected.map(product => (
                          <li>
                              <div className="d-flex align-items-center">
                              <div className="col-4">
                                  <img className="w-100" style={{height:"5rem", objectFit:"contain"}} src={product.image_url}/>
                              </div>
                              <div className="col-8 text-left">
                                  <div>
                                  <h5 className="mb-0">{product.name}</h5>
                                  </div>
                                  <div>
                                  <span className="span-color">{product.price} - Cantidad: {product.quantity}</span>
                                  </div>
                              </div>
                              </div>
                          </li>
                          ))}
                          </ul>
                      </div>
                  </div>

                  <div className="row justify-content-center bd mt-4">
                      <input
                          className="btn btn-lg btn-info"
                          type="button"
                          value="Generar link de pago"
                          onClick={() => {
                          setModalViewOrder(false);
                          setModalGenerateLink((prevMessage) => { return true});
                          }}
                      />
                  </div>
              </form>
          </Content>
      </Modal>
      
      <ToastContainer 
        position="top-center"
        autoClose={52000}
        // autoClose={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        // draggable
        theme={JSON.parse(localStorage.getItem("THEME")) ? "dark" : "light"}
        toastClassName="custom-toast" // Aplica estilos personalizados a cada toast individualmente
        bodyClassName="custom-toast-body"
      />

    </Fragment>
  );
};

export default Chat;

const IconList = styled.div`
  margin-bottom: 0px;

  div {
    height: 37px;
    width: 37px;
    border-radius: 50%;
    // box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.7);
    margin: 4px auto;
  }

  p {
    font-size: 18px;
    font-weight: 600;
  }
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  h1 {
    font-size: 42px;
    font-weight: 700;
    margin-bottom: 10px;
    text-align: center;
  }
  li {
    padding: 10px 5px;
    border-bottom: 1px solid rgb(0, 0, 0, 0.05);
  }
  li:hover {
    background: rgb(0, 0, 0, 0.05);
  }
  p {
    font-size: 18px;
    margin-bottom: 0px;
  }
  input {
    padding: 10px;
    border-radius: 0px;
    font-size: 17px;
  }
  img.img-answer {
    cursor: pointer;
    width: auto;
    height: 85px;
    object-fit: scale-down;
  }
  .content-img {
    position: relative;
  }
  .content-img img:hover {
    opacity: 0.6;
  }
`;
const Alert = styled.span`
  color: red;
  display: block;
  width: 200px;
`;