import React, { Fragment, useState,useEffect } from "react";
import * as XLSX from "xlsx";
import { sendToast } from "../../plugins/toastAll";
import { ToastContainer } from "react-toastify";

const MetricsPerAgent = ({dataAgents, selectedOptionInit, onUpdateMetrics}) => {
    console.log('-----METRICS AGENTS---→',  dataAgents);
    const [selectedOption, setSelectedOption] = useState(selectedOptionInit);
      
    const handleSelectChange = (event) => {
        console.log('▶▶▶▶▶▶', event.target.value);
        
        setSelectedOption(event.target.value);
        onUpdateMetrics(event.target.value);
    };
    
    // Exportar contactos
    const exportToExcel = async () => {
      console.log('DATA recibida para ▶✔:', dataAgents)
      console.log('▶:', selectedOption)
      let data = dataAgents;
      const jsonArray = Object.values(data).map(item => ({
        Nombre: item.user.name,
        Área: item.area || "N/A", // Si el campo área no existe
        Atendidas: item.open || 0,
        Cerradas: item.closed || 0,
        Pendientes: 0,
        "Tiempo de gestión": item.atentionTime
      }));
      console.log('DATA Fiiinal a exportar:', jsonArray)
      if (jsonArray.length <= 0) {
        sendToast({type: "info", message: "Sin contactos para exportar."})
      } else {
        const sheetNames = ["Hoy","Últimos 7 días","Últimos 15 días","Últimos 30 días","Últimos 60 días"];
        let sheetName = sheetNames[selectedOption] || "Sheet1";
        const worksheet = XLSX.utils.json_to_sheet(jsonArray);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
        XLSX.writeFile(workbook, `Métricas por agente (${sheetName}).xlsx`);
      }
    };

    return (
        <Fragment>
            <div div className="content-2 metrics-agent">
                <header className="text-center">
                    <h2 className="m-0">Métricas por Agente</h2>
                </header>
                <div className="text-right d-flex justify-content-end align-items-center mb-1">
                    <button
                        id="inventory-button"
                        className="btn btn-default mr-2"
                        onClick={exportToExcel}
                    >
                        Descargar Métricas
                    </button>
                    <select name="select" id="select" className="form-control" value={selectedOption} onChange={handleSelectChange} style={{width: "15%", height: "26px", padding: "0"}}>
                        <option value="0" key="0" selected>Hoy</option>
                        <option value="1" key="1" >Últimos 7 días</option>
                        <option value="2" key="2" >Últimos 15 días</option>
                        <option value="3" key="3" >Últimos 30 días</option>
                        <option value="4" key="4" >Últimos 60 días</option>
                    </select>
                </div>

                <div className="content-table pb-3" style={{ height: "500px", overflowY: "auto" }}>
                    <table id="metrics-agent" className="table table-striped campaigns-report" 
                        // style={{ height: "490px", overflowY: "auto" }}
                    >
                        <thead style={{ position: "sticky", top: "-1px", zIndex: "99" }}>
                            <tr>
                            <th>Agente</th>
                            <th>Departamento</th>
                            <th>Atendidas</th>
                            <th>Cerradas</th>
                            <th>Pendientes</th>
                            <th>Tiempo de gestión</th>
                            </tr>
                        </thead>
                        <tbody>
                            {dataAgents && Object.keys(dataAgents).map((id_user, i) =>(
                                <tr key={i}>
                                    <td>{dataAgents[id_user].user.name}</td>
                                    <td>{dataAgents[id_user].area}</td>
                                    <td>{dataAgents[id_user].open}</td>
                                    <td>{dataAgents[id_user].closed}</td>
                                    <td>0</td>
                                    <td>{dataAgents[id_user].atentionTime}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>

            <ToastContainer 
                position="top-center"
                autoClose={52000}
                // autoClose={false}
                newestOnTop={false}
                closeOnClick={false}
                rtl={false}
                pauseOnFocusLoss
                draggable={false}
                // draggable
                theme={JSON.parse(localStorage.getItem("THEME")) ? "dark" : "light"}
                toastClassName="custom-toast" // Aplica estilos personalizados a cada toast individualmente
                bodyClassName="custom-toast-body"
            />
        </Fragment>
    );
};

export default MetricsPerAgent;
