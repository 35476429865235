// Resources
import React, {useState} from "react";

// Icons
import { IoIosArrowDropdown, IoIosArrowDropup } from "react-icons/io";
import { TfiBackRight } from "react-icons/tfi";

const ErrorMonitor = ({
    handleErrorMonitoring,
    goToInitialNode,
    goToNode,
    findNodeColor,
    listDisconnectedNodes,
    listDisconnectedOptions,
    listNodesWithErrorInFields,
    triggersDuplicateCount,
    transitionsDuplicateCount,
    animateClass,
    isDark
}) => {
    // ------> Dropdowns
    const [selectDisconnectedNodes, setSelectDisconnectedNodes] = useState(false);
    const [selectDisconnectedOptions, setSelectDisconnectedOptions] = useState(false);
    const [selectErrorFieldNodes, setSelectErrorFieldNodes] = useState(false);
    const [selectErrorTriggers, setSelectErrorTriggers] = useState(false);
    const [selectErrorTransitions, setSelectErrorTransitions] = useState(false);

    // Functions

    //# Function para manejar cierre y aparetura de dropdowns
    const handleDropdown = (property) => {
        switch(property) {
          case 'selectDisconnectedNodes':
            setSelectDisconnectedNodes(!selectDisconnectedNodes);
            setSelectErrorFieldNodes(false);
            setSelectErrorTriggers(false);
            setSelectErrorTransitions(false);
            setSelectDisconnectedOptions(false);
            break;
          case 'selectDisconnectedOptions':
            setSelectDisconnectedOptions(!selectDisconnectedOptions);
            setSelectDisconnectedNodes(false);
            setSelectErrorFieldNodes(false);
            setSelectErrorTriggers(false);
            setSelectErrorTransitions(false);
            break;
          case 'selectErrorFieldNodes':
            setSelectErrorFieldNodes(!selectErrorFieldNodes);
            setSelectDisconnectedNodes(false);
            setSelectErrorTriggers(false);
            setSelectErrorTransitions(false);
            setSelectDisconnectedOptions(false);
            break;
          case 'selectErrorTriggers':
            setSelectErrorTriggers(!selectErrorTriggers);
            setSelectErrorFieldNodes(false);
            setSelectDisconnectedNodes(false);
            setSelectErrorTransitions(false);
            setSelectDisconnectedOptions(false);
            break;
          case 'selectErrorTransitions':
            setSelectErrorTransitions(!selectErrorTransitions);
            setSelectErrorTriggers(false);
            setSelectErrorFieldNodes(false);
            setSelectDisconnectedNodes(false);
            setSelectDisconnectedOptions(false);
            break;
          default:
            console.error(`Property ${property} not recognized`);
        }
    };
    
    return (
        <>
            <div 
                className={`card mt-4 border border-12 border-black mr-4 ${animateClass}`} 
                style={{
                    width: '300px', 
                    transition: 'height 0.5s ease', 
                    maxHeight: '460px',
                    zIndex: 100,
                    paddingBlock: "20px", 
                    overflowY: 'auto',
                    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                    backgroundColor: isDark == "true" ? "#111B21" : "#fff"
                }}
            >
                <div 
                    style={{
                        height: "10%",
                        fontSize: "1.2rem", 
                        fontWeight: "bold",
                        paddingInline: "20px",
                        paddingBottom: "20px",
                        borderBottom: "1px solid #c0c0c0"
                    }}
                    className="d-flex justify-content-around"
                >
                    <div>
                        Monitor de errores
                    </div>
                    <div>
                        <TfiBackRight 
                            style={{cursor: "pointer"}}
                            onClick={() => handleErrorMonitoring(true)}
                        />
                    </div>
                </div>
                
                <div
                    id="content-list-chats"
                    style={{
                        height: "90%",
                        overflowY: "auto",
                        paddingInline: "20px",
                        paddingTop: "20px"
                    }}
                >
                    <div>
                        <button
                            className="btn-lg col" 
                            style={{
                                backgroundColor: '#00A884', 
                                borderRadius: "4px", 
                                color: "#fff",
                                border: "none",
                                fontWeight: 700,
                                cursor: "pointer"
                            }}
                            onClick={goToInitialNode}
                        >
                            Ir al nodo inicial
                        </button>
                        <p style={{fontSize: 11, color: '#6C757D', fontWeight: 500, margin: 0}}>
                            Botón para buscar el nodo incial en todo el flujo.
                        </p>
                    </div>
                    <div className="p-0 mt-3 panel-group collapsible" id="user-collapsible">
                        <div className="panel panel-default">
                            <div 
                                className="panel-heading p-2" 
                                style={{
                                    border: "1px solid #4F4F4F",
                                    borderRadius: "4px"
                                }}
                            >
                                <div className="panel-title d-flex justify-content-between align-items-center pointer" onClick={() => handleDropdown('selectDisconnectedNodes')}>
                                    <span>
                                        {`Nodos desconectados ${listDisconnectedNodes.length && listDisconnectedNodes.length > 0 ?  `(${listDisconnectedNodes.length})` : ''}`}
                                    </span>
                                    {selectDisconnectedNodes ?
                                        <IoIosArrowDropup className="pointer mr-3" style={{ fontSize: "20px"}}/> :
                                        <IoIosArrowDropdown className="pointer mr-3" style={{ fontSize: "20px"}}/>
                                    }
                                </div>
                            </div>

                            {selectDisconnectedNodes ? (
                                <div 
                                    className="panel-collapse p-4" 
                                    style={{
                                        borderRadius: "4px",
                                        transition: 'height 0.5s ease',
                                        overflow: 'hidden'
                                    }}
                                >
                                    <div className="panel-body" style={{paddingInline: 0}}>
                                        {listDisconnectedNodes.length > 0 && listDisconnectedNodes.map(disNodes => {
                                            return (
                                                <button
                                                    key={disNodes.id}
                                                    className="col mt-2" 
                                                    style={{
                                                        height: "30px",
                                                        backgroundColor: findNodeColor(disNodes.type), 
                                                        borderRadius: "4px", 
                                                        color: "#fff",
                                                        border: "none",
                                                        fontWeight: 700,
                                                        cursor: "pointer"
                                                    }}
                                                    onClick={() => goToNode(disNodes)}
                                                >
                                                    {disNodes.data.title}
                                                </button>
                                            );
                                        })}
                                    </div>
                                </div>
                            ) : null}
                        </div>
                        <p style={{fontSize: 11, color: '#6C757D', fontWeight: 500, margin: 0}}>
                            Listado de nodos que existen en el flujo pero no estan conetados o en uso.
                        </p>
                    </div>
                    <div className="p-0 mt-3 panel-group collapsible" id="user-collapsible">
                        <div className="panel panel-default">
                            <div 
                                className="panel-heading p-2" 
                                style={{
                                    border: "1px solid #4F4F4F",
                                    borderRadius: "4px"
                                }}
                            >
                                <div className="panel-title d-flex justify-content-between align-items-center pointer" onClick={() => handleDropdown('selectDisconnectedOptions')}>
                                    <span>
                                        {`Botones sin conexión ${listDisconnectedOptions.length && listDisconnectedOptions.length > 0 ?  `(${listDisconnectedOptions.length})` : ''}`}
                                    </span>
                                    {selectDisconnectedOptions ?
                                        <IoIosArrowDropup className="pointer mr-3" style={{ fontSize: "20px"}}/> :
                                        <IoIosArrowDropdown className="pointer mr-3" style={{ fontSize: "20px"}}/>
                                    }
                                </div>
                            </div>

                            {selectDisconnectedOptions ? (
                                <div 
                                    className="panel-collapse p-4" 
                                    style={{
                                        borderRadius: "4px",
                                        transition: 'height 0.5s ease',
                                        overflow: 'hidden'
                                    }}
                                >
                                    <div className="panel-body" style={{paddingInline: 0}}>
                                        {listDisconnectedOptions.length > 0 && listDisconnectedOptions.map(disNodes => {
                                            return (
                                                <button
                                                    key={disNodes.id}
                                                    className="col mt-2" 
                                                    style={{
                                                        height: "30px",
                                                        backgroundColor: findNodeColor(disNodes.type), 
                                                        borderRadius: "4px", 
                                                        color: "#fff",
                                                        border: "none",
                                                        fontWeight: 700,
                                                        cursor: "pointer"
                                                    }}
                                                    onClick={() => goToNode(disNodes)}
                                                >
                                                    {disNodes.data.title}
                                                </button>
                                            );
                                        })}
                                    </div>
                                </div>
                            ) : null}
                        </div>
                        <p style={{fontSize: 11, color: '#6C757D', fontWeight: 500, margin: 0}}>
                            Listado de nodos que existen en el flujo y tienen opciones (Botones interactivos)
                            sin conexión alguna.
                        </p>
                    </div>
                    <div className="p-0 mt-3 panel-group collapsible" id="user-collapsible">
                        <div className="panel panel-default">
                            <div 
                                className="panel-heading p-2" 
                                style={{
                                    border: "1px solid #4F4F4F",
                                    borderRadius: "4px"
                                }}
                            >
                                <div className="panel-title d-flex justify-content-between align-items-center pointer" onClick={() => handleDropdown('selectErrorFieldNodes')}>
                                    <span>
                                        {`Nodos con errores ${listNodesWithErrorInFields.length && listNodesWithErrorInFields.length > 0 ? `(${listNodesWithErrorInFields.length})` : ''}`}
                                    </span>
                                    {selectErrorFieldNodes ?
                                        <IoIosArrowDropup className="pointer mr-3" style={{ fontSize: "20px"}}/> :
                                        <IoIosArrowDropdown className="pointer mr-3" style={{ fontSize: "20px"}}/>
                                    }
                                </div>
                            </div>

                            {selectErrorFieldNodes ? (
                                <div 
                                    className="panel-collapse p-4" 
                                    style={{
                                        borderRadius: "4px",
                                        transition: 'height 0.5s ease',
                                        overflow: 'hidden'
                                    }}
                                >
                                    <div className="panel-body" style={{paddingInline: 0}}>
                                        {listNodesWithErrorInFields.length > 0 && listNodesWithErrorInFields.map(errorNodes => {
                                            return (
                                                <button
                                                    key={errorNodes.id}
                                                    className="col mt-2" 
                                                    style={{
                                                        height: "30px",
                                                        backgroundColor: findNodeColor(errorNodes.type), 
                                                        borderRadius: "4px", 
                                                        color: "#fff",
                                                        border: "none",
                                                        fontWeight: 700,
                                                        cursor: "pointer"
                                                    }}
                                                    onClick={() => goToNode(errorNodes)}
                                                >
                                                    {errorNodes.data.title}
                                                </button>
                                            );
                                        })}
                                    </div>
                                </div>
                            ) : null}
                        </div>
                        <p style={{fontSize: 11, color: '#6C757D', fontWeight: 500, margin: 0}}>
                            Listado de nodos que existen en el flujo y tienen errores internos en sus 
                            campos correspondientes.
                        </p>
                    </div>
                    <div className="p-0 mt-3 panel-group collapsible" id="user-collapsible">
                        <div className="panel panel-default">
                            <div 
                                className="panel-heading p-2" 
                                style={{
                                    border: "1px solid #4F4F4F",
                                    borderRadius: "4px"
                                }}
                            >
                                <div className="panel-title d-flex justify-content-between align-items-center pointer" onClick={() => handleDropdown('selectErrorTriggers')}>
                                    <span>
                                        {`Disparadores duplicados ${triggersDuplicateCount > 1 ? `(${triggersDuplicateCount})` : ''}`}
                                    </span>
                                    {selectErrorTriggers ?
                                        <IoIosArrowDropup className="pointer mr-3" style={{ fontSize: "20px"}}/> :
                                        <IoIosArrowDropdown className="pointer mr-3" style={{ fontSize: "20px"}}/>
                                    }
                                </div>
                            </div>

                            {selectErrorTriggers ? (
                                <div 
                                    className="panel-collapse p-4" 
                                    style={{
                                        borderRadius: "4px",
                                        transition: 'height 0.5s ease',
                                        overflow: 'hidden'
                                    }}
                                >
                                    <div className="panel-body" style={{paddingInline: 0}}>
                                        {triggersDuplicateCount > 1 && (
                                            <p style={{fontSize: 12, color: '#6C757D', fontWeight: 500, margin: 0}}>
                                                Se han encontrado disparadores (triggers) duplicados en el flujo.
                                                Por favor entra a uno de los nodos y <strong>PRESIONA EL BOTÓN GUARDAR</strong> para
                                                que se realice un ajuste automático en los disparadores duplicados.
                                            </p>
                                        )}
                                    </div>
                                </div>
                            ) : null}
                        </div>
                    </div>
                    <div className="p-0 mt-3 panel-group collapsible" id="user-collapsible">
                        <div className="panel panel-default">
                            <div 
                                className="panel-heading p-2" 
                                style={{
                                    border: "1px solid #4F4F4F",
                                    borderRadius: "4px"
                                }}
                            >
                                <div className="panel-title d-flex justify-content-between align-items-center pointer" onClick={() => handleDropdown('selectErrorTransitions')}>
                                    <span>
                                        {`Transiciones duplicadas ${transitionsDuplicateCount > 1 ? `(${transitionsDuplicateCount})` : ''}`}
                                    </span>
                                    {selectErrorTransitions ?
                                        <IoIosArrowDropup className="pointer mr-3" style={{ fontSize: "20px"}}/> :
                                        <IoIosArrowDropdown className="pointer mr-3" style={{ fontSize: "20px"}}/>
                                    }
                                </div>
                            </div>

                            {selectErrorTransitions ? (
                                <div 
                                    className="panel-collapse p-4" 
                                    style={{
                                        borderRadius: "4px",
                                        transition: 'height 0.5s ease',
                                        overflow: 'hidden'
                                    }}
                                >
                                    <div className="panel-body" style={{paddingInline: 0}}>
                                        {transitionsDuplicateCount > 1 && (
                                            <p style={{fontSize: 12, color: '#6C757D', fontWeight: 500, margin: 0}}>
                                                Se han encontrado transiciones (transitions) duplicadas en el flujo.
                                                Por favor entra a uno de los nodos y <strong>PRESIONA EL BOTÓN GUARDAR</strong> para
                                                que se realice un ajuste automático en los transiciones duplicadas.
                                            </p>
                                        )}
                                    </div>
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ErrorMonitor;